<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5990)">
      <path
        d="M13.7461 8.3335H6.24609"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.24609 14.5835H13.7461"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7474 14.5835C13.9684 14.5835 14.1804 14.6713 14.3367 14.8276C14.4929 14.9839 14.5807 15.1958 14.5807 15.4168V18.3335C14.5807 18.665 14.449 18.983 14.2146 19.2174C13.9802 19.4518 13.6623 19.5835 13.3307 19.5835H6.66406C6.33254 19.5835 6.0146 19.4518 5.78018 19.2174C5.54576 18.983 5.41406 18.665 5.41406 18.3335V15.4168C5.41406 15.1958 5.50186 14.9839 5.65814 14.8276C5.81442 14.6713 6.02638 14.5835 6.2474 14.5835V8.3335C6.02638 8.3335 5.81442 8.2457 5.65814 8.08942C5.50186 7.93314 5.41406 7.72118 5.41406 7.50016V5.8335C5.41406 5.17045 5.67745 4.53457 6.1463 4.06573C6.61514 3.59689 7.25102 3.3335 7.91406 3.3335H12.0807C12.7438 3.3335 13.3797 3.59689 13.8485 4.06573C14.3173 4.53457 14.5807 5.17045 14.5807 5.8335V7.50016C14.5807 7.72118 14.4929 7.93314 14.3367 8.08942C14.1804 8.2457 13.9684 8.3335 13.7474 8.3335V14.5835Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0807 3.33341V1.25008C12.0807 1.02907 11.9929 0.817106 11.8367 0.660826C11.6804 0.504545 11.4684 0.416748 11.2474 0.416748H8.7474C8.52638 0.416748 8.31442 0.504545 8.15814 0.660826C8.00186 0.817106 7.91406 1.02907 7.91406 1.25008V3.33341"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5990">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
