<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8 7.20003L7.20003 16.8M7.19999 7.19999L16.8 16.8"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
