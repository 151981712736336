import { createStore } from "vuex";
import login from "./modules/login";
import accordionState from "./modules/accordion";
import basket from "./modules/basket";
// import schedule from "./modules/schedule";
const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    login,
    accordionState,
    basket,
    // schedule,
  },
  strict: debug,
});
