export default {
  /* _______________READ_______________ */
  CAN_READ_WALLET: "CAN_READ_WALLET",
  CAN_READ_PSELECTION: "CAN_READ_PSELECTION",
  CAN_READ_CATALOG_POINTS: "CAN_READ_CATALOG_POINTS",
  CAN_READ_CRM: "CAN_READ_CRM",
  CAN_READ_DASHBOARD: "CAN_READ_DASHBOARD",
  CAN_READ_PROFILE: "CAN_READ_PROFILE",
  CAN_READ_STAFF: "CAN_READ_STAFF",
  CAN_READ_SUPPORT: "CAN_READ_SUPPORT",
  CAN_READ_FAQ: "CAN_READ_FAQ",
  CAN_READ_LOYALTY: "CAN_READ_LOYALTY",
  CAN_READ_FEEDBACK: "CAN_READ_FEEDBACK",
  CAN_READ_EMPLOYEES: "CAN_READ_EMPLOYEES",
  CAN_READ_TASKS: "CAN_READ_TASKS",
  CAN_READ_NEWS: "CAN_READ_NEWS",
  CAN_READ_PROFILE_FULLNAME: "CAN_READ_PROFILE_FULLNAME",
  CAN_READ_PROFILE_PHONES: "CAN_READ_PROFILE_PHONES",
  CAN_READ_PROFILE_PASSWORD_CHANGE: "CAN_READ_PROFILE_PASSWORD_CHANGE",
  CAN_READ_FULLNAME: "CAN_READ_FULLNAME",
  CAN_READ_BACK_OFFICE: "CAN_READ_BACK_OFFICE",
  CAN_READ_CRM_CLIENTS: "CAN_READ_CRM_CLIENTS",
  CAN_READ_CRM_PUSH: "CAN_READ_CRM_PUSH",
  CAN_READ_CRM_TEMPLATE: "CAN_READ_CRM_TEMPLATE",
  CAN_READ_CRM_FEEDBACKS: "CAN_READ_CRM_FEEDBACKS",
  CAN_READ_SUBSCRIPTION: "CAN_READ_SUBSCRIPTION",
  CAN_READ_MAIN: "CAN_READ_MAIN",
  CAN_READ_BANKS: "CAN_READ_BANKS",
  CAN_READ_STATISTIC: "CAN_READ_STATISTIC",
  CAN_READ_NOTIFICATIONS: "CAN_READ_NOTIFICATIONS",
  CAN_READ_INTEGRATIONS: "CAN_READ_INTEGRATIONS",
  CAN_READ_ORDERS: "CAN_READ_ORDERS",

  /* _______________ADD_______________ */
  CAN_ADD_ROLE_OPERATOR: "CAN_ADD_ROLE_OPERATOR",
  CAN_ADD_ROLE_EMPLOYEE: "CAN_ADD_ROLE_EMPLOYEE",
  LOGGED_IN: "LOGGED_IN",
  /* _______________CREATE__EDIT_____________ */
  CAN_CREATE_TASKS: "CAN_CREATE_TASKS",
  CAN_EDIT_TASKS: "CAN_EDIT_TASKS",
  CAN_CREATE_NEWS: "CAN_CREATE_NEWS",
  CAN_EDIT_NEWS: "CAN_EDIT_NEWS",
  CAN_CREATE_FEEDBACK: "CAN_CREATE_FEEDBACK",
  CAN_EDIT_FEEDBACK: "CAN_EDIT_FEEDBACK",
  CAN_EDIT_PROFILE_BANK_DETAILS: "CAN_EDIT_PROFILE_BANK_DETAILS",
  CAN_EDIT_PROFILE_TIPS_CARD: "CAN_EDIT_PROFILE_TIPS_CARD",
  CAN_EDIT_PROFILE_PASSWORD_CHANGE: "CAN_EDIT_PROFILE_PASSWORD_CHANGE",
  CAN_EDIT_PROFILE_USER_DATA: "CAN_EDIT_PROFILE_USER_DATA",
  CAN_EDIT_PROFILE_FULLNAME: "CAN_EDIT_PROFILE_FULLNAME",
  CAN_EDIT_PROFILE_PHONES: "CAN_EDIT_PROFILE_PHONES",
  /* _______________SELECT_____________ */
  CAN_SELECT_POINT: "CAN_SELECT_POINT",
};
