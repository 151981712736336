<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9836 14.1667L17.6868 17.9167H2.31177L1.01489 14.1667H18.9836Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.4578 4.31252L14.5828 2.91669"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M17.2078 6.39589L18.3328 5.00006"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.19678 5.83857L8.7749 5.22919C10.2228 3.69794 12.3218 3.29169 13.452 4.31253C13.9572 4.76565 14.1864 5.42711 14.1603 6.15107"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.5828 1.25006V2.91673H16.2494"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.3328 3.33337V5.00004H19.9994"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.9421 14.1667C17.0476 13.9444 17.0951 13.6991 17.0802 13.4535C17.0653 13.2079 16.9885 12.9701 16.857 12.7622C16.7255 12.5542 16.5435 12.383 16.328 12.2643C16.1125 12.1456 15.8705 12.0834 15.6244 12.0834L16.6348 11.0209C18.0828 9.48963 18.3432 7.42192 17.2078 6.39587C16.0723 5.36983 13.9786 5.78129 12.5307 7.31254L12.2026 7.6615C11.65 7.08127 10.9848 6.61988 10.2478 6.30555C9.51076 5.99121 8.71735 5.83055 7.9161 5.83337C4.69214 5.83337 2.08276 8.38025 2.08276 11.5209C2.0826 12.445 2.31356 13.3546 2.75464 14.1667"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
