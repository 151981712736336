import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  // {
  //   path: "back-office",
  //   name: "back-office",
  //   component: load("backoffice/BackOffice"),
  //   meta: {
  //     requiresAuth: true,
  //     accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
  //     icon: "profile-settings",
  //   },
  //   children: [],
  // },
  {
    path: "merchants",
    name: "merchants",
    component: load("merchants/Merchants"),
    redirect: { name: "merchants:list" },
    meta: {
      requiresAuth: true,
      accessPermissions: [
        AccessPermissionsEnum.CAN_READ_BACK_OFFICE,
        AccessPermissionsEnum.CAN_READ_FAQ,
        AccessPermissionsEnum.CAN_READ_SUPPORT,
      ],
      icon: "agents",
    },
    children: [
      {
        path: "merchants-list",
        name: "merchants:list",
        component: load("merchants/merchantsList/MerchantsList"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
          icon: "agents",
        },
        children: [],
      },
      // {
      //   path: "edit-agent/:agentId",
      //   name: "edit-agent",
      //   component: load("agents/EditAgent"),
      //   meta: {
      //     requiresAuth: true,
      //     accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
      //     icon: "agents",
      //   },
      //   children: [],
      // },
    ],
  },
  {
    path: "edit-merchant/:merchantId",
    name: "edit-merchant",
    component: load("merchants/merchantsList/EditMerchant"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
      icon: "profile-settings",
    },
    children: [],
  },
  {
    path: "agents",
    name: "agents",
    component: load("agents/Agents"),
    redirect: { name: "agents:list" },
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
      icon: "agents",
    },
    children: [
      {
        path: "agents-list",
        name: "agents:list",
        component: load("agents/agentsList/AgentsList"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
          icon: "agents",
        },
        children: [],
      },
    ],
  },
  {
    path: "agents/edit-agent/:agentId",
    name: "edit-agent",
    component: load("agents/agentsList/EditAgent"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
      icon: "agents",
    },
    children: [],
  },
  {
    path: "agents/add-agent",
    name: "add-agent",
    component: load("agents/agentsList/EditAgent"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_BACK_OFFICE],
      icon: "agents",
    },
    children: [],
  },
];
