<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 16L28 4"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.4851 7.51462C22.3594 5.39314 19.5149 4.14676 16.5143 4.02207C13.5137 3.89737 10.5756 4.90344 8.2812 6.84124C5.98684 8.77904 4.50336 11.5074 4.12425 14.4866C3.74515 17.4658 4.49803 20.4787 6.23395 22.9294C7.96987 25.38 10.5624 27.0899 13.4986 27.7207C16.4348 28.3514 19.5008 27.8572 22.0901 26.3357C24.6793 24.8142 26.6032 22.3763 27.4811 19.5043C28.359 16.6322 28.1269 13.5353 26.8308 10.8262"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.2428 11.7572C19.2508 10.7653 17.9427 10.1523 16.5457 10.0248C15.1487 9.89734 13.7512 10.2634 12.596 11.0593C11.4409 11.8553 10.6012 13.0308 10.2228 14.3817C9.84448 15.7325 9.95142 17.1732 10.5251 18.4533C11.0987 19.7335 12.1028 20.7722 13.3627 21.3889C14.6227 22.0056 16.059 22.1613 17.4218 21.8289C18.7847 21.4965 19.988 20.6972 20.8227 19.5697C21.6573 18.4422 22.0705 17.0579 21.9904 15.6573"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
