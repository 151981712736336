import { computed } from "vue";
import { db, getUserState, storage } from "@/firebase";
import { ref as storageRef, getDownloadURL } from "firebase/storage";

import { httpsCallable } from "@firebase/functions";
import { functions } from "@/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
} from "firebase/firestore";
import store from "@/store";

const merchant = computed(() => store.getters.merchant);

/* ========================================= */
class User {
  constructor(user) {
    // this.email = user.email;
    this.name = `${user.firstname} ${user.lastname}`;
    this.phone = user.phones ? user.phones[0].number : "";
    this.id = user.id;
  }
}
/* ========================================= */
export async function getUserRole() {
  const user = await getUserState();
  const dbUser = (await getDoc(doc(db, "users", user.uid))).data();
  return dbUser?.role?.value;
}

export async function getUserRoleAndHash() {
  const user = await getUserState();
  const dbUser = (await getDoc(doc(db, "users", user.uid))).data();
  return { role: dbUser?.role?.value, hash: dbUser?.hash };
}
/**
 * This asynchronous function retrieves a user from a Firestore database.
 * It only returns the fields specified in the input parameter.
 *
 * @param {Array} fields - An array of strings representing the fields to be retrieved for the user.
 * @returns {Object|undefined} - An object containing the requested fields of the user, or undefined if no such fields exist.
 */
export async function getUser(fields) {
  const u = {};
  const { uid } = await getUserState();
  const usersRef = doc(db, "users", uid);
  const usersSnap = await getDoc(usersRef);
  if (!usersSnap.exists()) return;
  const dbUser = usersSnap.data();
  for (const key in dbUser) {
    const element = dbUser[key];
    if (fields.includes(key)) {
      u[key] = element;
    }
  }
  return Object.keys(u).length ? u : undefined;
}
export async function setUserField(field, value) {
  const { uid } = await getUserState();
  const usersRef = doc(db, "users", uid);
  const data = {
    [field]: value,
  };
  await setDoc(usersRef, data, { merge: true })
    .then(() => console.log("Document has been added successfully" /* , uid */))
    .catch((error) => console.log(error));
}

export const isEmailUnique = async (email) => {
  const isEmailUsedFn = httpsCallable(functions, "api-http-isEmailUsed");
  const { data: isEmailUnique } = await isEmailUsedFn({ email });
  console.log("isEmailUnique", isEmailUnique);
  return !isEmailUnique;
};

export async function isPhoneUnique(phone /* , pointId */) {
  let result = [];
  let arr = [];
  const { merchantId } = merchant.value;
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("merchantId", "==", merchantId));
  const qSnapshot = await getDocs(q);
  qSnapshot.forEach((doc) => {
    arr.push(doc.data());
  });
  arr.forEach((user) => {
    if ("point" in user) {
      if (Array.isArray(user.point) && user.point.length > 0) {
        user.point.forEach((p) => {
          result.push(...user.phones.map((ph) => ph.number));
        });
      } else if (typeof user.point === "object") {
        result.push(...user.phones.map((ph) => ph.number));
      }
    }
  });
  // console.log("result:isPhoneUnique", result);
  // console.log("phone:isPhoneUnique", phone);

  return !result.includes(phone);
}
export function firstIsUniqueSku(pointId, isEdit = false, productId = null) {
  console.log("firstIsUniqueSku pointId === >>> ", pointId);
  console.log("firstIsUniqueSku isEdit === >>> ", isEdit);
  console.log("firstIsUniqueSku productId === >>> ", productId);

  return async function (sku) {
    const { merchantId } = merchant.value;
    const pointsRef = doc(db, "merchants", merchantId, "points", pointId);
    const qSnapshot = await getDoc(pointsRef);

    const categories = qSnapshot.data()?.categories;
    console.log("||| categories === >>> ", categories);

    if (!categories) return true;
    let result;
    if (isEdit) {
      result = !categories.some((category) =>
        category.products?.some((item) => {
          console.log(sku, " = ", item.sku, item.sku === sku);
          console.log("isEdit", " = ", isEdit);
          console.log("merchantId", " = ", merchantId);
          console.log("item.sku", " = ", item.sku);
          console.log("productId", " = ", productId);
          console.log("item.id", " = ", item.id);
          console.log(
            "item.sku && item.sku === sku && item.id !== productId",
            " = ",
            (item.sku && item.sku) === sku && item.id !== productId
          );

          return (item.sku && item.sku) === sku && item.id !== productId;
        })
      );
    } else {
      result = !categories.some((category) =>
        category.products?.some((item) => {
          console.log(sku, " = ", item.sku, item.sku === sku);
          return item.sku && item.sku === sku;
        })
      );
    }
    return result;
  };
}
export async function getUsers(userIdList = []) {
  const { merchantId } = merchant.value;

  if (userIdList.length === 0) return [];
  const users = [];
  const collectionRef = collection(db, "users");
  const q = query(
    collectionRef,
    where("merchantId", "==", merchantId),
    where("id", "in", userIdList)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    users.push(new User(doc.data()));
  });
  return users;
}

export async function getUserImgUrl(userId) {
  const fileRef = storageRef(storage, `users/${userId}/img/avatar.jpg`);
  let imgUrl = "";
  try {
    imgUrl = await getDownloadURL(fileRef);
  } catch (error) {
    console.log("error", error);
  }
  return imgUrl;
}
