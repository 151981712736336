<template>
  <router-link
    :to="{ name: routeName }"
    :class="['item', { active: isActive }]"
    :title="$t(item.text)"
    @click="setItem(item.text)"
  >
    <div class="wrapper">
      <v-icon :name="item.icon" :stroke="isActive ? '#FFFFFF' : '#595A5B'" />
      <div v-show="isOpen" class="item__text">
        {{ $t(item.text) }}
      </div>
    </div>
    <!-- <v-icon
      v-if="isDropdown"
      name="arrow-left"
      class="arrow-left"
      :width="18"
      :height="18"
    /> -->
  </router-link>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import i18n from "@/i18n";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { locale } = i18n.global;

const route = useRoute();
// const router = useRouter();
const store = useStore();
// eslint-disable-next-line no-undef
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  // isDropdown: {
  //   type: Boolean,
  //   default: false,
  // },
  routeName: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: true,
  },
  paths: {
    type: Array,
    default: () => [],
  },
});
const isActive = computed(() => {
  if (route.fullPath === `/${locale}` && props.paths.length === 0) return true;
  return props.paths.some((path) => route.fullPath.includes(path));
});
function setItem(text) {
  store.commit("SET_MENUITEM", text);
}
onMounted(() => {
  // if (isActive.value) {
  //   store.commit("SET_MENUITEM", "Главнаяяяя");
  // }
});
</script>
<style lang="scss">
.item {
  position: relative;
  border: none;
  background-color: transparent;
  /* height: 44px; */
  width: 100%;
  color: #595a5b;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  .wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    overflow: hidden;
    align-items: center;
  }
  .item__text {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #595a5b;
    white-space: nowrap;
    flex: 1;
  }
  &:hover {
    background: #f5f6f8;
  }
  .open {
    transform: rotateZ(90deg);
  }
}
.item.active {
  background: $accent !important;
  .item__text {
    color: #f5f6f8 !important;
  }
  .item__text {
    color: #10b3c7;
  }
  .arrow-left {
    transform: rotateZ(90deg);
  }
}

a {
  display: "block";
  width: "100%";
}
.radio-enter-active,
.radio-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease-out, opacity 0.4s;
  overflow: hidden;
}

.radio-enter-from,
.radio-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
