<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56360)">
      <path
        d="M3.23214 5.94796L2.79464 6.67712C2.72952 6.78548 2.64061 6.8776 2.53462 6.94652C2.42864 7.01543 2.30836 7.05932 2.18291 7.07488C2.05745 7.09044 1.9301 7.07725 1.81049 7.03631C1.69089 6.99537 1.58217 6.92775 1.49255 6.83858L0.659222 6.00525C0.569053 5.91563 0.500583 5.80658 0.459052 5.68642C0.417522 5.56627 0.404032 5.43821 0.419614 5.31204C0.435197 5.18587 0.479439 5.06494 0.548955 4.9585C0.618471 4.85206 0.711417 4.76294 0.82068 4.69796L2.0811 3.95837"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.7633 5.94796L17.2008 6.67712C17.2659 6.78548 17.3548 6.8776 17.4608 6.94652C17.5668 7.01543 17.6871 7.05932 17.8125 7.07488C17.938 7.09044 18.0653 7.07725 18.185 7.03631C18.3046 6.99537 18.4133 6.92775 18.5029 6.83858L19.3362 6.00525C19.4264 5.91563 19.4949 5.80658 19.5364 5.68642C19.5779 5.56627 19.5914 5.43821 19.5758 5.31204C19.5602 5.18587 19.516 5.06494 19.4465 4.9585C19.377 4.85206 19.284 4.76294 19.1748 4.69796L17.9143 3.95837"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99797 5.41671C11.0553 5.41671 12.5084 5.58858 13.8938 5.9115L15.0969 6.19275C15.4337 6.27217 15.7841 6.27431 16.1218 6.199C16.4595 6.12369 16.7758 5.97289 17.0469 5.7579C17.318 5.54292 17.5369 5.26934 17.6872 4.95768C17.8375 4.64602 17.9152 4.30438 17.9146 3.95837C17.9146 3.08337 17.4563 2.26046 16.7063 1.93754C15.123 1.26567 12.9146 0.833374 9.99797 0.833374C7.0813 0.833374 4.87297 1.26567 3.28963 1.93754C2.53963 2.26046 2.0813 3.08337 2.0813 3.95837C2.0809 4.30382 2.1586 4.64489 2.30859 4.95608C2.45858 5.26726 2.67698 5.54051 2.94746 5.7554C3.21794 5.97028 3.5335 6.12123 3.87054 6.19697C4.20759 6.2727 4.55739 6.27126 4.8938 6.19275L6.09693 5.9115C7.48755 5.58858 8.94068 5.41671 9.99797 5.41671V5.41671Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6646 5.52087V8.57296C11.6648 8.88764 11.5936 9.19827 11.4565 9.48152C11.3194 9.76476 11.1199 10.0133 10.873 10.2084L9.99797 10.8959L9.12297 10.2084C8.87606 10.0133 8.67656 9.76476 8.53945 9.48152C8.40234 9.19827 8.33117 8.88764 8.3313 8.57296V5.52087"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99768 20.0001V5.41675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56360">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
