<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0003 6H7.00031C6.44802 6 6.00031 6.44772 6.00031 7V13C6.00031 13.5523 6.44802 14 7.00031 14H13.0003C13.5526 14 14.0003 13.5523 14.0003 13V7C14.0003 6.44772 13.5526 6 13.0003 6Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0003 18H7.00031C6.44802 18 6.00031 18.4477 6.00031 19V25C6.00031 25.5523 6.44802 26 7.00031 26H13.0003C13.5526 26 14.0003 25.5523 14.0003 25V19C14.0003 18.4477 13.5526 18 13.0003 18Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.0003 6H19.0003C18.448 6 18.0003 6.44772 18.0003 7V13C18.0003 13.5523 18.448 14 19.0003 14H25.0003C25.5526 14 26.0003 13.5523 26.0003 13V7C26.0003 6.44772 25.5526 6 25.0003 6Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0003 18V22"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.0003 26H22.0003V18"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.0003 20H26.0003"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.0003 24V26"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
