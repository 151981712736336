<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33203 2.9165H16.6654V15.8332C16.6654 16.2752 16.4898 16.6991 16.1772 17.0117C15.8646 17.3242 15.4407 17.4998 14.9987 17.4998H4.9987C4.55667 17.4998 4.13275 17.3242 3.82019 17.0117C3.50763 16.6991 3.33203 16.2752 3.33203 15.8332V2.9165Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.33203 5.8335H16.6654"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
