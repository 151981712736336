<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5871)">
      <path
        d="M7.66406 8.33341L6.20573 1.98966C6.18452 1.89782 6.1328 1.81589 6.05901 1.75724C5.98523 1.69859 5.89374 1.66669 5.79948 1.66675H3.33594"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.01953 8.3335L11.1654 1.13038C11.1912 1.04424 11.2443 0.968779 11.3165 0.91527C11.3888 0.861762 11.4765 0.833077 11.5664 0.833501H14.5872"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.75391 11.6653L10.4222 9.99695L12.0887 11.6634L10.4204 13.3318L8.75391 11.6653Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.50391 15.4167H10.0039V17.9167H7.50391V15.4167Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.34115 19.5834C6.03189 19.5838 5.73348 19.4696 5.50356 19.2627C5.27365 19.0559 5.12857 18.7712 5.09635 18.4636L3.71094 5.41675H15.4609L14.0755 18.4636C14.0433 18.7712 13.8982 19.0559 13.6683 19.2627C13.4384 19.4696 13.14 19.5838 12.8307 19.5834H6.34115Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.01953 8.3335H15.1549"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5871">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
