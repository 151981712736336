<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58617)">
      <path
        d="M14.1655 17.9167V16.6667"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.1655 10.4167V11.6667"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.2492 13.3334V12.9167C16.2492 12.224 15.6919 11.6667 14.9992 11.6667H13.3325C12.6398 11.6667 12.0825 12.224 12.0825 12.9167C12.0825 13.6095 12.6398 14.1667 13.3325 14.1667H14.9992C15.6919 14.1667 16.2492 14.724 16.2492 15.4167C16.2492 16.1095 15.6919 16.6667 14.9992 16.6667H13.3325C12.6398 16.6667 12.0825 16.1095 12.0825 15.4167V15.0001"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.1655 19.1666C16.927 19.1666 19.1655 16.928 19.1655 14.1666C19.1655 11.4051 16.927 9.16656 14.1655 9.16656C11.4041 9.16656 9.16553 11.4051 9.16553 14.1666C9.16553 16.928 11.4041 19.1666 14.1655 19.1666Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.7544 5.58848L16.6659 7.49994L18.5877 5.57806"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.6655 7.50004V5.41671C16.6655 2.88546 14.6134 0.833374 12.0822 0.833374H11.6655"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83252 10.8334C8.59394 10.8334 10.8325 8.5948 10.8325 5.83337C10.8325 3.07195 8.59394 0.833374 5.83252 0.833374C3.0711 0.833374 0.83252 3.07195 0.83252 5.83337C0.83252 8.5948 3.0711 10.8334 5.83252 10.8334Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83252 2.08313V5.83313L8.16585 8.16646"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.24919 14.4166L3.33252 12.4999L1.41064 14.4218"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.33252 12.4999V14.5833C3.33252 17.1145 5.3846 19.1666 7.91585 19.1666H8.33252"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58617">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
