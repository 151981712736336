<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8319 10.0887V8.08868C16.548 7.79797 17.1409 7.26744 17.509 6.58787C17.8771 5.9083 17.9977 5.12191 17.85 4.36327C17.7024 3.60462 17.2957 2.92087 16.6995 2.42899C16.1033 1.93712 15.3548 1.66769 14.5819 1.66681H5.41523C4.64235 1.66769 3.8938 1.93712 3.29764 2.42899C2.70148 2.92087 2.29477 3.60462 2.14711 4.36327C1.99944 5.12191 2.11999 5.9083 2.48812 6.58787C2.85626 7.26744 3.44911 7.79797 4.16523 8.08868V10.0887"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.56169 11.0417C5.54379 10.3628 4.34772 10.0004 3.12419 10.0001H2.08252V17.5001C2.08252 17.8316 2.21422 18.1495 2.44864 18.3839C2.68306 18.6184 3.001 18.7501 3.33252 18.7501H16.6659C16.9974 18.7501 17.3153 18.6184 17.5497 18.3839C17.7842 18.1495 17.9159 17.8316 17.9159 17.5001V10.0001H16.8742C15.6507 10.0004 14.4546 10.3628 13.4367 11.0417C12.4189 11.7209 11.2228 12.0834 9.99919 12.0834C8.77561 12.0834 7.57945 11.7209 6.56169 11.0417Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
