<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.58398 14.1667C10.2743 14.1667 10.834 13.607 10.834 12.9167C10.834 12.2263 10.2743 11.6667 9.58398 11.6667C8.89363 11.6667 8.33398 12.2263 8.33398 12.9167C8.33398 13.607 8.89363 14.1667 9.58398 14.1667Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33398 8.75006C9.02434 8.75006 9.58398 8.19042 9.58398 7.50006C9.58398 6.80971 9.02434 6.25006 8.33398 6.25006C7.64363 6.25006 7.08398 6.80971 7.08398 7.50006C7.08398 8.19042 7.64363 8.75006 8.33398 8.75006Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.00024 13.3334C5.00024 12.6704 5.26364 12.0345 5.73248 11.5657C6.20132 11.0968 6.8372 10.8334 7.50024 10.8334"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.86523 15.9896L6.53711 14.8542"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.65112 10.1459L11.9949 9.01044"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.823 11.4948L14.0365 12.7188"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.7505 13.3334C18.7505 6.66152 13.339 1.25006 6.66716 1.25006L1.75049 18.2501L18.7505 13.3334Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.93774 3.76569C11.5055 4.03131 15.969 8.49485 16.2346 14.0626"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
