<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.0005C17.5 22.0005 22 17.5005 22 12.0005C22 6.50049 17.5 2.00049 12 2.00049C6.5 2.00049 2 6.50049 2 12.0005C2 17.5005 6.5 22.0005 12 22.0005Z"
      stroke="#F73A53"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.16992 14.8304L14.8299 9.17041"
      stroke="#F73A53"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.8299 14.8304L9.16992 9.17041"
      stroke="#F73A53"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
