import { useAuthService } from "@/modules/auth/auth.service";
import { PathComponentEnum } from "@/router/constants";
import i18n from "@/i18n";

export function accessGuardMiddleware(to, from, next) {
  const { checkHasPermission } = useAuthService();
  const { accessPermissions } = to.meta;

  if (!accessPermissions) {
    next();

    return;
  }

  if (checkHasPermission(accessPermissions)) {
    next();

    return;
  }

  next({
    name: PathComponentEnum.accessError,
    params: { locale: i18n.global.locale },
  });
}
