<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25171 18.7498H18.335"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.1578 16.6666H5.41821"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.3349 2.08309V6.24976C18.3349 6.47077 18.2471 6.68273 18.0908 6.83901C17.9345 6.99529 17.7226 7.08309 17.5015 7.08309H14.6474L11.6682 8.74976V7.08309H11.2515C11.0305 7.08309 10.8186 6.99529 10.6623 6.83901C10.506 6.68273 10.4182 6.47077 10.4182 6.24976V2.08309C10.4182 1.86208 10.506 1.65011 10.6623 1.49383C10.8186 1.33755 11.0305 1.24976 11.2515 1.24976H17.5015C17.7226 1.24976 17.9345 1.33755 18.0908 1.49383C18.2471 1.65011 18.3349 1.86208 18.3349 2.08309Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.33521 18.7499V14.5833C3.33521 14.0307 3.5547 13.5008 3.9454 13.1101C4.3361 12.7194 4.866 12.4999 5.41854 12.4999H8.75187C9.30441 12.4999 9.83431 12.7194 10.225 13.1101C10.6157 13.5008 10.8352 14.0307 10.8352 14.5833"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.08504 10.833C8.23564 10.833 9.16838 9.90031 9.16838 8.74972C9.16838 7.59912 8.23564 6.66638 7.08504 6.66638C5.93445 6.66638 5.00171 7.59912 5.00171 8.74972C5.00171 9.90031 5.93445 10.833 7.08504 10.833Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.2309 18.7498L16.4184 14.5831H9.75171L8.56421 18.7498"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
