<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50016 34.6666V15.6C6.50016 13.1731 6.50016 11.9596 6.97247 11.0327C7.38792 10.2173 8.05083 9.55438 8.8662 9.13893C9.79315 8.66663 11.0066 8.66663 13.4335 8.66663H38.5668C40.9937 8.66663 42.2072 8.66663 43.1341 9.13893C43.9495 9.55438 44.6124 10.2173 45.0279 11.0327C45.5002 11.9596 45.5002 13.1731 45.5002 15.6V34.6666H33.9361C33.4062 34.6666 33.1412 34.6666 32.8918 34.7265C32.6707 34.7796 32.4594 34.8671 32.2655 34.9859C32.0469 35.1199 31.8595 35.3073 31.4848 35.682L31.3489 35.8179C30.9741 36.1927 30.7868 36.38 30.5681 36.514C30.3743 36.6328 30.1629 36.7204 29.9418 36.7734C29.6925 36.8333 29.4275 36.8333 28.8976 36.8333H23.1028C22.5728 36.8333 22.3078 36.8333 22.0585 36.7734C21.8374 36.7204 21.6261 36.6328 21.4322 36.514C21.2136 36.38 21.0262 36.1927 20.6515 35.8179L20.5155 35.682C20.1408 35.3073 19.9534 35.1199 19.7348 34.9859C19.5409 34.8671 19.3296 34.7796 19.1085 34.7265C18.8591 34.6666 18.5942 34.6666 18.0642 34.6666H6.50016ZM6.50016 34.6666C5.30355 34.6666 4.3335 35.6367 4.3335 36.8333V37.5555C4.3335 38.8988 4.3335 39.5705 4.48115 40.1215C4.88184 41.6169 6.04988 42.7849 7.54528 43.1856C8.09634 43.3333 8.76798 43.3333 10.1113 43.3333H41.8891C43.2323 43.3333 43.904 43.3333 44.455 43.1856C45.9504 42.7849 47.1185 41.6169 47.5192 40.1215C47.6668 39.5705 47.6668 38.8988 47.6668 37.5555C47.6668 36.8839 47.6668 36.548 47.593 36.2725C47.3927 35.5248 46.8086 34.9408 46.0609 34.7405C45.7854 34.6666 45.4496 34.6666 44.7779 34.6666H43.3335"
      stroke="#00CDD7"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
