import { ref, onMounted } from "vue";
import CreateNetwork from "@/views/points/modals/CreateNetwork.vue";
import { useModal } from "vue-final-modal";
import { getMerchantSlugNameV } from "@/services/merchant.service";
import { locale } from "moment";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
// import { getPointsName } from "@/services/points.service";

export function useCreateNetwork() {
  // const points = ref([]);
  const merchantSlugName = ref("");
  merchantSlugName.value = store.getters.merchantSlugName; //await getMerchantSlugNameV();

  const createNetworkModal = useModal({
    component: CreateNetwork,
    attrs: {
      title: "Create Network",
      clickToClose: false,
      escToClose: false,
      onConfirm() {
        createNetworkModal.close();
        router.push({
          name: "new-point",
          params: { locale: i18n.global.locale },
        });
      },
      onClose() {
        createNetworkModal.close();
      },
    },
  });
  // createNetworkModal.open();
  async function goToNewPoint() {
    merchantSlugName.value = store.getters.merchantSlugName; //await getMerchantSlugNameV();
    if (!merchantSlugName.value) {
      createNetworkModal.open();
    } else {
      router.push({
        name: "new-point",
        params: { locale: i18n.global.locale },
      });
    }
  }
  // onMounted(() => {});
  return { goToNewPoint };
}
