<template>
  <svg
    class="data__face-neutral"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15.136H16M15 9.13599H15.01M9 9.13599H9.01M22 12.136C22 17.6588 17.5228 22.136 12 22.136C6.47715 22.136 2 17.6588 2 12.136C2 6.61314 6.47715 2.13599 12 2.13599C17.5228 2.13599 22 6.61314 22 12.136ZM15.5 9.13599C15.5 9.41213 15.2761 9.63599 15 9.63599C14.7239 9.63599 14.5 9.41213 14.5 9.13599C14.5 8.85984 14.7239 8.63599 15 8.63599C15.2761 8.63599 15.5 8.85984 15.5 9.13599ZM9.5 9.13599C9.5 9.41213 9.27614 9.63599 9 9.63599C8.72386 9.63599 8.5 9.41213 8.5 9.13599C8.5 8.85984 8.72386 8.63599 9 8.63599C9.27614 8.63599 9.5 8.85984 9.5 9.13599Z"
      stroke="#FFD233"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
