<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.74951 1.66687H16.2495V4.16687H3.74951V1.66687Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.2495 4.16687L17.4995 8.33354V18.3335H2.49951V8.33354L3.74951 4.16687"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0828 10.8335C13.2334 10.8335 14.1662 9.9008 14.1662 8.7502C14.1662 7.59961 13.2334 6.66687 12.0828 6.66687C10.9323 6.66687 9.99951 7.59961 9.99951 8.7502C9.99951 9.9008 10.9323 10.8335 12.0828 10.8335Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M12.4991 8.3335H11.6658V9.16683H12.4991V8.3335Z"
      fill="#0E0E22"
    />

    <path
      d="M7.91634 12.9168H7.08301V13.7501H7.91634V12.9168Z"
      fill="#0E0E22"
    />

    <path
      d="M4.99953 13.3334C4.9992 13.202 5.0248 13.0718 5.07485 12.9502C5.12489 12.8287 5.19841 12.7182 5.29119 12.6251L6.78599 11.1303C6.87933 11.0369 6.99016 10.9629 7.11215 10.9123C7.23413 10.8618 7.36488 10.8358 7.49692 10.8358C7.62896 10.8358 7.75971 10.8618 7.8817 10.9123C8.00368 10.9629 8.11451 11.0369 8.20786 11.1303L9.70265 12.6251C9.79604 12.7185 9.87012 12.8293 9.92066 12.9513C9.9712 13.0733 9.99722 13.204 9.99722 13.336C9.99722 13.4681 9.9712 13.5988 9.92066 13.7208C9.87012 13.8428 9.79604 13.9536 9.70265 14.047L8.20786 15.5418C8.11451 15.6352 8.00368 15.7092 7.8817 15.7598C7.75971 15.8103 7.62896 15.8363 7.49692 15.8363C7.36488 15.8363 7.23413 15.8103 7.11215 15.7598C6.99016 15.7092 6.87933 15.6352 6.78599 15.5418L5.29119 14.047C5.19802 13.9531 5.12429 13.8417 5.07424 13.7193C5.02419 13.5968 4.9988 13.4657 4.99953 13.3334V13.3334Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
