<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3334 15.9999C29.3334 8.63612 23.3639 2.66659 16.0001 2.66659C8.63628 2.66659 2.66675 8.63612 2.66675 15.9999C2.66675 23.3637 8.63628 29.3333 16.0001 29.3333C23.3639 29.3333 29.3334 23.3637 29.3334 15.9999Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.6667 16H12.6667"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3335 20L11.3335 16L15.3335 12"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
