<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83203 18.7502H14.1654"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99609 18.7502V12.0835"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.16406 6.25024C4.16406 7.79734 4.77864 9.28107 5.87261 10.375C6.96657 11.469 8.4503 12.0836 9.9974 12.0836C11.5445 12.0836 13.0282 11.469 14.1222 10.375C15.2161 9.28107 15.8307 7.79734 15.8307 6.25024V1.25024H4.16406V6.25024Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.16406 3.75024H15.8307"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
