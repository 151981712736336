<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3332 1.66638H1.6665V13.333H18.3332V1.66638Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.3335 9.83309L7.50016 6.66642L10.4168 9.58309L15.0002 4.99976"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.6665 4.99976H14.9998V8.33309"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10 13.3331V19.1665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10 15.8329L13.1667 18.9996"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0002 15.8329L6.8335 18.9996"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
