<template>
  <div class="default-layout">
    <div class="main">
      <side-bar-left />
      <div 
        :class="[
          'js-container container',
          { subscription: route.fullPath.includes('sign/subscription') },
        ]" 
        @scroll="onScroll">
        <nav-bar-top :class="{ onScroll: !topOfPage }" />
        <div 
          class="content"
          :class="{ 'content--orders': route.fullPath.includes('crm/orders') && !route.fullPath.includes('create') }">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBarTop from "@/components/app/nav/NavBarTop.vue";
import SideBarLeft from "@/components/app/sidebar/SideBarLeft.vue";
import { useRoute } from "vue-router";
import { ref } from "vue";

const topOfPage = ref(true);

const route = useRoute();
function onScroll(e) {
  const { scrollTop } = e.target;
  if (scrollTop > 5) {
    if (topOfPage.value) topOfPage.value = false;
  } else {
    if (!topOfPage.value) topOfPage.value = true;
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  display: flex;
  flex: 1;
  overflow: auto;
  max-height: 100vh;
}

.default-layout {
  display: flex;
  background: #f5f6f8;
}
.container {
  position: relative;
  background-color: #f5f6f8;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100vh;
  min-height: 100%;
  /* padding: 0 32px 27.5px 32px; */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.content {
  position: relative;
  min-width: 1024px;
  padding: 16px;
  margin: 16px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04);
  /* flex: 1 1 auto; */
  /* height: 100%; */
  flex: 1;
  // flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Ширина всего элемента навигации */
  }
  &--orders {
    @media screen and (max-width: 1280px) {
      min-width: unset;
    }
  }
}
.navbar {
  &.onScroll {
    background: #fff !important;
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 7px -6px #222;
    transition: 0.7s;
  }
}

.my-scroll-up {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 75vw;
  top: 50vh;
  z-index: 99;
  height: 55px;
  width: 55px;
  background-color: rgba(0, 0, 0, 0.658);
  border: rgba(0, 0, 0, 0.658);
  border-radius: 100px;
  color: white;
  cursor: pointer;
}

.scroll-animation::after {
  content: "";
  height: 25px;
  width: 10px;
  display: block;
  margin: 0 auto;
  border: 2px solid white;
  border-radius: 10px;
}

.scroll-animation::before {
  content: "";
  display: block;
  height: 10px;
  width: 0;
  left: 49%;
  animation: 2s top infinite;
  border: 1px solid white;
  border-radius: 10px;
  position: absolute;
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.subscription {
  background-image: url(@/assets/img/vase.svg);
  background-repeat: no-repeat;
  background-color: #f5f6f8;
  background-position: calc(100%) 0%;
}
</style>
