<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.69547 27.6372L14.4909 18.8418C15.0189 18.3138 15.2829 18.0498 15.5873 17.9509C15.8551 17.8639 16.1436 17.8639 16.4114 17.9509C16.7158 18.0498 16.9798 18.3138 17.5078 18.8418L26.2446 27.5786M18.666 20L22.4909 16.1752C23.0189 15.6471 23.2829 15.3831 23.5873 15.2842C23.8551 15.1972 24.1436 15.1972 24.4114 15.2842C24.7158 15.3831 24.9798 15.6471 25.5078 16.1752L29.3327 20M13.3327 12C13.3327 13.4728 12.1388 14.6667 10.666 14.6667C9.19326 14.6667 7.99935 13.4728 7.99935 12C7.99935 10.5272 9.19326 9.33333 10.666 9.33333C12.1388 9.33333 13.3327 10.5272 13.3327 12ZM9.06602 28H22.9327C25.1729 28 26.293 28 27.1486 27.564C27.9013 27.1805 28.5132 26.5686 28.8967 25.816C29.3327 24.9603 29.3327 23.8402 29.3327 21.6V10.4C29.3327 8.15979 29.3327 7.03969 28.8967 6.18404C28.5132 5.43139 27.9013 4.81947 27.1486 4.43597C26.293 4 25.1729 4 22.9327 4H9.06602C6.82581 4 5.7057 4 4.85005 4.43597C4.09741 4.81947 3.48548 5.43139 3.10199 6.18404C2.66602 7.03969 2.66602 8.15979 2.66602 10.4V21.6C2.66602 23.8402 2.66602 24.9603 3.10199 25.816C3.48548 26.5686 4.09741 27.1805 4.85005 27.564C5.7057 28 6.82581 28 9.06602 28Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
