<template>
  <div :class="['v-icon-inside', 'prevent-select', { buttonStyle }]">
    <component
      :is="`icon-${name}`"
      :width="width"
      :height="height"
      :stroke="eventColor"
      :stroke-opacity="strokeOpacity"
      :fill="fill"
      :class="{ pointer, rotate180 }"
      @mouseover="hovered = true"
      @mouseout="hovered = false"
    />
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
const hovered = ref(false);
// eslint-disable-next-line no-undef
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  width: { type: [String, Number], default: "24" },
  height: { type: [String, Number], default: "24" },
  stroke: { type: String, default: "#9b9b9b" },
  hoverColor: { type: String, default: "" },
  actionColor: { type: String, default: "#0e0e22" },
  strokeOpacity: { type: String, default: "" },
  fill: { type: String, default: "none" },
  pointer: {
    type: Boolean,
    default: false,
  },
  buttonStyle: {
    type: Boolean,
    default: false,
  },
  // disabled: {
  //   type: Boolean,
  //   default: false,
  // },
  rotate180: {
    type: Boolean,
    default: false,
  },
});
const eventColor = computed(() =>
  hovered.value && props.hoverColor !== "" ? props.hoverColor : props.stroke
);
const activateColor = computed(() => {
  return props.actionColor;
});
</script>

<style lang="scss" scoped>
.v-icon-inside {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  user-select: none;
  outline: none;
  &:active {
    svg {
      stroke: v-bind(activateColor);
    }
  }
}
.buttonStyle {
  &:active {
    transform: scale(0.95);
  }
}
.pointer {
  cursor: pointer !important;
}
.prevent-select {
  user-select: none;
}
</style>
