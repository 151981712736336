<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5996)">
      <path
        d="M11.6667 0.416504H8.33333C7.8731 0.416504 7.5 0.7896 7.5 1.24984V1.6665C7.5 2.12674 7.8731 2.49984 8.33333 2.49984H11.6667C12.1269 2.49984 12.5 2.12674 12.5 1.6665V1.24984C12.5 0.7896 12.1269 0.416504 11.6667 0.416504Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83203 11.2498H10.4154V15.8331H5.83203"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6654 2.49976V3.74976C11.6654 4.02022 11.7531 4.28339 11.9154 4.49976L13.6654 6.83309C13.9899 7.26583 14.1654 7.79216 14.1654 8.33309V18.3331C14.1654 18.6646 14.0337 18.9825 13.7992 19.217C13.5648 19.4514 13.2469 19.5831 12.9154 19.5831H7.08203C6.75051 19.5831 6.43257 19.4514 6.19815 19.217C5.96373 18.9825 5.83203 18.6646 5.83203 18.3331V8.33309C5.83203 7.79216 6.00748 7.26583 6.33203 6.83309L8.08203 4.49976C8.24431 4.28339 8.33203 4.02022 8.33203 3.74976V2.49976"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5996">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
