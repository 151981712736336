<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56137)">
      <path
        d="M8.74859 2.91687L1.1288 10.5367C1.03541 10.63 0.961328 10.7408 0.910786 10.8628C0.860243 10.9848 0.834229 11.1156 0.834229 11.2476C0.834229 11.3796 0.860243 11.5104 0.910786 11.6324C0.961328 11.7544 1.03541 11.8652 1.1288 11.9585L8.03504 18.87C8.12839 18.9634 8.23922 19.0375 8.36121 19.088C8.48319 19.1385 8.61394 19.1646 8.74598 19.1646C8.87802 19.1646 9.00877 19.1385 9.13076 19.088C9.25274 19.0375 9.36357 18.9634 9.45692 18.87L17.0819 11.2502L16.4517 10.62C15.7888 9.9567 14.8895 9.58388 13.9517 9.58354C13.0138 9.58354 12.1143 9.21095 11.4511 8.54773C10.7878 7.88452 10.4153 6.98501 10.4153 6.04708C10.4149 5.10928 10.0421 4.21003 9.37879 3.54708L8.74859 2.91687Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.4259 9.23975L17.9884 3.67725C18.2095 3.45624 18.3336 3.15648 18.3336 2.84392C18.3336 2.53136 18.2095 2.2316 17.9884 2.01058C17.7674 1.78957 17.4677 1.66541 17.1551 1.66541C16.8425 1.66541 16.5428 1.78957 16.3218 2.01058L10.7593 7.57308"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.4985 4.16687L18.1599 4.82833C18.3467 5.01502 18.4948 5.23669 18.5959 5.48066C18.697 5.72463 18.749 5.98612 18.749 6.2502C18.749 6.51429 18.697 6.77578 18.5959 7.01975C18.4948 7.26372 18.3467 7.48538 18.1599 7.67208L15.7537 10.0783"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8316 2.50016L15.1702 1.83871C14.9835 1.65193 14.7618 1.50377 14.5178 1.40269C14.2739 1.3016 14.0124 1.24957 13.7483 1.24957C13.4842 1.24957 13.2227 1.3016 12.9787 1.40269C12.7348 1.50377 12.5131 1.65193 12.3264 1.83871L9.92017 4.24496"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56137">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
