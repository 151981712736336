<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6118)">
      <path
        d="M5.20703 14.1667H14.7904"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.5 8.75L5.91667 19.5833H14.0833L15.5 8.75"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 6.66675H16.25V8.75008H3.75V6.66675Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.4154 6.66675V6.25008C15.4154 5.36603 15.0642 4.51818 14.4391 3.89306C13.8139 3.26794 12.9661 2.91675 12.082 2.91675H7.91536C7.03131 2.91675 6.18346 3.26794 5.55834 3.89306C4.93322 4.51818 4.58203 5.36603 4.58203 6.25008V6.66675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.9076 0.072998L9.05859 14.1667"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6118">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
