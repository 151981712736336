<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6001)">
      <path
        d="M10.0039 15.4165V19.5832"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.25391 19.583H14.1706"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.62759 9.31755C4.53611 10.051 4.59569 10.7954 4.80268 11.5049C5.00967 12.2145 5.35968 12.8741 5.83117 13.4433C6.30266 14.0126 6.88565 14.4792 7.54427 14.8147C8.20288 15.1501 8.92318 15.3472 9.66082 15.3939C10.3985 15.4405 11.1379 15.3357 11.8335 15.0859C12.5291 14.836 13.1662 14.4465 13.7056 13.9412C14.2451 13.4359 14.6754 12.8256 14.9701 12.1477C15.2648 11.4699 15.4176 10.739 15.4193 9.99984C15.4196 9.77176 15.4057 9.54388 15.3776 9.31755L14.5182 2.9165H5.48697L4.62759 9.31755Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.15234 5.4165H14.8503"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.6315 2.9165C13.2148 1.479 11.7461 0.416504 10.0013 0.416504C8.25651 0.416504 6.78776 1.479 6.37109 2.9165"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6001">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
