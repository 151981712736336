<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56375)">
      <path
        d="M10.0001 19.5834C10.1711 19.5829 10.3381 19.5323 10.4807 19.4379C10.6232 19.3435 10.735 19.2094 10.8022 19.0521L14.849 9.58337H5.15112L9.198 19.0521C9.26517 19.2094 9.37693 19.3435 9.51947 19.4379C9.66202 19.5323 9.8291 19.5829 10.0001 19.5834Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.10962 2.95836C7.1994 2.25443 7.54274 1.60737 8.07533 1.1384C8.60792 0.669423 9.29321 0.410706 10.0028 0.410706C10.7125 0.410706 11.3978 0.669423 11.9304 1.1384C12.463 1.60737 12.8063 2.25443 12.8961 2.95836"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.15129 9.58341C4.25545 8.89591 3.75024 7.84383 3.75024 6.66675C3.75024 4.59383 5.52108 2.91675 7.70858 2.91675C9.89608 2.91675 11.6669 4.59383 11.6669 6.66675C11.6669 7.84383 11.094 8.89591 10.1982 9.58341"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0002 3.60929C10.678 3.15519 11.4761 2.91397 12.2919 2.91659C14.4794 2.91659 16.2502 4.59367 16.2502 6.66659C16.2502 7.84367 15.745 8.89575 14.8492 9.58325"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56375">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
