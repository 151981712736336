<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56281)">
      <path
        d="M18.6558 7.08333C17.1411 7.05918 15.6953 6.44672 14.6241 5.37553C13.5529 4.30434 12.9404 2.85845 12.9163 1.34375"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />

      <path
        d="M15.8332 2.91663H14.9999V3.74996H15.8332V2.91663Z"
        fill="#0E0E22"
      />

      <path
        d="M9.16634 14.1667H10.4163C12.6265 14.1667 14.7461 13.2887 16.3089 11.7259C17.8717 10.1631 18.7497 8.04347 18.7497 5.83333V2.08333C18.7497 1.86232 18.6619 1.65036 18.5056 1.49408C18.3493 1.3378 18.1374 1.25 17.9163 1.25H14.1663C11.9562 1.25 9.83659 2.12797 8.27378 3.69078C6.71098 5.25358 5.83301 7.3732 5.83301 9.58333V10.8333H4.99967C3.0153 10.8333 1.56217 11.6146 0.833008 13.3333L5.83301 14.1667L6.66634 19.1667C8.38509 18.4375 9.16634 16.9844 9.16634 15V14.1667Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.94788 5.41663H6.24996C5.41663 5.41663 4.81246 5.51558 4.276 5.72392C4.78642 3.99996 6.026 2.91663 7.91663 2.91663H9.16663"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5833 13.0521V13.75C14.5833 14.5834 14.4843 15.1875 14.276 15.724C16 15.2136 17.0833 13.974 17.0833 12.0834V10.8334"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56281">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
