<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.51562 5.83325L5.89583 18.3333H14.1042L15.4844 5.83325"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.68359 3.74984L5.83464 1.6665H14.168L15.3138 3.74984"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.75 3.74976H16.25V5.83309H3.75V3.74976Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0716 9.58319H12.7904C12.4396 9.19045 12.0098 8.87622 11.5292 8.66108C11.0485 8.44593 10.5279 8.33472 10.0013 8.33472C9.47471 8.33472 8.95406 8.44593 8.47343 8.66108C7.9928 8.87622 7.56302 9.19045 7.21224 9.58319H4.92578"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.48047 14.583H7.20963C7.56042 14.9757 7.99019 15.29 8.47083 15.5051C8.95146 15.7203 9.47211 15.8315 9.9987 15.8315C10.5253 15.8315 11.0459 15.7203 11.5266 15.5051C12.0072 15.29 12.437 14.9757 12.7878 14.583H14.5169"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
