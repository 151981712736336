<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56270)">
      <path
        d="M14.811 2.27101C15.1991 1.89067 15.7217 1.67886 16.2651 1.68161C16.8084 1.68435 17.3288 1.90143 17.713 2.28567C18.0973 2.66991 18.3144 3.19027 18.3171 3.73366C18.3199 4.27706 18.108 4.79958 17.7277 5.18768L5.18603 17.7293C4.79793 18.1097 4.27541 18.3215 3.73201 18.3188C3.18862 18.316 2.66827 18.0989 2.28402 17.7147C1.89978 17.3305 1.6827 16.8101 1.67996 16.2667C1.67721 15.7233 1.88903 15.2008 2.26937 14.8127L14.811 2.27101Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.582 2.50016L14.2174 2.13558C13.9373 1.85463 13.6045 1.63173 13.2381 1.47964C12.8716 1.32756 12.4788 1.24927 12.082 1.24927C11.6853 1.24927 11.2924 1.32756 10.926 1.47964C10.5596 1.63173 10.2267 1.85463 9.94661 2.13558L2.13411 9.94808C1.85317 10.2282 1.63027 10.561 1.47818 10.9275C1.32609 11.2939 1.2478 11.6867 1.2478 12.0835C1.2478 12.4802 1.32609 12.8731 1.47818 13.2395C1.63027 13.6059 1.85317 13.9388 2.13411 14.2189L2.49869 14.5835"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.4985 5.41687L17.8631 5.78145C18.1433 6.0615 18.3655 6.39399 18.5171 6.75995C18.6688 7.1259 18.7468 7.51814 18.7468 7.91427C18.7468 8.31039 18.6688 8.70263 18.5171 9.06858C18.3655 9.43454 18.1433 9.76703 17.8631 10.0471L10.0506 17.8648C9.77058 18.1449 9.43808 18.3672 9.07213 18.5188C8.70617 18.6704 8.31393 18.7485 7.91781 18.7485C7.52168 18.7485 7.12944 18.6704 6.76349 18.5188C6.39753 18.3672 6.06504 18.1449 5.78499 17.8648L5.42041 17.5002"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.1656 5.83356C13.7236 5.83356 13.2997 6.00915 12.9871 6.32171C12.6745 6.63427 12.4989 7.0582 12.4989 7.50022C12.4989 7.94225 12.3233 8.36617 12.0108 8.67873C11.6982 8.99129 11.2743 9.16689 10.8323 9.16689C10.3902 9.16689 9.96632 9.34248 9.65376 9.65505C9.3412 9.96761 9.16561 10.3915 9.16561 10.8336C9.16561 11.2756 8.99001 11.6995 8.67745 12.0121C8.36489 12.3246 7.94097 12.5002 7.49894 12.5002C7.05691 12.5002 6.63299 12.6758 6.32043 12.9884C6.00787 13.3009 5.83228 13.7249 5.83228 14.1669"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56270">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
