<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56245)">
      <path
        d="M17.0837 17.9167C17.7467 17.9167 18.3826 17.6533 18.8514 17.1845C19.3203 16.7157 19.5837 16.0798 19.5837 15.4167C19.5837 14.9011 19.3805 14.4428 19.1566 14.0209C17.8962 11.6407 15.0003 8.75006 10.0003 8.75006C5.00033 8.75006 2.10449 11.6407 0.844076 14.0209C0.620117 14.4428 0.416992 14.9011 0.416992 15.4167C0.416992 16.0798 0.680384 16.7157 1.14923 17.1845C1.61807 17.6533 2.25395 17.9167 2.91699 17.9167H17.0837Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.16715 6.25008C8.94623 6.25 8.72928 6.19138 8.53839 6.08017C8.3475 5.96897 8.18949 5.80916 8.08045 5.61703C7.97142 5.42489 7.91525 5.20729 7.91767 4.98638C7.92009 4.76548 7.98102 4.54916 8.09424 4.35946L8.5734 3.55737C8.68663 3.36767 8.74755 3.15135 8.74997 2.93045C8.75239 2.70954 8.69622 2.49194 8.58719 2.2998C8.47815 2.10767 8.32014 1.94786 8.12925 1.83666C7.93836 1.72545 7.72141 1.66683 7.50049 1.66675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.5002 6.25008C12.2792 6.25 12.0623 6.19138 11.8714 6.08017C11.6805 5.96897 11.5225 5.80916 11.4135 5.61703C11.3044 5.42489 11.2483 5.20729 11.2507 4.98638C11.2531 4.76548 11.314 4.54916 11.4272 4.35946L11.9064 3.55737C12.0196 3.36767 12.0806 3.15135 12.083 2.93045C12.0854 2.70954 12.0292 2.49194 11.9202 2.2998C11.8112 2.10767 11.6532 1.94786 11.4623 1.83666C11.2714 1.72545 11.0544 1.66683 10.8335 1.66675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0005 8.75006V12.0834"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.167 9.526V12.5"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.8335 9.526V12.5"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56245">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
