<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.2812 12.4848L10.9903 19.0541C10.7943 19.1093 10.6199 19.2231 10.4904 19.3801C10.3608 19.5372 10.2822 19.73 10.2652 19.9329C10.2482 20.1358 10.2935 20.339 10.3951 20.5154C10.4966 20.6919 10.6496 20.8331 10.8336 20.9203L21.535 25.9893C21.7437 26.0882 21.9118 26.2562 22.0106 26.465L27.0797 37.1663C27.1669 37.3503 27.3081 37.5033 27.4845 37.6049C27.661 37.7065 27.8642 37.7518 28.0671 37.7347C28.2699 37.7177 28.4628 37.6392 28.6198 37.5096C28.7769 37.38 28.8906 37.2056 28.9459 37.0097L35.5151 13.7187C35.5634 13.5476 35.5652 13.3668 35.5203 13.1948C35.4754 13.0228 35.3855 12.8659 35.2598 12.7402C35.1341 12.6145 34.9772 12.5245 34.8052 12.4797C34.6332 12.4348 34.4523 12.4366 34.2812 12.4848Z"
      stroke="#010101"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.8574 26.1412L27.5143 20.4844"
      stroke="#010101"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
