<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0835 5.83356H7.91687V18.3336H12.0835V5.83356Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.83354 2.08356H1.66687V18.3336H5.83354V2.08356Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.3335 9.58356H14.1669V18.3336H18.3335V9.58356Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
