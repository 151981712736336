<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2000"
    height="2000"
    viewBox="0 0 2000 2000"
  >
    <defs>
      <clipPath id="clip-path-dot-color">
        <path
          d="M 101 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,132,628)"
        />
        <rect
          x="101"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,132,690)"
        />
        <rect
          x="101"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,132,752)"
        />
        <rect
          x="101"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,132,814)"
        />
        <path
          d="M 101 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,132,938)"
        />
        <path
          d="M 101 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,132,1000)"
        />
        <path
          d="M 101 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,132,1124)"
        />
        <rect
          x="101"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,132,1186)"
        />
        <path
          d="M 163 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,194,628)"
        />
        <rect
          x="163"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,194,752)"
        />
        <rect
          x="163"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,194,814)"
        />
        <path
          d="M 163 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,194,1186)"
        />
        <path
          d="M 225 659v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,256,690)"
        />
        <path
          d="M 225 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,256,814)"
        />
        <path
          d="M 225 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,256,938)"
        />
        <rect
          x="225"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,256,1000)"
        />
        <path
          d="M 225 1279v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,256,1310)"
        />
        <rect
          x="287"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,318,690)"
        />
        <path
          d="M 287 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,318,752)"
        />
        <rect
          x="287"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,318,938)"
        />
        <rect
          x="287"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,318,1000)"
        />
        <rect
          x="287"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,318,1062)"
        />
        <path
          d="M 287 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,318,1186)"
        />
        <rect
          x="287"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,318,1248)"
        />
        <path
          d="M 349 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,380,814)"
        />
        <path
          d="M 349 845v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,380,876)"
        />
        <rect
          x="349"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,380,1000)"
        />
        <rect
          x="349"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,380,1062)"
        />
        <rect
          x="349"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,380,1124)"
        />
        <rect
          x="349"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,380,1186)"
        />
        <rect
          x="349"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,380,1248)"
        />
        <rect
          x="349"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,380,1310)"
        />
        <rect
          x="349"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,380,1372)"
        />
        <path
          d="M 411 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,442,752)"
        />
        <path
          d="M 411 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,442,814)"
        />
        <path
          d="M 411 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,442,938)"
        />
        <rect
          x="411"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,442,1000)"
        />
        <rect
          x="411"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,442,1062)"
        />
        <rect
          x="411"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,442,1124)"
        />
        <rect
          x="411"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,442,1186)"
        />
        <rect
          x="411"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,442,1248)"
        />
        <rect
          x="411"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,442,1372)"
        />
        <path
          d="M 473 597v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,628)"
        />
        <path
          d="M 473 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,752)"
        />
        <path
          d="M 473 845v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,876)"
        />
        <rect
          x="473"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,504,1000)"
        />
        <path
          d="M 473 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,1124)"
        />
        <rect
          x="473"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,504,1248)"
        />
        <rect
          x="473"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,504,1372)"
        />
        <path
          d="M 535 783v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,566,814)"
        />
        <path
          d="M 535 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,566,938)"
        />
        <path
          d="M 535 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,566,1000)"
        />
        <rect
          x="535"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,566,1248)"
        />
        <rect
          x="535"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,566,1310)"
        />
        <rect
          x="535"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,566,1372)"
        />
        <path
          d="M 597 163v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,628,194)"
        />
        <path
          d="M 597 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,628,504)"
        />
        <rect
          x="597"
          y="535"
          width="62"
          height="62"
          transform="rotate(0,628,566)"
        />
        <path
          d="M 597 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,628,628)"
        />
        <path
          d="M 597 845v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,628,876)"
        />
        <path
          d="M 597 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,628,938)"
        />
        <path
          d="M 597 1031v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,628,1062)"
        />
        <path
          d="M 597 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,628,1186)"
        />
        <rect
          x="597"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,628,1372)"
        />
        <path
          d="M 597 1403v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,628,1434)"
        />
        <path
          d="M 597 1775v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,628,1806)"
        />
        <path
          d="M 597 1837v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,628,1868)"
        />
        <rect
          x="659"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,690,194)"
        />
        <path
          d="M 659 535v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,690,566)"
        />
        <path
          d="M 659 659v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,690,690)"
        />
        <rect
          x="659"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,690,752)"
        />
        <rect
          x="659"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,690,814)"
        />
        <rect
          x="659"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,690,876)"
        />
        <path
          d="M 659 969v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,690,1000)"
        />
        <rect
          x="659"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,690,1186)"
        />
        <path
          d="M 659 1217v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,690,1248)"
        />
        <path
          d="M 659 1527v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,690,1558)"
        />
        <path
          d="M 659 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,690,1620)"
        />
        <path
          d="M 659 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,690,1744)"
        />
        <path
          d="M 659 1775v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,690,1806)"
        />
        <path
          d="M 721 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,752,132)"
        />
        <path
          d="M 721 163v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,752,194)"
        />
        <path
          d="M 721 287v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,752,318)"
        />
        <rect
          x="721"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,752,380)"
        />
        <path
          d="M 721 473v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,752,504)"
        />
        <rect
          x="721"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,752,814)"
        />
        <rect
          x="721"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,752,876)"
        />
        <path
          d="M 721 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,752,1124)"
        />
        <path
          d="M 721 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,752,1186)"
        />
        <path
          d="M 721 1403v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,752,1434)"
        />
        <rect
          x="721"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,752,1496)"
        />
        <rect
          x="721"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,752,1558)"
        />
        <path
          d="M 721 1651v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,752,1682)"
        />
        <path
          d="M 721 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,752,1744)"
        />
        <rect
          x="783"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,814,132)"
        />
        <path
          d="M 783 225v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,814,256)"
        />
        <rect
          x="783"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,814,318)"
        />
        <path
          d="M 783 349v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,814,380)"
        />
        <path
          d="M 783 535v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,814,566)"
        />
        <path
          d="M 783 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,814,628)"
        />
        <rect
          x="783"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,814,876)"
        />
        <rect
          x="783"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,814,938)"
        />
        <path
          d="M 783 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,814,1000)"
        />
        <path
          d="M 783 1217v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,814,1248)"
        />
        <rect
          x="783"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,814,1496)"
        />
        <path
          d="M 783 1527v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,814,1558)"
        />
        <rect
          x="783"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,814,1682)"
        />
        <path
          d="M 845 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,876,132)"
        />
        <path
          d="M 845 411v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,876,442)"
        />
        <path
          d="M 845 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,876,504)"
        />
        <path
          d="M 845 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,876,814)"
        />
        <rect
          x="845"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,876,876)"
        />
        <path
          d="M 845 1031v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,876,1062)"
        />
        <path
          d="M 845 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,876,1124)"
        />
        <path
          d="M 845 1403v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,876,1434)"
        />
        <rect
          x="845"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,876,1496)"
        />
        <rect
          x="845"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,876,1682)"
        />
        <path
          d="M 845 1775v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,876,1806)"
        />
        <path
          d="M 907 163v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,938,194)"
        />
        <path
          d="M 907 287v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,938,318)"
        />
        <rect
          x="907"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,938,442)"
        />
        <path
          d="M 907 535v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,938,566)"
        />
        <rect
          x="907"
          y="597"
          width="62"
          height="62"
          transform="rotate(0,938,628)"
        />
        <rect
          x="907"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,938,876)"
        />
        <path
          d="M 907 1279v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,938,1310)"
        />
        <rect
          x="907"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,938,1372)"
        />
        <rect
          x="907"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,938,1496)"
        />
        <path
          d="M 907 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,938,1620)"
        />
        <path
          d="M 907 1651v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,938,1682)"
        />
        <path
          d="M 907 1837v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,938,1868)"
        />
        <rect
          x="969"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1000,318)"
        />
        <rect
          x="969"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,1000,380)"
        />
        <rect
          x="969"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,1000,442)"
        />
        <path
          d="M 969 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1000,504)"
        />
        <rect
          x="969"
          y="597"
          width="62"
          height="62"
          transform="rotate(0,1000,628)"
        />
        <rect
          x="969"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1000,690)"
        />
        <path
          d="M 969 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1000,814)"
        />
        <rect
          x="969"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1000,876)"
        />
        <rect
          x="969"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,1000,938)"
        />
        <rect
          x="969"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,1000,1000)"
        />
        <rect
          x="969"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,1000,1062)"
        />
        <rect
          x="969"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,1000,1124)"
        />
        <rect
          x="969"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,1000,1186)"
        />
        <path
          d="M 969 1217v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1000,1248)"
        />
        <rect
          x="969"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1000,1372)"
        />
        <rect
          x="969"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1000,1434)"
        />
        <rect
          x="969"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,1000,1496)"
        />
        <path
          d="M 969 1527v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1000,1558)"
        />
        <path
          d="M 1031 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1062,132)"
        />
        <rect
          x="1031"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,1062,194)"
        />
        <rect
          x="1031"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,1062,256)"
        />
        <rect
          x="1031"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1062,318)"
        />
        <rect
          x="1031"
          y="597"
          width="62"
          height="62"
          transform="rotate(0,1062,628)"
        />
        <rect
          x="1031"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1062,690)"
        />
        <rect
          x="1031"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,1062,752)"
        />
        <rect
          x="1031"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1062,814)"
        />
        <rect
          x="1031"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1062,876)"
        />
        <rect
          x="1031"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,1062,938)"
        />
        <path
          d="M 1031 1279v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1062,1310)"
        />
        <path
          d="M 1031 1465v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1062,1496)"
        />
        <path
          d="M 1031 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1062,1620)"
        />
        <rect
          x="1031"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,1062,1682)"
        />
        <rect
          x="1031"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,1062,1744)"
        />
        <path
          d="M 1093 163v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,194)"
        />
        <rect
          x="1093"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1124,318)"
        />
        <path
          d="M 1093 473v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,504)"
        />
        <rect
          x="1093"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1124,690)"
        />
        <rect
          x="1093"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1124,876)"
        />
        <path
          d="M 1093 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,938)"
        />
        <path
          d="M 1093 1031v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,1062)"
        />
        <path
          d="M 1093 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1124,1186)"
        />
        <path
          d="M 1093 1217v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,1248)"
        />
        <path
          d="M 1093 1341v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1124,1372)"
        />
        <rect
          x="1093"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,1124,1682)"
        />
        <rect
          x="1093"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,1124,1744)"
        />
        <path
          d="M 1093 1837v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1124,1868)"
        />
        <path
          d="M 1155 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,132)"
        />
        <path
          d="M 1155 225v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,256)"
        />
        <path
          d="M 1155 287v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1186,318)"
        />
        <path
          d="M 1155 411v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1186,442)"
        />
        <path
          d="M 1155 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,628)"
        />
        <rect
          x="1155"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1186,690)"
        />
        <path
          d="M 1155 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1186,752)"
        />
        <path
          d="M 1155 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,1000)"
        />
        <path
          d="M 1155 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,1124)"
        />
        <path
          d="M 1155 1279v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,1310)"
        />
        <path
          d="M 1155 1465v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1186,1496)"
        />
        <rect
          x="1155"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1186,1558)"
        />
        <rect
          x="1155"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1186,1620)"
        />
        <rect
          x="1155"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,1186,1682)"
        />
        <path
          d="M 1155 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1186,1744)"
        />
        <rect
          x="1155"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,1186,1868)"
        />
        <rect
          x="1217"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1248,132)"
        />
        <path
          d="M 1217 163v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1248,194)"
        />
        <path
          d="M 1217 349v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1248,380)"
        />
        <path
          d="M 1217 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1248,504)"
        />
        <rect
          x="1217"
          y="535"
          width="62"
          height="62"
          transform="rotate(0,1248,566)"
        />
        <rect
          x="1217"
          y="597"
          width="62"
          height="62"
          transform="rotate(0,1248,628)"
        />
        <path
          d="M 1217 659v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1248,690)"
        />
        <path
          d="M 1217 845v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1248,876)"
        />
        <path
          d="M 1217 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1248,1000)"
        />
        <rect
          x="1217"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,1248,1124)"
        />
        <rect
          x="1217"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,1248,1186)"
        />
        <rect
          x="1217"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,1248,1248)"
        />
        <rect
          x="1217"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1248,1310)"
        />
        <rect
          x="1217"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1248,1372)"
        />
        <rect
          x="1217"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,1248,1868)"
        />
        <rect
          x="1279"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1310,132)"
        />
        <path
          d="M 1279 411v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1310,442)"
        />
        <path
          d="M 1279 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1310,628)"
        />
        <path
          d="M 1279 783v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1310,814)"
        />
        <path
          d="M 1279 1031v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1310,1062)"
        />
        <rect
          x="1279"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,1310,1124)"
        />
        <rect
          x="1279"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1310,1372)"
        />
        <rect
          x="1279"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1310,1434)"
        />
        <rect
          x="1279"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,1310,1496)"
        />
        <rect
          x="1279"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1310,1558)"
        />
        <path
          d="M 1279 1651v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1310,1682)"
        />
        <path
          d="M 1279 1775v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1310,1806)"
        />
        <rect
          x="1279"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,1310,1868)"
        />
        <path
          d="M 1341 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,132)"
        />
        <path
          d="M 1341 225v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,256)"
        />
        <path
          d="M 1341 349v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,380)"
        />
        <path
          d="M 1341 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1372,504)"
        />
        <path
          d="M 1341 535v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,566)"
        />
        <path
          d="M 1341 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1372,752)"
        />
        <path
          d="M 1341 845v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1372,876)"
        />
        <path
          d="M 1341 969v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,1000)"
        />
        <rect
          x="1341"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,1372,1124)"
        />
        <rect
          x="1341"
          y="1155"
          width="62"
          height="62"
          transform="rotate(0,1372,1186)"
        />
        <rect
          x="1341"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1372,1372)"
        />
        <rect
          x="1341"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1372,1434)"
        />
        <rect
          x="1341"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,1372,1496)"
        />
        <rect
          x="1341"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1372,1558)"
        />
        <rect
          x="1341"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1372,1620)"
        />
        <path
          d="M 1341 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1372,1744)"
        />
        <path
          d="M 1341 1837v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1372,1868)"
        />
        <path
          d="M 1403 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1434,628)"
        />
        <path
          d="M 1403 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1434,752)"
        />
        <rect
          x="1403"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1434,876)"
        />
        <rect
          x="1403"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,1434,938)"
        />
        <path
          d="M 1403 1031v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1434,1062)"
        />
        <path
          d="M 1403 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1434,1186)"
        />
        <path
          d="M 1403 1279v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1434,1310)"
        />
        <rect
          x="1403"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1434,1372)"
        />
        <rect
          x="1403"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1434,1620)"
        />
        <rect
          x="1403"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,1434,1682)"
        />
        <path
          d="M 1403 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1434,1744)"
        />
        <path
          d="M 1465 597v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1496,628)"
        />
        <path
          d="M 1465 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1496,814)"
        />
        <rect
          x="1465"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1496,876)"
        />
        <path
          d="M 1465 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1496,938)"
        />
        <rect
          x="1465"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,1496,1062)"
        />
        <path
          d="M 1465 1217v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1496,1248)"
        />
        <rect
          x="1465"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1496,1310)"
        />
        <rect
          x="1465"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1496,1372)"
        />
        <path
          d="M 1465 1465v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1496,1496)"
        />
        <rect
          x="1465"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1496,1620)"
        />
        <path
          d="M 1465 1775v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1496,1806)"
        />
        <path
          d="M 1527 659v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1558,690)"
        />
        <rect
          x="1527"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,1558,752)"
        />
        <rect
          x="1527"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1558,814)"
        />
        <rect
          x="1527"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1558,876)"
        />
        <rect
          x="1527"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,1558,1062)"
        />
        <rect
          x="1527"
          y="1093"
          width="62"
          height="62"
          transform="rotate(0,1558,1124)"
        />
        <rect
          x="1527"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1558,1310)"
        />
        <rect
          x="1527"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1558,1372)"
        />
        <rect
          x="1527"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1558,1620)"
        />
        <path
          d="M 1527 1651v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1558,1682)"
        />
        <rect
          x="1527"
          y="1775"
          width="62"
          height="62"
          transform="rotate(0,1558,1806)"
        />
        <rect
          x="1589"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1620,690)"
        />
        <rect
          x="1589"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,1620,752)"
        />
        <rect
          x="1589"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1620,814)"
        />
        <rect
          x="1589"
          y="845"
          width="62"
          height="62"
          transform="rotate(0,1620,876)"
        />
        <rect
          x="1589"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,1620,938)"
        />
        <rect
          x="1589"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,1620,1000)"
        />
        <rect
          x="1589"
          y="1031"
          width="62"
          height="62"
          transform="rotate(0,1620,1062)"
        />
        <path
          d="M 1589 1093v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1620,1124)"
        />
        <rect
          x="1589"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1620,1310)"
        />
        <rect
          x="1589"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1620,1372)"
        />
        <rect
          x="1589"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1620,1434)"
        />
        <rect
          x="1589"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,1620,1496)"
        />
        <rect
          x="1589"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1620,1558)"
        />
        <path
          d="M 1589 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1620,1620)"
        />
        <rect
          x="1589"
          y="1775"
          width="62"
          height="62"
          transform="rotate(0,1620,1806)"
        />
        <rect
          x="1651"
          y="659"
          width="62"
          height="62"
          transform="rotate(0,1682,690)"
        />
        <rect
          x="1651"
          y="721"
          width="62"
          height="62"
          transform="rotate(0,1682,752)"
        />
        <rect
          x="1651"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1682,814)"
        />
        <rect
          x="1651"
          y="907"
          width="62"
          height="62"
          transform="rotate(0,1682,938)"
        />
        <path
          d="M 1651 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1682,1000)"
        />
        <rect
          x="1651"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1682,1310)"
        />
        <rect
          x="1651"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1682,1372)"
        />
        <rect
          x="1651"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1682,1558)"
        />
        <path
          d="M 1651 1651v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1682,1682)"
        />
        <rect
          x="1651"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,1682,1744)"
        />
        <rect
          x="1651"
          y="1775"
          width="62"
          height="62"
          transform="rotate(0,1682,1806)"
        />
        <rect
          x="1713"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1744,814)"
        />
        <path
          d="M 1713 1031v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1744,1062)"
        />
        <path
          d="M 1713 1155v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1744,1186)"
        />
        <rect
          x="1713"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,1744,1248)"
        />
        <rect
          x="1713"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1744,1310)"
        />
        <rect
          x="1713"
          y="1341"
          width="62"
          height="62"
          transform="rotate(0,1744,1372)"
        />
        <rect
          x="1713"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1744,1434)"
        />
        <rect
          x="1713"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,1744,1496)"
        />
        <rect
          x="1713"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,1744,1558)"
        />
        <rect
          x="1713"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1744,1620)"
        />
        <path
          d="M 1713 1651v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1744,1682)"
        />
        <path
          d="M 1713 1775v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1744,1806)"
        />
        <path
          d="M 1775 597v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1806,628)"
        />
        <rect
          x="1775"
          y="783"
          width="62"
          height="62"
          transform="rotate(0,1806,814)"
        />
        <path
          d="M 1775 907v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1806,938)"
        />
        <rect
          x="1775"
          y="969"
          width="62"
          height="62"
          transform="rotate(0,1806,1000)"
        />
        <path
          d="M 1775 1093v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1806,1124)"
        />
        <rect
          x="1775"
          y="1217"
          width="62"
          height="62"
          transform="rotate(0,1806,1248)"
        />
        <rect
          x="1775"
          y="1279"
          width="62"
          height="62"
          transform="rotate(0,1806,1310)"
        />
        <rect
          x="1775"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1806,1434)"
        />
        <rect
          x="1775"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,1806,1620)"
        />
        <path
          d="M 1775 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1806,1744)"
        />
        <path
          d="M 1775 1837v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1806,1868)"
        />
        <path
          d="M 1837 721v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1868,752)"
        />
        <path
          d="M 1837 783v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,814)"
        />
        <path
          d="M 1837 969v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1000)"
        />
        <path
          d="M 1837 1155v 31a 31 31, 0, 0, 0, 31 31h 31v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1186)"
        />
        <path
          d="M 1837 1279v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1310)"
        />
        <rect
          x="1837"
          y="1403"
          width="62"
          height="62"
          transform="rotate(0,1868,1434)"
        />
        <path
          d="M 1837 1465v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1496)"
        />
        <path
          d="M 1837 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1620)"
        />
        <path
          d="M 1837 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,1744)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-square-color-0-0">
        <path
          d="M 101 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,132,132)"
        />
        <rect
          x="101"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,132,194)"
        />
        <rect
          x="101"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,132,256)"
        />
        <rect
          x="101"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,132,318)"
        />
        <rect
          x="101"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,132,380)"
        />
        <rect
          x="101"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,132,442)"
        />
        <rect
          x="101"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,132,504)"
        />
        <rect
          x="163"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,194,132)"
        />
        <rect
          x="163"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,194,504)"
        />
        <rect
          x="225"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,256,132)"
        />
        <rect
          x="225"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,256,504)"
        />
        <rect
          x="287"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,318,132)"
        />
        <rect
          x="287"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,318,504)"
        />
        <rect
          x="349"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,380,132)"
        />
        <rect
          x="349"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,380,504)"
        />
        <rect
          x="411"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,442,132)"
        />
        <rect
          x="411"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,442,504)"
        />
        <rect
          x="473"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,504,132)"
        />
        <rect
          x="473"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,504,194)"
        />
        <rect
          x="473"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,504,256)"
        />
        <rect
          x="473"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,504,318)"
        />
        <rect
          x="473"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,504,380)"
        />
        <rect
          x="473"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,504,442)"
        />
        <path
          d="M 473 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,504)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-dot-color-0-0">
        <path
          d="M 225 225v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,256,256)"
        />
        <rect
          x="225"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,256,318)"
        />
        <rect
          x="225"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,256,380)"
        />
        <rect
          x="287"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,318,256)"
        />
        <rect
          x="287"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,318,318)"
        />
        <rect
          x="287"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,318,380)"
        />
        <rect
          x="349"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,380,256)"
        />
        <rect
          x="349"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,380,318)"
        />
        <path
          d="M 349 349v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,380,380)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-square-color-1-0">
        <path
          d="M 1465 101v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1496,132)"
        />
        <rect
          x="1465"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,1496,194)"
        />
        <rect
          x="1465"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,1496,256)"
        />
        <rect
          x="1465"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1496,318)"
        />
        <rect
          x="1465"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,1496,380)"
        />
        <rect
          x="1465"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,1496,442)"
        />
        <rect
          x="1465"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1496,504)"
        />
        <rect
          x="1527"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1558,132)"
        />
        <rect
          x="1527"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1558,504)"
        />
        <rect
          x="1589"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1620,132)"
        />
        <rect
          x="1589"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1620,504)"
        />
        <rect
          x="1651"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1682,132)"
        />
        <rect
          x="1651"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1682,504)"
        />
        <rect
          x="1713"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1744,132)"
        />
        <rect
          x="1713"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1744,504)"
        />
        <rect
          x="1775"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1806,132)"
        />
        <rect
          x="1775"
          y="473"
          width="62"
          height="62"
          transform="rotate(0,1806,504)"
        />
        <rect
          x="1837"
          y="101"
          width="62"
          height="62"
          transform="rotate(0,1868,132)"
        />
        <rect
          x="1837"
          y="163"
          width="62"
          height="62"
          transform="rotate(0,1868,194)"
        />
        <rect
          x="1837"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,1868,256)"
        />
        <rect
          x="1837"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1868,318)"
        />
        <rect
          x="1837"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,1868,380)"
        />
        <rect
          x="1837"
          y="411"
          width="62"
          height="62"
          transform="rotate(0,1868,442)"
        />
        <path
          d="M 1837 473v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1868,504)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-dot-color-1-0">
        <path
          d="M 1589 225v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,1620,256)"
        />
        <rect
          x="1589"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1620,318)"
        />
        <rect
          x="1589"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,1620,380)"
        />
        <rect
          x="1651"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,1682,256)"
        />
        <rect
          x="1651"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1682,318)"
        />
        <rect
          x="1651"
          y="349"
          width="62"
          height="62"
          transform="rotate(0,1682,380)"
        />
        <rect
          x="1713"
          y="225"
          width="62"
          height="62"
          transform="rotate(0,1744,256)"
        />
        <rect
          x="1713"
          y="287"
          width="62"
          height="62"
          transform="rotate(0,1744,318)"
        />
        <path
          d="M 1713 349v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,1744,380)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-square-color-0-1">
        <path
          d="M 101 1465v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,132,1496)"
        />
        <rect
          x="101"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,132,1558)"
        />
        <rect
          x="101"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,132,1620)"
        />
        <rect
          x="101"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,132,1682)"
        />
        <rect
          x="101"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,132,1744)"
        />
        <rect
          x="101"
          y="1775"
          width="62"
          height="62"
          transform="rotate(0,132,1806)"
        />
        <rect
          x="101"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,132,1868)"
        />
        <rect
          x="163"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,194,1496)"
        />
        <rect
          x="163"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,194,1868)"
        />
        <rect
          x="225"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,256,1496)"
        />
        <rect
          x="225"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,256,1868)"
        />
        <rect
          x="287"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,318,1496)"
        />
        <rect
          x="287"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,318,1868)"
        />
        <rect
          x="349"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,380,1496)"
        />
        <rect
          x="349"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,380,1868)"
        />
        <rect
          x="411"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,442,1496)"
        />
        <rect
          x="411"
          y="1837"
          width="62"
          height="62"
          transform="rotate(0,442,1868)"
        />
        <rect
          x="473"
          y="1465"
          width="62"
          height="62"
          transform="rotate(0,504,1496)"
        />
        <rect
          x="473"
          y="1527"
          width="62"
          height="62"
          transform="rotate(0,504,1558)"
        />
        <rect
          x="473"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,504,1620)"
        />
        <rect
          x="473"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,504,1682)"
        />
        <rect
          x="473"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,504,1744)"
        />
        <rect
          x="473"
          y="1775"
          width="62"
          height="62"
          transform="rotate(0,504,1806)"
        />
        <path
          d="M 473 1837v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,504,1868)"
        />
      </clipPath>
      <clipPath id="clip-path-corners-dot-color-0-1">
        <path
          d="M 225 1589v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(-90,256,1620)"
        />
        <rect
          x="225"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,256,1682)"
        />
        <rect
          x="225"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,256,1744)"
        />
        <rect
          x="287"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,318,1620)"
        />
        <rect
          x="287"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,318,1682)"
        />
        <rect
          x="287"
          y="1713"
          width="62"
          height="62"
          transform="rotate(0,318,1744)"
        />
        <rect
          x="349"
          y="1589"
          width="62"
          height="62"
          transform="rotate(0,380,1620)"
        />
        <rect
          x="349"
          y="1651"
          width="62"
          height="62"
          transform="rotate(0,380,1682)"
        />
        <path
          d="M 349 1713v 62h 62v -31a 31 31, 0, 0, 0, -31 -31"
          transform="rotate(90,380,1744)"
        />
      </clipPath>
    </defs>
    <rect
      x="0"
      y="0"
      height="2000"
      width="2000"
      clip-path="url('#clip-path-background-color')"
      fill="#ffffff"
    />
    <rect
      x="101"
      y="101"
      height="1798"
      width="1798"
      clip-path="url('#clip-path-dot-color')"
      fill="#000000"
    />
    <rect
      x="101"
      y="101"
      height="434"
      width="434"
      clip-path="url('#clip-path-corners-square-color-0-0')"
      fill="#000000"
    />
    <rect
      x="225"
      y="225"
      height="186"
      width="186"
      clip-path="url('#clip-path-corners-dot-color-0-0')"
      fill="#000000"
    />
    <rect
      x="1465"
      y="101"
      height="434"
      width="434"
      clip-path="url('#clip-path-corners-square-color-1-0')"
      fill="#000000"
    />
    <rect
      x="1589"
      y="225"
      height="186"
      width="186"
      clip-path="url('#clip-path-corners-dot-color-1-0')"
      fill="#000000"
    />
    <rect
      x="101"
      y="1465"
      height="434"
      width="434"
      clip-path="url('#clip-path-corners-square-color-0-1')"
      fill="#000000"
    />
    <rect
      x="225"
      y="1589"
      height="186"
      width="186"
      clip-path="url('#clip-path-corners-dot-color-0-1')"
      fill="#000000"
    />
  </svg>
</template>
