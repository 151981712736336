<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.54663 11.0259L5.04142 17.8593C5.12337 18.2304 5.3297 18.5624 5.62621 18.8001C5.92271 19.0379 6.29157 19.1672 6.67163 19.1666H13.3279C13.7079 19.1672 14.0768 19.0379 14.3733 18.8001C14.6698 18.5624 14.8761 18.2304 14.9581 17.8593L16.4841 10.9009"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.5883 17.3749L6.54663 13.0156"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.4114 17.3749L13.453 13.0156"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99976 17.4998V12.9165"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.4168 11.25C16.5679 11.25 17.5002 10.1302 17.5002 8.75C17.5002 7.14062 16.3804 5.83333 15.0002 5.83333V5C15.0002 4.00544 14.6051 3.05161 13.9018 2.34835C13.1986 1.64509 12.2447 1.25 11.2502 1.25H10.8335C10.8335 2.40104 9.91162 3.33333 8.62516 3.33333H8.15641C6.60954 3.33333 5.41683 4.45312 5.41683 5.83333C3.60954 5.83333 2.0835 7.14062 2.0835 8.75C2.0835 9.41304 2.34689 10.0489 2.81573 10.5178C3.28457 10.9866 3.92045 11.25 4.5835 11.25H15.4168Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
