<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 15.9999V19.9999M18.25 13.4999H14.8C13.1198 13.4999 12.2798 13.4999 11.638 13.8269C11.0735 14.1145 10.6146 14.5735 10.327 15.1379C10 15.7797 10 16.6198 10 18.2999L10 19.4999C10 20.4318 10 20.8977 10.1522 21.2653C10.3552 21.7553 10.7446 22.1447 11.2346 22.3477C11.6022 22.4999 12.0681 22.4999 13 22.4999V26.7499C13 26.9821 13 27.0982 13.0096 27.1959C13.1032 28.1455 13.8544 28.8968 14.804 28.9903C14.9017 28.9999 15.0178 28.9999 15.25 28.9999C15.4822 28.9999 15.5983 28.9999 15.696 28.9903C16.6456 28.8968 17.3968 28.1455 17.4904 27.1959C17.5 27.0982 17.5 26.9821 17.5 26.7499V22.4999H18.25C20.0164 22.4999 22.1772 23.4468 23.8443 24.3556C24.8168 24.8857 25.3031 25.1508 25.6216 25.1118C25.9169 25.0756 26.1402 24.943 26.3133 24.701C26.5 24.4401 26.5 23.9179 26.5 22.8736V13.1262C26.5 12.0819 26.5 11.5598 26.3133 11.2988C26.1402 11.0568 25.9169 10.9242 25.6216 10.888C25.3031 10.849 24.8168 11.1141 23.8443 11.6443C22.1772 12.553 20.0164 13.4999 18.25 13.4999Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
