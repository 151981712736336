<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56334)">
      <path
        d="M2.08357 15.4168C1.79826 15.416 1.52128 15.5129 1.29864 15.6913C1.076 15.8697 0.921094 16.119 0.859653 16.3976C0.798211 16.6762 0.833931 16.9674 0.96088 17.223C1.08783 17.4785 1.29837 17.6829 1.55752 17.8022C3.20857 18.5678 4.89607 19.1668 7.08357 19.1668C13.7554 19.1668 19.1669 14.3178 19.1669 8.33343C19.1669 6.69281 18.6409 5.14073 17.9169 3.7501V1.8751C17.9169 1.59883 17.8072 1.33388 17.6118 1.13853C17.4165 0.943182 17.1515 0.833435 16.8752 0.833435C16.599 0.833435 16.334 0.943182 16.1387 1.13853C15.9433 1.33388 15.8336 1.59883 15.8336 1.8751V3.7501C15.8336 9.52614 9.94815 15.4064 2.08357 15.4168Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56334">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
