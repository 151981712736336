<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6056)">
      <path
        d="M9.16797 15.4163V19.5829"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5 19.583H13.3333"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 6.6665H14.5833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.582 5.83325H16.2487C16.4697 5.83325 16.6817 5.92105 16.838 6.07733C16.9942 6.23361 17.082 6.44557 17.082 6.66659V9.16659C17.082 9.60861 16.9064 10.0325 16.5939 10.3451C16.2813 10.6577 15.8574 10.8333 15.4154 10.8333H14.582"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 11.2498C3.75 13.5518 5.94271 15.4164 9.16667 15.4164C12.3906 15.4164 14.5833 13.5518 14.5833 11.2498V3.74976H3.75V11.2498Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.9805 3.74984C13.3867 1.82796 11.4544 0.416504 9.16797 0.416504C6.88151 0.416504 4.94922 1.82796 4.35547 3.74984"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.7878 0.202881L13.4805 2.68205"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6056">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
