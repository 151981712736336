import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}
export default [
  {
    path: "statistic",
    name: "statistic",
    component: load("statistic/Statistic"),
    redirect: {
      name: "statistic:satisfaction",
    },
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
      menuItems: "leftSideMenu.statistic",
    },
    children: [
      {
        path: "satisfaction",
        name: "statistic:satisfaction",
        component: load("statistic/Satisfaction"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
          menuItems: "leftSideMenu.statistic",
        },
      },
      {
        path: "average-check",
        name: "statistic:average-check",
        component: load("statistic/AverageCheck"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
          menuItems: "leftSideMenu.statistic",
        },
      },
      {
        path: "top-selling",
        name: "statistic:top-selling",
        component: load("statistic/TopSelling"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
          menuItems: "leftSideMenu.statistic",
        },
      },
      {
        path: "retention",
        name: "statistic:retention",
        component: load("statistic/RetentionLevel"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
          menuItems: "leftSideMenu.statistic",
        },
      },
      {
        path: "transaction",
        name: "statistic:transaction",
        component: load("statistic/Transaction"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STATISTIC],
          menuItems: "leftSideMenu.statistic",
        },
      },
    ],
  },
];
