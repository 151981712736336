<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33447 13.3335H12.5011"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33447 15.8336H12.5011"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1574 18.7502L18.0168 7.50024H2.81885L4.67822 18.7502H16.1574Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.30322 10.4169H17.5324"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.7616 7.50021C16.7928 7.42382 16.8223 7.34569 16.8501 7.26583C17.2021 6.24247 17.1469 5.12288 16.6959 4.13912C16.2449 3.15536 15.4326 2.38287 14.4274 1.98175C13.4223 1.58063 12.3014 1.58164 11.2969 1.98458C10.2925 2.38751 9.48165 3.16147 9.03239 4.14604L7.62614 7.26583L7.52197 7.50021"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.04308 4.13044L8.8712 4.0471L5.85558 2.68252C5.47 2.50881 5.04068 2.45656 4.62469 2.53271C4.2087 2.60887 3.82574 2.80982 3.5267 3.10885C3.22766 3.40789 3.02671 3.79085 2.95056 4.20684C2.87441 4.62283 2.92666 5.05215 3.10037 5.43773L4.03266 7.50023"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.50073 2.0835L3.52677 3.10954"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M0.834229 2.08348H2.5009V0.416809"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
