<template>
  <div :class="['text-input', { h37 }]">
    <label class="text-input__label-wrapper">
      <div v-if="noTitle" :class="['text-input__label', { aqsi: classAqsi }]">
        {{ title }}
      </div>
      <input
        v-bind="$attrs"
        v-model.trim="textInputValue"
        :class="['text-input__input', { h37 }]"
        :type="inputType"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
        @paste="handlePaste"
      />
      <!-- @input="emit('update:modelValue', $event.target.value)" -->
    </label>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: { type: [String, Number, Object], default: "" },
  title: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  noTitle: {
    type: Boolean,
    default: () => false,
  },
  classAqsi: {
    type: Boolean,
    default: () => false,
  },
  inputType: {
    type: String,
    default: "text",
  },
  h37: {
    type: Boolean,
    default: () => false,
  },
  noPaste: {
    type: Boolean,
    default: () => false,
  },
  alphanumeric: {
    type: Boolean,
    default: () => false,
  },
});
const mb = computed(() => (props.noTitle ? "0px" : "4px"));
const textInputValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    if (props.alphanumeric) {
      emit("update:modelValue", value.replace(/[^a-zA-Z0-9]/g, ""));
    } else {
      emit(
        "update:modelValue",
        typeof value === "string" ? value?.trim() : value
      );
    }
  },
});

const handlePaste = (event) => {
  if (event.target.type === "text" && !props.noPaste) {
    const pastedText = event.clipboardData.getData("text");
    // Получаем индекс начала и конца выделенного текста
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    // Заменяем выделенный текст на вставленный текст
    const newValue =
      textInputValue.value.substring(0, selectionStart) +
      pastedText +
      textInputValue.value.substring(selectionEnd);

    // Перемещаем курсор в конец вставленного текста
    const newCursorPosition = selectionStart + pastedText.length;
    event.target.setSelectionRange(newCursorPosition, newCursorPosition);

    // Заменяем значение в инпуте
    textInputValue.value = newValue;
    event.preventDefault();
  }
};

// eslint-disable-next-line no-undef
const emit = defineEmits(["update:modelValue"]);
onMounted(() => {});
</script>

<style lang="scss" scoped>
.text-input {
  width: 100%;
  height: 45px;
  &__label-wrapper {
    width: 100%;
    text-align: left;
  }
  &__label {
    font-size: 12px;
    line-height: 130%;

    color: #9b9b9b;
    margin-bottom: v-bind(mb);
  }
  &__input {
    width: 100%;
    border: 1px solid #e7e7e9;
    border-radius: 8px;
    padding: 0px 16px;
    height: 45px;
    color: #0e0e22 !important;
    font: 400 14px/130% "Inter", sans-serif;
    text-align: left;
    position: relative;
    outline: none;
    &:not(:placeholder-shown) {
      border-color: #a0a0a0;
    }
    &:focus {
      border-color: #00cdd7;
    }
    &:active {
      border-color: rgba(0, 205, 215, 0.5);
    }
    /* &:placeholder-shown {
      border-color: #d0d0d0;
    } */
    &::placeholder {
      text-transform: none !important;
    }

    &:-ms-input-placeholder {
      text-transform: none !important;
    }

    &::-ms-input-placeholder {
      text-transform: none !important;
    }

    &[readonly] {
      border-color: #e7e7e9;
      color: #bcbbbb !important;
      cursor: not-allowed;
    }
  }
  &:hover {
    background: #ffffff;
  }
}

.placeholder-none {
  &::placeholder {
    text-transform: none !important;
  }

  &:-ms-input-placeholder {
    text-transform: none !important;
  }

  &::-ms-input-placeholder {
    text-transform: none !important;
  }
}
[disabled] {
  background: #eeeff2;
  color: #bcbbbb !important;
  border: none !important;
  outline: none;
  cursor: auto;
}
.aqsi {
  &::placeholder {
    color: #0e0e22 !important;
  }

  &:-ms-input-placeholder {
    color: #0e0e22 !important;
  }

  &::-ms-input-placeholder {
    color: #0e0e22 !important;
  }
}
.h37 {
  height: 37px;
  border-radius: 12px;
}
</style>
