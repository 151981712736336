<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6687 4.58335V3.33335C6.6687 2.89133 6.8443 2.4674 7.15686 2.15484C7.46942 1.84228 7.89334 1.66669 8.33537 1.66669H11.6687C12.1107 1.66669 12.5347 1.84228 12.8472 2.15484C13.1598 2.4674 13.3354 2.89133 13.3354 3.33335V4.58335"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.82495 10.4167L7.86662 18.3334H2.55412L1.59058 10.4167"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.408 10.4167L17.4496 18.3334H12.1371L11.1736 10.4167"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M0.835205 10.4166H19.1685C19.1685 7.19269 17.8612 4.58331 16.2519 4.58331H3.75187C2.1425 4.58331 0.835205 7.19269 0.835205 10.4166Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
