function load(component) {
  return () => import(`@/views/wallet/${component}.vue`);
}
export default [
  {
    path: "wallet",
    name: "wallet",
    component: load("forClient/Wallet"),
    meta: {
      requiresAuth: true,
      icon: "puzzle",
      menuItems: "leftSideMenu.wallet",
    },
  },
  {
    path: "client",
    name: "wallet:client",
    component: load("Client"),
    // redirect: { name: "wallet" },
    meta: {
      requiresAuth: true,
      icon: "puzzle",
    },
    children: [
      {
        path: "s-template",
        name: "s-template",
        component: load("forClient/STemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "new-template",
        name: "new-template",
        component: load("forClient/NewTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "news-template/edit/:templateId",
        name: "edit-template",
        component: load("forClient/EditTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "news-template",
        name: "news-template",
        component: load("forClient/NewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "template/edit/:templateId",
        name: "edit-template",
        component: load("forClient/EditTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "new-news-template",
        name: "new-news-template",
        component: load("forClient/NewNewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "template-news/edit/:templateId",
        name: "edit-news-template",
        component: load("forClient/EditNewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "brand",
        name: "brand",
        component: load("forClient/ClientBrand"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
    ],
  },
  {
    path: "f-staff",
    name: "f-staff",
    redirect: { name: "staff-stock-template" },
    component: load("Staff"),
    meta: {
      requiresAuth: true,
      icon: "puzzle",
    },
    children: [
      {
        path: "news-template-staff",
        name: "news-template-staff",
        component: load("forStaff/NewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "staff-new-stock-template",
        name: "staff-new-stock-template",
        component: load("forStaff/StaffNewTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "new-staff-news-template",
        name: "new-staff-news-template",
        component: load("forStaff/NewStaffNewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "edit-staff-news-template/edit/:templateId",
        name: "edit-staff-news-template",
        component: load("forStaff/EditStaffNewsTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "staff-stock-template",
        name: "staff-stock-template",
        component: load("forStaff/StockTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "staff-stock-template/edit/:templateId",
        name: "staff-edit-stock-template",
        component: load("forStaff/StaffEditTemplate"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
      {
        path: "staff-brand",
        name: "staff-brand",
        component: load("forStaff/StaffBrand"),
        meta: {
          requiresAuth: true,
          icon: "puzzle",
        },
      },
    ],
  },
];
