<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5943)">
      <path
        d="M3.33594 2.91675H6.2526"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.25391 11.6667H5.42057V15.4167H1.25391"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.25391 6.77596V0.833252H3.33724V6.77596C2.71686 7.05628 2.19042 7.50945 1.82093 8.08123C1.45145 8.653 1.25458 9.31915 1.25391 9.99992V18.7499C1.25391 18.9709 1.3417 19.1829 1.49798 19.3392C1.65426 19.4955 1.86623 19.5833 2.08724 19.5833H7.50391C7.72492 19.5833 7.93688 19.4955 8.09316 19.3392C8.24944 19.1829 8.33724 18.9709 8.33724 18.7499V9.99992C8.33656 9.31915 8.1397 8.653 7.77021 8.08123C7.40073 7.50945 6.87428 7.05628 6.25391 6.77596V6.77596Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.3477 8.75H18.6602"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0039 13.3333V19.5833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0859 19.5833H17.9193"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.3633 6.25L11.5664 8.08333C11.3176 8.654 11.2148 9.27762 11.2672 9.89795C11.3197 10.5183 11.5257 11.1158 11.8668 11.6366C12.2079 12.1574 12.6733 12.5851 13.2209 12.8811C13.7686 13.177 14.3814 13.332 15.0039 13.332C15.6264 13.332 16.2392 13.177 16.7869 12.8811C17.3345 12.5851 17.7999 12.1574 18.141 11.6366C18.4821 11.1158 18.6881 10.5183 18.7406 9.89795C18.793 9.27762 18.6902 8.654 18.4414 8.08333L17.6393 6.25H12.3633Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5943">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
