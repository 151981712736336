<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.832031 18.3333H18.332"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.5651 10H17.4974C17.9394 10 18.3633 10.1756 18.6759 10.4882C18.9885 10.8007 19.1641 11.2246 19.1641 11.6667C19.1641 12.1087 18.9885 12.5326 18.6759 12.8452C18.3633 13.1577 17.9394 13.3333 17.4974 13.3333H16.0859"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.11458 18.3333C4.15625 18.3333 2.5 13.4844 2.5 7.5H16.6667C16.6667 13.4844 15.0104 18.3333 13.0521 18.3333"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33073 5.83333C8.10981 5.83325 7.89286 5.77463 7.70197 5.66343C7.51108 5.55222 7.35307 5.39242 7.24403 5.20028C7.13499 5.00814 7.07883 4.79054 7.08125 4.56963C7.08367 4.34873 7.14459 4.13241 7.25781 3.94271L7.73698 3.14062C7.8502 2.95092 7.91112 2.73461 7.91355 2.5137C7.91597 2.29279 7.8598 2.07519 7.75076 1.88305C7.64173 1.69092 7.48372 1.53111 7.29283 1.41991C7.10193 1.3087 6.88498 1.25008 6.66406 1.25"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.6667 5.83333C11.4457 5.83325 11.2288 5.77463 11.0379 5.66343C10.847 5.55222 10.689 5.39242 10.58 5.20028C10.4709 5.00814 10.4148 4.79054 10.4172 4.56963C10.4196 4.34873 10.4805 4.13241 10.5937 3.94271L11.0729 3.14062C11.1861 2.95092 11.2471 2.73461 11.2495 2.5137C11.2519 2.29279 11.1957 2.07519 11.0867 1.88305C10.9777 1.69092 10.8197 1.53111 10.6288 1.41991C10.4379 1.3087 10.2209 1.25008 10 1.25"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.7474 11.8228L7.08073 10.2603L5.41406 11.8228V14.5832H8.7474V11.8228Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.08203 10.2604V7.5"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
