<template>
  <svg
    width="200"
    height="100"
    viewBox="0 0 200 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M168 100H200C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100H32C32 62.4446 62.4446 32 100 32C137.555 32 168 62.4446 168 100Z"
      fill="url(#paint0_radial_63_640)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_63_640"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-2.2985e-06 100) scale(200 400)"
      >
        <stop stop-color="#F73A53" />
        <stop
          offset="0.389146"
          stop-color="#FFD233"
        />
        <stop
          offset="0.612344"
          stop-color="#FFD233"
        />
        <stop
          offset="1"
          stop-color="#00EEBB"
        />
      </radialGradient>
    </defs>
  </svg>
</template>
