<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4183 16.6664C12.2592 16.6664 13.7516 15.174 13.7516 13.3331C13.7516 11.4921 12.2592 9.99976 10.4183 9.99976C8.57735 9.99976 7.08496 11.4921 7.08496 13.3331C7.08496 15.174 8.57735 16.6664 10.4183 16.6664Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1579 18.7497L18.0173 7.49969H2.81934L4.67871 18.7497H16.1579Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.762 7.49972C16.7933 7.42333 16.8228 7.34521 16.8506 7.26534C17.2026 6.24198 17.1474 5.12239 16.6964 4.13863C16.2453 3.15488 15.4331 2.38238 14.4279 1.98126C13.4228 1.58014 12.3018 1.58115 11.2974 1.98409C10.293 2.38702 9.48214 3.16098 9.03288 4.14555L7.62663 7.26534L7.52246 7.49972"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.04356 4.12989L8.87169 4.04655L5.85606 2.68197C5.47048 2.50826 5.04117 2.45601 4.62518 2.53216C4.20919 2.60832 3.82623 2.80927 3.52719 3.1083C3.22815 3.40734 3.0272 3.7903 2.95105 4.20629C2.8749 4.62228 2.92715 5.0516 3.10086 5.43718L4.03315 7.49968"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.5022 2.08307L3.52824 3.10911"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M0.834961 2.08305H2.50163V0.416382"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
