<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6090)">
      <path
        d="M1.80859 12.9165H15.694"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.31641 9.1665H16.181"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.4141 3.74976L12.4986 1.66527L13.7485 2.91522L11.664 4.99971L10.4141 3.74976Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.51432 4.21851L5.83203 4.99976L4.58203 2.08309L6.2487 1.24976L7.51432 4.21851Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.2591 7.08301C14.8841 5.87467 13.8841 4.83822 12.5039 4.16113"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.39453 3.98413C3.81641 4.64559 2.64974 5.76017 2.23828 7.08309"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.6419 3.5258C9.50833 3.29017 8.34062 3.26897 7.19922 3.4633"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.1185 9.99976H17.082C17.6346 9.99976 18.1645 10.2192 18.5552 10.61C18.9459 11.0007 19.1654 11.5306 19.1654 12.0831C19.1654 12.6356 18.9459 13.1655 18.5552 13.5562C18.1645 13.9469 17.6346 14.1664 17.082 14.1664H15.3945"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0833 18.7499C14.3854 18.7499 16.25 13.526 16.25 7.08325H1.25C1.25 13.526 3.11458 18.7499 5.41667 18.7499H12.0833Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6090">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
