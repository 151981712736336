<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.33325 14.667H14.6666"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.07324 14.667V8.66699C2.07324 7.56033 3.06658 6.66699 4.29324 6.66699H11.6999C12.9266 6.66699 13.9199 7.56033 13.9199 8.66699V14.667"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.70679 6.66634V4.77967C3.70679 3.97967 4.42679 3.33301 5.32012 3.33301H10.6868C11.5735 3.33301 12.2935 3.97967 12.2935 4.77967V6.66634"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.35327 9.32031L2.59994 9.32698C3.09327 9.33365 3.4866 9.73365 3.4866 10.227V10.447C3.4866 10.9403 3.8866 11.347 4.3866 11.347C4.87994 11.347 5.2866 10.947 5.2866 10.447V10.2403C5.2866 9.74698 5.6866 9.34031 6.1866 9.34031C6.67994 9.34031 7.0866 9.74031 7.0866 10.2403V10.447C7.0866 10.9403 7.4866 11.347 7.9866 11.347C8.47994 11.347 8.8866 10.947 8.8866 10.447V10.2403C8.8866 9.74698 9.2866 9.34031 9.7866 9.34031C10.2799 9.34031 10.6866 9.74031 10.6866 10.2403V10.447C10.6866 10.9403 11.0866 11.347 11.5866 11.347C12.0799 11.347 12.4866 10.947 12.4866 10.447V10.2403C12.4866 9.74698 12.8866 9.34031 13.3866 9.34031H13.6866"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33325 3.33333V2"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 3.33333V2"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 3.33301V1.33301"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
