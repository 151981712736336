<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4.33321H0.666667C0.266667 4.33321 0 4.06654 0 3.66654C0 3.26654 0.266667 2.99988 0.666667 2.99988H10C10.4 2.99988 10.6667 3.26654 10.6667 3.66654C10.6667 4.06654 10.4 4.33321 10 4.33321Z"
      fill="#595A5B"
    />
    <path
      d="M13.3333 0.999919C13.3333 0.599919 13.0667 0.333252 12.6667 0.333252H0.666667C0.266667 0.333252 0 0.599919 0 0.999919C0 1.39992 0.266667 1.66659 0.666667 1.66659H12.6667C13.0667 1.66659 13.3333 1.39992 13.3333 0.999919Z"
      fill="#595A5B"
    />
    <path
      d="M13.3333 6.33329C13.3333 5.93329 13.0667 5.66663 12.6667 5.66663H0.666667C0.266667 5.66663 0 5.93329 0 6.33329C0 6.73329 0.266667 6.99996 0.666667 6.99996H12.6667C13.0667 6.99996 13.3333 6.73329 13.3333 6.33329Z"
      fill="#595A5B"
    />
    <path
      d="M10.6667 8.99992C10.6667 8.59992 10.4 8.33325 10 8.33325H0.666667C0.266667 8.33325 0 8.59992 0 8.99992C0 9.39992 0.266667 9.66659 0.666667 9.66659H10C10.4 9.66659 10.6667 9.39992 10.6667 8.99992Z"
      fill="#595A5B"
    />
  </svg>
</template>
