<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58757)">
      <path
        d="M14.1656 13.2289V19.0778L9.99894 16.9997L5.83228 19.0778V13.2289"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99911 14.5832C13.9111 14.5832 17.0824 11.4119 17.0824 7.4999C17.0824 3.58788 13.9111 0.416565 9.99911 0.416565C6.08709 0.416565 2.91577 3.58788 2.91577 7.4999C2.91577 11.4119 6.08709 14.5832 9.99911 14.5832Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99911 9.78111L12.7908 11.4842L12.0304 8.35923L14.5824 6.29152L11.2231 5.95298L9.99911 3.06757L8.77515 5.95298L5.41577 6.29152L7.96786 8.35923L7.20744 11.4842L9.99911 9.78111Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58757">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
