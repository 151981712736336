<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58580)">
      <path
        d="M18.7499 8.75012H9.99988V18.7501H18.7499V8.75012Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.7499 11.2501H9.99988"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6663 13.3334H12.9163"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6663 15.0001H12.9163"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6663 16.6667H12.9163"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7499 13.3334H14.9999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7499 15.0001H14.9999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7499 16.6667H14.9999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8335 13.3334H17.0835"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8335 15.0001H17.0835"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8335 16.6667H17.0835"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.2502 8.75037V5.00037H7.0835V17.5004H10.0002"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3335 8.75037V1.25037H17.5002V8.75037"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.00016 8.75012H0.833496V17.5001H5.00016V8.75012Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58580">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
