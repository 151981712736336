<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.41626 6.93762V11.2501"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.4998 17.5003L15.5362 14.4639C15.6296 14.3705 15.7404 14.2964 15.8624 14.2459C15.9844 14.1953 16.1151 14.1693 16.2472 14.1693C16.3792 14.1693 16.5099 14.1953 16.6319 14.2459C16.7539 14.2964 16.8647 14.3705 16.9581 14.4639L19.3956 16.9014"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.5828 15.4168L12.3796 13.2136C12.2863 13.1202 12.1755 13.0462 12.0535 12.9956C11.9315 12.9451 11.8007 12.9191 11.6687 12.9191C11.5367 12.9191 11.4059 12.9451 11.2839 12.9956C11.1619 13.0462 11.0511 13.1202 10.9578 13.2136L9.58276 14.5834"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.5465 18.5469L6.83813 11.8385C6.65144 11.6517 6.42978 11.5036 6.18581 11.4025C5.94184 11.3014 5.68034 11.2494 5.41626 11.2494C5.15218 11.2494 4.89068 11.3014 4.64671 11.4025C4.40274 11.5036 4.18108 11.6517 3.99438 11.8385L0.57251 15.2604"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.01 7.08351C10.2796 7.08334 10.5473 7.03755 10.8017 6.94809L12.0829 6.50017V2.3335L10.8017 2.78142C10.478 2.89477 10.1337 2.93716 9.79225 2.90571C9.45076 2.87425 9.12002 2.76969 8.82251 2.59913L8.40584 2.35955C8.10887 2.18984 7.77539 2.09414 7.43362 2.08054C7.09184 2.06694 6.75182 2.13585 6.4423 2.28142L5.41626 2.771V6.93767L6.4423 6.4533C6.75182 6.30772 7.09184 6.23882 7.43362 6.25242C7.77539 6.26602 8.10887 6.36171 8.40584 6.53142L8.82251 6.77101C9.18438 6.97679 9.59372 7.08451 10.01 7.08351V7.08351Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
