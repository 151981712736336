<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 3.16667C13.3333 3.56667 13.0666 3.83333 12.6666 3.83333H10.4666L6.99996 13.1667H9.33329C9.73329 13.1667 9.99996 13.4333 9.99996 13.8333C9.99996 14.2333 9.73329 14.5 9.33329 14.5H3.33329C2.93329 14.5 2.66663 14.2333 2.66663 13.8333C2.66663 13.4333 2.93329 13.1667 3.33329 13.1667H5.53329L9.06663 3.83333H6.66663C6.26663 3.83333 5.99996 3.56667 5.99996 3.16667C5.99996 2.76667 6.26663 2.5 6.66663 2.5H12.6666C13.0666 2.5 13.3333 2.76667 13.3333 3.16667Z"
      fill="#595A5B"
    />
  </svg>
</template>
