<template>
  <svg
    class="icons-loyalty"
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66671 39.9987C8.77143 40.3334 10.2636 40.3334 12.8 40.3334H31.8667C34.4031 40.3334 35.8952 40.3334 37 39.9987M7.66671 39.9987C7.42984 39.9269 7.21078 39.8398 7.00305 39.7339C5.96816 39.2066 5.12677 38.3653 4.59946 37.3304C4 36.1538 4 34.6137 4 31.5334V12.4667C4 9.38646 4 7.84631 4.59946 6.6698C5.12677 5.63491 5.96816 4.79352 7.00305 4.26621C8.17957 3.66675 9.71971 3.66675 12.8 3.66675H31.8667C34.947 3.66675 36.4871 3.66675 37.6636 4.26621C38.6985 4.79352 39.5399 5.63491 40.0672 6.6698C40.6667 7.84631 40.6667 9.38646 40.6667 12.4667V31.5334C40.6667 34.6137 40.6667 36.1538 40.0672 37.3304C39.5399 38.3653 38.6985 39.2066 37.6636 39.7339C37.4559 39.8398 37.2368 39.9269 37 39.9987M7.66671 39.9987C7.66731 38.5149 7.67621 37.7298 7.80757 37.0694C8.38622 34.1604 10.6603 31.8863 13.5693 31.3077C14.2777 31.1667 15.1296 31.1667 16.8333 31.1667H27.8333C29.5371 31.1667 30.3889 31.1667 31.0973 31.3077C34.0064 31.8863 36.2804 34.1604 36.8591 37.0694C36.9905 37.7298 36.9994 38.5149 37 39.9987M29.6667 17.4167C29.6667 21.4668 26.3834 24.7501 22.3333 24.7501C18.2832 24.7501 15 21.4668 15 17.4167C15 13.3667 18.2832 10.0834 22.3333 10.0834C26.3834 10.0834 29.6667 13.3667 29.6667 17.4167Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
