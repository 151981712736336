<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6671 8.74976V18.3331H3.33374V8.74976"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.6675 5.41656H17.9175V8.7499H11.6675V5.41656Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.08374 5.41656H8.33374V8.7499H2.08374V5.41656Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33374 4.58323L5.84416 2.09365C5.56872 1.81986 5.19606 1.6663 4.8077 1.66657C4.4306 1.66687 4.06765 1.81019 3.79207 2.06761C3.64708 2.20505 3.53164 2.37063 3.45284 2.55421C3.37403 2.7378 3.3335 2.93553 3.33374 3.13532V5.41657"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.6675 4.58323L14.1571 2.09365C14.4325 1.81986 14.8052 1.6663 15.1935 1.66657C15.5706 1.66687 15.9336 1.81019 16.2091 2.06761C16.3541 2.20505 16.4696 2.37063 16.5484 2.55421C16.6272 2.7378 16.6677 2.93553 16.6675 3.13532V5.41657"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33374 4.58319V18.3332H11.6671V4.58319C11.6671 4.25167 11.5354 3.93373 11.301 3.69931C11.0665 3.46489 10.7486 3.33319 10.4171 3.33319H9.58374C9.25222 3.33319 8.93428 3.46489 8.69986 3.69931C8.46544 3.93373 8.33374 4.25167 8.33374 4.58319V4.58319Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
