<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 3.99996H1.66667C1.26667 3.99996 1 3.73329 1 3.33329C1 2.93329 1.26667 2.66663 1.66667 2.66663H11C11.4 2.66663 11.6667 2.93329 11.6667 3.33329C11.6667 3.73329 11.4 3.99996 11 3.99996Z"
      fill="#595A5B"
    />
    <path
      d="M13.3333 0.666667C13.3333 0.266667 13.0667 0 12.6667 0H0.666667C0.266667 0 0 0.266667 0 0.666667C0 1.06667 0.266667 1.33333 0.666667 1.33333H12.6667C13.0667 1.33333 13.3333 1.06667 13.3333 0.666667Z"
      fill="#595A5B"
    />
    <path
      d="M13.3333 6.00004C13.3333 5.60004 13.0667 5.33337 12.6667 5.33337H0.666667C0.266667 5.33337 0 5.60004 0 6.00004C0 6.40004 0.266667 6.66671 0.666667 6.66671H12.6667C13.0667 6.66671 13.3333 6.40004 13.3333 6.00004Z"
      fill="#595A5B"
    />
    <path
      d="M11.6667 8.66667C11.6667 8.26667 11.4 8 11 8H1.66667C1.26667 8 1 8.26667 1 8.66667C1 9.06667 1.26667 9.33333 1.66667 9.33333H11C11.4 9.33333 11.6667 9.06667 11.6667 8.66667Z"
      fill="#595A5B"
    />
  </svg>
</template>
