<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7721 6.25H3.23047L5.54297 18.75H14.4596L16.7721 6.25Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.77344 9.16675H16.2318"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.4596 13.3335H4.54297"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M7.91927 4.16675H7.08594V5.00008H7.91927V4.16675Z"
      fill="#0E0E22"
    />

    <path
      d="M10.4193 2.91675H9.58594V3.75008H10.4193V2.91675Z"
      fill="#0E0E22"
    />

    <path
      d="M12.5013 3.75H11.668V4.58333H12.5013V3.75Z"
      fill="#0E0E22"
    />

    <path
      d="M15.543 6.25008C15.1263 3.65633 12.793 1.66675 10.0013 1.66675C7.20964 1.66675 4.89714 3.65633 4.48047 6.25008"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
