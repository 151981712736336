import { getStatusLoyalties } from "@/services/loyalty.service";
import { ref } from "vue";
const activeLoyalty = ref([]);

export async function loyaltyProgramCheckMiddleware(to, from, next) {
  await getStatusLoyalties(activeLoyalty);
  if (to.name === "pselection") {
    if (!activeLoyalty.value.length) {
      return next();
    }
    return next({ name: activeLoyalty.value[0] });
  }
  return next();
}
