<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2515_43020)">
      <path
        d="M2.9165 7.91669L4.67692 19.1667H15.3228L17.0832 7.91669"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.9165 7.91669L7.49984 12.5H12.4998L17.0832 7.91669"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M19.4113 10.2448L17.0832 7.91669H2.9165L0.588379 10.2448"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.19775 6.33854L17.8071 1.17188"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.15625 2.46356L17.8437 5.0521"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2515_43020">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
