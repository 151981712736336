import router from "@/router";
import {
  createAuthService,
  resetAuthService,
} from "@/modules/auth/auth.service";
createAuthService();
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { getUserState, auth } from "@/firebase";
import { getMerchantOne } from "@/services/merchant.service";
import { getUserRole, getUser } from "@/services/user.service";

export default {
  state: () => ({
    menuitem: "",
    user: null,
    merchant: null,
    errorLogin: null,
    errorPassword: null,
    messageSuccessReset: null,
    userid: null,
    role: null,
    loyalty: null,
    pointIDS: null,
  }),
  mutations: {
    set_loyalty(state, loyalty) {
      state.loyalty = loyalty;
    },
    set_merchantSlugName(state, merchantSlugName) {
      state.merchant.merchantSlugName = merchantSlugName;
    },
    set_merchantName(state, merchantName) {
      state.merchant.merchantName = merchantName;
    },
    SET_MENUITEM(state, menuitem) {
      state.menuitem = menuitem;
    },
    set_merchant(state, merchant) {
      state.merchant = merchant;
    },
    set_userid(state, userid) {
      state.userid = userid;
    },
    set_role(state, role) {
      state.role = role;
    },
    reset_merchant(state) {
      state.merchant = null;
    },
    reset_userid(state) {
      state.userid = null;
    },
    reset_role(state) {
      state.role = null;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_USER(state) {
      state.user = null;
      state.merchant = null;
    },
    SET_ERROR_LOGIN(state, errorLogin) {
      state.errorLogin = errorLogin;
    },
    SET_ERROR_RESET(state, errorLogin) {
      state.errorLogin = errorLogin;
    },

    SET_ERROR_PASSWORD(state, errorPassword) {
      state.errorPassword = errorPassword;
    },
    SET_MESSAGE_FOR_SUCCESS_RESET(state, messageSuccessReset) {
      state.messageSuccessReset = messageSuccessReset;
    },
    CLEAR_MESSAGE_FOR_SUCCESS_RESET(state) {
      state.messageSuccessReset = null;
    },
    set_currencySign(state, currencySign) {
      state.merchant.currencySign = currencySign;
    },
  },
  actions: {
    setMenuitem({ commit }, details) {
      commit("SET_MENUITEM", details);
    },
    async login({ commit, state }, details) {
      const { email, password, isRememberMe } = details;
      try {
        await setPersistence(
          auth,
          isRememberMe ? browserLocalPersistence : browserSessionPersistence
        );
        await signInWithEmailAndPassword(auth, email, password);
        resetAuthService();
        console.log("login:state.role", state.role);

        if (state.role === "back-office") {
          router.replace({ name: "merchants" });
        } else {
          router.replace({ name: "main" });
        }

        sessionStorage.setItem("email", email);
        sessionStorage.removeItem("stateItems");
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-email":
            commit("SET_ERROR_LOGIN", "login.errors.emailNotFound");
            break;
          case "auth/user-disabled":
            alert("User disabled");
            break;
          case "auth/user-not-found":
            commit("SET_ERROR_LOGIN", "login.errors.emailNotFound");
            break;
          case "auth/wrong-password":
            commit("SET_ERROR_PASSWORD", "login.errors.wrongPassword");
            break;
          default:
            alert(error);
        }
        return;
      }
      commit("SET_USER", auth.currentUser);
    },
    async logout({ commit }) {
      await signOut(auth);

      commit("CLEAR_USER");
      resetAuthService();

      router.push({ name: "login" });
    },
    async reset_password({ commit }, details) {
      const { email } = details;
      //       console.log("reset mail", email);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          //console.log("res");
          commit(
            "SET_MESSAGE_FOR_SUCCESS_RESET",
            "login.forgotQuery.messageSuccessReset"
          );
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            commit("SET_ERROR_RESET", "login.errors.resetNotEmail");
          }
        });
    },
    async get_merchant({ commit }) {
      const user = await getUserState();
      const userDb = await getUser([
        "firstname",
        "lastname",
        "email",
        "phones",
        "point",
      ]);
      // console.log("user:get_merchant", user);

      const merchant = await getMerchantOne(user.uid);
      const role = await getUserRole();
      if (user) {
        commit("set_merchant", merchant);
        commit("set_userid", user.uid);
        commit("set_role", role);
        commit("SET_USER", userDb);
      } else {
        commit("reset_merchant");
        commit("reset_userid");
        commit("reset_role");
      }
    },
  },
  getters: {
    get_menuitem(state) {
      return state.menuitem;
    },
    errorPassword(state) {
      return state.errorPassword;
    },
    errorLogin(state) {
      return state.errorLogin;
    },
    messageForgotSuccess(state) {
      return state.messageSuccessReset;
    },
    merchant(state) {
      return state.merchant;
    },
    userid(state) {
      return state.userid;
    },
    user(state) {
      return state.user;
    },
    role(state) {
      return state.role;
    },
    pointIDS(state) {
      return state.user?.point?.map((p) => p.id);
    },
    merchantSlugName(state) {
      return state.merchant?.merchantSlugName;
    },
    merchantId(state) {
      return state.merchant?.merchantId;
    },
    currencySymbol(state) {
      return state.merchant?.currencySign?.currencySign;
    },
    currencySign(state) {
      return state.merchant?.currencySign;
    },
    loyalty(state) {
      return state.loyalty;
    },
  },
};
