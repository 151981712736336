<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 8H13.5"
      stroke="url(#linear)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 2.5V13.5"
      stroke="url(#linear)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="linear"
        x1="2.5"
        y1="8"
        x2="5.27267"
        y2="15.8839"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10B3C7" />
        <stop
          offset="1"
          stop-color="#0052CC"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
