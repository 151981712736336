<template>
  <svg
    width="242"
    height="189"
    viewBox="0 0 242 189"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_267_3554)">
      <g clip-path="url(#clip1_267_3554)">
        <rect
          width="286"
          height="189"
          transform="translate(-22 0.185547)"
          fill="white"
        />
        <path
          d="M-25.3645 -0.665128C-25.3645 -0.665128 46.7612 -1.86793 95.2612 32.6329C143.761 67.1337 188.261 149.686 188.261 149.686C188.261 149.686 199.761 172.186 216.261 172.186C232.761 172.186 240.261 145.133 240.261 145.133"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-30.5191 19.7804C-30.5191 19.7804 41.2837 19.242 89.8811 53.5425C138.478 87.8431 170.553 151.494 170.553 151.494C170.553 151.494 185.43 191.661 216.715 191.661C248 191.661 259.061 140.558 259.061 140.558"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-43.4995 38.6855C-43.4995 38.6855 29.3636 34.0801 77.8636 68.5809C126.364 103.082 144.5 142.187 144.5 142.187C144.5 142.187 180.5 215.187 219 210.134C257.5 205.08 277.5 145.187 277.5 145.187"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-52.501 55.1847C-52.501 55.1847 17.999 57.1891 70.499 86.6873C122.999 116.186 155.499 193.186 155.499 193.186"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-64.7629 83.2821C-64.7629 83.2821 7.36274 82.0793 55.8628 116.58C104.363 151.081 150.863 236.08 150.863 236.08C150.863 236.08 160.863 256.08 173.363 257.08C185.863 258.08 191.363 247.081 191.363 247.081L200.863 229.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-76.7629 97.2821C-76.7629 97.2821 -4.63726 96.0793 43.8628 130.58C92.3629 165.081 138.863 250.08 138.863 250.08C138.863 250.08 148.863 270.08 161.363 271.08C173.863 272.08 179.363 261.081 179.363 261.081L188.863 243.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-82.7629 114.282C-82.7629 114.282 -10.6373 113.079 37.8628 147.58C86.3629 182.081 132.863 267.08 132.863 267.08C132.863 267.08 142.863 287.08 155.363 288.08C167.863 289.08 173.363 278.081 173.363 278.081L182.863 260.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-88.7629 130.335C-88.7629 130.335 -16.6373 129.132 31.8628 163.633C80.3629 198.134 126.863 283.133 126.863 283.133C126.863 283.133 136.863 303.133 149.363 304.133C161.863 305.133 167.363 294.134 167.363 294.134L176.863 276.133"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-91.7629 145.282C-91.7629 145.282 -19.6373 144.079 28.8628 178.58C77.3629 213.081 123.863 298.08 123.863 298.08C123.863 298.08 133.863 318.08 146.363 319.08C158.863 320.08 164.363 309.081 164.363 309.081L173.863 291.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-98.7629 158.282C-98.7629 158.282 -26.6373 157.079 21.8628 191.58C70.3629 226.081 116.863 311.08 116.863 311.08C116.863 311.08 126.863 331.08 139.363 332.08C151.863 333.08 157.363 322.081 157.363 322.081L166.863 304.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <path
          d="M-98.7629 172.282C-98.7629 172.282 -26.6373 171.079 21.8628 205.58C70.3629 240.081 116.863 325.08 116.863 325.08C116.863 325.08 126.863 345.08 139.363 346.08C151.863 347.08 157.363 336.081 157.363 336.081L166.863 318.08"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="23"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="12.1855"
          r="148"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="12.1855"
          r="128"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="108"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="88"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="68"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="53"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="215"
          cy="10.1855"
          r="38"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="23"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="203.186"
          r="148"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="203.186"
          r="128"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="108"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="88"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="68"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="53"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
        <circle
          cx="-13"
          cy="201.186"
          r="38"
          fill="white"
          stroke="#00CDD7"
          stroke-width="4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_267_3554">
        <rect
          width="242"
          height="188"
          fill="white"
          transform="translate(0 0.185547)"
        />
      </clipPath>
      <clipPath id="clip1_267_3554">
        <rect
          width="286"
          height="189"
          fill="white"
          transform="translate(-22 0.185547)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
