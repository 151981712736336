<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6015)">
      <path
        d="M9.16797 15.4165V19.5832"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5 19.5833H13.3333"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 6.6665H14.5833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.582 5.83325H16.2487C16.4697 5.83325 16.6817 5.92105 16.838 6.07733C16.9942 6.23361 17.082 6.44557 17.082 6.66659V9.16659C17.082 9.60861 16.9064 10.0325 16.5939 10.3451C16.2813 10.6577 15.8574 10.8333 15.4154 10.8333H14.582"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 11.25C3.75 13.5521 5.94271 15.4167 9.16667 15.4167C12.3906 15.4167 14.5833 13.5521 14.5833 11.25V3.75H3.75V11.25Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.72266 1.2603C8.35373 0.712042 9.16274 0.412136 9.9987 0.416552C11.8372 0.416552 13.332 1.81759 13.332 3.54155V3.74988"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.71354 2.35913C9.29167 1.69767 8.45833 1.24976 7.5 1.24976C6.11979 1.24976 5 2.18205 5 3.33309V3.74976"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6015">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
