<template>
  <svg
    width="44"
    height="32"
    viewBox="0 0 44 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 24.2499H14.5C14.3011 24.2499 14.1103 24.1709 13.9697 24.0302C13.829 23.8896 13.75 23.6988 13.75 23.4999V19.3105C13.75 19.2121 13.7694 19.1145 13.8071 19.0235C13.8448 18.9325 13.9 18.8499 13.9697 18.7802L25.2197 7.53022C25.3603 7.38956 25.5511 7.31055 25.75 7.31055C25.9489 7.31055 26.1397 7.38956 26.2803 7.53022L30.4697 11.7196C30.6103 11.8602 30.6893 12.051 30.6893 12.2499C30.6893 12.4488 30.6103 12.6396 30.4697 12.7802L19 24.2499Z"
      stroke="#595A5B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.75 10L28 15.25"
      stroke="#595A5B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
