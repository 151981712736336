<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4338 5.3855L14.689 6.86467"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.6528 11.3752L15.4653 10.7554"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0278 17.3178L13.5903 14.7189"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.16846 15.8336V18.6513"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.25179 8.75012L2.91846 9.62512"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M0.83497 6.45844C0.83497 8.1876 2.01726 9.21885 2.9183 9.6251V10.0001C2.9183 15.4532 6.40789 18.7501 10.3246 18.7501C15.1579 18.7501 18.7516 14.8334 18.7516 10.0001C18.751 8.13448 18.1544 6.31785 17.0488 4.81508C15.9433 3.3123 14.3866 2.20196 12.6058 1.64594C11.2777 2.78656 10.4183 4.60948 10.4183 6.66677C10.4184 7.10527 10.4585 7.54285 10.5381 7.97406C11.0948 8.09637 11.593 8.40526 11.9502 8.84953C12.3073 9.2938 12.5019 9.84676 12.5016 10.4168C12.5016 11.797 11.3037 13.3334 9.65268 13.3334C7.59018 13.3334 6.25164 11.6668 6.25164 8.95843V8.7501C6.76726 8.15114 7.08497 7.15635 7.08497 6.2501C7.08482 5.66043 6.94561 5.0791 6.67865 4.55332C6.41169 4.02754 6.0245 3.57213 5.54851 3.22406C4.77768 3.63552 4.02768 4.54698 3.75164 5.41677C3.05372 4.85427 2.31935 4.56781 1.33497 4.58344C1.00599 5.15346 0.833501 5.80029 0.83497 6.45844V6.45844Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
