<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.49951 1.66656H17.4995V18.3332H2.49951V1.66656Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.41602 4.37504C5.41602 4.09877 5.52576 3.83382 5.72111 3.63847C5.91646 3.44312 6.18142 3.33337 6.45768 3.33337H13.541C13.8173 3.33337 14.0822 3.44312 14.2776 3.63847C14.4729 3.83382 14.5827 4.09877 14.5827 4.37504C14.5827 4.65131 14.4729 4.91626 14.2776 5.11161C14.0822 5.30696 13.8173 5.41671 13.541 5.41671H6.45768C6.18142 5.41671 5.91646 5.30696 5.72111 5.11161C5.52576 4.91626 5.41602 4.65131 5.41602 4.37504Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.58325 13.3334H15.4166"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99992 8.33337C12.302 8.33337 14.1666 9.78129 14.1666 12.0834V14.5834C14.1666 14.9149 14.0349 15.2328 13.8005 15.4673C13.566 15.7017 13.2481 15.8334 12.9166 15.8334H7.08325C6.75173 15.8334 6.43379 15.7017 6.19937 15.4673C5.96495 15.2328 5.83325 14.9149 5.83325 14.5834V12.0834C5.83325 9.78129 7.69784 8.33337 9.99992 8.33337Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M8.75008 10.4166H7.91675V11.2499H8.75008V10.4166Z"
      fill="#0E0E22"
    />

    <path
      d="M12.0828 9.99988H11.2495V10.8332H12.0828V9.99988Z"
      fill="#0E0E22"
    />

    <path
      d="M10.4166 11.2498H9.58325V12.0832H10.4166V11.2498Z"
      fill="#0E0E22"
    />
  </svg>
</template>
