<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66675 4.83321H13.0001C13.4001 4.83321 13.6667 4.56654 13.6667 4.16654C13.6667 3.76654 13.4001 3.49988 13.0001 3.49988H3.66675C3.26675 3.49988 3.00008 3.76654 3.00008 4.16654C3.00008 4.56654 3.26675 4.83321 3.66675 4.83321Z"
      fill="#595A5B"
    />
    <path
      d="M0.333414 1.49992C0.333414 1.09992 0.600081 0.833252 1.00008 0.833252H13.0001C13.4001 0.833252 13.6667 1.09992 13.6667 1.49992C13.6667 1.89992 13.4001 2.16659 13.0001 2.16659H1.00008C0.600081 2.16659 0.333414 1.89992 0.333414 1.49992Z"
      fill="#595A5B"
    />
    <path
      d="M0.333414 6.83329C0.333414 6.43329 0.600081 6.16663 1.00008 6.16663H13.0001C13.4001 6.16663 13.6667 6.43329 13.6667 6.83329C13.6667 7.23329 13.4001 7.49996 13.0001 7.49996H1.00008C0.600081 7.49996 0.333414 7.23329 0.333414 6.83329Z"
      fill="#595A5B"
    />
    <path
      d="M3.00008 9.49992C3.00008 9.09992 3.26675 8.83325 3.66675 8.83325H13.0001C13.4001 8.83325 13.6667 9.09992 13.6667 9.49992C13.6667 9.89992 13.4001 10.1666 13.0001 10.1666H3.66675C3.26675 10.1666 3.00008 9.89992 3.00008 9.49992Z"
      fill="#595A5B"
    />
  </svg>
</template>
