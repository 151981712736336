<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.251 4.16693V1.66693H8.75098V10.2867"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.08447 5.00027V2.91693H4.58447V8.34922"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.418 8.3492V2.50024H12.918V4.16691"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.75098 5.00024H6.25098V9.10441"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.7355 8.33362C15.0409 8.33981 14.3703 8.58852 13.8397 9.03674C12.76 9.93019 11.4025 10.419 10.0011 10.419C8.59975 10.419 7.24225 9.93019 6.1626 9.03674C5.63195 8.58852 4.96135 8.33981 4.26676 8.33362H2.80322L4.45947 17.719C4.51096 18.0087 4.66297 18.2709 4.88876 18.4596C5.11455 18.6482 5.39964 18.7511 5.69385 18.7503H14.3084C14.6017 18.7499 14.8856 18.6464 15.1103 18.4579C15.335 18.2694 15.4863 18.0078 15.5376 17.719L17.1991 8.33362H15.7355Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.251 10.2867V4.16693H13.751V9.10443"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
