<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1663 2.08313L17.9788 18.3331H2.02051L3.83301 2.08313H16.1663Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.83301 4.79154C5.83301 4.51528 5.94275 4.25033 6.1381 4.05498C6.33346 3.85962 6.59841 3.74988 6.87467 3.74988H13.1247C13.4009 3.74988 13.6659 3.85962 13.8612 4.05498C14.0566 4.25033 14.1663 4.51528 14.1663 4.79154C14.1663 5.06781 14.0566 5.33276 13.8612 5.52811C13.6659 5.72346 13.4009 5.83321 13.1247 5.83321H6.87467C6.59841 5.83321 6.33346 5.72346 6.1381 5.52811C5.94275 5.33276 5.83301 5.06781 5.83301 4.79154V4.79154Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
