<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6.50006L6.00007 14.5M6.00003 6.50003L14 14.5"
      stroke="#A0A0A0"
      stroke-width="1.2"
      stroke-linecap="round"
    />
  </svg>
</template>
