<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56386)">
      <path
        d="M18.7489 6.66671C15.5457 6.66671 14.1655 5.27087 14.1655 2.08337C17.327 2.08337 18.7489 3.51567 18.7489 6.66671Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.1651 2.08337C14.1654 2.45834 14.0601 2.82581 13.8611 3.14363C13.6621 3.46145 13.3776 3.71675 13.0401 3.88025L10.0193 5.349"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.42074 15.3073C5.42074 15.3438 5.41553 15.3802 5.41553 15.4167C5.41553 16.0797 5.67892 16.7156 6.14776 17.1845C6.6166 17.6533 7.25249 17.9167 7.91553 17.9167C8.57857 17.9167 9.21445 17.6533 9.6833 17.1845C10.1521 16.7156 10.4155 16.0797 10.4155 15.4167C10.4155 15.3646 10.4051 15.323 10.4051 15.2709"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.87923 15.5625C2.59965 15.806 2.38348 16.1138 2.24936 16.4595C2.11523 16.8051 2.06716 17.1782 2.1093 17.5465C2.15144 17.9149 2.28252 18.2675 2.49124 18.5739C2.69997 18.8803 2.98008 19.1314 3.30742 19.3054C3.63477 19.4795 3.99954 19.5714 4.37028 19.5731C4.74103 19.5748 5.10664 19.4864 5.43559 19.3154C5.76455 19.1443 6.04698 18.8959 6.25856 18.5914C6.47013 18.287 6.60449 17.9356 6.65006 17.5677"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.25406 7.31239C5.95292 7.04004 5.58997 6.84513 5.19659 6.74452C4.80322 6.64392 4.39125 6.64064 3.99633 6.73497C3.6014 6.82931 3.23539 7.01841 2.92995 7.28594C2.62451 7.55347 2.38883 7.89137 2.24328 8.27043C2.09774 8.64948 2.04671 9.05828 2.09461 9.46149C2.14251 9.86469 2.28789 10.2502 2.5182 10.5846C2.7485 10.919 3.05679 11.1922 3.41641 11.3808C3.77603 11.5693 4.17615 11.6674 4.58219 11.6666C4.72533 11.6666 4.86821 11.6544 5.00927 11.6301"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.49334 5.33842C7.48166 4.93458 7.37228 4.53959 7.17457 4.18726C6.97686 3.83494 6.6967 3.53578 6.35809 3.3154C6.01949 3.09502 5.63252 2.95999 5.23031 2.92186C4.82811 2.88374 4.42265 2.94365 4.04867 3.09648C3.67468 3.24931 3.34331 3.49051 3.08292 3.7994C2.82253 4.1083 2.64088 4.47571 2.55352 4.87016C2.46617 5.26461 2.47571 5.67435 2.58134 6.06431C2.68696 6.45426 2.88552 6.81281 3.16001 7.10925"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.29069 13.7499C8.55634 13.7499 9.58236 12.7239 9.58236 11.4582C9.58236 10.1926 8.55634 9.16656 7.29069 9.16656C6.02504 9.16656 4.99902 10.1926 4.99902 11.4582C4.99902 12.7239 6.02504 13.7499 7.29069 13.7499Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.55632 11.1198C9.96933 11.4745 10.4963 11.6687 11.0407 11.6667C11.5144 11.6716 11.9782 11.5309 12.3694 11.2636C12.7606 10.9963 13.0602 10.6153 13.2277 10.1722C13.3953 9.72902 13.4227 9.24511 13.3062 8.78588C13.1898 8.32665 12.9351 7.91427 12.5766 7.60452C12.2181 7.29477 11.7732 7.1026 11.3019 7.05401C10.8306 7.00541 10.3558 7.10274 9.94166 7.33281C9.52752 7.56289 9.19403 7.91462 8.98631 8.34042C8.7786 8.76622 8.70667 9.24555 8.78027 9.71356"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.76929 13.2084C8.81671 13.6205 8.96592 14.0143 9.20348 14.3543C9.44103 14.6943 9.75946 14.9699 10.1301 15.1562C10.5007 15.3425 10.9118 15.4337 11.3264 15.4215C11.7411 15.4093 12.1461 15.2941 12.5052 15.0863C12.8642 14.8785 13.1658 14.5847 13.383 14.2313C13.6001 13.8779 13.7259 13.476 13.749 13.0618C13.7721 12.6476 13.6918 12.2342 13.5153 11.8589C13.3388 11.4835 13.0717 11.1579 12.738 10.9115"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.2119 7.08847C11.1485 6.70357 10.9959 6.33884 10.7664 6.02345C10.5368 5.70807 10.2366 5.45078 9.88984 5.27217C9.54305 5.09355 9.15927 4.99856 8.7692 4.99481C8.37914 4.99105 7.9936 5.07862 7.64343 5.25052C7.29327 5.42242 6.98818 5.67388 6.75259 5.98478C6.51699 6.29568 6.35742 6.65741 6.28663 7.04101C6.21584 7.42462 6.2358 7.81947 6.34491 8.19399C6.45403 8.5685 6.64927 8.91229 6.91502 9.19785"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.6287 13.6875C13.8047 13.7278 13.9846 13.7488 14.1651 13.75C14.5316 13.7505 14.8938 13.6705 15.2259 13.5155C15.558 13.3605 15.852 13.1344 16.0871 12.8532C16.3221 12.572 16.4924 12.2425 16.586 11.8881C16.6795 11.5337 16.694 11.1631 16.6284 10.8026C16.5628 10.442 16.4187 10.1002 16.2064 9.80149C15.994 9.50277 15.7186 9.25439 15.3996 9.07396C15.0805 8.89352 14.7258 8.78543 14.3603 8.75735C13.9949 8.72927 13.6278 8.78188 13.2849 8.91146"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.34261 11.3384C2.93127 11.4529 2.5601 11.6801 2.27095 11.9943C1.98181 12.3085 1.78619 12.6972 1.70615 13.1166C1.62611 13.536 1.66484 13.9694 1.81796 14.368C1.97109 14.7666 2.23252 15.1145 2.57279 15.3724C2.91305 15.6303 3.31861 15.7881 3.74373 15.8279C4.16886 15.8676 4.59664 15.7878 4.97884 15.5975C5.36105 15.4071 5.68247 15.1138 5.90687 14.7505C6.13126 14.3873 6.24971 13.9685 6.24886 13.5416C6.24886 13.5259 6.24886 13.5155 6.24886 13.4999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.1657 0V2.91667C14.1681 4.44388 13.5694 5.9107 12.499 7L12.1396 7.35938"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56386">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
