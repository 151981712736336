<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.9168 26V25.9784M47.6668 36.4V15.6C47.6668 13.1731 47.6668 11.9597 47.1945 11.0327C46.7791 10.2174 46.1162 9.55446 45.3008 9.13901C44.3738 8.66671 43.1604 8.66671 40.7335 8.66671H11.2668C8.83993 8.66671 7.62649 8.66671 6.69954 9.13901C5.88417 9.55446 5.22125 10.2174 4.8058 11.0327C4.3335 11.9597 4.3335 13.1731 4.3335 15.6L4.3335 36.4C4.3335 38.8269 4.3335 40.0404 4.8058 40.9673C5.22125 41.7827 5.88417 42.4456 6.69954 42.8611C7.62649 43.3334 8.83994 43.3334 11.2668 43.3334H40.7335C43.1604 43.3334 44.3738 43.3334 45.3008 42.8611C46.1162 42.4456 46.7791 41.7827 47.1945 40.9673C47.6668 40.0404 47.6668 38.8269 47.6668 36.4ZM37.9168 24.9167C38.5151 24.9167 39.0002 25.4017 39.0002 26C39.0002 26.5983 38.5151 27.0834 37.9168 27.0834C37.3185 27.0834 36.8335 26.5983 36.8335 26C36.8335 25.4017 37.3185 24.9167 37.9168 24.9167Z"
      stroke="#00CDD7"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
