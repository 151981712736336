export default {
  mounted: (el) => {
    el.addEventListener("keydown", (event) => {
      //       console.log("Время заказа:keydown:ОНЛИ", el.value);

      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab" ||
        event.key === "Enter"
      ) {
        return;
      }

      if (
        !(
          (event.keyCode >= 48 && event.keyCode <= 57) ||
          (event.keyCode >= 96 && event.keyCode <= 105)
        )
      ) {
        event.preventDefault();
      }
    });
  },
};
