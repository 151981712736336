<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.68376 0C1.59642 -8.52344e-07 0.500122 1.31332 0.500122 3.28253V20.7175C0.500122 22.6867 1.59642 24 3.68376 24H28.3165C30.4038 24 31.5001 22.6867 31.5001 20.7175V3.28253C31.5001 1.31332 30.4038 -4.26172e-07 28.3165 0H3.68376Z"
      fill="#D9D6CC"
    />
    <path
      d="M3.93508 1.3457C2.28289 1.3457 1.79083 2.36662 1.79083 3.6348V11.9805L9.08237 11.9944C11.0304 11.9981 11.828 12.8061 12.6398 13.9368C12.8465 14.2247 13.098 14.615 13.3436 14.8684C14.0769 15.6252 15.0385 16.0033 16.0001 16.0033C16.9618 16.0033 17.9234 15.6252 18.6567 14.8684C18.9023 14.615 19.1538 14.2247 19.3605 13.9368C20.1722 12.8061 20.9699 11.9981 22.9179 11.9944L30.2094 11.9805V3.6348C30.2094 2.36662 29.7174 1.3457 28.0652 1.3457H3.93508Z"
      fill="#F16D5E"
    />
    <path
      d="M3.93508 1.3457C2.28289 1.3457 1.79083 2.36662 1.79083 3.6348V6.30863C1.79083 5.04045 2.28289 4.01953 3.93508 4.01953H28.0652C29.7174 4.01953 30.2094 5.04045 30.2094 6.30863V3.6348C30.2094 2.36662 29.7174 1.3457 28.0652 1.3457H3.93508Z"
      fill="#3295C9"
    />
    <mask
      id="mask0_11_1399"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="30"
      height="6"
    >
      <path
        d="M3.93508 1.3457C2.28289 1.3457 1.79083 2.36662 1.79083 3.6348V6.30863C1.79083 5.04045 2.28289 4.01953 3.93508 4.01953H28.0652C29.7174 4.01953 30.2094 5.04045 30.2094 6.30863V3.6348C30.2094 2.36662 29.7174 1.3457 28.0652 1.3457H3.93508Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_11_1399)">
      <g filter="url(#filter0_f_11_1399)">
        <path
          d="M3.93508 4.01953C2.28289 4.01953 1.79083 5.04045 1.79083 6.30863V8.98246C1.79083 7.71428 2.28289 6.69336 3.93508 6.69336H28.0652C29.7174 6.69336 30.2094 7.71428 30.2094 8.98246V6.30863C30.2094 5.04045 29.7174 4.01953 28.0652 4.01953H3.93508Z"
          fill="black"
          fill-opacity="0.317757"
        />
      </g>
    </g>
    <path
      d="M3.93508 4.01953C2.28289 4.01953 1.79083 5.04045 1.79083 6.30863V8.98246C1.79083 7.71428 2.28289 6.69336 3.93508 6.69336H28.0652C29.7174 6.69336 30.2094 7.71428 30.2094 8.98246V6.30863C30.2094 5.04045 29.7174 4.01953 28.0652 4.01953H3.93508Z"
      fill="#FCAD00"
    />
    <mask
      id="mask1_11_1399"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="4"
      width="30"
      height="5"
    >
      <path
        d="M3.93508 4.01953C2.28289 4.01953 1.79083 5.04045 1.79083 6.30863V8.98246C1.79083 7.71428 2.28289 6.69336 3.93508 6.69336H28.0652C29.7174 6.69336 30.2094 7.71428 30.2094 8.98246V6.30863C30.2094 5.04045 29.7174 4.01953 28.0652 4.01953H3.93508Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_11_1399)">
      <g filter="url(#filter1_f_11_1399)">
        <path
          d="M3.93508 6.69324C2.28289 6.69324 1.79083 7.71415 1.79083 8.98233V11.6562C1.79083 10.388 2.28289 9.36707 3.93508 9.36707H28.0652C29.7174 9.36707 30.2094 10.388 30.2094 11.6562V8.98233C30.2094 7.71415 29.7174 6.69324 28.0652 6.69324H3.93508Z"
          fill="black"
          fill-opacity="0.313725"
        />
      </g>
    </g>
    <path
      d="M3.93508 6.69336C2.28289 6.69336 1.79083 7.71428 1.79083 8.98245V11.6563C1.79083 10.3881 2.28289 9.36719 3.93508 9.36719H28.0652C29.7174 9.36719 30.2094 10.3881 30.2094 11.6563V8.98245C30.2094 7.71428 29.7174 6.69336 28.0652 6.69336H3.93508Z"
      fill="#50BE3D"
    />
    <mask
      id="mask2_11_1399"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="6"
      width="30"
      height="6"
    >
      <path
        d="M3.93508 6.69336C2.28289 6.69336 1.79083 7.71428 1.79083 8.98246V11.6563C1.79083 10.3881 2.28289 9.36719 3.93508 9.36719H28.0652C29.7174 9.36719 30.2094 10.3881 30.2094 11.6563V8.98246C30.2094 7.71428 29.7174 6.69336 28.0652 6.69336H3.93508Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_11_1399)">
      <g filter="url(#filter2_f_11_1399)">
        <path
          d="M3.93508 9.36707C2.28289 9.36707 1.79083 10.388 1.79083 11.6562V14.33C1.79083 13.0618 2.28289 12.0409 3.93508 12.0409H28.0652C29.7174 12.0409 30.2094 13.0618 30.2094 14.33V11.6562C30.2094 10.388 29.7174 9.36707 28.0652 9.36707H3.93508Z"
          fill="black"
          fill-opacity="0.313725"
        />
      </g>
    </g>
    <mask
      id="mask3_11_1399"
      style="mask-type: luminance"
      maskUnits="userSpaceOnUse"
      x="1"
      y="9"
      width="30"
      height="8"
    >
      <path
        d="M3.93508 9.36719C2.28289 9.36719 1.79083 10.3881 1.79083 11.6563V11.9804L9.08237 11.9943C11.0304 11.9981 11.828 12.8061 12.6398 13.9368C12.8465 14.2247 13.098 14.615 13.3436 14.8684C14.0769 15.6252 15.0385 16.0033 16.0001 16.0033C16.9618 16.0033 17.9234 15.6252 18.6567 14.8684C18.9023 14.615 19.1538 14.2247 19.3605 13.9368C20.1722 12.8061 20.9699 11.9981 22.9179 11.9943L30.2094 11.9804V11.6563C30.2094 10.3881 29.7174 9.36719 28.0652 9.36719H3.93508Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_11_1399)">
      <g filter="url(#filter3_f_11_1399)">
        <path
          d="M3.68376 0C1.59642 -8.52344e-07 0.500122 1.31332 0.500122 3.28253V20.7175C0.500122 22.6867 1.59642 24 3.68376 24H28.3165C30.4038 24 31.5001 22.6867 31.5001 20.7175V3.28253C31.5001 1.31332 30.4038 -4.26172e-07 28.3165 0H3.68376ZM3.93507 1.34569H28.0652C29.7174 1.34569 30.2094 2.36661 30.2094 3.63478V11.9804L22.9179 11.9943C20.9699 11.9981 20.1722 12.8061 19.3605 13.9368C19.1537 14.2247 18.9023 14.615 18.6567 14.8684C17.9234 15.6252 16.9618 16.0033 16.0001 16.0033C15.0385 16.0033 14.0769 15.6252 13.3436 14.8684C13.098 14.615 12.8465 14.2247 12.6398 13.9368C11.828 12.8061 11.0304 11.9981 9.08236 11.9943L1.79082 11.9804V3.63478C1.79082 2.36661 2.28287 1.34569 3.93507 1.34569Z"
          fill="#F26D5F"
          fill-opacity="0.298039"
        />
      </g>
    </g>
    <path
      d="M0.500122 10.0214H1.79082V11.9805H0.500122V10.0214Z"
      fill="url(#paint0_linear_11_1399)"
    />
    <path
      d="M30.2094 10.0215H31.5001V11.9806H30.2094V10.0215Z"
      fill="url(#paint1_linear_11_1399)"
    />
    <defs>
      <filter
        id="filter0_f_11_1399"
        x="-3.96606"
        y="-1.73736"
        width="39.9324"
        height="16.4767"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2.87844"
          result="effect1_foregroundBlur_11_1399"
        />
      </filter>
      <filter
        id="filter1_f_11_1399"
        x="-3.96606"
        y="0.936349"
        width="39.9324"
        height="16.4767"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2.87844"
          result="effect1_foregroundBlur_11_1399"
        />
      </filter>
      <filter
        id="filter2_f_11_1399"
        x="-3.96606"
        y="3.61018"
        width="39.9324"
        height="16.4767"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2.87844"
          result="effect1_foregroundBlur_11_1399"
        />
      </filter>
      <filter
        id="filter3_f_11_1399"
        x="-6.65706"
        y="-7.15718"
        width="45.3144"
        height="38.3144"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3.57859"
          result="effect1_foregroundBlur_11_1399"
        />
      </filter>
      <linearGradient
        id="paint0_linear_11_1399"
        x1="0.500122"
        y1="11.9805"
        x2="0.500122"
        y2="10.965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBC9BE" />
        <stop
          offset="1"
          stop-color="#C8C5BB"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11_1399"
        x1="30.2094"
        y1="11.9806"
        x2="30.2094"
        y2="10.9652"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBC9BE" />
        <stop
          offset="1"
          stop-color="#C8C5BB"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
