<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="28"
      height="28"
      rx="14"
      transform="matrix(-1 0 0 1 28 0)"
      fill="#F8F8F8"
    />
    <path
      d="M11.5334 17.5333C11.2667 17.7999 11.2667 18.1999 11.5334 18.4666C11.6667 18.5999 11.8001 18.6666 12.0001 18.6666C12.2001 18.6666 12.3334 18.5999 12.4667 18.4666L16.4667 14.4666C16.7334 14.1999 16.7334 13.7999 16.4667 13.5333L12.4667 9.53325C12.2001 9.26659 11.8001 9.26659 11.5334 9.53325C11.2667 9.79992 11.2667 10.1999 11.5334 10.4666L15.0667 13.9999L11.5334 17.5333Z"
      fill="black"
    />
  </svg>
</template>
