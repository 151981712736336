<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.90322 8.58751C5.07197 8.23126 5.26884 7.89376 5.49384 7.56563L5.45634 5.12813C5.45634 4.90313 5.55009 4.68751 5.72822 4.53751C6.60009 3.80626 7.59384 3.21563 8.69072 2.82188C8.90634 2.74688 9.14072 2.76563 9.32822 2.88751L11.4188 4.14376C11.8126 4.11563 12.2063 4.11563 12.6001 4.14376L14.6907 2.88751C14.8876 2.77501 15.122 2.74688 15.3376 2.82188C16.4063 3.20626 17.4095 3.77813 18.3001 4.52813C18.4688 4.66876 18.572 4.89376 18.5626 5.11876L18.5251 7.55626C18.7501 7.88438 18.947 8.22188 19.1157 8.57813L21.2438 9.75938C21.4407 9.87188 21.5813 10.0594 21.6188 10.2844C21.8157 11.4 21.8251 12.5625 21.6188 13.6969C21.5813 13.9219 21.4407 14.1094 21.2438 14.2219L19.1157 15.4031C18.947 15.7594 18.7501 16.0969 18.5251 16.425L18.5626 18.8625C18.5626 19.0875 18.4688 19.3031 18.2907 19.4531C17.4188 20.1844 16.4251 20.775 15.3282 21.1688C15.1126 21.2438 14.8782 21.225 14.6907 21.1031L12.6001 19.8469C12.2063 19.875 11.8126 19.875 11.4188 19.8469L9.32822 21.1031C9.13134 21.2156 8.89697 21.2438 8.68134 21.1688C7.61259 20.7844 6.60947 20.2125 5.71884 19.4625C5.55009 19.3219 5.44697 19.0969 5.45634 18.8719L5.49384 16.4344C5.26884 16.1063 5.07197 15.7688 4.90322 15.4125L2.77509 14.2313C2.57822 14.1188 2.43759 13.9313 2.40009 13.7063C2.20322 12.5906 2.19384 11.4281 2.40009 10.2938C2.43759 10.0688 2.57822 9.88126 2.77509 9.76876L4.90322 8.58751Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
