<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99951 16.2496V14.9996"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99951 7.91626V9.16626"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.4995 10.8331V10.4164C12.4995 10.0849 12.3678 9.76698 12.1334 9.53256C11.899 9.29814 11.581 9.16644 11.2495 9.16644H8.74951C8.41799 9.16644 8.10005 9.29814 7.86563 9.53256C7.63121 9.76698 7.49951 10.0849 7.49951 10.4164V10.8331C7.49951 11.1646 7.63121 11.4826 7.86563 11.717C8.10005 11.9514 8.41799 12.0831 8.74951 12.0831H11.2495C11.581 12.0831 11.899 12.2148 12.1334 12.4492C12.3678 12.6836 12.4995 13.0016 12.4995 13.3331V13.7498C12.4995 14.0813 12.3678 14.3992 12.1334 14.6337C11.899 14.8681 11.581 14.9998 11.2495 14.9998H8.74951C8.41799 14.9998 8.10005 14.8681 7.86563 14.6337C7.63121 14.3992 7.49951 14.0813 7.49951 13.7498V13.3331"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.62451 4.99963C9.07011 4.44736 8.75572 3.69882 8.74951 2.9163V2.49963"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.1659 1.24963C14.1659 3.32255 13.4211 4.99963 12.4992 4.99963H7.49919C6.57731 4.99963 5.83252 3.32255 5.83252 1.24963H14.1659Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.4993 4.99963C15.0306 4.99963 17.0827 8.35901 17.0827 12.4996V17.4996C17.0827 17.8312 16.951 18.1491 16.7166 18.3835C16.4821 18.6179 16.1642 18.7496 15.8327 18.7496H4.16602C3.83449 18.7496 3.51655 18.6179 3.28213 18.3835C3.04771 18.1491 2.91602 17.8312 2.91602 17.4996V12.4996C2.91602 8.35901 4.9681 4.99963 7.49935 4.99963"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
