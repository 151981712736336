import { AccessPermissionsEnum } from "@/router/constants";
function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "staff",
    name: "staff",
    component: load("staff/View"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    redirect: {
      name: "staff:list",
    },
    children: [
      {
        path: "staff-list",
        name: "staff:list",
        component: load("staff/employees/Staff"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.staff",
        },
      },
      {
        path: "staff-calls",
        name: "staff:calls",
        component: load("staff/calls/Calls"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.staff",
        },
      },
      {
        path: "tasks",
        name: "staff:tasks",
        component: load("staff/tasks/Tasks"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_TASKS],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.staff",
        },
      },
      {
        path: "news",
        name: "staff:news",
        component: load("staff/news/News"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_NEWS],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.staff",
        },
      },
      {
        path: "notifications",
        name: "staff:notifications",
        component: load("staff/notifications/Notifications"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_NOTIFICATIONS],
          icon: "user-circle-gear",
          menuItems: "leftSideMenu.staff",
        },
      },
      {
        path: "feedbacks",
        name: "feedbacks:staff",
        component: load("staff/feedback/Feedbacks"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_FEEDBACK],
          icon: "chat",
          menuItems: "leftSideMenu.staff",
        },
        children: [],
      },
    ],
  },
  {
    path: "staff/employee/create",
    name: "new-employee",
    component: load("staff/employees/EditEmployee"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  {
    path: "staff/employee/edit/:staffId",
    name: "edit-employee",
    component: load("staff/employees/EditEmployee"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  /* Tasks */
  {
    path: "staff/tasks/create",
    name: "new-task",
    component: load("staff/tasks/EditTask"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_TASKS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  {
    path: "staff/tasks/edit/:taskId",
    name: "edit-task",
    props: true,
    component: load("staff/tasks/EditTask"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_TASKS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  /* News */
  {
    path: "staff/news/create",
    name: "new-news",
    component: load("staff/news/EditNews"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_NOTIFICATIONS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  {
    path: "staff/news/edit/:newsId",
    name: "edit-news",
    props: true,
    component: load("staff/news/EditNews"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_EDIT_NEWS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  /* Notifications */
  {
    path: "staff/notifications/create",
    name: "new-notifications",
    component: load("staff/notifications/EditNotifications"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_NOTIFICATIONS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  {
    path: "staff/notifications/edit/:notificationsId",
    name: "edit-notifications",
    props: true,
    component: load("staff/notifications/EditNotifications"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_EDIT_NEWS],
      icon: "user-circle-gear",
      menuItems: "leftSideMenu.staff",
    },
    children: [],
  },
  /* feedback */
  {
    path: "staff/feedbacks/view/:feedbackId",
    name: "feedbacks-view",
    component: load("staff/feedback/ViewFeedback"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_FEEDBACK],
      icon: "chat",
      menuItems: "leftSideMenu.staff",
    },
  },
  {
    path: "staff/calls/halls/create",
    name: "staff:calls:hall",
    component: load("staff/calls/EditHall"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "chat",
      menuItems: "leftSideMenu.staff",
    },
  },
  {
    path: "staff/calls/halls/edit/:hallId",
    name: "staff:calls:hall:edit",
    component: load("staff/calls/EditHall"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "chat",
      menuItems: "leftSideMenu.staff",
    },
  },
  {
    path: "staff/calls/shift/create",
    name: "staff:calls:shift",
    component: load("staff/calls/EditShift"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "chat",
      menuItems: "leftSideMenu.staff",
    },
  },
  {
    path: "staff/calls/shift/edit/:shiftId",
    name: "staff:calls:shift:edit",
    component: load("staff/calls/EditShift"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_STAFF],
      icon: "chat",
      menuItems: "leftSideMenu.staff",
    },
  },
];
// feedback
