<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2517_44591)">
      <path
        d="M5.83382 8.3335H5.00049V9.16683H5.83382V8.3335Z"
        fill="#0E0E22"
      />
      <path
        d="M7.20923 4.59367C7.1311 4.32804 7.08423 4.04159 7.08423 3.74992C7.08423 2.14054 8.39152 0.833252 10.0009 0.833252C11.6103 0.833252 12.9176 2.14054 12.9176 3.74992C12.9176 4.04159 12.8759 4.32804 12.7926 4.59367"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.4899 5.21338C19.1305 5.60921 19.5837 6.64046 19.5837 7.854C19.5837 9.40609 18.8337 10.6613 17.917 10.6613"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0003 4.16654C8.51595 4.16654 7.13053 4.5155 5.94303 5.11446C5.01595 4.29154 3.88574 3.69259 2.63053 3.40613C3.07324 4.29675 3.51074 5.40613 3.70866 6.79675C3.18262 7.38009 2.76074 8.03634 2.4847 8.74988H1.25033C0.791992 8.74988 0.416992 9.12488 0.416992 9.58321V12.0832C0.416992 12.5415 0.791992 12.9165 1.25033 12.9165H2.4847C2.96387 14.1353 3.84408 15.203 5.00033 15.9947V18.7499H7.91699V17.4999H12.0837V18.7499H15.0003V15.9999C16.7816 14.7759 17.917 12.9165 17.917 10.8332C17.917 7.15092 14.3701 4.16654 10.0003 4.16654Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2517_44591">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
