<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25024 10.0003C1.25024 9.66878 1.38194 9.35084 1.61636 9.11642C1.85078 8.882 2.16872 8.75031 2.50024 8.75031H17.5002C17.8318 8.75031 18.1497 8.882 18.3841 9.11642C18.6185 9.35084 18.7502 9.66878 18.7502 10.0003C18.7502 10.3318 18.6185 10.6498 18.3841 10.8842C18.1497 11.1186 17.8318 11.2503 17.5002 11.2503H2.50024C2.16872 11.2503 1.85078 11.1186 1.61636 10.8842C1.38194 10.6498 1.25024 10.3318 1.25024 10.0003Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.37524 8.75549L3.72941 2.73466C3.7708 2.55002 3.87381 2.38496 4.02147 2.26663C4.16914 2.14831 4.35268 2.08377 4.54191 2.08362H15.4586C15.6478 2.08377 15.8313 2.14831 15.979 2.26663C16.1267 2.38496 16.2297 2.55002 16.2711 2.73466L17.6252 8.75549"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.37524 11.2451L3.72941 17.2659C3.7708 17.4505 3.87381 17.6156 4.02147 17.7339C4.16914 17.8522 4.35268 17.9168 4.54191 17.9169H15.4586C15.6478 17.9168 15.8313 17.8522 15.979 17.7339C16.1267 17.6156 16.2297 17.4505 16.2711 17.2659L17.6252 11.2451"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.25024 17.917V15.0003"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.75024 17.917V15.0003"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.2502 17.917V15.0003"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.7502 17.917V15.0003"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
