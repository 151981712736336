<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56262)">
      <path
        d="M9.99967 16.6668C13.6816 16.6668 16.6663 13.6821 16.6663 10.0002C16.6663 6.31826 13.6816 3.3335 9.99967 3.3335C6.31778 3.3335 3.33301 6.31826 3.33301 10.0002C3.33301 13.6821 6.31778 16.6668 9.99967 16.6668Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.49951 3.50018L16.4995 11.5002"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.4995 16.5001L3.49951 8.50012"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99967 11.6668C10.9201 11.6668 11.6663 10.9206 11.6663 10.0002C11.6663 9.07969 10.9201 8.3335 9.99967 8.3335C9.0792 8.3335 8.33301 9.07969 8.33301 10.0002C8.33301 10.9206 9.0792 11.6668 9.99967 11.6668Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.09863 13.0991L0.864258 14.6147L5.38509 19.1356L6.90072 15.9012"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.9009 6.896L19.1405 5.39079L14.6144 0.864746L13.104 4.09912"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56262">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
