<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5014 7.50004C13.7149 7.50004 12.0847 5.849 12.0847 2.08337C15.8191 2.08337 17.5014 3.77608 17.5014 7.50004Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.7511 14.5834C8.75107 13.9576 8.59422 13.3418 8.29488 12.7923C7.99555 12.2427 7.56327 11.777 7.03756 11.4375C6.61568 11.1667 6.08443 11.2031 5.66776 11.474C5.46862 11.601 5.23732 11.6685 5.0011 11.6685C4.76488 11.6685 4.53358 11.601 4.33443 11.474C3.91256 11.2031 3.38131 11.1667 2.96464 11.4375C2.41853 11.7907 1.97366 12.2799 1.67371 12.8569C1.37377 13.434 1.22907 14.0792 1.25381 14.7291C1.27856 15.379 1.4719 16.0113 1.81486 16.5638C2.15781 17.1164 2.63859 17.5703 3.20998 17.881C3.78136 18.1916 4.42372 18.3483 5.07397 18.3357C5.72421 18.323 6.35999 18.1415 6.91888 17.8089C7.47777 17.4763 7.94054 17.0041 8.26177 16.4386C8.58299 15.8731 8.75162 15.2337 8.7511 14.5834V14.5834Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0844 2.08337H11.6678C8.16256 2.08337 5.0011 6.00004 5.0011 10.8334V11.6667"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.5743 10.8334C14.37 10.8342 14.1704 10.8948 14.0001 11.0077C13.8298 11.1206 13.6963 11.2809 13.616 11.4688C13.4904 11.7733 13.2774 12.0337 13.004 12.2172C12.7305 12.4008 12.4088 12.4992 12.0795 12.5C11.6941 12.5 11.3295 12.6875 11.1732 13.0365C10.9405 13.5464 10.825 14.102 10.8354 14.6625C10.8457 15.2229 10.9815 15.7739 11.2328 16.2749C11.4841 16.776 11.8446 17.2143 12.2876 17.5576C12.7307 17.901 13.2451 18.1407 13.793 18.259C14.3409 18.3773 14.9083 18.3714 15.4536 18.2415C15.9989 18.1116 16.5081 17.8611 16.9438 17.5085C17.3795 17.1559 17.7307 16.7101 17.9714 16.2039C18.2121 15.6976 18.3362 15.1439 18.3347 14.5834C18.334 14.0902 18.2362 13.6021 18.0469 13.1467C17.8575 12.6914 17.5803 12.2778 17.2311 11.9296C16.882 11.5813 16.4676 11.3053 16.0117 11.1172C15.5559 10.9291 15.0674 10.8327 14.5743 10.8334Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0841 2.08337C11.1623 2.08337 10.4175 3.39067 10.4175 5.00004C10.4175 8.40108 12.0633 10.6615 13.0477 12.1875"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
