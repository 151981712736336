// directives/index.js
import onlyNumbers from "./onlyNumbers";
import VAutofocus from "./VAutofocus";
import alphanumeric from "./alphanumeric";
import { vMaska } from "maska";

export default {
  install(app) {
    app.directive("onlyNumbers", onlyNumbers);
    app.directive("autofocus", VAutofocus);
    app.directive("maska", vMaska);
    app.directive("alphanumeric", alphanumeric);
  },
};
