<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.249 4.16644V1.66644H8.74902V10.2862"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.08252 4.99978V2.91644H4.58252V8.34874"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.416 8.34877V2.49982H12.916V4.16648"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.74902 4.99982H6.24902V9.10398"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.7336 8.33307C15.039 8.33926 14.3684 8.58797 13.8377 9.03619C12.7581 9.92964 11.4006 10.4185 9.99919 10.4185C8.5978 10.4185 7.2403 9.92964 6.16064 9.03619C5.62999 8.58797 4.9594 8.33926 4.26481 8.33307H2.80127L4.45752 17.7185C4.50901 18.0082 4.66102 18.2704 4.88681 18.459C5.11259 18.6477 5.39768 18.7506 5.6919 18.7497H14.3065C14.5998 18.7494 14.8836 18.6459 15.1084 18.4573C15.3331 18.2688 15.4843 18.0073 15.5356 17.7185L17.1971 8.33307H15.7336Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.249 10.2862V4.16644H13.749V9.10394"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
