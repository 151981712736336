import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "profile",
    name: "profile",
    component: load("profile/Profile"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_PROFILE$$],
      icon: "profile-settings",
      menuItems: "leftSideMenu.profile",
    },
    children: [],
  },
];
