<template>
  <component :is="route.meta.layoutComponent">
    <router-view />
  </component>
  <modals-container />
</template>
<script setup>
import { provide, computed, watch, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ModalsContainer, useModal } from "vue-final-modal";
import ResolutionAlert from "@/components/UI/modals/ResolutionAlert.vue";
import Offline from "@/components/UI/modals/Offline.vue";
import { useWindowSize } from "@vueuse/core";

const { width, height } = useWindowSize();
const route = useRoute();

watch([width, height], checkResolution);
const checkForSidebar = computed(
  () => width.value <= 1180 || height.value <= 820
);

const isOnline = ref(navigator.onLine);

const handleOnlineStatusChange = () => {
  isOnline.value = navigator.onLine;
  if (!isOnline.value) {
    offlineModal.open();
  } else {
    offlineModal.close();
  }
};

provide("checkForSidebar", checkForSidebar);

function checkResolution() {
  if (width.value <= 1024 || height.value <= 768) {
    open();
  } else {
    close();
  }
}
const { open, close } = useModal({
  component: ResolutionAlert,
  attrs: {
    width: width,
    height: height,
    onConfirm() {
      close();
    },
  },
});
const offlineModal = useModal({
  component: Offline,
  attrs: {
    clickToClose: false,
    escToClose: false,
    // onConfirm() {
    //   close();
    // },
  },
});

onMounted(() => {});

onMounted(() => {
  checkResolution();
  window.addEventListener("online", handleOnlineStatusChange);
  window.addEventListener("offline", handleOnlineStatusChange);
});
onUnmounted(() => {
  window.removeEventListener("online", handleOnlineStatusChange);
  window.removeEventListener("offline", handleOnlineStatusChange);
});
</script>
