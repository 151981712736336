export const colorPresets = [
  {
    title: "Светлый",
    colors: {
      "relief-1": "#FFFFFF",
      "relief-2": "#0E0E22",
      "relief-3": "#A0A0A0",
      "relief-4": "#0E0E22",
      "relief-5": "#595A5B",
      "relief-6": "#0E0E22",
      "relief-7": "#BCBBBB",
      "relief-8": "#EFEEF3",
      "relief-9": "#0E0E22",
      "relief-10": "#FFFFFF",
      "preset-cover": "linear-gradient(180deg, #FFF 0%, #EFEEF3 100%)",
    },
    value: "light",
  },
  {
    title: "Темный",
    colors: {
      "relief-1": "#0E0E22",
      "relief-2": "#FFFFFF",
      "relief-3": "#A0A0A0",
      "relief-4": "#FFFFFF",
      "relief-5": "#EFEEF3",
      "relief-6": "#FFFFFF",
      "relief-7": "#A0A0A0",
      "relief-8": "#595A5B",
      "relief-9": "#FFFFFF",
      "relief-10": "#353637",
      "preset-cover": "linear-gradient(180deg, #353637 0%, #0E0E22 100%)",
    },
    value: "dark",
  },
  {
    title: "Красный",
    colors: {
      "relief-1": "#FBF6F6",
      "relief-2": "#A4121C",
      "relief-3": "#F89D9F",
      "relief-4": "#FBF6F6",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#6C1322",
      "relief-8": "#C37683",
      "relief-9": "#9B1B30",
      "relief-10": "#9B1B30",
      "preset-cover": "linear-gradient(180deg, #9B1B30 0%, #6C1322 100%)",
    },
    value: "red",
  },
  {
    title: "Оранжевый",
    colors: {
      "relief-1": "#FEF8F5",
      "relief-2": "#FF8F00",
      "relief-3": "#FFC780",
      "relief-4": "#FEFDFD",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#A13900",
      "relief-8": "#F09766",
      "relief-9": "#E65100",
      "relief-10": "#E65100",
      "preset-cover": "linear-gradient(180deg, #FF8F00 0%, #E65100 100%)",
    },
    value: "orange",
  },
  {
    title: "Золотой",
    colors: {
      "relief-1": "#FCF9F5",
      "relief-2": "#B36832",
      "relief-3": "#D9B398",
      "relief-4": "#FEFDFD",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#B0610D",
      "relief-8": "#D4A572",
      "relief-9": "#B86914",
      "relief-10": "#B86914",
      "preset-cover": "linear-gradient(180deg, #CF7617 0%, #B86914 100%)",
    },
    value: "gold",
  },
  {
    title: "Зеленый",
    colors: {
      "relief-1": "#F6F9F6",
      "relief-2": "#004D40",
      "relief-3": "#80A69F",
      "relief-4": "#F6FBF7",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#1B5E20",
      "relief-8": "#82B184",
      "relief-9": "#2E7D32",
      "relief-10": "#2E7D32",
      "preset-cover": "linear-gradient(180deg, #2E7D32 0%, #1B5E20 100%)",
    },
    value: "green",
  },
  {
    title: "Синий",
    colors: {
      "relief-1": "#F5F8FC",
      "relief-2": "#0277BD",
      "relief-3": "#80BBDE",
      "relief-4": "#F5F8FC",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#0D47A1",
      "relief-8": "#73A3D9",
      "relief-9": "#1565C0",
      "relief-10": "#1565C0",
      "preset-cover": "linear-gradient(180deg, #1565C0 0%, #0D47A1 100%)",
    },
    value: "blue",
  },
  {
    title: "Фиолетовый",
    colors: {
      "relief-1": "#F7F6FB",
      "relief-2": "#673AB7",
      "relief-3": "#B39CDB",
      "relief-4": "#F7F6FB",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#673AB7",
      "relief-8": "#8F7DC6",
      "relief-9": "#4527A0",
      "relief-10": "#4527A0",
      "preset-cover": "linear-gradient(180deg, #673AB7 0%, #4527A0 100%)",
    },
    value: "purple",
  },
  {
    title: "Розовый",
    colors: {
      "relief-1": "#FDF8F9",
      "relief-2": "#E4446C",
      "relief-3": "#F1A1B5",
      "relief-4": "#FDF8F9",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#E4446C",
      "relief-8": "#E29DAE",
      "relief-9": "#CE5B78",
      "relief-10": "#CE5B78",
      "preset-cover": "linear-gradient(180deg, #CE5B78 0%, #E4446C 100%)",
    },
    value: "pink",
  },
  {
    title: "Голубой",
    colors: {
      "relief-1": "#F7FBFC",
      "relief-2": "#55C0CE",
      "relief-3": "#B0E9E9",
      "relief-4": "#F7FBFC",
      "relief-5": "#FEFDFD",
      "relief-6": "#FFFFFF",
      "relief-7": "#3392AC",
      "relief-8": "#86C6E0",
      "relief-9": "#35A0CB",
      "relief-10": "#35A0CB",
      "preset-cover": "linear-gradient(180deg, #55C0CE 0%, #35A0CB 100%)",
    },
    value: "aquablue",
  },
  {
    title: "Манго",
    colors: {
      "relief-1": "#FCFAF6",
      "relief-2": "#C78D20",
      "relief-3": "#E3C68F",
      "relief-4": "#0C0C0C",
      "relief-5": "#050505",
      "relief-6": "#0C0C0C",
      "relief-7": "#C78D20",
      "relief-8": "#FFF1D8",
      "relief-9": "#FFC700",
      "relief-10": "#FFC700",
      "preset-cover": "linear-gradient(180deg, #FFD233 0%, #C78D20 100%)",
    },
    value: "mango",
  },
];
export const currency = [
  {
    currencySign: "$",
    currencyCode: "USD",
    currencyName: "Доллар США",
    currencyNumCode: "840",
  },
  {
    currencySign: "€",
    currencyCode: "EUR",
    currencyName: "Евро",
    currencyNumCode: "978",
  },
  {
    currencySign: "₴",
    currencyCode: "UAH",
    currencyName: "Гривна",
    currencyNumCode: "980",
  },
  {
    currencySign: "₸",
    currencyCode: "KZT",
    currencyName: "Тенге",
    currencyNumCode: "398",
  },
  {
    currencySign: "₺",
    currencyCode: "TRY",
    currencyName: "Лира(турецкая лира)",
    currencyNumCode: "949",
  },
  {
    currencySign: "₽",
    currencyCode: "RUB",
    currencyName: "Рубль(российский рубль)",
    currencyNumCode: "643",
  },
  {
    currencySign: "₾",
    currencyCode: "GEL",
    currencyName: "Лари",
    currencyNumCode: "981",
  },
  {
    currencySign: "$",
    currencyCode: "CAD",
    currencyName: "Канадский доллар",
    currencyNumCode: "124",
  },
  {
    currencySign: "£",
    currencyCode: "GBP",
    currencyName: "Фунт стерлингов",
    currencyNumCode: "826",
  },
];
export const countryCurrency = [
  {
    country: "AFG",
    currency: "AFN",
    currencycode: 971,
  },
  {
    country: "ALA",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "ALB",
    currency: "ALL",
    currencycode: 8,
  },
  {
    country: "DZA",
    currency: "DZD",
    currencycode: 12,
  },
  {
    country: "ASM",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "AND",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "AGO",
    currency: "AOA",
    currencycode: 973,
  },
  {
    country: "AIA",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "ATG",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "ARG",
    currency: "ARS",
    currencycode: 32,
  },
  {
    country: "ARM",
    currency: "AMD",
    currencycode: 51,
  },
  {
    country: "ABW",
    currency: "AWG",
    currencycode: 533,
  },
  {
    country: "AUS",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "AUT",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "AZE",
    currency: "AZN",
    currencycode: 944,
  },
  {
    country: "BHS",
    currency: "BSD",
    currencycode: 44,
  },
  {
    country: "BHR",
    currency: "BHD",
    currencycode: 48,
  },
  {
    country: "BGD",
    currency: "BDT",
    currencycode: 50,
  },
  {
    country: "BRB",
    currency: "BBD",
    currencycode: 52,
  },
  {
    country: "BLR",
    currency: "BYN",
    currencycode: 933,
  },
  {
    country: "BEL",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "BLZ",
    currency: "BZD",
    currencycode: 84,
  },
  {
    country: "BEN",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "BMU",
    currency: "BMD",
    currencycode: 60,
  },
  {
    country: "BTN",
    currency: "BTN",
    currencycode: 64,
  },
  {
    country: "BTN",
    currency: "INR",
    currencycode: 356,
  },
  {
    country: "BOL",
    currency: "BOB",
    currencycode: 68,
  },
  {
    country: "BOL",
    currency: "BOV",
    currencycode: 984,
  },
  {
    country: "BES",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "BIH",
    currency: "BAM",
    currencycode: 977,
  },
  {
    country: "BWA",
    currency: "BWP",
    currencycode: 72,
  },
  {
    country: "BVT",
    currency: "NOK",
    currencycode: 578,
  },
  {
    country: "BRA",
    currency: "BRL",
    currencycode: 986,
  },
  {
    country: "IOT",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "BRN",
    currency: "BND",
    currencycode: 96,
  },
  {
    country: "BGR",
    currency: "BGN",
    currencycode: 975,
  },
  {
    country: "BFA",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "BDI",
    currency: "BIF",
    currencycode: 108,
  },
  {
    country: "CPV",
    currency: "CVE",
    currencycode: 132,
  },
  {
    country: "KHM",
    currency: "KHR",
    currencycode: 116,
  },
  {
    country: "CMR",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "CAN",
    currency: "CAD",
    currencycode: 124,
  },
  {
    country: "CYM",
    currency: "KYD",
    currencycode: 136,
  },
  {
    country: "CAF",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "TCD",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "CHL",
    currency: "CLF",
    currencycode: 990,
  },
  {
    country: "CHL",
    currency: "CLP",
    currencycode: 152,
  },
  {
    country: "CHN",
    currency: "CNY",
    currencycode: 156,
  },
  {
    country: "CXR",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "CCK",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "COL",
    currency: "COP",
    currencycode: 170,
  },
  {
    country: "COL",
    currency: "COU",
    currencycode: 970,
  },
  {
    country: "COM",
    currency: "KMF",
    currencycode: 174,
  },
  {
    country: "COD",
    currency: "CDF",
    currencycode: 976,
  },
  {
    country: "COG",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "COK",
    currency: "NZD",
    currencycode: 554,
  },
  {
    country: "CRI",
    currency: "CRC",
    currencycode: 188,
  },
  {
    country: "CIV",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "HRV",
    currency: "HRK",
    currencycode: 191,
  },
  {
    country: "CUB",
    currency: "CUC",
    currencycode: 931,
  },
  {
    country: "CUB",
    currency: "CUP",
    currencycode: 192,
  },
  {
    country: "CUW",
    currency: "ANG",
    currencycode: 532,
  },
  {
    country: "CYP",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "DNK",
    currency: "DKK",
    currencycode: 208,
  },
  {
    country: "DJI",
    currency: "DJF",
    currencycode: 262,
  },
  {
    country: "DMA",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "DOM",
    currency: "DOP",
    currencycode: 214,
  },
  {
    country: "ECU",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "EGY",
    currency: "EGP",
    currencycode: 818,
  },
  {
    country: "SLV",
    currency: "SVC",
    currencycode: 222,
  },
  {
    country: "SLV",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "GNQ",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "ERI",
    currency: "ERN",
    currencycode: 232,
  },
  {
    country: "EST",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "ETH",
    currency: "ETB",
    currencycode: 230,
  },
  {
    country: "FLK",
    currency: "FKP",
    currencycode: 238,
  },
  {
    country: "FRO",
    currency: "DKK",
    currencycode: 208,
  },
  {
    country: "FJI",
    currency: "FJD",
    currencycode: 242,
  },
  {
    country: "FIN",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "FRA",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "GUF",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "PYF",
    currency: "XPF",
    currencycode: 953,
  },
  {
    country: "ATF",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "GAB",
    currency: "XAF",
    currencycode: 950,
  },
  {
    country: "GMB",
    currency: "GMD",
    currencycode: 270,
  },
  {
    country: "GEO",
    currency: "GEL",
    currencycode: 981,
  },
  {
    country: "DEU",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "GHA",
    currency: "GHS",
    currencycode: 936,
  },
  {
    country: "GIB",
    currency: "GIP",
    currencycode: 292,
  },
  {
    country: "GRC",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "GRL",
    currency: "DKK",
    currencycode: 208,
  },
  {
    country: "GRD",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "GLP",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "GUM",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "GTM",
    currency: "GTQ",
    currencycode: 320,
  },
  {
    country: "GGY",
    currency: "GBP",
    currencycode: 826,
  },
  {
    country: "GIN",
    currency: "GNF",
    currencycode: 324,
  },
  {
    country: "GNB",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "GUY",
    currency: "GYD",
    currencycode: 328,
  },
  {
    country: "HTI",
    currency: "HTG",
    currencycode: 332,
  },
  {
    country: "HTI",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "HMD",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "VAT",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "HND",
    currency: "HNL",
    currencycode: 340,
  },
  {
    country: "HKG",
    currency: "HKD",
    currencycode: 344,
  },
  {
    country: "HUN",
    currency: "HUF",
    currencycode: 348,
  },
  {
    country: "ISL",
    currency: "ISK",
    currencycode: 352,
  },
  {
    country: "IND",
    currency: "INR",
    currencycode: 356,
  },
  {
    country: "IDN",
    currency: "IDR",
    currencycode: 360,
  },
  {
    country: "IRN",
    currency: "IRR",
    currencycode: 364,
  },
  {
    country: "IRQ",
    currency: "IQD",
    currencycode: 368,
  },
  {
    country: "IRL",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "IMN",
    currency: "GBP",
    currencycode: 826,
  },
  {
    country: "ISR",
    currency: "ILS",
    currencycode: 376,
  },
  {
    country: "ITA",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "JAM",
    currency: "JMD",
    currencycode: 388,
  },
  {
    country: "JPN",
    currency: "JPY",
    currencycode: 392,
  },
  {
    country: "JEY",
    currency: "GBP",
    currencycode: 826,
  },
  {
    country: "JOR",
    currency: "JOD",
    currencycode: 400,
  },
  {
    country: "KAZ",
    currency: "KZT",
    currencycode: 398,
  },
  {
    country: "KEN",
    currency: "KES",
    currencycode: 404,
  },
  {
    country: "KIR",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "KOR",
    currency: "KRW",
    currencycode: 410,
  },
  {
    country: "KWT",
    currency: "KWD",
    currencycode: 414,
  },
  {
    country: "KGZ",
    currency: "KGS",
    currencycode: 417,
  },
  {
    country: "LVA",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "LBN",
    currency: "LBP",
    currencycode: 422,
  },
  {
    country: "LSO",
    currency: "LSL",
    currencycode: 426,
  },
  {
    country: "LSO",
    currency: "ZAR",
    currencycode: 710,
  },
  {
    country: "LBR",
    currency: "LRD",
    currencycode: 430,
  },
  {
    country: "LBY",
    currency: "LYD",
    currencycode: 434,
  },
  {
    country: "LIE",
    currency: "CHF",
    currencycode: 756,
  },
  {
    country: "LTU",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "LUX",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MAC",
    currency: "MOP",
    currencycode: 446,
  },
  {
    country: "MKD",
    currency: "MKD",
    currencycode: 807,
  },
  {
    country: "MDG",
    currency: "MGA",
    currencycode: 969,
  },
  {
    country: "MWI",
    currency: "MWK",
    currencycode: 454,
  },
  {
    country: "MYS",
    currency: "MYR",
    currencycode: 458,
  },
  {
    country: "MDV",
    currency: "MVR",
    currencycode: 462,
  },
  {
    country: "MLI",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "MLT",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MHL",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "MTQ",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MRT",
    currency: "MRU",
    currencycode: 929,
  },
  {
    country: "MUS",
    currency: "MUR",
    currencycode: 480,
  },
  {
    country: "MYT",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MEX",
    currency: "MXN",
    currencycode: 484,
  },
  {
    country: "MEX",
    currency: "MXV",
    currencycode: 979,
  },
  {
    country: "FSM",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "MDA",
    currency: "MDL",
    currencycode: 498,
  },
  {
    country: "MCO",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MNG",
    currency: "MNT",
    currencycode: 496,
  },
  {
    country: "MNE",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "MSR",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "MAR",
    currency: "MAD",
    currencycode: 504,
  },
  {
    country: "MOZ",
    currency: "MZN",
    currencycode: 943,
  },
  {
    country: "MMR",
    currency: "MMK",
    currencycode: 104,
  },
  {
    country: "NAM",
    currency: "NAD",
    currencycode: 516,
  },
  {
    country: "NAM",
    currency: "ZAR",
    currencycode: 710,
  },
  {
    country: "NRU",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "NPL",
    currency: "NPR",
    currencycode: 524,
  },
  {
    country: "NLD",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "NCL",
    currency: "XPF",
    currencycode: 953,
  },
  {
    country: "NZL",
    currency: "NZD",
    currencycode: 554,
  },
  {
    country: "NIC",
    currency: "NIO",
    currencycode: 558,
  },
  {
    country: "NER",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "NGA",
    currency: "NGN",
    currencycode: 566,
  },
  {
    country: "NIU",
    currency: "NZD",
    currencycode: 554,
  },
  {
    country: "NFK",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "MNP",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "NOR",
    currency: "NOK",
    currencycode: 578,
  },
  {
    country: "OMN",
    currency: "OMR",
    currencycode: 512,
  },
  {
    country: "PAK",
    currency: "PKR",
    currencycode: 586,
  },
  {
    country: "PLW",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "PAN",
    currency: "PAB",
    currencycode: 590,
  },
  {
    country: "PAN",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "PNG",
    currency: "PGK",
    currencycode: 598,
  },
  {
    country: "PRY",
    currency: "PYG",
    currencycode: 600,
  },
  {
    country: "PER",
    currency: "PEN",
    currencycode: 604,
  },
  {
    country: "PHL",
    currency: "PHP",
    currencycode: 608,
  },
  {
    country: "PCN",
    currency: "NZD",
    currencycode: 554,
  },
  {
    country: "POL",
    currency: "PLN",
    currencycode: 985,
  },
  {
    country: "PRT",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "PRI",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "QAT",
    currency: "QAR",
    currencycode: 634,
  },
  {
    country: "REU",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "ROU",
    currency: "RON",
    currencycode: 946,
  },
  {
    country: "RUS",
    currency: "RUB",
    currencycode: 643,
  },
  {
    country: "RWA",
    currency: "RWF",
    currencycode: 646,
  },
  {
    country: "BLM",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "SHN",
    currency: "SHP",
    currencycode: 654,
  },
  {
    country: "KNA",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "LCA",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "MAF",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "SPM",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "VCT",
    currency: "XCD",
    currencycode: 951,
  },
  {
    country: "WSM",
    currency: "WST",
    currencycode: 882,
  },
  {
    country: "SMR",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "STP",
    currency: "STN",
    currencycode: 930,
  },
  {
    country: "SAU",
    currency: "SAR",
    currencycode: 682,
  },
  {
    country: "SEN",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "SRB",
    currency: "RSD",
    currencycode: 941,
  },
  {
    country: "SYC",
    currency: "SCR",
    currencycode: 690,
  },
  {
    country: "SLE",
    currency: "SLE",
    currencycode: 925,
  },
  {
    country: "SGP",
    currency: "SGD",
    currencycode: 702,
  },
  {
    country: "SXM",
    currency: "ANG",
    currencycode: 532,
  },
  {
    country: "SVK",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "SVN",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "SLB",
    currency: "SBD",
    currencycode: 90,
  },
  {
    country: "SOM",
    currency: "SOS",
    currencycode: 706,
  },
  {
    country: "ZAF",
    currency: "ZAR",
    currencycode: 710,
  },
  {
    country: "SSD",
    currency: "SSP",
    currencycode: 728,
  },
  {
    country: "ESP",
    currency: "EUR",
    currencycode: 978,
  },
  {
    country: "LKA",
    currency: "LKR",
    currencycode: 144,
  },
  {
    country: "SDN",
    currency: "SDG",
    currencycode: 938,
  },
  {
    country: "SUR",
    currency: "SRD",
    currencycode: 968,
  },
  {
    country: "SJM",
    currency: "NOK",
    currencycode: 578,
  },
  {
    country: "SWE",
    currency: "SEK",
    currencycode: 752,
  },
  {
    country: "CHE",
    currency: "CHE",
    currencycode: 947,
  },
  {
    country: "CHE",
    currency: "CHF",
    currencycode: 756,
  },
  {
    country: "CHE",
    currency: "CHW",
    currencycode: 948,
  },
  {
    country: "SYR",
    currency: "SYP",
    currencycode: 760,
  },
  {
    country: "TWN",
    currency: "TWD",
    currencycode: 901,
  },
  {
    country: "TJK",
    currency: "TJS",
    currencycode: 972,
  },
  {
    country: "TZA",
    currency: "TZS",
    currencycode: 834,
  },
  {
    country: "THA",
    currency: "THB",
    currencycode: 764,
  },
  {
    country: "TLS",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "TGO",
    currency: "XOF",
    currencycode: 952,
  },
  {
    country: "TKL",
    currency: "NZD",
    currencycode: 554,
  },
  {
    country: "TON",
    currency: "TOP",
    currencycode: 776,
  },
  {
    country: "TTO",
    currency: "TTD",
    currencycode: 780,
  },
  {
    country: "TUN",
    currency: "TND",
    currencycode: 788,
  },
  {
    country: "TUR",
    currency: "TRY",
    currencycode: 949,
  },
  {
    country: "TKM",
    currency: "TMT",
    currencycode: 934,
  },
  {
    country: "TCA",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "TUV",
    currency: "AUD",
    currencycode: 36,
  },
  {
    country: "UGA",
    currency: "UGX",
    currencycode: 800,
  },
  {
    country: "UKR",
    currency: "UAH",
    currencycode: 980,
  },
  {
    country: "ARE",
    currency: "AED",
    currencycode: 784,
  },
  {
    country: "GBR",
    currency: "GBP",
    currencycode: 826,
  },
  {
    country: "UMI",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "USA",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "USA",
    currency: "USN",
    currencycode: 997,
  },
  {
    country: "URY",
    currency: "UYI",
    currencycode: 940,
  },
  {
    country: "URY",
    currency: "UYU",
    currencycode: 858,
  },
  {
    country: "UZB",
    currency: "UZS",
    currencycode: 860,
  },
  {
    country: "VUT",
    currency: "VUV",
    currencycode: 548,
  },
  {
    country: "VEN",
    currency: "VEF",
    currencycode: 937,
  },
  {
    country: "VEN",
    currency: "VED",
    currencycode: 926,
  },
  {
    country: "VNM",
    currency: "VND",
    currencycode: 704,
  },
  {
    country: "VGB",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "VIR",
    currency: "USD",
    currencycode: 840,
  },
  {
    country: "WLF",
    currency: "XPF",
    currencycode: 953,
  },
  {
    country: "ESH",
    currency: "MAD",
    currencycode: 504,
  },
  {
    country: "YEM",
    currency: "YER",
    currencycode: 886,
  },
  {
    country: "ZMB",
    currency: "ZMW",
    currencycode: 967,
  },
  {
    country: "ZWE",
    currency: "ZWL",
    currencycode: 932,
  },
];
