<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2227 8.96313L5.06641 11.4944"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.931 11.4998L5.34766 13.9633"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.6445 13.9998L5.62891 16.4581"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.75 4.58301H16.25V6.66634H3.75V4.58301Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.3125 4.58293L13.75 1.66626H11.6094C11.516 2.02121 11.3077 2.33524 11.017 2.55933C10.7263 2.78342 10.3696 2.90495 10.0026 2.90495C9.63558 2.90495 9.27888 2.78342 8.9882 2.55933C8.69752 2.33524 8.48922 2.02121 8.39583 1.66626H6.25L4.6875 4.58293"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.51562 6.66626L5.89583 18.7496H14.1042L15.4844 6.66626"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
