<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56336)">
      <path
        d="M2.08252 13.495C2.08344 12.3226 2.54992 11.1986 3.37939 10.37L10.369 3.38045C11.2068 2.59673 12.3164 2.1693 13.4635 2.18842C14.6105 2.20754 15.7053 2.67173 16.5165 3.48294C17.3277 4.29416 17.7919 5.3889 17.811 6.53597C17.8301 7.68304 17.4027 8.79264 16.619 9.63045L9.62939 16.62C9.011 17.2377 8.22341 17.6583 7.3661 17.8287C6.5088 17.999 5.62025 17.9114 4.81269 17.577C4.00512 17.2426 3.31477 16.6764 2.82885 15.9499C2.34292 15.2233 2.08321 14.3691 2.08252 13.495V13.495Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.79085 15.896L0.83252 16.6668L3.33252 19.1668L4.10335 17.2085"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.2075 4.10433L19.1659 3.3335L16.6659 0.833496L15.895 2.79183"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.16585 4.5835L12.9159 8.3335L8.33252 12.9168L4.58252 9.16683"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56336">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
