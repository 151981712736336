<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6160)">
      <path
        d="M1.61589 11.2501L1.18359 6.66675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.72005 13.75L7.44401 16.6667H2.13151L1.85547 13.75"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.39323 6.66675L7.96094 11.2501"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.2474 5.00016L2.4974 3.3335H7.08073L8.33073 5.00016H9.16406V6.66683H0.414062V5.00016H1.2474Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M0.414062 11.25H19.5807V13.75H0.414062V11.25Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0339 11.2501L11.6016 6.66675"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.138 13.75L17.862 16.6667H12.5495L12.2734 13.75"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.8112 6.66675L18.3789 11.2501"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6654 5.00016L12.9154 3.3335H17.4987L18.7487 5.00016H19.582V6.66683H10.832V5.00016H11.6654Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6160">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
