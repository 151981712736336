import { defineAsyncComponent } from "vue";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import VIcon from "@/components/UI/iconComponents/VIcon.vue";
// import LocaleSwitcher from "@/components/UI/localeSwitcher/LocaleSwitcher.vue";
import ActionButton from "@/components/UI/buttons/ActionButton.vue";
import Datepicker from "@vuepic/vue-datepicker";
import SquareGrid from "@/components/UI/spinner/SquareGrid.vue";
import { Cropper } from "vue-advanced-cropper";
import Vue3EasyDataTable from "vue3-easy-data-table";
import CustomizeFooter from "@/components/UI/table/CustomizeFooter.vue";
import ErrorCheck from "@/components/UI/errors/Error.vue";
import "vue3-easy-data-table/dist/style.css";
import "vue-advanced-cropper/dist/style.css";
// import "vue-advanced-cropper/dist/theme.compact.css";
import "vue-advanced-cropper/dist/theme.classic.css";

const iconsComp = require.context("@/icons/", true, /\.vue$/);
const iconsTmpl = require.context("@/icons/package/", true, /\.vue$/);

export default function install(app) {
  app
    .component("DefaultLayout", DefaultLayout)
    .component("AuthLayout", AuthLayout)
    .component("v-icon", VIcon)
    .component("action-button", ActionButton)
    .component("EasyDataTable", Vue3EasyDataTable)
    .component("CustomizeFooter", CustomizeFooter)
    .component("ErrorCheck", ErrorCheck)
    .component("v-datepicker", Datepicker)
    // .component("locale-switcher", LocaleSwitcher)
    .component("cropper", Cropper)
    .component("SquareGrid", SquareGrid);

  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, "").replace(/\.\w+$/, "");
    app.component(
      `Icon${name}`,
      defineAsyncComponent(() => import("@/icons/" + name + ".vue"))
    );
  }
  for (const comp of iconsTmpl.keys()) {
    const name = comp.replace(/^\.\//, "").replace(/\.\w+$/, "");
    app.component(
      `Icon${name}`,
      defineAsyncComponent(() => import("@/icons/package/" + name + ".vue"))
    );
  }
}
