<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 18.333C14.6022 18.333 18.3332 14.602 18.3332 9.99965C18.3332 5.39728 14.6022 1.66632 9.99984 1.66632C5.39746 1.66632 1.6665 5.39728 1.6665 9.99965C1.6665 14.602 5.39746 18.333 9.99984 18.333Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M1.66626 9.99963H9.99959"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.7498 13.7498V12.4998"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.7498 6.24963V7.49963"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.8329 9.16648V8.74982C15.8329 8.4183 15.7012 8.10035 15.4668 7.86593C15.2324 7.63151 14.9144 7.49982 14.5829 7.49982H12.9163C12.5847 7.49982 12.2668 7.63151 12.0324 7.86593C11.798 8.10035 11.6663 8.4183 11.6663 8.74982C11.6663 9.08134 11.798 9.39928 12.0324 9.6337C12.2668 9.86812 12.5847 9.99982 12.9163 9.99982H14.5829C14.9144 9.99982 15.2324 10.1315 15.4668 10.3659C15.7012 10.6004 15.8329 10.9183 15.8329 11.2498C15.8329 11.5813 15.7012 11.8993 15.4668 12.1337C15.2324 12.3681 14.9144 12.4998 14.5829 12.4998H12.9163C12.5847 12.4998 12.2668 12.3681 12.0324 12.1337C11.798 11.8993 11.6663 11.5813 11.6663 11.2498V10.8332"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.11955 2.17676L9.99976 9.99967L4.10913 15.8903"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
