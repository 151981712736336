<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58747)">
      <path
        d="M2.49878 19.9999V18.3332H7.08211"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.08228 19.9999V17.0832H12.9156V19.9999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.9153 18.7498H17.4986V19.9998"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99862 4.58323C11.1492 4.58323 12.0819 3.65049 12.0819 2.4999C12.0819 1.34931 11.1492 0.416565 9.99862 0.416565C8.84802 0.416565 7.91528 1.34931 7.91528 2.4999C7.91528 3.65049 8.84802 4.58323 9.99862 4.58323Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0823 17.0833V8.74994"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.91528 8.74994V17.0833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99878 17.0832V13.3332"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.91545 12.0835H7.49878C7.16726 12.0835 6.84932 11.9518 6.6149 11.7174C6.38048 11.483 6.24878 11.165 6.24878 10.8335V8.33352C6.24878 7.78098 6.46827 7.25108 6.85897 6.86038C7.24967 6.46968 7.77958 6.25018 8.33211 6.25018H11.6654C12.218 6.25018 12.7479 6.46968 13.1386 6.86038C13.5293 7.25108 13.7488 7.78098 13.7488 8.33352V10.8335C13.7488 11.165 13.6171 11.483 13.3827 11.7174C13.1482 11.9518 12.8303 12.0835 12.4988 12.0835H12.0821"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58747">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
