<template>
  <svg
    width="164"
    height="37"
    viewBox="0 0 164 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12.2954 8.2793V12.8563C11.8713 12.7784 11.4865 12.7405 11.1416 12.7405C9.20445 12.7405 7.69276 13.2858 6.60438 14.375C5.51672 15.4655 4.97361 17.0354 4.97361 19.086V29.0478H0V8.51018H2.42872L4.73546 11.5096C6.16801 9.35513 8.68821 8.2793 12.2954 8.2793Z"
        fill="#1E1E1E"
      />
      <path
        d="M32.8222 29.0477H27.8486V6.91282L32.8222 0.509766V29.0477Z"
        fill="#1E1E1E"
      />
      <path
        d="M70.05 20.3184H53.3772C53.6683 21.8307 54.4322 23.0286 55.665 23.9136C56.8979 24.7978 58.4307 25.2407 60.2618 25.2407C62.5954 25.2407 64.5188 24.4975 66.0305 23.0097L68.5006 26.1909C67.5451 27.2934 66.5344 27.9074 65.0764 28.471C63.6163 29.0359 61.9732 29.3173 60.142 29.3173C57.807 29.3173 55.7507 28.8689 53.974 27.972C52.1966 27.0744 50.8236 25.8245 49.8557 24.2217C48.8864 22.6188 48.4028 20.8054 48.4028 18.7787C48.4028 16.7786 48.8741 14.9778 49.815 13.3756C50.7568 11.7728 52.0644 10.5229 53.7351 9.62531C55.4066 8.72844 57.2907 8.2793 59.3854 8.2793C61.4555 8.2793 63.3048 8.72142 64.9363 9.60566C66.5685 10.4913 67.842 11.7349 68.7569 13.337C69.6717 14.9399 70.1299 16.7926 70.1299 18.8944C70.1306 19.2271 70.1023 19.7015 70.05 20.3184ZM55.3477 13.4907C54.2463 14.4016 53.5768 15.6122 53.3372 17.1246H65.3952C65.1824 15.6389 64.5333 14.4325 63.4457 13.5104C62.3565 12.5868 61.0046 12.1244 59.3862 12.1244C57.7939 12.1237 56.4477 12.5798 55.3477 13.4907Z"
        fill="#1E1E1E"
      />
      <path
        d="M94.8012 20.3184H78.1284C78.4188 21.8307 79.1826 23.0286 80.4155 23.9136C81.6498 24.7978 83.1819 25.2407 85.0123 25.2407C87.3459 25.2407 89.2693 24.4975 90.7824 23.0097L93.1981 26.2133C92.2433 27.3158 91.2863 27.9074 89.8269 28.471C88.3682 29.0359 86.7237 29.3173 84.8925 29.3173C82.5582 29.3173 80.5012 28.8689 78.7252 27.972C76.9471 27.0744 75.5741 25.8245 74.6062 24.2217C73.6383 22.6188 73.1533 20.8054 73.1533 18.7787C73.1533 16.7786 73.6253 14.9778 74.567 13.3756C75.5073 11.7728 76.8149 10.5229 78.4856 9.62531C80.1578 8.72844 82.0412 8.2793 84.1359 8.2793C86.206 8.2793 88.056 8.72142 89.6882 9.60566C91.319 10.4913 92.5925 11.7349 93.5074 13.337C94.4237 14.9399 94.8804 16.7926 94.8804 18.8944C94.8804 19.2271 94.8543 19.7015 94.8012 20.3184ZM80.0975 13.4907C78.9968 14.4016 78.3281 15.6122 78.0885 17.1246H90.1464C89.9329 15.6389 89.2831 14.4325 88.1954 13.5104C87.107 12.5868 85.7544 12.1244 84.1359 12.1244C82.5444 12.1237 81.1982 12.5798 80.0975 13.4907Z"
        fill="#1E1E1E"
      />
      <path
        d="M116.985 9.58601C118.617 10.4583 119.897 11.69 120.825 13.2788C121.753 14.869 122.218 16.7014 122.218 18.7794C122.218 20.8552 121.753 22.696 120.825 24.2982C119.897 25.901 118.617 27.1376 116.985 28.0099C115.353 28.8808 113.503 29.3173 111.434 29.3173C108.569 29.3173 106.3 28.3945 104.63 26.5488V36.5099H99.6553V8.51018H102.411L104.391 11.2015C105.213 10.2274 106.227 9.49689 107.435 9.00915C108.641 8.52352 109.976 8.2793 111.434 8.2793C113.503 8.2793 115.353 8.7151 116.985 9.58601ZM115.394 23.4329C116.574 22.2546 117.165 20.7015 117.165 18.7794C117.165 16.8565 116.574 15.3048 115.394 14.1251C114.212 12.9454 112.706 12.3552 110.877 12.3552C109.684 12.3552 108.608 12.6198 107.654 13.1447C106.699 13.6704 105.942 14.4206 105.385 15.3954C104.829 16.3694 104.55 17.4972 104.55 18.7794C104.55 20.0608 104.829 21.1893 105.385 22.1633C105.942 23.1374 106.699 23.8876 107.654 24.414C108.608 24.9396 109.684 25.2028 110.877 25.2028C112.706 25.2028 114.212 24.6126 115.394 23.4329Z"
        fill="#1E1E1E"
      />
      <path
        d="M146.85 20.3184H130.176C130.468 21.8307 131.231 23.0286 132.464 23.9136C133.697 24.7978 135.231 25.2407 137.061 25.2407C139.395 25.2407 141.318 24.4975 142.83 23.0097L145.282 26.1796C144.326 27.2821 143.335 27.9074 141.875 28.471C140.416 29.0359 138.773 29.3173 136.941 29.3173C134.606 29.3173 132.55 28.8689 130.773 27.972C128.995 27.0744 127.623 25.8245 126.655 24.2217C125.686 22.6188 125.202 20.8054 125.202 18.7787C125.202 16.7786 125.673 14.9778 126.616 13.3756C127.556 11.7728 128.864 10.5229 130.534 9.62531C132.206 8.72844 134.089 8.2793 136.185 8.2793C138.255 8.2793 140.105 8.72142 141.736 9.60566C143.368 10.4913 144.641 11.7349 145.556 13.337C146.471 14.9399 146.929 16.7926 146.929 18.8944C146.929 19.2271 146.903 19.7015 146.85 20.3184ZM132.146 13.4907C131.045 14.4016 130.375 15.6122 130.137 17.1246H142.194C141.981 15.6389 141.332 14.4325 140.244 13.5104C139.156 12.5868 137.802 12.1244 136.185 12.1244C134.593 12.1237 133.247 12.5798 132.146 13.4907Z"
        fill="#1E1E1E"
      />
      <path
        d="M164 8.2793V12.8563C163.574 12.7784 163.19 12.7405 162.845 12.7405C160.909 12.7405 159.397 13.2858 158.309 14.375C157.221 15.4655 156.677 17.0354 156.677 19.086V29.0478H151.703V8.51018H154.178L156.439 11.5096C157.871 9.35513 160.391 8.2793 164 8.2793Z"
        fill="#1E1E1E"
      />
      <path
        d="M46.8781 29.0477L38.7519 18.5329L46.632 8.47073L41.8725 8.43213L33.9663 18.5266L33.9975 18.549L42.0824 29.0105L46.8781 29.0477Z"
        fill="#1E1E1E"
      />
      <path
        d="M29.9244 32.7594H7.64697L10.5447 29.0483H32.8222L29.9244 32.7594Z"
        fill="#00CF6F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="164"
          height="36"
          fill="white"
          transform="translate(0 0.509766)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
