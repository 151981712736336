export default {
  mounted: (el) => {
    el.addEventListener("input", (event) => {
      const input = event.target;
      const value = input.value;
      input.value = value.replace(/[^a-zA-Z0-9]/g, "");
    });

    el.addEventListener("paste", (event) => {
      event.preventDefault();
      let clipboardData = event.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData("text/plain");
      event.target.value = pastedData.replace(/[^a-zA-Z0-9]/g, "");
    });
  },
};
