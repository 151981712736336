<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2517_44589)">
      <path
        d="M10.0005 1.62512L12.5005 7.50012L18.9536 8.18762L14.1463 12.4064L15.4172 18.698L10.0005 15.3074L4.58382 18.698L5.85465 12.4064L1.04736 8.18762L7.50049 7.50012L10.0005 1.62512Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2517_44589">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
