<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z"
      fill="#7360F2"
    />
    <path
      d="M11.3549 4.2438C11.1278 4.0298 10.2101 3.34944 8.16599 3.34019C8.16599 3.34019 5.75547 3.19174 4.58037 4.29253C3.92625 4.96071 3.69614 5.9384 3.67187 7.15053C3.6476 8.36266 3.61619 10.6343 5.76027 11.2502H5.76232L5.76095 12.1901C5.76095 12.1901 5.74724 12.5706 5.99257 12.6482C6.28933 12.7423 6.46348 12.4531 6.7468 12.1413C6.90231 11.9702 7.11706 11.7188 7.27888 11.5267C8.74524 11.6527 9.87302 11.3646 10.0011 11.322C10.2972 11.224 11.9725 11.0047 12.245 8.73337C12.5263 6.39216 12.109 4.91127 11.3549 4.2438ZM11.6034 8.56545C11.3734 10.462 10.0145 10.5812 9.764 10.6633C9.65745 10.6983 8.66694 10.9499 7.42136 10.8669C7.42136 10.8669 6.49324 12.0104 6.20334 12.3077C6.15809 12.3542 6.10488 12.373 6.06936 12.3637C6.01945 12.3513 6.00573 12.2909 6.00628 12.2027C6.00711 12.0766 6.01424 10.6408 6.01424 10.6408C6.01314 10.6408 6.01424 10.6408 6.01424 10.6408C4.20052 10.1266 4.30625 8.1932 4.32682 7.18078C4.34739 6.16836 4.53361 5.33898 5.08681 4.78117C6.08075 3.86174 8.12828 3.99913 8.12828 3.99913C9.85739 4.00683 10.6859 4.5386 10.8781 4.71674C11.516 5.27456 11.841 6.60938 11.6034 8.56489V8.56545Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.20133 6.36191C8.20466 6.2968 8.25904 6.24677 8.3228 6.25016C8.56135 6.26287 8.75981 6.33662 8.90117 6.48587C9.04198 6.63456 9.111 6.84227 9.12312 7.09174C9.12628 7.15686 9.07715 7.21227 9.01338 7.2155C8.94962 7.21873 8.89536 7.16856 8.89219 7.10344C8.88182 6.88989 8.82445 6.74452 8.73497 6.65003C8.64603 6.55611 8.5103 6.4966 8.31076 6.48597C8.247 6.48257 8.19801 6.42703 8.20133 6.36191Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.02619 5.72566C8.03085 5.66063 8.08625 5.61177 8.14992 5.61653C8.63539 5.65282 9.02614 5.82222 9.31076 6.13952L9.3108 6.13956C9.59234 6.45374 9.72615 6.84458 9.71643 7.30301C9.71505 7.3682 9.66218 7.4199 9.59835 7.41849C9.53452 7.41708 9.4839 7.36309 9.48528 7.2979C9.49385 6.89362 9.37743 6.56367 9.14024 6.29898C8.90377 6.03538 8.57321 5.88492 8.13305 5.85203C8.06937 5.84727 8.02153 5.79069 8.02619 5.72566Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.82202 5.12115C7.82247 5.05594 7.87459 5.00346 7.93843 5.00391C8.60931 5.00868 9.17639 5.23677 9.63169 5.69006C10.0908 6.14714 10.3178 6.7694 10.3236 7.54225C10.3241 7.60745 10.2727 7.66071 10.2089 7.66121C10.145 7.6617 10.0929 7.60925 10.0924 7.54405C10.087 6.81985 9.87604 6.26309 9.4703 5.85914C9.06075 5.4514 8.55224 5.2444 7.93682 5.24003C7.87298 5.23957 7.82158 5.18635 7.82202 5.12115Z"
      fill="white"
    />
    <path
      d="M8.33965 8.67868C8.33965 8.67868 8.50215 8.69269 8.58964 8.58261L8.76024 8.36343C8.84252 8.25475 9.04109 8.18543 9.23554 8.29607C9.38126 8.38094 9.52273 8.47321 9.65942 8.57253C9.78846 8.66944 10.0527 8.89464 10.0535 8.89464C10.1794 9.00318 10.2085 9.16256 10.1228 9.33062C10.1228 9.33159 10.1221 9.33328 10.1221 9.33412C10.0277 9.50124 9.90786 9.65195 9.76707 9.78059C9.76542 9.78143 9.76542 9.78228 9.76391 9.78312C9.64159 9.8875 9.52142 9.94683 9.40339 9.96112C9.38601 9.96422 9.36835 9.96535 9.35073 9.96448C9.29869 9.96499 9.24691 9.95686 9.19742 9.94039L9.19358 9.93465C9.01174 9.88227 8.70813 9.75118 8.20252 9.46632C7.90993 9.30342 7.63138 9.1155 7.36985 8.90458C7.23876 8.79893 7.11372 8.68567 6.99534 8.56538L6.98272 8.5525L6.9701 8.53961L6.95749 8.52673C6.95324 8.52253 6.94912 8.51819 6.94487 8.51384C6.82709 8.39295 6.71619 8.26524 6.61273 8.13137C6.40624 7.8643 6.22224 7.57987 6.06269 7.28113C5.78377 6.76462 5.65541 6.45483 5.60412 6.26885L5.5985 6.26493C5.58242 6.21438 5.5745 6.1615 5.57505 6.10835C5.57409 6.09036 5.57515 6.07232 5.5782 6.05457C5.59292 5.93422 5.65111 5.8114 5.75277 5.6861C5.7536 5.68456 5.75442 5.68456 5.75524 5.68288C5.88117 5.53909 6.02875 5.41674 6.19242 5.32043C6.19325 5.32043 6.19489 5.31959 6.19585 5.31959C6.36041 5.23206 6.51647 5.26175 6.62261 5.38962C6.62343 5.39046 6.84353 5.66033 6.93801 5.79212C7.03527 5.93185 7.12562 6.07647 7.20871 6.22543C7.31705 6.42388 7.24917 6.62709 7.14275 6.71084L6.92814 6.88507C6.81981 6.97442 6.83407 7.14038 6.83407 7.14038C6.83407 7.14038 7.15194 8.36903 8.33965 8.67868Z"
      fill="white"
    />
  </svg>
</template>
