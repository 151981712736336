<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58715)">
      <path
        d="M10.8317 1.25002V5.41669C10.8317 5.6377 10.7439 5.84966 10.5876 6.00594C10.4313 6.16222 10.2194 6.25002 9.99837 6.25002H7.14421L4.16504 7.91669V6.25002H3.74837C3.52736 6.25002 3.3154 6.16222 3.15912 6.00594C3.00284 5.84966 2.91504 5.6377 2.91504 5.41669V1.25002C2.91504 1.02901 3.00284 0.817045 3.15912 0.660765C3.3154 0.504484 3.52736 0.416687 3.74837 0.416687H9.99837C10.2194 0.416687 10.4313 0.504484 10.5876 0.660765C10.7439 0.817045 10.8317 1.02901 10.8317 1.25002Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.16504 6.24998V7.08331C9.16504 7.30433 9.25284 7.51629 9.40912 7.67257C9.5654 7.82885 9.77736 7.91665 9.99837 7.91665H12.8421L15.8317 9.58331V7.91665H16.2484C16.4694 7.91665 16.6813 7.82885 16.8376 7.67257C16.9939 7.51629 17.0817 7.30433 17.0817 7.08331V2.91665C17.0817 2.69563 16.9939 2.48367 16.8376 2.32739C16.6813 2.17111 16.4694 2.08331 16.2484 2.08331H10.8317"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99837 15.4165C11.149 15.4165 12.0817 14.4838 12.0817 13.3332C12.0817 12.1826 11.149 11.2499 9.99837 11.2499C8.84778 11.2499 7.91504 12.1826 7.91504 13.3332C7.91504 14.4838 8.84778 15.4165 9.99837 15.4165Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83203 20V19.1666C5.83203 18.6141 6.05152 18.0842 6.44223 17.6935C6.83293 17.3028 7.36283 17.0833 7.91536 17.0833H12.082C12.6346 17.0833 13.1645 17.3028 13.5552 17.6935C13.9459 18.0842 14.1654 18.6141 14.1654 19.1666V20"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.1652 14.5832C5.08568 14.5832 5.83187 13.837 5.83187 12.9165C5.83187 11.9961 5.08568 11.2499 4.1652 11.2499C3.24473 11.2499 2.49854 11.9961 2.49854 12.9165C2.49854 13.837 3.24473 14.5832 4.1652 14.5832Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.24854 19.1667V17.7084C1.24854 17.3216 1.40218 16.9507 1.67567 16.6772C1.94916 16.4037 2.32009 16.2501 2.70687 16.2501H5.62354C5.93663 16.2501 6.24141 16.3509 6.49279 16.5375C6.74416 16.7242 6.92879 16.9868 7.01937 17.2865"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8317 14.5832C16.7522 14.5832 17.4984 13.837 17.4984 12.9165C17.4984 11.9961 16.7522 11.2499 15.8317 11.2499C14.9112 11.2499 14.165 11.9961 14.165 12.9165C14.165 13.837 14.9112 14.5832 15.8317 14.5832Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.9775 17.2865C13.0681 16.9868 13.2527 16.7242 13.5041 16.5375C13.7555 16.3509 14.0603 16.2501 14.3734 16.2501H17.29C17.6768 16.2501 18.0477 16.4037 18.3212 16.6772C18.5947 16.9507 18.7484 17.3216 18.7484 17.7084V19.1667"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58715">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
