<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.7706 21.2966L19.9848 17.123C19.8659 17.0499 19.7329 17.0024 19.5945 16.9838L16.7422 16.5993C16.528 16.5704 16.3102 16.6117 16.1215 16.717C15.9327 16.8223 15.7832 16.9859 15.6952 17.1833L13.9828 21.0256C13.9032 21.2042 13.8777 21.4024 13.9096 21.5954C13.9414 21.7884 14.0292 21.9678 14.162 22.1115L16.512 24.6533C16.6185 24.7685 16.6964 24.9072 16.7394 25.0582C16.7824 25.2091 16.7893 25.368 16.7595 25.5221L16.2808 27.9966"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.11912 6.95068L7.00109 9.5944C6.90141 9.83012 6.89589 10.0951 6.98569 10.3348L8.42205 14.1683C8.48087 14.3253 8.57821 14.4649 8.70512 14.5745C8.83204 14.684 8.98445 14.7599 9.14835 14.7951L11.8271 15.3708C11.9757 15.4028 12.1151 15.4682 12.2347 15.5621C12.3542 15.656 12.4508 15.776 12.5171 15.9128L12.9928 16.8956C13.0746 17.0647 13.2025 17.2073 13.3617 17.3071C13.5209 17.4069 13.705 17.4599 13.8928 17.4599H15.5719"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0573 4.39355L20.224 6.48335C20.3343 6.68091 20.3744 6.91003 20.3376 7.13329C20.3009 7.35654 20.1895 7.56073 20.0217 7.7125L16.6578 10.7547C16.6007 10.8063 16.5378 10.8512 16.4705 10.8884L14.9402 11.7334C14.7922 11.8152 14.6259 11.8581 14.4568 11.8581H11.7886C11.5919 11.8581 11.3995 11.9161 11.2356 12.0249C11.0716 12.1338 10.9435 12.2885 10.8671 12.4699L9.82617 14.9409"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
