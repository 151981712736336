export default {
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile setup"])},
    "firstname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John"])}
    },
    "lastname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doe"])}
    },
    "patronymic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronymic"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])}
    },
    "email": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["john.doe", "@", "gmail.com"])}
    },
    "phone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
    },
    "buttons": {
      "changePasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
      "tipCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip card number"])},
      "saveUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update user data"])}
    },
    "passwordChange": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "form": {
        "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
        "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New password"])}
      },
      "buttons": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])}
      },
      "errors": {
        "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot be empty"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Password must contain at least ", _interpolate(_named("n")), " characters"])},
        "samePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
        "hasDigitMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one number"])},
        "hasUppercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one uppercase letter"])},
        "hasLowercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwor must contain at least one  one special character"])},
        "hasSpecialSymbolsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasSpecialSymbolsMessage"])},
        "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many requests"])},
        "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong current password"])}
      }
    },
    "bankDetails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])},
      "address": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])}
      },
      "buttons": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
      }
    }
  },
  "datapicker": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "timeplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time"])},
    "dateplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])}
  },
  "tasks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Settings"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a task for a specific time"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the task"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task name cannot be empty"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task name cannot be less than ", _interpolate(_named("n")), " characters"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task description cannot be empty"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Task description cannot be less than ", _interpolate(_named("n")), " characters"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit task"])}
    }
  },
  "news": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Settings"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a news for a specific time"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the news"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News name cannot be empty"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["News name cannot be less than ", _interpolate(_named("n")), " characters"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News description cannot be empty"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["News description cannot be less than ", _interpolate(_named("n")), " characters"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing news"])}
    }
  },
  "feedbacks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback Settings"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up a feedback for a specific time"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and Time"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time of the feedback"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback name cannot be empty"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feedback name cannot be less than ", _interpolate(_named("n")), " characters"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback description cannot be empty"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feedback description cannot be less than ", _interpolate(_named("n")), " characters"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select at least one point"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be after end date"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date cannot be before start date"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing feedback"])}
    }
  },
  "multiselect": {
    "current": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 points selected"]), _normalize([_interpolate(_named("n")), " point selected"]), _normalize([_interpolate(_named("n")), " points selected"]), _normalize([_interpolate(_named("n")), " points selected"])])},
    "allpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All points selected"])}
  },
  "phone": {
    "countrySelector": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])}
    },
    "phoneInput": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example:"])}
    }
  },
  "button": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "toast": {
    "successSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " settings saved!"])},
    "errorSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error saving ", _interpolate(_named("target")), " settings!"])},
    "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " deleted!"])},
    "word": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])}
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of clients"])}
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клиентов"])}
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push"])},
      "feedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedbacks"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic mailing"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
  },
  "staff": {
    "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff setting"])},
    "buttonCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calling staff"])},
    "buttonTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "buttonNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News feed"])},
    "buttonNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "buttonFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New employee"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new employee"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit employee"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make edits"])}
    },
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong login"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not be empty"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid"])},
      "surnameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname must not be empty"])},
      "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name must not be empty"])},
      "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field must contain at least 3 characters"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not be empty"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find a user with this email address."])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone cannot be empty"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])}
      }
    }
  },
  "pselection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a loyalty program"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сhoose and customize your loyalty program"])},
    "radioButton": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "discount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount loyalty program"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up your direct discount to the customer"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting levels"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the required number of levels, their sum and percentage of the discount"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum from"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cardowner"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "untilDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until discount"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount settings saved!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Discount settings!"])}
      }
    },
    "bonus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus loyalty system"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize for your bonus program"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting levels"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the required number of levels, their sum and percentage of the discount"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level name"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum from"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add level"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balans"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cardowner"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until bonus"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus settings saved!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Bonus settings!"])}
      }
    },
    "coupon": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon loyalty program"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The loyalty system allows creating one-time discount coupons (gift certificates) with different face values."])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "untilDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until discount"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])},
        "accumulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accumulation"])},
        "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["congratulation"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Bonus settings!"])}
      }
    },
    "clubcard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Card"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview of the club card"])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balans"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card owner"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until bonus"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])}
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
      "qr-catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])}
    },
    "qrCatalog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сustomize your QR-catalog"])},
      "colors": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Color"])},
        "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title color"])},
        "relief-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color"])},
        "relief-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category color"])},
        "relief-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color price"])},
        "relief-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color price"])}
      },
      "logoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo for Catalog"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo text for catalog"])},
      "downloadLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download QR Code"])},
      "previewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "previewLinkHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All changes will be available after clicking on the button \"Publish\""])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRCatalog settings saved!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving QR-catalog settings!"])}
      }
    },
    "wallet": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сard Wallet"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сustomize your card WALLET"])},
      "cardColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card color"])},
      "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color"])},
      "titleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title color"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo for Wallet"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo text for Wallet"])},
      "centralImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сentral image for Wallet"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet settings saved!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving Wallet settings!"])}
      }
    }
  },
  "leftSideMenu": {
    "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agents"])},
    "averageСheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average check"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-catalog"])},
    "clientData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client data"])},
    "crm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "for-client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Clent"])},
    "for-staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Staff"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
    "levelRetention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer retention"])},
    "levelSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "pointsOfSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points of sale"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "pselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty program"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "topSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top sales"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сard Wallet"])}
  },
  "application": {
    "imageForBothTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])}
  },
  "locale": {
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "forgotQuery": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the e-mail specified during registration. We will send you a link to reset your password."])},
      "messageSuccessReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent a password recovery link to the specified Email."])}
    },
    "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please sign-in to your account"])},
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "buttonReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong login"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email must not be empty"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not valid"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong password"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must not be empty"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find a user with this email address."])}
    }
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])}
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Page"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
  },
  "main": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", want to start setting up?"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go out"])},
    "new_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no message"]), _normalize(["one message"]), _normalize([_interpolate(_named("count")), " messages"])])}
  },
  "nav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal account"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyalty program"])},
    "bank-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Links"])}
  },
  "points": {
    "workTime": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating mode"])}
    },
    "titlenameNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New point of sale"])},
    "subtitleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new point of sale. After adding information, click \"Save\"."])},
    "titlenameEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit point"])},
    "subtitleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit point for customers to contact you"])},
    "phone": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete phone number"])},
      "addPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add phone"])}
    },
    "btnNewPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add point of sale"])},
    "table": {
      "header": {
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POINTS OF SALE"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADDRESS"])},
        "qrQatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR CATALOG"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE"])},
        "viber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])},
        "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTION"])}
      },
      "body": {
        "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any sale points yet."])}
      },
      "delete": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete сontact"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the сontact? All data will be deleted"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      }
    },
    "address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing your address"])}
    },
    "title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the title"])}
    },
    "subdomain": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the domain name"])}
    },
    "errors": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address cannot be empty"])},
      "subdomain": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name cannot be empty"])},
        "latin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only latin symbols are allowed"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name already exists"])}
      },
      "domain_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact not saved. Such domain name already exists"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title cannot be empty"])},
      "title_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field is in the wrong format"])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone cannot be empty"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])}
      }
    },
    "catalog": {
      "point": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])}
      },
      "copy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy QR-catalog from previous point?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      }
    }
  },
  "catalog": {
    "sale_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale channels"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products and services"])},
    "qr_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog appearance"])},
    "point": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a point"])},
      "not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start working with the catalog, select a point"])},
      "list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you start working with the catalog, create a point"])}
    }
  },
  "products": {
    "errors": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field cannot be empty"])}
    }
  },
  "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}