<template>
  <svg
    id="app-icon_ios"
    preserveAspectRatio="xMidYMid slice"
    viewBox="0 0 160 160"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect
        id="ugid9"
        height="144"
        rx="32"
        width="144"
      />
      <rect
        id="ugid3"
        height="144"
        rx="16"
        width="144"
      />
      <filter
        id="ugid10"
        filterUnits="objectBoundingBox"
        height="118.1%"
        width="118.1%"
        x="-9%"
        y="-7.6%"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <filter
        id="ugid4"
        filterUnits="objectBoundingBox"
        height="118.1%"
        width="118.1%"
        x="-9%"
        y="-7.6%"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
      </filter>
      <radialGradient
        id="ugid6"
        cx="27.538%"
        cy="27.486%"
        fx="27.538%"
        fy="27.486%"
        r="65.878%"
      >
        <stop
          offset="0%"
          stop-color="#FFF"
          stop-opacity=".1"
        />
        <stop
          offset="100%"
          stop-color="#FFF"
          stop-opacity="0"
        />
      </radialGradient>
      <radialGradient
        id="ugid5"
        cx="30.79%"
        cy="10.901%"
        fx="30.79%"
        fy="10.901%"
        gradientTransform="scale(.70205 1) rotate(41.526 .373 .281)"
        r="113.341%"
      >
        <stop
          offset="0%"
          stop-opacity=".15"
        />
        <stop
          offset="14.151%"
          stop-opacity=".15"
        />
        <stop
          offset="64.34%"
          stop-opacity=".02"
        />
        <stop
          offset="100%"
          stop-opacity="0"
        />
      </radialGradient>
      <linearGradient
        id="ugid8"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#373B45"
        />
        <stop
          offset="100%"
          stop-color="#181A1D"
        />
      </linearGradient>
      <linearGradient
        id="ugid2"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#373B45"
        />
        <stop
          offset="100%"
          stop-color="#181A1D"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g transform="translate(8 6)">
        <use
          fill="#000"
          filter="url(#ugid10)"
          xlink:href="#ugid9"
        />
        <use
          fill="url(#ugid8)"
          xlink:href="#ugid9"
        />
      </g>
      <path
        d="M59.39 88V73.691c0-4.059-2.173-5.986-5.822-5.986-3.034 0-5.658 1.804-6.806 3.608-.738-2.255-2.583-3.608-5.494-3.608-3.034 0-5.658 1.886-6.478 3.075v-2.583h-5.207V88h5.207V74.675c.779-1.107 2.296-2.337 4.1-2.337 2.132 0 2.952 1.312 2.952 3.157V88h5.248V74.634c.738-1.066 2.255-2.296 4.1-2.296 2.132 0 2.952 1.312 2.952 3.157V88h5.248zm14.309.492c-6.519 0-10.455-4.756-10.455-10.414 0-5.617 3.936-10.373 10.455-10.373 6.56 0 10.496 4.756 10.496 10.373 0 5.658-3.936 10.414-10.496 10.414zm0-4.633c3.239 0 5.084-2.665 5.084-5.781 0-3.075-1.845-5.74-5.084-5.74s-5.043 2.665-5.043 5.74c0 3.116 1.804 5.781 5.043 5.781zM106.622 88V74.019c0-3.854-2.091-6.314-6.437-6.314-3.239 0-5.658 1.558-6.929 3.075v-2.583h-5.207V88h5.207V74.675c.861-1.189 2.46-2.337 4.51-2.337 2.214 0 3.649.943 3.649 3.69V88h5.207zm14.35.492c-6.519 0-10.455-4.756-10.455-10.414 0-5.617 3.936-10.373 10.455-10.373 6.56 0 10.496 4.756 10.496 10.373 0 5.658-3.936 10.414-10.496 10.414zm0-4.633c3.239 0 5.084-2.665 5.084-5.781 0-3.075-1.845-5.74-5.084-5.74s-5.043 2.665-5.043 5.74c0 3.116 1.804 5.781 5.043 5.781z"
        fill="#FFF"
      />
    </g>
  </svg>
</template>
