<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25895 16.5934C3.14076 14.7084 2.74916 12.48 3.15768 10.3267C3.56621 8.17341 4.74675 6.24331 6.47764 4.89884C8.20853 3.55437 10.3707 2.88801 12.5581 3.0249C14.7455 3.16178 16.8078 4.09249 18.3575 5.64225C19.9073 7.19202 20.838 9.25423 20.9749 11.4417C21.1118 13.6291 20.4455 15.7912 19.101 17.5221C17.7565 19.253 15.8264 20.4336 13.6732 20.8421C11.5199 21.2507 9.29149 20.8591 7.40649 19.7409L7.40651 19.7408L4.29808 20.6289C4.16947 20.6657 4.03338 20.6673 3.90391 20.6338C3.77443 20.6002 3.65628 20.5327 3.5617 20.4381C3.46713 20.3435 3.39956 20.2254 3.36601 20.0959C3.33246 19.9664 3.33415 19.8303 3.37089 19.7017L4.25901 16.5933L4.25895 16.5934Z"
      stroke="#595A5B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 10.5H15"
      stroke="#595A5B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 13.5H15"
      stroke="#595A5B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
