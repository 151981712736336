<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3339 14.0103V15.4166C18.3339 16.1093 17.7454 16.6666 17.0214 16.6666H2.97974C2.25578 16.6666 1.66724 16.1093 1.66724 15.4166V14.0103"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M12.5006 8.75H11.6672V9.58333H12.5006V8.75Z"
      fill="#0E0E22"
    />

    <path
      d="M7.91781 9.16656H7.08447V9.9999H7.91781V9.16656Z"
      fill="#0E0E22"
    />

    <path
      d="M10.4178 5.83337H9.58447V6.66671H10.4178V5.83337Z"
      fill="#0E0E22"
    />

    <path
      d="M5.41781 7.08337H4.58447V7.91671H5.41781V7.08337Z"
      fill="#0E0E22"
    />

    <path
      d="M15.4178 7.5H14.5845V8.33333H15.4178V7.5Z"
      fill="#0E0E22"
    />

    <path
      d="M9.66309 14.1668H2.361C1.51725 14.1668 0.834961 13.5157 0.834961 12.7085C0.834961 11.9012 1.51725 11.2501 2.361 11.2501H17.6423C18.486 11.2501 19.1683 11.9012 19.1683 12.7085C19.1683 13.5157 18.486 14.1668 17.6423 14.1668H12.0068"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M1.66858 11.4064V10.0001C1.66858 6.547 5.16858 3.75012 10.0019 3.75012C14.8352 3.75012 18.3352 6.547 18.3352 10.0001V11.4064"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.00134 11.2501C5.80734 11.2507 6.5867 11.5388 7.19926 12.0626L10.8347 15.1668L14.4701 12.0626C15.0827 11.5388 15.862 11.2507 16.668 11.2501"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
