<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99959 5.83317C11.1502 5.83317 12.0829 4.90043 12.0829 3.74984C12.0829 2.59924 11.1502 1.6665 9.99959 1.6665C8.849 1.6665 7.91626 2.59924 7.91626 3.74984C7.91626 4.90043 8.849 5.83317 9.99959 5.83317Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99959 16.2499C11.1502 16.2499 12.0829 15.6902 12.0829 14.9999C12.0829 14.3095 11.1502 13.7499 9.99959 13.7499C8.849 13.7499 7.91626 14.3095 7.91626 14.9999C7.91626 15.6902 8.849 16.2499 9.99959 16.2499Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.91626 14.9999H3.80688"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.8072 12.0206L15.552 18.0831C15.5137 18.2707 15.4121 18.4393 15.2641 18.5607C15.1161 18.6821 14.9309 18.7489 14.7395 18.7498H5.26034C5.06805 18.7501 4.88158 18.6838 4.73255 18.5623C4.58351 18.4408 4.48108 18.2715 4.44263 18.0831L3.19263 12.0206"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0833 14.9999H16.1926"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.93205 3.505C4.09871 4.14563 1.24976 6.53105 1.24976 9.3748C1.24976 10.8696 2.31746 12.0831 3.74976 12.0831C4.14768 12.0833 4.54073 11.9957 4.90086 11.8264C5.26099 11.6571 5.57931 11.4105 5.83309 11.104C6.08724 11.4099 6.40567 11.6562 6.76573 11.8251C7.1258 11.9941 7.51867 12.0818 7.91642 12.0818C8.31417 12.0818 8.70704 11.9941 9.06711 11.8251C9.42718 11.6562 9.7456 11.4099 9.99976 11.104C10.2539 11.4099 10.5723 11.6562 10.9324 11.8251C11.2925 11.9941 11.6853 12.0818 12.0831 12.0818C12.4808 12.0818 12.8737 11.9941 13.2338 11.8251C13.5938 11.6562 13.9123 11.4099 14.1664 11.104C14.4202 11.4105 14.7385 11.6571 15.0986 11.8264C15.4588 11.9957 15.8518 12.0833 16.2498 12.0831C17.682 12.0831 18.7498 10.8696 18.7498 9.3748C18.7498 6.53105 15.9008 4.14563 12.0675 3.505"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
