<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56108)">
      <path
        d="M9.06112 5.90125L8.68612 5.73979C8.52067 5.6705 8.37207 5.56642 8.25042 5.43461C8.12876 5.3028 8.0369 5.14635 7.98107 4.97589C7.92524 4.80543 7.90674 4.62496 7.92685 4.44672C7.94695 4.26848 8.00518 4.09665 8.09757 3.94291L8.57674 3.14083C8.66914 2.98709 8.72737 2.81526 8.74747 2.63702C8.76757 2.45878 8.74908 2.27831 8.69325 2.10785C8.63742 1.93739 8.54555 1.78094 8.4239 1.64913C8.30224 1.51732 8.15365 1.41324 7.9882 1.34395L7.60278 1.1825"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.3946 5.90125L12.0196 5.73979C11.8542 5.6705 11.7056 5.56642 11.5839 5.43461C11.4623 5.3028 11.3704 5.14635 11.3146 4.97589C11.2587 4.80543 11.2402 4.62496 11.2603 4.44672C11.2804 4.26848 11.3387 4.09665 11.4311 3.94291L11.9102 3.14083C12.0026 2.98709 12.0609 2.81526 12.081 2.63702C12.1011 2.45878 12.0826 2.27831 12.0267 2.10785C11.9709 1.93739 11.879 1.78094 11.7574 1.64913C11.6357 1.51732 11.4871 1.41324 11.3217 1.34395L10.9363 1.1825"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.91528 7.91687L4.6757 19.1669H15.3215L17.0819 7.91687"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.91528 7.91687L7.49862 12.5002H12.4986L17.0819 7.91687"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M19.4101 10.245L17.082 7.91687H2.91528L0.587158 10.245"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56108">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
