<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75146 1.66687H16.2515V4.16687H3.75146V1.66687Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.2515 4.16687L17.5015 8.33354V18.3335H2.50146V8.33354L3.75146 4.16687"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.16797 7.91687V6.66687H10.8346V11.0783"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.835 7.50018H12.5016V10.0262"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.50146 10.0262V7.91687H9.16813V11.0783"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.7878 10.0002C12.2982 10.0002 11.8503 10.2346 11.5117 10.5939C11.3174 10.8011 11.0826 10.9663 10.8219 11.0791C10.5612 11.192 10.2802 11.2503 9.99609 11.2503C9.71202 11.2503 9.43096 11.192 9.17028 11.0791C8.90959 10.9663 8.67482 10.8011 8.48047 10.5939C8.14193 10.2346 7.69922 10.0002 7.20443 10.0002H5.71484L7.20964 16.2502H12.793L14.2878 10.0002H12.7878Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
