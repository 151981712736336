<template>
  <svg
    width="90"
    height="17"
    viewBox="0 0 90 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M82.4807 16.0455V5.54546H83.6142V6.75853H83.7534C83.8396 6.62595 83.9589 6.45692 84.1113 6.25143C84.2671 6.04262 84.4892 5.85701 84.7775 5.69461C85.0692 5.52889 85.4636 5.44603 85.9608 5.44603C86.6038 5.44603 87.1705 5.60678 87.6611 5.92827C88.1516 6.24977 88.5344 6.7055 88.8095 7.29546C89.0846 7.88542 89.2221 8.58145 89.2221 9.38353C89.2221 10.1922 89.0846 10.8932 88.8095 11.4865C88.5344 12.0765 88.1532 12.5339 87.666 12.8587C87.1788 13.1802 86.617 13.3409 85.9806 13.3409C85.4901 13.3409 85.0974 13.2597 84.8024 13.0973C84.5074 12.9316 84.2804 12.7443 84.1213 12.5355C83.9622 12.3234 83.8396 12.1477 83.7534 12.0085H83.6539V16.0455H82.4807ZM83.6341 9.36364C83.6341 9.94035 83.7186 10.4491 83.8876 10.8899C84.0566 11.3274 84.3036 11.6705 84.6284 11.919C84.9532 12.1643 85.3509 12.2869 85.8216 12.2869C86.3121 12.2869 86.7214 12.1577 87.0495 11.8992C87.381 11.6373 87.6296 11.286 87.7953 10.8452C87.9643 10.401 88.0488 9.9072 88.0488 9.36364C88.0488 8.82671 87.966 8.34281 87.8003 7.91194C87.6378 7.47775 87.3909 7.13471 87.0595 6.88282C86.7314 6.62761 86.3187 6.50001 85.8216 6.50001C85.3443 6.50001 84.9432 6.62098 84.6184 6.86293C84.2936 7.10157 84.0484 7.43632 83.8826 7.86719C83.7169 8.29475 83.6341 8.79357 83.6341 9.36364Z"
      fill="#0E0E22"
    />
    <path
      d="M73.9494 16.0455V5.54546H75.0829V6.75853H75.2221C75.3083 6.62595 75.4276 6.45692 75.5801 6.25143C75.7359 6.04262 75.9579 5.85701 76.2463 5.69461C76.5379 5.52889 76.9324 5.44603 77.4295 5.44603C78.0725 5.44603 78.6393 5.60678 79.1298 5.92827C79.6203 6.24977 80.0031 6.7055 80.2782 7.29546C80.5533 7.88542 80.6909 8.58145 80.6909 9.38353C80.6909 10.1922 80.5533 10.8932 80.2782 11.4865C80.0031 12.0765 79.622 12.5339 79.1348 12.8587C78.6476 13.1802 78.0858 13.3409 77.4494 13.3409C76.9589 13.3409 76.5661 13.2597 76.2711 13.0973C75.9762 12.9316 75.7491 12.7443 75.59 12.5355C75.4309 12.3234 75.3083 12.1477 75.2221 12.0085H75.1227V16.0455H73.9494ZM75.1028 9.36364C75.1028 9.94035 75.1873 10.4491 75.3564 10.8899C75.5254 11.3274 75.7723 11.6705 76.0971 11.919C76.4219 12.1643 76.8197 12.2869 77.2903 12.2869C77.7808 12.2869 78.1902 12.1577 78.5183 11.8992C78.8497 11.6373 79.0983 11.286 79.264 10.8452C79.4331 10.401 79.5176 9.9072 79.5176 9.36364C79.5176 8.82671 79.4347 8.34281 79.269 7.91194C79.1066 7.47775 78.8597 7.13471 78.5282 6.88282C78.2001 6.62761 77.7875 6.50001 77.2903 6.50001C76.813 6.50001 76.412 6.62098 76.0872 6.86293C75.7624 7.10157 75.5171 7.43632 75.3514 7.86719C75.1857 8.29475 75.1028 8.79357 75.1028 9.36364Z"
      fill="#0E0E22"
    />
    <path
      d="M65.0652 13.1818H63.7726L67.5112 3H68.7839L72.5226 13.1818H71.2299L68.1873 4.6108H68.1078L65.0652 13.1818ZM65.5424 9.20455H70.7527V10.2983H65.5424V9.20455Z"
      fill="#0E0E22"
    />
    <path
      d="M62.5831 7.25569L61.5291 7.55398C61.4628 7.37832 61.3651 7.20763 61.2358 7.04191C61.1099 6.87288 60.9375 6.73367 60.7188 6.6243C60.5 6.51492 60.2199 6.46023 59.8786 6.46023C59.4112 6.46023 59.0218 6.56795 58.7102 6.78339C58.402 6.99551 58.2479 7.26563 58.2479 7.59376C58.2479 7.88542 58.3539 8.11577 58.5661 8.28481C58.7782 8.45384 59.1096 8.5947 59.5604 8.70739L60.6939 8.9858C61.3767 9.15152 61.8854 9.40507 62.2202 9.74646C62.5549 10.0845 62.7223 10.5204 62.7223 11.054C62.7223 11.4915 62.5964 11.8826 62.3445 12.2273C62.0959 12.572 61.7479 12.8438 61.3004 13.0426C60.853 13.2415 60.3326 13.3409 59.7393 13.3409C58.9605 13.3409 58.3158 13.1719 57.8054 12.8338C57.295 12.4957 56.9718 12.0019 56.8359 11.3523L57.9496 11.0739C58.0556 11.4849 58.2562 11.7931 58.5511 11.9986C58.8494 12.2041 59.2389 12.3068 59.7195 12.3068C60.2663 12.3068 60.7005 12.1908 61.022 11.9588C61.3468 11.7235 61.5092 11.4418 61.5092 11.1136C61.5092 10.8485 61.4164 10.6264 61.2308 10.4475C61.0452 10.2652 60.7602 10.1293 60.3757 10.0398L59.103 9.74148C58.4036 9.57576 57.8899 9.3189 57.5618 8.97089C57.237 8.61956 57.0746 8.1804 57.0746 7.65342C57.0746 7.22254 57.1955 6.84139 57.4375 6.50995C57.6828 6.17851 58.0159 5.91833 58.4368 5.72941C58.861 5.54049 59.3416 5.44603 59.8786 5.44603C60.6342 5.44603 61.2275 5.61175 61.6584 5.94319C62.0926 6.27463 62.4008 6.71213 62.5831 7.25569Z"
      fill="#0E0E22"
    />
    <path
      d="M55.5458 5.54546V6.53978H51.5884V5.54546H55.5458ZM52.7418 3.71591H53.9151V10.9943C53.9151 11.3258 53.9632 11.5743 54.0593 11.7401C54.1587 11.9025 54.2847 12.0118 54.4371 12.0682C54.5929 12.1212 54.757 12.1477 54.9293 12.1477C55.0586 12.1477 55.1646 12.1411 55.2475 12.1278C55.3304 12.1113 55.3966 12.098 55.4464 12.0881L55.685 13.142C55.6055 13.1719 55.4944 13.2017 55.3519 13.2315C55.2094 13.2647 55.0288 13.2813 54.81 13.2813C54.4786 13.2813 54.1537 13.21 53.8356 13.0675C53.5207 12.925 53.2589 12.7079 53.0501 12.4162C52.8446 12.1245 52.7418 11.7566 52.7418 11.3125V3.71591Z"
      fill="#0E0E22"
    />
    <path
      d="M46.5833 13.3608C46.0994 13.3608 45.6602 13.2697 45.2658 13.0874C44.8714 12.9018 44.5582 12.635 44.3262 12.2869C44.0942 11.9356 43.9781 11.5114 43.9781 11.0142C43.9781 10.5767 44.0643 10.2221 44.2367 9.95029C44.409 9.6752 44.6394 9.45976 44.9277 9.30398C45.2161 9.14821 45.5343 9.0322 45.8823 8.95597C46.2336 8.87643 46.5866 8.81345 46.9412 8.76705C47.4052 8.70739 47.7814 8.66265 48.0698 8.63282C48.3614 8.59968 48.5736 8.54499 48.7061 8.46876C48.842 8.39253 48.91 8.25995 48.91 8.07103V8.03126C48.91 7.54073 48.7757 7.15957 48.5073 6.88779C48.2421 6.61601 47.8394 6.48012 47.2992 6.48012C46.739 6.48012 46.2999 6.60275 45.9817 6.84802C45.6635 7.09328 45.4398 7.35512 45.3105 7.63353L44.1969 7.2358C44.3958 6.77179 44.6609 6.41052 44.9924 6.152C45.3271 5.89016 45.6917 5.70787 46.0861 5.60512C46.4838 5.49906 46.8749 5.44603 47.2594 5.44603C47.5047 5.44603 47.7864 5.47586 48.1046 5.53552C48.4261 5.59186 48.736 5.70952 49.0343 5.8885C49.3359 6.06748 49.5861 6.3376 49.785 6.69887C49.9838 7.06014 50.0833 7.54404 50.0833 8.15058V13.1818H48.91V12.1477H48.8503C48.7708 12.3135 48.6382 12.4908 48.4526 12.6797C48.267 12.8686 48.0201 13.0294 47.7118 13.1619C47.4036 13.2945 47.0274 13.3608 46.5833 13.3608ZM46.7622 12.3068C47.2263 12.3068 47.6174 12.2157 47.9355 12.0334C48.257 11.8511 48.499 11.6158 48.6614 11.3274C48.8271 11.0391 48.91 10.7358 48.91 10.4176V9.34376C48.8603 9.40342 48.7509 9.4581 48.5818 9.50782C48.4161 9.55422 48.2239 9.59565 48.0051 9.63211C47.7897 9.66525 47.5792 9.69508 47.3737 9.7216C47.1716 9.7448 47.0075 9.76469 46.8816 9.78126C46.5766 9.82103 46.2916 9.88566 46.0264 9.97515C45.7646 10.0613 45.5525 10.1922 45.3901 10.3679C45.231 10.5403 45.1514 10.7756 45.1514 11.0739C45.1514 11.4815 45.3022 11.7898 45.6039 11.9986C45.9088 12.2041 46.2949 12.3068 46.7622 12.3068Z"
      fill="#0E0E22"
    />
    <path
      d="M37.2379 8.58807V13.1818H36.0646V3H37.2379V6.73864H37.3374C37.5163 6.34422 37.7848 6.03101 38.1428 5.79901C38.504 5.56368 38.9846 5.44602 39.5845 5.44602C40.1049 5.44602 40.5606 5.55043 40.9517 5.75923C41.3428 5.96473 41.6461 6.28125 41.8615 6.70881C42.0803 7.13305 42.1896 7.6733 42.1896 8.32955V13.1818H41.0163V8.40909C41.0163 7.80256 40.8589 7.33357 40.544 7.00213C40.2325 6.66738 39.8 6.5 39.2465 6.5C38.862 6.5 38.5173 6.5812 38.2124 6.74361C37.9108 6.90601 37.6721 7.14299 37.4965 7.45455C37.3241 7.7661 37.2379 8.14394 37.2379 8.58807Z"
      fill="#0E0E22"
    />
    <path
      d="M24.7841 13.1818L22 3H23.2528L25.3807 11.2926H25.4801L27.6477 3H29.0398L31.2074 11.2926H31.3068L33.4347 3H34.6875L31.9034 13.1818H30.6307L28.3835 5.06818H28.304L26.0568 13.1818H24.7841Z"
      fill="#0E0E22"
    />
    <path
      d="M4.44054 13.4737L4.68724 13.614C5.70926 14.2105 6.87226 14.5263 8.03525 14.5263C11.6652 14.5263 14.6256 11.5789 14.6256 7.96491C14.6256 6.21053 13.956 4.5614 12.6872 3.33333C11.4538 2.10526 9.79737 1.40351 8.03525 1.40351C4.4053 1.40351 1.44494 4.35088 1.44494 7.96491C1.44494 9.19298 1.79737 10.4211 2.46697 11.4386L2.60794 11.6842L1.93834 14.1053L4.44054 13.4737ZM0.0704956 16L1.19825 11.8947C0.493403 10.7018 0.14098 9.33333 0.14098 7.96491C0.14098 3.61403 3.70045 0.0701752 8.07049 0.0701752C10.185 0.0701752 12.1938 0.877193 13.674 2.38596C15.1542 3.85965 16 5.85965 16 7.96491C16 12.3158 12.4405 15.8596 8.07049 15.8596C6.73129 15.8596 5.42732 15.5439 4.29957 14.9123L0.0704956 16Z"
      fill="#B3B3B3"
    />
    <path
      d="M0 15.8947L1.12775 11.8246C0.422907 10.6316 0.0704846 9.26316 0.0704846 7.89474C0.0704846 3.54386 3.62996 0 8 0C10.1145 0 12.1233 0.807017 13.6035 2.31579C15.0837 3.78947 15.9295 5.78947 15.9295 7.89474C15.9295 12.2456 12.37 15.7895 8 15.7895C6.66079 15.7895 5.35683 15.4737 4.22907 14.8421L0 15.8947Z"
      fill="white"
    />
    <path
      d="M7.96476 1.33333C4.3348 1.33333 1.37445 4.2807 1.37445 7.89473C1.37445 9.1228 1.72687 10.3509 2.39648 11.3684L2.53745 11.614L1.86784 14.0351L4.37005 13.3684L4.61674 13.5088C5.63877 14.1053 6.80176 14.421 7.96476 14.421C11.5947 14.421 14.5551 11.4737 14.5551 7.85964C14.5551 6.10526 13.8502 4.45614 12.6167 3.22807C11.3833 2.03508 9.72687 1.33333 7.96476 1.33333Z"
      fill="url(#paint0_linear_0_1)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.99119 4.5965C5.85022 4.28071 5.67401 4.24562 5.53304 4.24562H5.14538C5.00441 4.24562 4.79295 4.28071 4.61674 4.49123C4.44053 4.70176 3.9119 5.1579 3.9119 6.14036C3.9119 7.12281 4.61674 8.03509 4.72247 8.17544C4.8282 8.3158 6.09692 10.3509 8.10573 11.1579C9.76212 11.8246 10.1145 11.6842 10.467 11.6491C10.8194 11.614 11.63 11.1579 11.8062 10.7018C11.9824 10.2456 11.9824 9.85966 11.9119 9.75439C11.8767 9.68422 11.7357 9.61404 11.5242 9.50878C11.3128 9.40352 10.3612 8.94737 10.185 8.8772C10.0088 8.80702 9.86784 8.77194 9.72687 8.98246C9.5859 9.19299 9.23348 9.61404 9.09251 9.75439C8.98679 9.89474 8.84582 9.89474 8.6696 9.78948C8.49339 9.68422 7.82379 9.47369 7.0837 8.80702C6.48458 8.28071 6.09692 7.64913 5.99119 7.4386C5.88546 7.22808 5.99119 7.12281 6.06168 7.05264C6.1674 6.94738 6.27313 6.80702 6.34361 6.70176C6.44934 6.5965 6.48458 6.49123 6.55507 6.38597C6.62555 6.28071 6.59031 6.14036 6.55507 6.03509C6.51983 5.92983 6.1674 4.98246 5.99119 4.5965Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_1"
        x1="7.92952"
        y1="2.1228"
        x2="7.99589"
        y2="13.3228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57D163" />
        <stop offset="1" stop-color="#23B33A" />
      </linearGradient>
    </defs>
  </svg>
</template>
