<template>
  <svg
    width="242"
    height="189"
    viewBox="0 0 242 189"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_267_3541)">
      <circle
        cx="78"
        cy="67.1719"
        r="23"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="38"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="53"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="68"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="88"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="108"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="69.1719"
        r="128"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="69.1719"
        r="148"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="69.1719"
        r="168"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="69.1719"
        r="188"
        stroke="#00CDD7"
        stroke-width="4"
      />
      <circle
        cx="78"
        cy="67.1719"
        r="208"
        stroke="#00CDD7"
        stroke-width="4"
      />
    </g>
    <defs>
      <clipPath id="clip0_267_3541">
        <rect
          width="242"
          height="188"
          fill="white"
          transform="translate(0 0.171875)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
