<template>
  <svg
    width="40"
    height="31"
    viewBox="0 0 40 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 18.5V13.5"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5996 7.5417L16.7215 2.64324C16.5746 2.52088 16.4017 2.43383 16.216 2.38876C16.0302 2.3437 15.8366 2.34182 15.6501 2.38328L6.95738 4.31499C6.75017 4.36104 6.55809 4.45909 6.39926 4.59992C6.24043 4.74074 6.12008 4.91969 6.04956 5.1199C5.97903 5.32011 5.96065 5.53499 5.99615 5.74426C6.03164 5.95354 6.11985 6.15034 6.25245 6.31609L9.99962 11"
      fill="none"
    />
    <path
      d="M22.5996 7.5417L16.7215 2.64324C16.5746 2.52088 16.4017 2.43383 16.216 2.38876C16.0302 2.3437 15.8366 2.34182 15.6501 2.38328L6.95738 4.31499C6.75017 4.36104 6.55809 4.45909 6.39926 4.59992C6.24043 4.74074 6.12008 4.91969 6.04956 5.1199C5.97903 5.32011 5.96065 5.53499 5.99615 5.74426C6.03164 5.95354 6.11985 6.15034 6.25245 6.31609L9.99962 11"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.7129 24.3643L16.7219 29.3568C16.575 29.4792 16.4021 29.5662 16.2164 29.6113C16.0306 29.6563 15.837 29.6582 15.6505 29.6168L6.95779 27.6851C6.75058 27.639 6.5585 27.541 6.39967 27.4001C6.24085 27.2593 6.1205 27.0804 6.04997 26.8801C5.97944 26.6799 5.96106 26.4651 5.99656 26.2558C6.03206 26.0465 6.12026 25.8497 6.25287 25.684L10 21"
      fill="none"
    />
    <path
      d="M22.7129 24.3643L16.7219 29.3568C16.575 29.4792 16.4021 29.5662 16.2164 29.6113C16.0306 29.6563 15.837 29.6582 15.6505 29.6168L6.95779 27.6851C6.75058 27.639 6.5585 27.541 6.39967 27.4001C6.24085 27.2593 6.1205 27.0804 6.04997 26.8801C5.97944 26.6799 5.96106 26.4651 5.99656 26.2558C6.03206 26.0465 6.12026 25.8497 6.25287 25.684L10 21"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.9111 15.214C34.5339 12.2465 25.0347 2.32086 9.99903 10.9999V20.9999C25.024 29.4984 34.5205 19.718 36.906 16.7816C37.086 16.5602 37.1846 16.2839 37.1855 15.9987C37.1865 15.7135 37.0896 15.4365 36.9111 15.214V15.214Z"
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.125 16C23.125 17.0355 23.9645 17.875 25 17.875C26.0355 17.875 26.875 17.0355 26.875 16C26.875 14.9645 26.0355 14.125 25 14.125C23.9645 14.125 23.125 14.9645 23.125 16Z"
      fill="#000000"
    />
  </svg>
</template>
