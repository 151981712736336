<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#6FD454"
    />
    <path
      d="M8.48572 6.02449C8.63143 6.01225 8.75633 6.04776 8.83715 6.18123C9.01838 6.47511 9.20083 6.76899 9.37349 7.07022C9.57308 7.41798 9.75676 7.77554 9.95268 8.12697C10.0029 8.22126 10.0727 8.30698 10.1315 8.39759C10.1853 8.48331 10.2368 8.57147 10.287 8.65719C10.3898 8.85189 10.3849 9.04781 10.2319 9.20332C10.0139 9.42618 9.7739 9.63312 9.54247 9.84496C9.349 10.0225 9.14818 10.1927 8.9596 10.374C8.81266 10.516 8.7747 10.6935 8.87389 10.8809C9.02695 11.1674 9.17879 11.454 9.34655 11.7344C9.9686 12.7715 10.7633 13.6532 11.7674 14.3328C12.1543 14.5961 12.5572 14.8373 12.9638 15.0675C13.347 15.2842 13.4964 15.2475 13.794 14.9157C14.0707 14.6083 14.3377 14.2961 14.6132 13.9863C14.6793 13.9116 14.7589 13.8454 14.836 13.7818C14.9928 13.6495 15.1642 13.6348 15.3442 13.7254C15.3944 13.7499 15.4483 13.772 15.4924 13.8038C15.9699 14.1454 16.5038 14.3867 17.0083 14.6769C17.2789 14.8324 17.5422 14.9989 17.8079 15.1581C17.9512 15.2426 18.0087 15.3663 17.9989 15.5365C17.972 16.0153 17.8924 16.4855 17.6144 16.8896C17.4981 17.0573 17.3328 17.2043 17.1663 17.323C16.7046 17.6463 16.1891 17.8459 15.6405 17.9684C15.1422 18.0798 14.645 18.0394 14.1674 17.89C13.2454 17.6059 12.3368 17.2802 11.5005 16.7867C10.7413 16.3373 10.0506 15.7949 9.41145 15.1887C8.60204 14.4185 7.87958 13.5785 7.29549 12.6222C6.91467 12.0013 6.64283 11.3327 6.3967 10.6507C6.24731 10.2393 6.10037 9.83149 6.03425 9.39557C5.95833 8.90209 6.01098 8.42698 6.16894 7.95922C6.31099 7.53676 6.48854 7.13267 6.75671 6.77144C6.96732 6.49103 7.23059 6.28531 7.57101 6.19347C7.84407 6.12123 8.11959 6.06367 8.39388 6C8.42939 6.02816 8.45755 6.02816 8.48572 6.02449Z"
      fill="white"
    />
  </svg>
</template>
