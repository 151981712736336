<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56143)">
      <path
        d="M13.3343 7.50006H12.501V8.3334H13.3343V7.50006Z"
        fill="#0E0E22"
      />

      <path
        d="M7.50081 8.33337H6.66748V9.16671H7.50081V8.33337Z"
        fill="#0E0E22"
      />

      <path
        d="M10.0008 5.00006H9.16748V5.83339H10.0008V5.00006Z"
        fill="#0E0E22"
      />

      <path
        d="M6.56462 11.875C5.54672 11.1961 4.35065 10.8336 3.12712 10.8333H2.08545V18.3333C2.08545 18.6648 2.21715 18.9828 2.45157 19.2172C2.68599 19.4516 3.00393 19.5833 3.33545 19.5833H16.6688C17.0003 19.5833 17.3182 19.4516 17.5527 19.2172C17.7871 18.9828 17.9188 18.6648 17.9188 18.3333V10.8333H16.8771C15.6536 10.8336 14.4575 11.1961 13.4396 11.875C12.4219 12.5542 11.2257 12.9166 10.0021 12.9166C8.77854 12.9166 7.58238 12.5542 6.56462 11.875V11.875Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.6167 10.8385C16.6513 10.5603 16.6687 10.2803 16.6688 9.99998C16.6688 8.23187 15.9664 6.53618 14.7162 5.28593C13.4659 4.03569 11.7702 3.33331 10.0021 3.33331C8.23401 3.33331 6.53831 4.03569 5.28807 5.28593C4.03783 6.53618 3.33545 8.23187 3.33545 9.99998C3.33557 10.2803 3.35296 10.5603 3.38753 10.8385"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.6167 9.14066C16.8775 9.18803 17.1463 9.16342 17.3942 9.06947C17.6421 8.97552 17.8596 8.81579 18.0235 8.60744C18.1874 8.3991 18.2914 8.15002 18.3244 7.887C18.3573 7.62397 18.3179 7.35694 18.2104 7.11462L17.6271 5.79691C17.5448 5.6116 17.5023 5.41112 17.5021 5.20837C17.501 4.76834 17.3451 4.34272 17.0619 4.00601C16.7786 3.6693 16.3859 3.44295 15.9525 3.36659C15.5192 3.29022 15.0728 3.36871 14.6914 3.5883C14.3101 3.80789 14.0182 4.1546 13.8667 4.56774"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.4969 3.81766C12.4709 3.23172 12.2805 2.66492 11.9476 2.18208C11.6146 1.69924 11.1525 1.31985 10.614 1.08731C10.0756 0.854757 9.48254 0.778434 8.90275 0.867064C8.32297 0.955694 7.77983 1.2057 7.33545 1.58849L6.65316 2.18224H6.64795C6.12191 2.63537 5.39274 2.91662 4.58545 2.91662C3.20524 2.91662 2.08545 4.41141 2.08545 6.24995C2.08545 7.33849 2.66878 8.27078 3.48128 8.60932"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56143">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
