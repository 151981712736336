import { AccessPermissionsEnum } from "@/router/constants";
import wallet from "./wallet";

function load(component) {
  return () => import(`@/views/loyalty/${component}.vue`);
}

export default [
  {
    path: "loyalty",
    name: "loyalty",
    component: load("ViewLoyalties"),
    redirect: { name: "wallet" },
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_PSELECTION],
      icon: "trophy",
    },
    children: [
      ...wallet,
      {
        path: "pselection",
        name: "pselection",
        component: load("loyaltyTab/ProgramSelection"),
        meta: {
          requiresAuth: true,
          icon: "trophy",
          menuItems: "leftSideMenu.pselection",
        },
      },
      {
        path: "pselection/bonus",
        name: "bonus",
        component: load("loyaltyTab/Bonus"),
        meta: {
          requiresAuth: true,
          icon: "trophy",
          menuItems: "leftSideMenu.pselection",
        },
      },
      {
        path: "pselection/discount",
        name: "discount",
        component: load("loyaltyTab/Discount"),
        meta: {
          requiresAuth: true,
          icon: "trophy",
          menuItems: "leftSideMenu.pselection",
        },
      },
      // {
      //   path: "pselection/coupon",
      //   name: "coupon",
      //   component: load("loyaltyTab/Coupon"),
      //   meta: {
      //     requiresAuth: true,
      //     icon: "trophy",
      //     menuItems: "leftSideMenu.pselection",
      //   },
      // },
      // {
      //   path: "pselection/clubcard",
      //   name: "clubcard",
      //   component: load("loyaltyTab/Clubcard"),
      //   meta: {
      //     requiresAuth: true,
      //     icon: "trophy",
      //     menuItems: "leftSideMenu.pselection",
      //   },
      // },
    ],
  },
];
