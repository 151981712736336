<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02547 12.9999C7.02381 11.8139 7.25691 10.6393 7.71132 9.54386C8.16573 8.44838 8.83248 7.45367 9.67311 6.61707C10.5138 5.78047 11.5117 5.11852 12.6093 4.66938C13.707 4.22025 14.8827 3.99281 16.0686 4.00017C21.0173 4.03696 24.9757 8.15034 24.9757 13.1129V13.9999C24.9757 18.4769 25.9124 21.0748 26.7373 22.4947C26.8262 22.6464 26.8735 22.8189 26.8744 22.9947C26.8754 23.1705 26.8299 23.3435 26.7427 23.4962C26.6554 23.6488 26.5295 23.7758 26.3775 23.8642C26.2256 23.9527 26.053 23.9995 25.8771 23.9999H6.12304C5.9472 23.9995 5.77458 23.9526 5.6226 23.8642C5.47062 23.7757 5.34466 23.6487 5.25743 23.496C5.1702 23.3434 5.12478 23.1704 5.12575 22.9945C5.12672 22.8187 5.17405 22.6462 5.26296 22.4945C6.08837 21.0745 7.02546 18.4766 7.02546 13.9999L7.02547 12.9999Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
