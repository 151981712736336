export default {
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування профілю"])},
    "firstname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степан"])}
    },
    "lastname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степаненко"])}
    },
    "patronymic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По-батькові"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степонович"])}
    },
    "email": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stepan.stepanenko", "@", "gmail.com"])}
    },
    "phone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "buttons": {
      "changePasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])},
      "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банківські реквізити"])},
      "tipCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер картки для чайових"])},
      "saveUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити дані користувача"])}
    },
    "passwordChange": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])},
      "form": {
        "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний пароль"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль"])},
        "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердити новий пароль"])}
      },
      "buttons": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])}
      },
      "errors": {
        "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не може бути порожнім"])},
        "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні 8 символів"])},
        "samePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паролі повинні збігатися"])},
        "hasDigitMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну цифру"])},
        "hasUppercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну прописну букву"])},
        "hasLowercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні одну строчну букву"])},
        "hasSpecialSymbolsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль повинен містити принаймні один спеціальний символ"])}
      }
    }
  },
  "datapicker": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігти"])},
    "timeplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть час"])},
    "dateplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть дату"])}
  },
  "tasks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування завдання"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування завдання на певний час"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете налаштувати дату та час завдання"])}
      },
      "erorrs": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва завдання не може бути порожньою"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва завдання не може бути менше ", _interpolate(_named("n")), " символів"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис завдання не може бути порожнім"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис завдання не може бути менше ", _interpolate(_named("n")), " символів"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б одну точку"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування завдання"])}
    }
  },
  "news": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування новини"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування новини на певний час"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете налаштувати дату та час новини"])}
      },
      "erorrs": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва новини не може бути порожньою"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва новини не може бути менше ", _interpolate(_named("n")), " символів"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис новини не може бути порожнім"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис новини не може бути менше ", _interpolate(_named("n")), " символів"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б одну точку"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])},
        "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забагато запитів"])},
        "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний поточний пароль"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування новини"])}
    }
  },
  "feedbacks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування зворотного зв'язку"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування зворотного зв'язку на певний час"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата та час"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете встановити дату та час зворотного зв'язку"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва зворотного зв'язку не може бути порожньою"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Назва зворотного зв'язку не може бути менше ", _interpolate(_named("n")), " символів"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис зворотного зв'язку не може бути порожнім"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Опис зворотного зв'язку не може бути менше ", _interpolate(_named("n")), " символів"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть хоча б один пункт"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути після кінцевої"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не може бути до початкової"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування зворотного зв'язку"])}
    }
  },
  "multiselect": {
    "current": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Вибрано 0 точок"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точку"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точки"]), _normalize(["Вибрано ", _interpolate(_named("n")), " точок"])])},
    "allpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрано всі точки"])}
  },
  "phone": {
    "countrySelector": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код країни"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть країну"])}
    },
    "phoneInput": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приклад:"])}
    }
  },
  "button": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігти"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])}
  },
  "toast": {
    "successSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Налаштування ", _interpolate(_named("target")), " збережено!"])},
    "errorSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Помилка збереження налаштувань ", _interpolate(_named("target")), "!"])},
    "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " видалено!"])},
    "word": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])}
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список агентів"])}
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клієнтів"])}
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнти"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розсилка"])},
      "feedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відгуки"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблони"])},
      "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичне розсилання"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовлення"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сповіщення"])}
  },
  "staff": {
    "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування персоналу"])},
    "buttonCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виклик персоналу"])},
    "buttonTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдання"])},
    "buttonNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новинна стрічка"])},
    "buttonNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cповіщення"])},
    "buttonFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])},
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий співробітник"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте нового співробітника"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати співробітник"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внести зміни"])}
    },
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний логін"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не знайдений"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не повинен бути порожнім"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний формат email"])},
      "surnameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище не повинно бути пустим"])},
      "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я не повинно бути пустим"])},
      "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле повинно містити принаймні 3 символа"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен бути порожнім"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не можемо знайти користувача з цим email адресою."])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон не може бути порожнім"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевірте довжину телефону"])}
      }
    }
  },
  "pselection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибір програми лояльності"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть та налаштуйте свою програму лояльності"])},
    "radioButton": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далі"])},
    "discount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисконтна програма лояльності"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування прямих знижок для вашого клієнта"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування рівнів"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть потрібну кількість рівнів, їх суму та відсоток знижки"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рівня"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума від"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати рівень"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До знижки"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування знижок збережено!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштувань знижок!"])}
      }
    },
    "bonus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусна система лояльності"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте свою бонусну програму"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування рівнів"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть потрібну кількість рівнів, їх суму та відсоток знижки"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва рівня"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума від"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума до"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати  рівень"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування бонусів збережено!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштувань бонусів!"])}
      }
    },
    "coupon": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купонна програма лояльності"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Система лояльності дозволяє створювати одноразові купони на знижку (подарункові сертифікати) з різними номіналами."])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До знижки"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])},
        "accumulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["накопичення"])},
        "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["привітання"])}
      }
    },
    "clubcard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клубна карта"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередній перегляд клубної картки"])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знижка"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник карти"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонусу"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я Прізвище"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["купон"])}
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
      "qr-catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн меню"])}
    },
    "qrCatalog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн меню"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштуйте своє Онлайн меню"])},
      "colors": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту"])},
        "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір заголовка"])},
        "relief-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір фону"])},
        "relief-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір категорії"])},
        "relief-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту ціни"])},
        "relief-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір фону ціни"])}
      },
      "logoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для каталогу"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип текст для каталогу"])},
      "downloadLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження QR Code"])},
      "previewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляд"])},
      "previewLinkHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всі зміни будуть доступні після натискання на кнопку \"Опублікувати\""])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн меню налаштування збережені!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка збереження налаштування Онлайн меню!"])}
      }
    },
    "wallet": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet для карток"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування Wallet для карток"])},
      "cardColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір картки"])},
      "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір тексту"])},
      "titleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір заголовка"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для Wallet"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст логотипу для Wallet"])},
      "centralImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральне зображення для Wallet"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередній перегляд"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування Wallet збережені!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час збереження налаштувань Wallet!"])}
      }
    }
  },
  "leftSideMenu": {
    "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенти"])},
    "averageСheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середній чек"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн меню"])},
    "clientData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные клиента"])},
    "crm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])},
    "for-client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для клієнтів"])},
    "for-staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для персоналу"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія"])},
    "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтеграції"])},
    "levelRetention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утримання клієнтів"])},
    "levelSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задоволеність клієнтів"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])},
    "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнти"])},
    "pointsOfSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продажу"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
    "pselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програма лояльності"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонал"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка"])},
    "topSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ продажів"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакциії"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта Wallet"])}
  },
  "application": {
    "imageForBothTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])}
  },
  "locale": {
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ласкаво просимо"])},
    "forgotQuery": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть e-mail, вказаний під час реєстрації. Ми надішлемо вам посилання для скидання пароля."])},
      "messageSuccessReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми відправили посилання для відновлення пароля на вказаний Email. "])}
    },
    "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійдіть у свій обліковий запис"])},
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти"])},
    "buttonReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скинути пароль"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запам'ятати мене"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний email"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронну пошту не знайдено"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не повинен бути порожнім"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна адреса недійсна"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильний пароль"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не повинен бути порожнім"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ми не можемо знайти користувача з цією електронною адресою."])}
    }
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна сторінка"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])}
  },
  "main": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", бажаєте почати налаштування?"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
    "new_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Немає повідомлень"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлень"])])}
  },
  "nav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особистий кабінет"])},
    "profiel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Програма лояльності"])},
    "bank-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковські посилання"])}
  },
  "points": {
    "workTime": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим роботи"])}
    },
    "titlenameNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нова точка продажу"])},
    "subtitleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати нову точку продажу. Після додавання інформації натисніть \"Зберегти\"."])},
    "titlenameEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати точку"])},
    "subtitleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відредагуйте точку, щоб клієнти могли зв'язатися з вами"])},
    "phone": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити номер телефона"])},
      "addPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати телефон"])}
    },
    "btnNewPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати точку продажу"])},
    "table": {
      "header": {
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОЧКИ ПРОДАЖУ"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["АДРЕСА"])},
        "qrQatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн меню"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕЛЕФОН"])},
        "viber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])},
        "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДІЇ"])}
      },
      "body": {
        "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви ще не додали жодної точки продажу."])}
      },
      "delete": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити контакт"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви впевнені, що хочете видалити контакт? Всі дані будуть видалені"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])}
      }
    },
    "address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почніть вводити свою адресу"])}
    },
    "title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву"])}
    },
    "subdomain": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть доменне ім'я"])}
    },
    "errors": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса не може бути порожньою"])},
      "subdomain": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я не може бути порожнім"])},
        "latin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допускаються лише латинські символи"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменне ім'я вже існує"])}
      },
      "domain_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт не збережено. Таке доменне ім'я вже існує"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва не може бути порожньою"])},
      "title_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле має хибний формат"])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон не може бути порожнім"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone length check"])}
      }
    },
    "catalog": {
      "point": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть точку"])}
      },
      "copy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати Онлайн меню із попереднього пункту?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Так"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ні"])}
      }
    }
  },
  "catalog": {
    "sale_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продажу"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товари та послуги"])},
    "qr_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зовнішній вигляд каталогу"])},
    "point": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть точку"])},
      "not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед початком роботи з каталогом виберіть точку"])},
      "list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом створіть точку"])}
    }
  },
  "products": {
    "errors": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не може бути порожнім"])}
    }
  },
  "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}