<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99997 20.25C5.90148 20.25 5.80395 20.2306 5.71296 20.1929C5.62196 20.1552 5.53928 20.1 5.46964 20.0304C5.39999 19.9607 5.34475 19.878 5.30706 19.787C5.26937 19.696 5.24997 19.5985 5.24997 19.5L5.25002 15.4974C4.84992 15.6869 4.40839 15.7721 3.9665 15.7451C3.52461 15.7182 3.09672 15.5799 2.72264 15.3431C2.34855 15.1064 2.04041 14.7789 1.82689 14.3911C1.61337 14.0033 1.5014 13.5677 1.5014 13.125C1.5014 12.6823 1.61337 12.2468 1.82689 11.859C2.04041 11.4712 2.34855 11.1436 2.72264 10.9069C3.09672 10.6702 3.52461 10.5319 3.9665 10.5049C4.40839 10.4779 4.84992 10.5632 5.25002 10.7527L5.24997 6.75003C5.24997 6.65154 5.26937 6.55401 5.30706 6.46301C5.34475 6.37202 5.4 6.28934 5.46964 6.21969C5.53929 6.15005 5.62197 6.0948 5.71296 6.05711C5.80396 6.01942 5.90149 6.00002 5.99998 6.00002L10.3776 6.00007C10.1881 5.59997 10.1029 5.15844 10.1298 4.71656C10.1568 4.27467 10.2951 3.84678 10.5318 3.47269C10.7686 3.09861 11.0961 2.79047 11.4839 2.57695C11.8717 2.36343 12.3072 2.25146 12.75 2.25146C13.1927 2.25146 13.6282 2.36343 14.016 2.57695C14.4038 2.79047 14.7313 3.09861 14.9681 3.47269C15.2048 3.84678 15.3431 4.27467 15.3701 4.71656C15.397 5.15844 15.3118 5.59997 15.1223 6.00007L19.4999 6.00002C19.6988 6.00002 19.8896 6.07904 20.0303 6.21969C20.1709 6.36034 20.2499 6.5511 20.2499 6.75001L20.25 10.7527C19.8499 10.5632 19.4084 10.4779 18.9665 10.5049C18.5246 10.5319 18.0967 10.6702 17.7226 10.9069C17.3485 11.1436 17.0404 11.4712 16.8269 11.859C16.6133 12.2468 16.5014 12.6823 16.5014 13.125C16.5014 13.5677 16.6133 14.0032 16.8269 14.3911C17.0404 14.7789 17.3485 15.1064 17.7226 15.3431C18.0967 15.5799 18.5246 15.7182 18.9665 15.7451C19.4084 15.7721 19.8499 15.6869 20.25 15.4974L20.2499 19.5C20.2499 19.6989 20.1709 19.8897 20.0303 20.0304C19.8896 20.171 19.6988 20.25 19.4999 20.25L5.99997 20.25Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
