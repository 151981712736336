function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "support",
    name: "support",
    component: load("support/Support"),
    meta: {
      requiresAuth: true,
      icon: "gear",
      menuItems: "leftSideMenu.support",
    },
  },
];
