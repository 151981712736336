<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9141 5.82813V3.03646L3.85677 1.32812L4.92448 5.5625C2.95573 6.98438 1.66406 9.375 1.66406 12.0833C1.66406 15.1823 2.9349 17.8646 5.39323 19.1667H12.1016C14.5599 17.8698 15.8307 15.1823 15.8307 12.0833C15.8307 9.53646 14.6849 7.27604 12.9141 5.82813Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.9141 4.1665H16.8984C17.2354 4.16614 17.5661 4.25789 17.8548 4.43184C18.1434 4.60579 18.3791 4.85532 18.5362 5.15347C18.6933 5.45161 18.7659 5.78703 18.7462 6.12346C18.7266 6.4599 18.6153 6.78455 18.4245 7.06234L15.7578 10.9425"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.36328 8.75H15.1341"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.16406 10.4165H11.6641V12.9165H9.16406V10.4165Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.83203 15.8352L7.50036 14.1669L9.16685 15.8334L7.49852 17.5017L5.83203 15.8352Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
