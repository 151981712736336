<template>
  <div ref="select" class="v-select">
    <div class="label">
      {{ title }}
    </div>
    <div :class="['input']" @click="toggleDropdown">
      <span :class="['input__text', { 'active-label': arepOtionsVisisble }]">{{
        modelValue
      }}</span>
      <v-icon
        name="chevron"
        width="16"
        height="16"
        stroke="#A0A0A0"
        :class="{ 'icon-rotate': arepOtionsVisisble }"
      />
    </div>
    <div v-if="arepOtionsVisisble" ref="dropdown" :class="['option-list']">
      <div
        v-for="option in options"
        :key="option"
        class="option"
        :class="{ active: option === modelValue }"
        @click="setoption(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";
import { onClickOutside } from "@vueuse/core";

// eslint-disable-next-line no-undef
defineProps({
  modelValue: { type: [String, Number], default: () => "10" },
  title: { type: String, default: "label" },
  options: { type: Array, default: () => [] },
});
// eslint-disable-next-line no-undef
const emit = defineEmits({
  "update:modelValue": null,
});
const arepOtionsVisisble = ref(false);
const dropdown = ref();
const select = ref();
onClickOutside(dropdown, () => (arepOtionsVisisble.value = false), {
  ignore: [select],
});
function setoption(option) {
  emit("update:modelValue", option);
  arepOtionsVisisble.value = false;
}
const toggleDropdown = async () => {
  arepOtionsVisisble.value = !arepOtionsVisisble.value;
  await nextTick();
  // const { offsetTop, offsetHeight } = select.value;
  // const { innerHeight } = window;
  if (dropdown.value) {
    const dropdownHeight = dropdown.value.offsetHeight;

    const dropdownTop = -dropdownHeight;
    // innerHeight < offsetTop + offsetHeight + dropdownHeight + 30
    //   ? -dropdownHeight
    //   : offsetHeight;

    dropdown.value.style.top = `${dropdownTop}px`;
    dropdown.value.style.right = "0";
  }
};
</script>

<style lang="scss" scoped>
.v-select {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #9b9b9b;
}
.input {
  border-radius: 4px;
  border: 1px solid #e7e7e9;
  padding: 0px 4px 0px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 56px;
  height: 28px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #f5f6f8;
  }
  &__text {
    color: #595a5b;
    text-align: left;
    font: 500 14px/130% "Inter", sans-serif;
    position: relative;
  }
}
.option-list {
  width: 56px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(14, 14, 34, 0.04);
  border-radius: 4px;
  color: #9b9b9b;
  position: absolute;
  padding: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  z-index: 20;
  .option {
    width: 100%;
    display: flex;
    width: 48px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #f5f6f8;
    }
  }
}

.icon-chevron {
  transition: transform 0.2s ease-in;
}
.icon-rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in;
}
.input-outline {
  outline: none !important;
  border: #10b3c7;
  outline: #10b3c7;
  // background: linear-gradient(white, white) padding-box,
  //   linear-gradient(104.49deg, #10b3c7 0%, #0052cc 181.96%) border-box !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #10b3c7 !important;
}
.active {
  color: #00cdd7;
}
.active-label {
  border-color: #a0a0a0;
  color: #0e0e22;
  background: #fff;
}
</style>
