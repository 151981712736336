<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5952)">
      <path
        d="M13.3333 9.1665H12.5V9.99984H13.3333V9.1665Z"
        fill="#0E0E22"
      />

      <path
        d="M10.4154 11.6667H9.58203V12.5001H10.4154V11.6667Z"
        fill="#0E0E22"
      />

      <path
        d="M7.91536 8.33325H7.08203V9.16659H7.91536V8.33325Z"
        fill="#0E0E22"
      />

      <path
        d="M4.51172 6.6665H15.4909"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.6458 7.28621C15.8682 8.14816 15.8903 9.04955 15.7105 9.92136C15.5307 10.7932 15.1538 11.6123 14.6086 12.3159C14.0634 13.0196 13.3645 13.5892 12.5652 13.981C11.7659 14.3729 10.8876 14.5766 9.9974 14.5766C9.10724 14.5766 8.22889 14.3729 7.42962 13.981C6.63034 13.5892 5.93134 13.0196 5.38615 12.3159C4.84097 11.6123 4.46405 10.7932 4.28427 9.92136C4.10449 9.04955 4.12662 8.14816 4.34896 7.28621L5.58854 2.49976H14.4115L15.6458 7.28621Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10 14.5833V19.5833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83203 19.583H14.1654"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.0508 6.66651L12.8268 0.713383C12.8527 0.627246 12.9057 0.551787 12.978 0.498278C13.0503 0.444769 13.1379 0.416084 13.2279 0.416509H16.2487"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5952">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
