<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 23.5265L24.74 28.4998L22.5533 19.1265L29.8333 12.8198L20.2467 12.0065L16.5 3.1665L12.7533 12.0065L3.16667 12.8198L10.4467 19.1265L8.26001 28.4998L16.5 23.5265Z"
      fill="none"
    />
  </svg>
</template>
