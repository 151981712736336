<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#F73A53" />
    <path
      d="M25 9.97998C21.67 9.64998 18.32 9.47998 14.98 9.47998C13 9.47998 11.02 9.57998 9.04 9.77998L7 9.97998"
      stroke="#F73A53"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 8.97L12.72 7.66C12.88 6.71 13 6 14.69 6H17.31C19 6 19.13 6.75 19.28 7.67L19.5 8.97"
      stroke="#F73A53"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.8499 13.14L22.1999 23.21C22.0899 24.78 21.9999 26 19.2099 26H12.7899C9.9999 26 9.9099 24.78 9.7999 23.21L9.1499 13.14"
      stroke="#F73A53"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3301 20.5H17.6601"
      stroke="#F73A53"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 16.5H18.5"
      stroke="#F73A53"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
