<template>
  <svg
    id="privat24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 320 320"
    style="enable-background: new 0 0 320 320"
    xml:space="preserve"
    fill="#000000"
  >
    <linearGradient
      id="SVGID"
      gradientUnits="userSpaceOnUse"
      x1="9.4"
      y1="838"
      x2="321.6"
      y2="838"
      gradientTransform="matrix(1 0 0 1 0 -678)"
    >
      <stop
        offset="0"
        style="stop-color: #333333"
      />
      <stop
        offset="1"
        style="stop-color: #000000"
      />
    </linearGradient>
    <path
      class="st0"
      d="M165.5,3.9L165.5,3.9c86.2,0,156.1,69.9,156.1,156.1l0,0c0,86.2-69.9,156.1-156.1,156.1l0,0
    C79.3,316.1,9.4,246.2,9.4,160l0,0C9.4,73.8,79.3,3.9,165.5,3.9z"
    />

    <path
      fill="#8dc641"
      d="M165.5,2.9C78.8,2.9,8.4,73.2,8.4,160s70.3,157.1,157.1,157.1S322.6,246.8,322.6,160S252.3,2.9,165.5,2.9z
    M165.5,299.8c-77.2,0-139.8-62.6-139.8-139.8S88.3,20.2,165.5,20.2S305.3,82.8,305.3,160S242.7,299.8,165.5,299.8z"
    />
    <path
      fill="#ffffff"
      d="M220.4,124.3l-35,51.9h35V124.3z
    M233.6,103.3v73H249v13.2h-15.4v27.3h-13.3v-27.3h-49.5v-14.2l49.9-71.9
    L233.6,103.3L233.6,103.3z
    M156.2,203.5v13.2H82v-0.4c0.7-12.4,3.9-21.5,10.1-29c5-6.2,11.1-10.8,23.6-18.6c0,0,4.4-2.8,5.7-3.6
    c6.7-4.3,11-7.7,14.5-11.6c4.3-5,6.5-10.4,6.5-16.8c0-12.4-8.8-20.9-21.4-20.9c-14.7,0-22.2,11.2-22.6,27.9v0.3H85.2v-0.3l-0.1-3
    c0-22.4,14.6-37.4,36.6-37.4c21.5,0,35.5,13,35.5,33.1c0,10.3-3.7,18.6-10.8,25.9c-5.3,5.6-10.8,9.3-23.1,16.8
    c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-3.3,1.9-4,2.4c-5.9,3.5-9.5,6-12.7,9c-3.9,3.7-6.5,7.8-7.7,12.6h57.8V203.5z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.st0 {
  fill: url(#SVGID);
}
/*.st1 {
  fill: #8dc641;
}
.st2 {
  fill: #ffffff;
} */
</style>
