<template>
  <svg
    class="icons-loyalty"
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24911 16.5H40.0824M18.9991 5.5L15.3324 16.5L22.6658 37.5833L29.9991 16.5L26.3324 5.5M23.7925 37.1479L40.2167 17.4389C40.495 17.1049 40.6342 16.9379 40.6874 16.7515C40.7343 16.5871 40.7343 16.4129 40.6874 16.2485C40.6342 16.0621 40.495 15.8951 40.2167 15.5611L32.2722 6.02773C32.1106 5.83375 32.0297 5.73676 31.9307 5.66703C31.8429 5.60525 31.7449 5.55937 31.6413 5.53148C31.5243 5.5 31.398 5.5 31.1455 5.5H14.1861C13.9336 5.5 13.8073 5.5 13.6903 5.53148C13.5866 5.55937 13.4887 5.60525 13.4009 5.66703C13.3018 5.73676 13.221 5.83375 13.0593 6.02773L5.11489 15.5611C4.83654 15.8951 4.69736 16.0621 4.64418 16.2485C4.59729 16.4129 4.59729 16.5871 4.64418 16.7515C4.69736 16.9379 4.83654 17.1049 5.11489 17.4389L21.5391 37.1479C21.926 37.6123 22.1195 37.8444 22.3511 37.9291C22.5543 38.0034 22.7772 38.0034 22.9805 37.9291C23.2121 37.8444 23.4056 37.6123 23.7925 37.1479Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
