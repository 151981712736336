<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5884)">
      <path
        d="M13.8333 18.8438L15.4844 4.16675H4.51562L6.16667 18.8438C6.18971 19.0473 6.28687 19.2352 6.43962 19.3716C6.59237 19.508 6.79 19.5834 6.99479 19.5834H13.0052C13.21 19.5834 13.4076 19.508 13.5604 19.3716C13.7131 19.2352 13.8103 19.0473 13.8333 18.8438Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0625 7.91675H4.9375"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />

      <path
        d="M10.8333 16.25H10V17.0833H10.8333V16.25Z"
        fill="#0E0E22"
      />

      <path
        d="M8.33333 12.5H7.5V13.3333H8.33333V12.5Z"
        fill="#0E0E22"
      />

      <path
        d="M12.9154 10.4167H12.082V11.2501H12.9154V10.4167Z"
        fill="#0E0E22"
      />

      <path
        d="M10.6445 7.9165L13.0039 0.702962C13.0313 0.61962 13.0844 0.547059 13.1554 0.495622C13.2265 0.444185 13.312 0.416497 13.3997 0.416504H16.2487"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5884">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
