<template>
  <svg
    width="31"
    height="28"
    viewBox="0 0 31 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6846 10.6722V16.0055M15.6846 21.3388H15.6979M13.8383 3.86115L2.87181 22.8033C2.26354 23.854 1.9594 24.3793 2.00435 24.8104C2.04356 25.1865 2.24059 25.5282 2.5464 25.7506C2.89701 26.0055 3.50402 26.0055 4.71806 26.0055H26.6511C27.8651 26.0055 28.4721 26.0055 28.8227 25.7506C29.1286 25.5282 29.3256 25.1865 29.3648 24.8104C29.4097 24.3793 29.1056 23.854 28.4973 22.8033L17.5308 3.86114C16.9247 2.81426 16.6217 2.29082 16.2263 2.11501C15.8814 1.96166 15.4877 1.96166 15.1428 2.11501C14.7475 2.29082 14.4444 2.81426 13.8383 3.86115Z"
      stroke="#FB8E41"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
