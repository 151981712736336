<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00012 5.2C4.00012 2.32812 6.32824 0 9.20012 0H22.8001C25.672 0 28.0001 2.32812 28.0001 5.2V18.8C28.0001 21.6719 25.672 24 22.8001 24H9.20012C6.32824 24 4.00012 21.6719 4.00012 18.8V5.2Z"
      fill="url(#paint0_linear_11_1298)"
    />
    <path
      d="M7.73352 5.60006C7.73352 4.56913 8.56926 3.7334 9.60019 3.7334H22.4002C23.4311 3.7334 24.2669 4.56913 24.2669 5.60007V16.1334H7.73352V5.60006Z"
      fill="url(#paint1_linear_11_1298)"
    />
    <path
      d="M7.73352 9.73337C7.73352 8.70243 8.56926 7.8667 9.60019 7.8667H22.4002C23.4311 7.8667 24.2669 8.70243 24.2669 9.73337V16.1334H7.73352V9.73337Z"
      fill="url(#paint2_linear_11_1298)"
    />
    <path
      d="M7.73352 13.8667C7.73352 12.8357 8.56926 12 9.60019 12H22.4002C23.4311 12 24.2669 12.8357 24.2669 13.8667V16.1333H7.73352V13.8667Z"
      fill="url(#paint3_linear_11_1298)"
    />
    <path
      d="M7.73352 19.8666C7.73352 20.8976 8.56926 21.7333 9.60019 21.7333H22.4002C23.4311 21.7333 24.2669 20.8976 24.2669 19.8666V16.1333H7.73352V19.8666Z"
      fill="url(#paint4_linear_11_1298)"
      fill-opacity="0.2"
    />
    <path
      d="M9.06677 20.8V18.3757H9.57932V19.376H10.6198V18.3757H11.1312V20.8H10.6198V19.7986H9.57932V20.8H9.06677Z"
      fill="white"
    />
    <path
      d="M13.1845 18.3757H13.697V19.9501C13.697 20.1268 13.6548 20.2815 13.5704 20.4141C13.4867 20.5467 13.3695 20.65 13.2188 20.7242C13.0681 20.7976 12.8925 20.8343 12.6921 20.8343C12.4908 20.8343 12.3148 20.7976 12.1641 20.7242C12.0134 20.65 11.8962 20.5467 11.8126 20.4141C11.7289 20.2815 11.6871 20.1268 11.6871 19.9501V18.3757H12.1996V19.9063C12.1996 19.9986 12.2198 20.0807 12.26 20.1525C12.301 20.2243 12.3586 20.2807 12.4328 20.3218C12.507 20.3628 12.5934 20.3833 12.6921 20.3833C12.7915 20.3833 12.8779 20.3628 12.9513 20.3218C13.0255 20.2807 13.0827 20.2243 13.1229 20.1525C13.164 20.0807 13.1845 19.9986 13.1845 19.9063V18.3757Z"
      fill="white"
    />
    <path
      d="M14.6699 20.8H14.1207L14.9576 18.3757H15.6181L16.4538 20.8H15.9045L15.2973 18.9297H15.2783L14.6699 20.8ZM14.6356 19.8471H15.9329V20.2472H14.6356V19.8471Z"
      fill="white"
    />
    <path
      d="M17.1804 20.8L16.4868 18.3757H17.0467L17.448 20.0602H17.4681L17.9108 18.3757H18.3902L18.8317 20.0637H18.853L19.2543 18.3757H19.8142L19.1205 20.8H18.621L18.1594 19.215H18.1404L17.68 20.8H17.1804Z"
      fill="white"
    />
    <path
      d="M20.2219 20.8V18.3757H21.8554V18.7983H20.7344V19.376H21.7713V19.7986H20.7344V20.3774H21.8601V20.8H20.2219Z"
      fill="white"
    />
    <path
      d="M22.9087 18.3757V20.8H22.3962V18.3757H22.9087Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11_1298"
        x1="16.0001"
        y1="0"
        x2="16.0001"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF5357" />
        <stop
          offset="1"
          stop-color="#FE213C"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_11_1298"
        x1="16.0002"
        y1="3.7334"
        x2="16.0002"
        y2="16.1334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF9F09" />
        <stop
          offset="1"
          stop-color="#FD9400"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_11_1298"
        x1="16.0002"
        y1="7.8667"
        x2="16.0002"
        y2="16.1334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFCC00" />
        <stop
          offset="1"
          stop-color="#FDC501"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_11_1298"
        x1="16.0002"
        y1="12"
        x2="16.0002"
        y2="16.1333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFBEF" />
        <stop
          offset="1"
          stop-color="#FFFBF3"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_11_1298"
        x1="16.0002"
        y1="21.7333"
        x2="16.0002"
        y2="16.1333"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#D9D9D9"
          stop-opacity="0"
        />
        <stop
          offset="1"
          stop-color="#D9D9D9"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
