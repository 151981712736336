import { useCreateNetwork } from "@/composables/useCreateNetwork";
import store from "@/store";
export function goToNewPointMiddlewar(to, from, next) {
  const { goToNewPoint } = useCreateNetwork();
  if (to.name === "new-point") {
    if (store.getters.merchantSlugName) return next();

    goToNewPoint();
  } else {
    return next();
  }
}
