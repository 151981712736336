<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83228 18.7498H14.1656"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99866 18.7499V14.5832"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.8326 2.08319V4.93402C15.8326 8.94652 14.5871 12.4144 12.7805 14.0578C12.4021 14.4019 11.9046 14.5832 11.3934 14.5832H8.60506C8.09381 14.5832 7.59631 14.4019 7.21839 14.0582C5.41131 12.4149 4.16589 8.94652 4.16589 4.93402V2.08319H15.8326Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.2542 12.0865C16.9729 12.0369 19.1654 9.81357 19.1654 7.08315V5.83315C19.1654 5.37273 18.7925 4.99982 18.3321 4.99982H15.8321"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.74311 12.086C3.02436 12.0364 0.832275 9.8143 0.832275 7.08388V5.83388C0.832275 5.37347 1.20519 5.00055 1.66561 5.00055H4.16561"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.99936 9.50143L12.0698 10.7418L11.5289 8.56226L13.4477 7.0081L10.9602 6.69226L9.99936 4.64435L9.03561 6.69268L6.55103 7.0081L8.46978 8.56226L7.92894 10.7418L9.99936 9.50143Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
