<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5891)">
      <path
        d="M11.2513 15.8335H10.418V16.6668H11.2513V15.8335Z"
        fill="#0E0E22"
      />

      <path
        d="M15 3.3335H5V18.3335C5 18.665 5.1317 18.983 5.36612 19.2174C5.60054 19.4518 5.91848 19.5835 6.25 19.5835H13.75C14.0815 19.5835 14.3995 19.4518 14.6339 19.2174C14.8683 18.983 15 18.665 15 18.3335V3.3335Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5 7.0835H15"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />

      <path
        d="M13.3333 10.0002H12.5V10.8336H13.3333V10.0002Z"
        fill="#0E0E22"
      />

      <path
        d="M7.91927 11.6667H7.08594V12.5001H7.91927V11.6667Z"
        fill="#0E0E22"
      />

      <path
        d="M13.0208 0.474121L11.0781 7.08349"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5891">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
