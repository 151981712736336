<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56235)">
      <path
        d="M13.1038 5.22935L14.3954 3.93768"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.5201 8.1461L14.8118 6.85443"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.1874 7.72935L11.479 6.43768"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.6038 10.2293L11.8954 8.93768"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.0201 4.81266L17.3118 3.521"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.74931 11.2502C8.76278 12.3475 8.66506 13.4434 8.45764 14.521L7.70764 18.4221"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.74953 11.2502C8.7326 9.88466 8.54361 8.52673 8.18703 7.2085L7.30161 3.94287L6.20265 6.49495C5.68093 7.70815 4.93328 8.81107 3.99953 9.74496L2.12453 11.62C1.34081 12.4578 0.913375 13.5674 0.9325 14.7144C0.951624 15.8615 1.41581 16.9562 2.22702 17.7675C3.03824 18.5787 4.13298 19.0429 5.28005 19.062C6.42712 19.0811 7.53672 18.6537 8.37453 17.87L10.2495 15.995C11.1834 15.0612 12.2863 14.3135 13.4995 13.7918L16.0516 12.6929L12.7912 11.8127C11.473 11.4561 10.115 11.2671 8.74953 11.2502V11.2502Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.19202 7.22417L9.59306 5.82313C13.3795 2.03667 17.4837 -0.0831241 18.7806 1.21896C20.0774 2.52104 17.9576 6.62521 14.1764 10.4065L12.7753 11.8075"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56235">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
