<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6147)">
      <path
        d="M4.51562 7.9165L5.89583 19.5832H14.1042L15.4844 7.9165"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.75 5.83325H16.25V7.91659H3.75V5.83325Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.9792 0.531006L14.1875 4.11955"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0885 5.83325C15.0885 5.28072 14.869 4.75081 14.4783 4.36011C14.0876 3.96941 13.5577 3.74992 13.0052 3.74992H12.9167C12.9167 2.97637 12.6094 2.2345 12.0624 1.68752C11.5154 1.14054 10.7735 0.833252 10 0.833252C10 1.49629 9.73661 2.13218 9.26777 2.60102C8.79893 3.06986 8.16304 3.33325 7.5 3.33325H7.42187C6.75883 3.33325 6.12295 3.59664 5.65411 4.06549C5.18527 4.53433 4.92188 5.17021 4.92188 5.83325"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6147">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
