<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6671 5.41636V5.83303H8.33374V5.41636C8.33374 4.97433 8.50934 4.55041 8.8219 4.23785C9.13446 3.92529 9.55838 3.74969 10.0004 3.74969C10.4424 3.74969 10.8664 3.92529 11.1789 4.23785C11.4915 4.55041 11.6671 4.97433 11.6671 5.41636V5.41636Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.16724 18.7497L16.6672 11.2497"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.3337 18.7498L16.6671 15.4165"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.00024 18.7498L14.5836 9.1665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.33374 16.2498L10.4171 9.1665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.33374 12.4998L6.66707 9.1665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.6671 9.1665V18.7498H3.33374V9.1665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.08374 5.83313H17.9171V9.16646H2.08374V5.83313Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.5212 4.73412L12.7348 2.42683C12.8384 2.22928 12.9871 2.05889 13.1688 1.92943C13.3505 1.79997 13.56 1.71508 13.7806 1.68162C14.0012 1.64815 14.2265 1.66705 14.4384 1.7368C14.6503 1.80654 14.8428 1.92516 15.0004 2.08308L16.74 3.82266C16.905 3.98729 17.0174 4.1972 17.063 4.4258C17.1086 4.6544 17.0853 4.89139 16.996 5.10672C16.9068 5.32205 16.7556 5.50604 16.5617 5.63535C16.3677 5.76466 16.1398 5.83348 15.9067 5.83308"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.47956 4.73412L7.26602 2.42683C7.16238 2.22928 7.01371 2.05889 6.83203 1.92943C6.65035 1.79997 6.44076 1.71508 6.2202 1.68162C5.99964 1.64815 5.7743 1.66705 5.56239 1.7368C5.35049 1.80654 5.15797 1.92516 5.0004 2.08308L3.26081 3.82266C3.09579 3.98729 2.98335 4.1972 2.93777 4.4258C2.89218 4.6544 2.9155 4.89139 3.00475 5.10672C3.09401 5.32205 3.24519 5.50604 3.43913 5.63535C3.63308 5.76466 3.86105 5.83348 4.09415 5.83308"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
