<script setup></script>

<template>
  <div class="version">
    <div class="version__version-2-0-powered-by-qbeek">
      Version 1.0<br>Powered by qbeek
    </div>
  </div>
</template>

<style scoped>
.version {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  margin-top: 16px;
}

.version__version-2-0-powered-by-qbeek {
  color: #bcbbbb;
  text-align: center;
  font: 400 10px/130% "Roboto", sans-serif;
  height: 55px;
}
</style>
