<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_55980)">
      <path
        d="M18.7499 12.7396C18.0415 12.4948 17.2499 12.0833 16.6665 12.0833C15.4165 12.0833 14.5832 13.3333 13.3332 13.3333C12.7499 13.3333 11.9582 12.9219 11.2499 12.6771"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0734 6.24992L16.9327 4.70825"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.4999 6.24994H17.4999L18.1405 6.89056C18.3339 7.08418 18.4873 7.31401 18.5918 7.56692C18.6964 7.81983 18.7501 8.09085 18.7499 8.36452V17.052C18.7501 17.3257 18.6964 17.5967 18.5918 17.8496C18.4873 18.1025 18.3339 18.3324 18.1405 18.526L17.4999 19.1666H12.4999L11.8593 18.526C11.6658 18.3324 11.5125 18.1025 11.4079 17.8496C11.3034 17.5967 11.2497 17.3257 11.2499 17.052V8.36452C11.2497 8.09085 11.3034 7.81983 11.4079 7.56692C11.5125 7.31401 11.6658 7.08418 11.8593 6.89056L12.4999 6.24994Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.0835 1.24994H12.9168V3.74994H2.0835V1.24994Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.4377 17.9166H0.833496V7.91661L2.0835 3.74994"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.9171 3.74994L13.6671 6.24994"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_55980">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
