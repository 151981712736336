<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.125 3.875L4.875 9.12477L2.25 6.5"
      stroke="#595A5B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
