<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_55970)">
      <path
        d="M1.25 15.4164H18.75V19.1664H1.25V15.4164Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.25 15.4164V11.6664H18.75V15.4164"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.41638 11.6664V9.16636C5.41638 8.72433 5.59198 8.30041 5.90454 7.98785C6.2171 7.67529 6.64102 7.49969 7.08305 7.49969H12.9164C13.3584 7.49969 13.7823 7.67529 14.0949 7.98785C14.4075 8.30041 14.583 8.72433 14.583 9.16636V11.6664"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />

      <path
        d="M10.4168 9.16656H9.5835V9.9999H10.4168V9.16656Z"
        fill="#0E0E22"
      />

      <path
        d="M12.9168 9.16656H12.0835V9.9999H12.9168V9.16656Z"
        fill="#0E0E22"
      />

      <path
        d="M7.91683 9.16656H7.0835V9.9999H7.91683V9.16656Z"
        fill="#0E0E22"
      />

      <path
        d="M9.06209 5.48419L8.68709 5.32273C8.52165 5.25345 8.37305 5.14936 8.25139 5.01756C8.12974 4.88575 8.03788 4.7293 7.98204 4.55884C7.92621 4.38838 7.90772 4.2079 7.92782 4.02966C7.94793 3.85142 8.00615 3.6796 8.09855 3.52586L8.57772 2.72378C8.67011 2.57003 8.72834 2.39821 8.74844 2.21997C8.76855 2.04173 8.75006 1.86125 8.69422 1.69079C8.63839 1.52033 8.54653 1.36389 8.42487 1.23208C8.30322 1.10027 8.15462 0.996188 7.98918 0.9269L7.60376 0.765442"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.3957 5.48419L12.0207 5.32273C11.8553 5.25345 11.7067 5.14936 11.585 5.01756C11.4634 4.88575 11.3715 4.7293 11.3157 4.55884C11.2598 4.38838 11.2413 4.2079 11.2614 4.02966C11.2815 3.85142 11.3398 3.6796 11.4322 3.52586L11.9113 2.72378C12.0037 2.57003 12.062 2.39821 12.0821 2.21997C12.1022 2.04173 12.0837 1.86125 12.0278 1.69079C11.972 1.52033 11.8801 1.36389 11.7585 1.23208C11.6368 1.10027 11.4882 0.996188 11.3228 0.9269L10.9374 0.765442"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_55970">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
