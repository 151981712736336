import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "",
    name: "main",
    component: load("main/MainPage"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_MAIN],
      menuItems: "leftSideMenu.main",
    },
  },
  {
    path: "login",
    name: "login",
    component: load("LoginPage"),
    meta: {
      requiresUnauth: true,
      layout: "AuthLayout",
    },
  },
  {
    path: "forgot-password",
    name: "forgot-password",
    component: load("ForgotPassword"),
    meta: {
      requiresUnauth: true,
      layout: "AuthLayout",
    },
  },
  {
    path: "sign",
    name: "sign",
    component: load("sign/Sign"),
    meta: {
      requiresAuth: true,
      icon: "wallet",
    },
    children: [
      {
        path: "account",
        name: "account",
        component: load("account/Account"),
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_SUBSCRIPTION],
          icon: "wallet",
          menuItems: "nav.account",
        },
      },
      {
        path: "tariff",
        name: "tariff",
        component: load("sign/Tariff"),
        meta: {
          requiresAuth: true,
          icon: "p-chart",
        },
      },
    ],
  },
  {
    path: "terms",
    name: "terms",
    component: load("Terms"),
    meta: {
      requiresUnauth: true,
      layout: "AuthLayout",
    },
  },
];
