<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.1665 1.66669H7.49984C3.33317 1.66669 1.6665 3.33335 1.6665 7.50002V12.5C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5V10.8334"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3666 2.51669L6.7999 9.08336C6.5499 9.33336 6.2999 9.82503 6.2499 10.1834L5.89157 12.6917C5.75823 13.6 6.3999 14.2334 7.30823 14.1084L9.81657 13.75C10.1666 13.7 10.6582 13.45 10.9166 13.2L17.4832 6.63336C18.6166 5.50003 19.1499 4.18336 17.4832 2.51669C15.8166 0.850027 14.4999 1.38336 13.3666 2.51669Z"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4248 3.45831C12.9831 5.44998 14.5415 7.00831 16.5415 7.57498"
      stroke-width="1.2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
