<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56253)">
      <path
        d="M11.2495 14.1667V18.3334C11.2495 18.6649 11.1178 18.9828 10.8834 19.2172C10.649 19.4517 10.331 19.5834 9.99951 19.5834C9.66799 19.5834 9.35005 19.4517 9.11563 19.2172C8.88121 18.9828 8.74951 18.6649 8.74951 18.3334V14.1667"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.16602 3.54181C4.16602 1.81785 6.77539 0.416809 9.99935 0.416809C13.2233 0.416809 15.8327 1.81785 15.8327 3.54181V13.3335C15.8327 13.5545 15.7449 13.7665 15.5886 13.9227C15.4323 14.079 15.2204 14.1668 14.9993 14.1668H4.99935C4.77834 14.1668 4.56637 14.079 4.41009 13.9227C4.25381 13.7665 4.16602 13.5545 4.16602 13.3335V3.54181Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.16602 5.00012H5.41602C5.74754 5.00012 6.06548 5.13182 6.2999 5.36624C6.53432 5.60066 6.66602 5.9186 6.66602 6.25012V7.08346C6.66602 7.52548 6.84161 7.94941 7.15417 8.26197C7.46673 8.57453 7.89065 8.75012 8.33268 8.75012C8.77471 8.75012 9.19863 8.57453 9.51119 8.26197C9.82375 7.94941 9.99935 7.52548 9.99935 7.08346V6.66679C9.99935 6.33527 10.131 6.01733 10.3655 5.78291C10.5999 5.54848 10.9178 5.41679 11.2493 5.41679C11.5809 5.41679 11.8988 5.54848 12.1332 5.78291C12.3677 6.01733 12.4993 6.33527 12.4993 6.66679V7.50012C12.4993 7.77371 12.5532 8.04462 12.6579 8.29738C12.7626 8.55014 12.9161 8.77981 13.1095 8.97326C13.5002 9.36396 14.0301 9.58346 14.5827 9.58346H15.8327"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />

      <path
        d="M11.2489 2.08337H10.4155V2.91671H11.2489V2.08337Z"
        fill="#0E0E22"
      />

      <path
        d="M7.49886 2.91663H6.66553V3.74996H7.49886V2.91663Z"
        fill="#0E0E22"
      />

      <path
        d="M8.74886 5.41675H7.91553V6.25008H8.74886V5.41675Z"
        fill="#0E0E22"
      />

      <path
        d="M14.1659 4.16675H13.3325V5.00008H14.1659V4.16675Z"
        fill="#0E0E22"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56253">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
