// initial state
const state = () => ({
  accordionItems: {},
});

// getters
const getters = {
  stateAllCategories(state) {
    return state.accordionItems;
  },
  getCatById: (state) => (id) => {
    return state.accordionItems[id];
  },
};

// actions
const actions = {
  getCategoryById({ getters }, id) {
    const item = getters.getCatById(id);
    console.log(item);
  },
};

// mutations
const mutations = {
  setCategoriesState(state, points) {
    points.forEach((point) => {
      if (state.accordionItems[point.id]) {
        return;
      }
      const statePoint = state.accordionItems[point.id];

      const isStateCategories = state.accordionItems[point.id]
        ? Object.keys(state.accordionItems[point.id]).length
        : 0;

      const isPointCategories = point?.categories ? point.categories.length : 0;

      const compareCategories = isStateCategories !== isPointCategories;
      if (!statePoint || compareCategories) {
        state.accordionItems[point.id] = isPointCategories
          ? point.categories?.reduce((a, c) => {
              return {
                ...a,
                [c.id]: false,
              };
            }, {})
          : {};
      } else {
        //         console.log("exist!");
      }
    });
  },
  setCategoriesStateDirectly(state, accordionState) {
    state.accordionItems = accordionState;
  },
  toggleCategoryState(state, payload) {
    if (state.accordionItems[payload.pointID]) {
      state.accordionItems[payload.pointID][payload.id] =
        !state.accordionItems[payload.pointID][payload.id];
    }
    sessionStorage.setItem("stateItems", JSON.stringify(state.accordionItems));
  },
  setCategoryState(state, payload) {
    if (state.accordionItems[payload.pointID]) {
      state.accordionItems[payload.pointID][payload.id] = payload.st;
      sessionStorage.setItem(
        "stateItems",
        JSON.stringify(state.accordionItems)
      );
    }
  },
  saveToSessionStorage(state) {
    sessionStorage.setItem("stateItems", JSON.stringify(state.accordionItems));
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
