/* eslint-disable no-prototype-builtins */
import moment from "moment";
import { computed, ref } from "vue";
import { db } from "@/firebase";
import { getCollection } from "./collection.service";
import { useFetch } from "@vueuse/core";
const url = ref("https://ip2c.org/s");
import { currency, countryCurrency } from "@/constants/index";

import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  onSnapshot,
  // arrayRemove,
  arrayUnion,
  deleteDoc,
} from "firebase/firestore";
import store from "@/store";

const merchant = computed(() => store.getters.merchant);
// console.log("merchant:=>>>>", merchant.value);

// const merchantsCollectionRef = collection(db, "merchants");

/* === Get DATA Collection in Merchant === */
export const getDataCollectionInMerchant = async (target, collectionName) => {
  try {
    const { merchantId } = await merchant.value;
    const docRef = collection(db, "merchants", merchantId, collectionName);
    onSnapshot(docRef, (docSnaps) => {
      const temp = [];
      if (!docSnaps.empty) {
        docSnaps.docs.map((doc) => {
          const t = doc.data();
          if (collectionName === "tasks") {
            t["dateEnd"] = t["dateEnd"]?.toDate();
            t["dateStart"] = t["dateStart"]?.toDate();
          }
          temp.push({
            ...t,
            id: doc.id,
          });
        });
        target.value = temp.filter((it) => !it.disabled);
      } else {
        target.value = temp.filter((it) => !it.disabled);
      }
    });
  } catch (error) {
    //     console.log("getDataCollectionInMerchant:fnFirebase.js ===>>> ", error);
  }
};
export const getDataCollectionInMerchantOneItem = async (
  target,
  id,
  collectionName
) => {
  try {
    const { merchantId } = await merchant.value;

    const docRef = doc(db, "merchants", merchantId, collectionName, id);
    const docSnap = await getDoc(docRef);
    const temp = docSnap.data();
    for (const key in temp) {
      if (
        key === "date" &&
        // eslint-disable-next-line no-prototype-builtins
        (temp[key]?.hasOwnProperty("start") || temp[key]?.hasOwnProperty("end"))
      ) {
        // eslint-disable-next-line no-prototype-builtins
        if (temp[key]?.hasOwnProperty("start")) {
          target[key]["start"] = temp[key]["start"]?.toDate();
        }
        if (temp[key]?.hasOwnProperty("end")) {
          target[key]["end"] = temp[key]["end"]?.toDate();
        }
      } else if (key === "time") {
        if (temp[key]?.hasOwnProperty("start")) {
          target[key]["start"] = temp[key]["start"]?.toDate();
        }
        if (temp[key]?.hasOwnProperty("end")) {
          target[key]["end"] = temp[key]["end"]?.toDate();
        }
      } else if (key === "birthday") {
        target[key] = temp[key]?.toDate();
      } else if (key === "dateSent") {
        target[key] = temp[key]?.toDate();
      } else if (key === "dateStart" || key === "dateEnd") {
        target[key] = temp[key]?.toDate();
      } else {
        target[key] = temp[key];
      }
    }
  } catch (error) {
    //     console.log("getDataCollectionInMerchantOneItem => ", error);
  }
};

export async function saveDataCollectionInMerchant(
  target,
  collectionName,
  docId
) {
  try {
    //     console.log("collectionName ***>", collectionName);
    const { merchantId } = await merchant.value;
    let docRef = null;
    if (docId) {
      docRef = doc(
        collection(db, "merchants", merchantId, collectionName),
        docId
      );
    } else {
      docRef = doc(collection(db, "merchants", merchantId, collectionName));
    }
    target.id = docRef.id;
    // console.log("target", target);

    await setDoc(docRef, target);
  } catch (error) {
    console.error("saveDataCollectionInMerchant", error.message);
  }
}

export const updateDataCollectionInMerchant = async (
  target,
  collectionName
) => {
  try {
    const { merchantId } = await merchant.value;
    const docRef = doc(
      db,
      `merchants/${merchantId}/${collectionName}/${target.id}`
    );
    await setDoc(docRef, target, { merge: true });
  } catch (error) {
    console.error("updateDataCollectionInMerchant", error.message);
  }
};

export const updateDataFieldCollectionInMerchant = async (
  target,
  collectionName
) => {
  try {
    const { merchantId } = await merchant.value;
    const docRef = doc(
      db,
      `merchants/${merchantId}/${collectionName}/${target.id}`
    );
    await setDoc(docRef, target, { merge: true });
  } catch (error) {
    console.error("updateDataCollectionInMerchant", error.message);
  }
};

export const deleteDataCollectionInMerchant = async (id, collectionName) => {
  try {
    const { merchantId } = await merchant.value;
    const docRef = doc(db, `merchants/${merchantId}/${collectionName}/${id}`);

    await deleteDoc(docRef);
  } catch (error) {
    console.error("deleteDataCollectionInMerchant ==> ", error.message);
  }
};
export const disabledDataCollectionInMerchant = async (id, collectionName) => {
  try {
    const { merchantId } = await merchant.value;
    const docRef = doc(db, `merchants/${merchantId}/${collectionName}/${id}`);

    // await updateDoc(docRef);
    await updateDoc(docRef, { disabled: true });
  } catch (error) {
    console.error("deleteDataCollectionInMerchant ==> ", error.message);
  }
};
export const updateDateSubscriptionInMerchants = async (id) => {
  try {
    const docRef = doc(db, "merchants", id.trim());
    const currentDate = moment();
    // const currentDate = moment(date.toDate());
    const futureDate = currentDate.add(1, "month").subtract(1, "day").toDate();
    await updateDoc(docRef, { endOfSubscription: futureDate });
  } catch (error) {
    console.error("Error fetching collection:", error.message);
  }
};
export const updateStatusSubscriptionInMerchants = async (id, status) => {
  try {
    const docRef = doc(db, "merchants", id.trim());
    await updateDoc(docRef, { statusOfSubscription: status });
  } catch (error) {
    console.error("Error fetching collection:", error.message);
  }
};

export async function getMerchantCurrencySign(target) {
  const { merchantId } = await merchant.value;
  const docRef = doc(db, "merchants", merchantId);
  try {
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists) {
      const merchantData = docSnapshot.data();
      if (merchantData?.currencySign) {
        target.value = merchantData?.currencySign;
      } else {
        const { error, data } = await useFetch(url);
        if (!error.value) {
          const country = data.value.split(";")[2];
          const { currencycode } = countryCurrency.find(
            (el) => el.country === country
          );
          target.value = currency.find(
            (el) => el.currencyNumCode == currencycode
          );
          await updateMerchantCurrencySign(target.value);
        }
      }
    }
    // onSnapshot(docRef, (doc) => {
    //   const merchantData = doc.data();
    //   target.value = merchantData?.currencySign;
    // });
  } catch (error) {
    console.log(error);
  }
}
export async function getMerchantSlugName(merchantSlugName) {
  const { merchantId } = await merchant.value;
  const docRef = doc(db, "merchants", merchantId);
  try {
    onSnapshot(docRef, (doc) => {
      merchantSlugName.value = doc.data()?.merchantSlugName;
    });
  } catch (error) {
    console.log(error);
  }
}
export async function getMerchantSlugNameV() {
  const { merchantId } = merchant.value;
  let docData;
  try {
    const docRef = doc(db, "merchants", merchantId);
    const snapshot = await getDoc(docRef);
    docData = snapshot.data();
  } catch (error) {
    console.log(error);
  }
  return docData?.merchantSlugName;
}
export async function updateMerchantSlugName(name) {
  const { merchantId } = await merchant.value;
  const docRef = doc(db, "merchants", merchantId);

  try {
    await setDoc(docRef, { merchantSlugName: name }, { merge: true });
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
}
export async function updateMerchantCurrencySign(name) {
  const { merchantId } = await merchant.value;
  const docRef = doc(db, "merchants", merchantId);
  try {
    await setDoc(docRef, { currencySign: name }, { merge: true });
    //     console.log("currencySign:saved!");
  } catch (error) {
    console.log(error);
  }
}

/* Merchant */
export async function getMerchant(uid) {
  let currentUser;
  try {
    currentUser = await getCollection("users", uid);
    console.log("currentUser", currentUser);
  } catch (error) {
    console.log("Error fetching collection:getMerchant", error);
  }

  const { merchantId } = currentUser;
  try {
    return await getCollection("merchants", merchantId).catch((error) => {
      console.log("Error fetching collection:getMerchant", error);
    });
  } catch (error) {
    console.log("Error fetching collection:getMerchant", error);
    return;
  }
}
export async function getMerchantOne(uid) {
  const userRef = doc(db, "users", uid);
  try {
    const userSnap = await getDoc(userRef);
    const user = userSnap.data();
    const merchRef = doc(db, "merchants", user?.merchantId);
    const merchSnap = await getDoc(merchRef);
    return merchSnap.data();
  } catch (error) {
    console.log("Error fetching collection:getMerchantOne", error);
  }
}
/* MerchantId */
export async function getMerchantId(uid) {
  try {
    const { merchantId } = await getMerchant(uid);
    return merchantId;
  } catch (error) {
    console.log("Error fetching collection:getMerchantId", error);
  }
}

/* ===Staff=== */

/* Get Staff */
export async function getMerchantStaff(uid) {
  try {
    const { staff } = await getMerchant(uid);
    return staff;
  } catch (error) {
    console.log("Error fetching collection:getMerchantStaff", error);
  }
}
/* Add Staff */
export async function saveMerchantEmployee(uid, employee) {
  try {
    const merchantId = await getMerchantId(uid);
    const docRef = doc(db, "merchants", merchantId);
    await updateDoc(docRef, {
      staff: arrayUnion(employee),
    });
  } catch (error) {
    console.log("Error fetching collection:saveMerchantEmployee", error);
  }
  //   console.log("Сотрудник успешно сохранен!");
}
export async function checkIfDomainAlreadyExists(target, subdomain) {
  try {
    const { merchantId } = merchant.value;
    const pointsRef = collection(db, "merchants", merchantId, "points");
    const q = query(
      pointsRef,
      where("subdomain", "==", subdomain),
      where("disabled", "!=", true)
    );
    const docsSnap = await getDocs(q);
    if (docsSnap.docs.length) {
      target.value = true;
      return true;
    }
    target.value = false;
    return false;
  } catch (error) {
    console.log("Error fetching collection:checkIfDomainAlreadyExists", error);
  }
}
