<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_55988)">
      <path
        d="M18.2819 16.25H10.0527L9.53711 11.6666H18.8027L18.2819 16.25Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.5994 11.6666L10.3599 9.16663"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.2979 16.2499L17.0167 19.1666H11.324L11.0427 16.2499"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.9744 9.16663L17.7349 11.6666"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.4173 7.49992L11.6673 5.83325H16.6673L17.9173 7.49992H18.7507V9.16658H9.58398V7.49992H10.4173Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.08398 1.24994H12.9173V3.74994H2.08398V1.24994Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.2038 17.9166H0.833984V7.91661L2.08398 3.74994"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.9177 3.74994L13.5427 5.83327"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_55988">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
