<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.3335 15.1667H14.6668"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.07324 15.1667V9.16675C2.07324 8.06008 3.06658 7.16675 4.29324 7.16675H11.6999C12.9266 7.16675 13.9199 8.06008 13.9199 9.16675V15.1667"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.70679 7.16659V5.27992C3.70679 4.47992 4.42679 3.83325 5.32012 3.83325H10.6868C11.5735 3.83325 12.2935 4.47992 12.2935 5.27992V7.16659"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.35352 9.82007L2.60018 9.82674C3.09352 9.8334 3.48685 10.2334 3.48685 10.7267V10.9467C3.48685 11.4401 3.88685 11.8467 4.38685 11.8467C4.88018 11.8467 5.28685 11.4467 5.28685 10.9467V10.7401C5.28685 10.2467 5.68685 9.84007 6.18685 9.84007C6.68018 9.84007 7.08685 10.2401 7.08685 10.7401V10.9467C7.08685 11.4401 7.48685 11.8467 7.98685 11.8467C8.48018 11.8467 8.88685 11.4467 8.88685 10.9467V10.7401C8.88685 10.2467 9.28685 9.84007 9.78685 9.84007C10.2802 9.84007 10.6868 10.2401 10.6868 10.7401V10.9467C10.6868 11.4401 11.0868 11.8467 11.5868 11.8467C12.0802 11.8467 12.4868 11.4467 12.4868 10.9467V10.7401C12.4868 10.2467 12.8868 9.84007 13.3868 9.84007H13.6868"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33325 3.83333V2.5"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6667 3.83333V2.5"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 3.83325V1.83325"
      stroke="#00CDD7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
