<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5898)">
      <path
        d="M10 14.5833V19.5833"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.75 19.5833H6.25"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0833 7.91675H11.25V8.75008H12.0833V7.91675Z"
        fill="#0E0E22"
      />

      <path d="M8.7474 10.8333H7.91406V11.6666H8.7474V10.8333Z" />

      <path
        d="M11.6654 11.6667H10.832V12.5001H11.6654V11.6667Z"
        fill="#0E0E22"
      />
      <path
        d="M6.47266 5H13.5247"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.0833 3.12484L14.4583 8.94255C14.617 9.61543 14.6215 10.3155 14.4713 10.9903C14.3211 11.6652 14.0202 12.2973 13.5911 12.8394C13.162 13.3815 12.6158 13.8194 11.9935 14.1205C11.3711 14.4216 10.6887 14.578 9.9974 14.578C9.30605 14.578 8.62365 14.4216 8.00131 14.1205C7.37897 13.8194 6.8328 13.3815 6.40371 12.8394C5.97461 12.2973 5.6737 11.6652 5.52352 10.9903C5.37333 10.3155 5.37775 9.61543 5.53646 8.94255L6.91667 3.13005C7.02882 2.65032 7.08475 2.15917 7.08333 1.6665C7.08447 1.24692 7.04434 0.828237 6.96354 0.416504H13.0417C12.8646 1.31235 12.8805 2.23566 13.0885 3.12484H13.0833Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5898">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
