<template>
  <svg
    width="40"
    height="37"
    viewBox="0 0 40 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="12.5"
      r="2"
      fill="#9B9B9B"
    />
    <circle
      cx="20"
      cy="18.5"
      r="2"
      fill="#9B9B9B"
    />
    <circle
      cx="20"
      cy="24.5"
      r="2"
      fill="#9B9B9B"
    />
  </svg>
</template>
