<template>
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.97869 10.5H0.348011L4.01207 0.318182H5.78693L9.45099 10.5H7.82031L4.94176 2.16761H4.86222L1.97869 10.5ZM2.25213 6.51278H7.5419V7.8054H2.25213V6.51278Z"
      fill="#595A5B"
    />
  </svg>
</template>
