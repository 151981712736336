<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="28"
      height="28"
      rx="14"
      fill="#F8F8F8"
    />
    <path
      d="M16.4666 17.5333C16.7333 17.7999 16.7333 18.1999 16.4666 18.4666C16.3333 18.5999 16.2 18.6666 16 18.6666C15.8 18.6666 15.6666 18.5999 15.5333 18.4666L11.5333 14.4666C11.2666 14.1999 11.2666 13.7999 11.5333 13.5333L15.5333 9.53325C15.8 9.26659 16.2 9.26659 16.4666 9.53325C16.7333 9.79992 16.7333 10.1999 16.4666 10.4666L12.9333 13.9999L16.4666 17.5333Z"
      fill="black"
    />
  </svg>
</template>
