<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.77002 11.6663L4.01481 17.3486C4.07536 17.627 4.22919 17.8764 4.45084 18.0554C4.67249 18.2344 4.94865 18.3324 5.23356 18.333H14.7648C15.0506 18.3336 15.328 18.2362 15.5507 18.057C15.7734 17.8779 15.928 17.6279 15.9888 17.3486L17.2284 11.6663"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M1.66602 10.6247C1.66602 10.3485 1.77576 10.0835 1.97111 9.88817C2.16646 9.69282 2.43142 9.58307 2.70768 9.58307H17.291C17.5673 9.58307 17.8322 9.69282 18.0276 9.88817C18.2229 10.0835 18.3327 10.3485 18.3327 10.6247C18.3327 10.901 18.2229 11.166 18.0276 11.3613C17.8322 11.5567 17.5673 11.6664 17.291 11.6664H2.70768C2.43142 11.6664 2.16646 11.5567 1.97111 11.3613C1.77576 11.166 1.66602 10.901 1.66602 10.6247V10.6247Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.67151 5.33828L5.34339 2.01016C5.12237 1.78914 4.82261 1.66498 4.51005 1.66498C4.19749 1.66498 3.89773 1.78914 3.67672 2.01016C3.45571 2.23117 3.33154 2.53093 3.33154 2.84349C3.33154 3.15605 3.45571 3.45581 3.67672 3.67682L5.65589 5.65599"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M17.0827 9.58311V9.16644C17.0827 5.02582 14.8431 1.66644 12.0827 1.66644H11.666C11.666 2.661 11.2709 3.61483 10.5677 4.31809C9.8644 5.02135 8.91058 5.41644 7.91602 5.41644H7.08268C4.7806 5.41644 2.91602 7.18728 2.91602 9.37478V9.58311"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
