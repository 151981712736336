<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0837 12.0832V11.6666C12.0837 10.9739 11.5264 10.4166 10.8337 10.4166H9.16699C8.47428 10.4166 7.91699 10.9739 7.91699 11.6666C7.91699 12.3593 8.47428 12.9166 9.16699 12.9166H10.8337C11.5264 12.9166 12.0837 13.4739 12.0837 14.1666C12.0837 14.8593 11.5264 15.4166 10.8337 15.4166H9.16699C8.47428 15.4166 7.91699 14.8593 7.91699 14.1666V13.7499"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.5838 2.08337L10.8338 5.83337L7.91716 2.91671L4.75049 6.08337"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.2505 2.08337H14.5838V5.41671"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.3337 7.91675H1.66699V17.9167H18.3337V7.91675Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0005 16.6667V15.4167"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0005 9.16656V10.4166"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0005 17.9165V17.4998C15.0005 15.8904 16.3078 14.5831 17.9172 14.5831H18.3338"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0005 7.91675V8.33341C15.0005 9.94279 16.3078 11.2501 17.9172 11.2501H18.3338"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.00033 17.9165V17.4998C5.00033 15.8904 3.69303 14.5831 2.08366 14.5831H1.66699"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.00033 7.91675V8.33341C5.00033 9.94279 3.69303 11.2501 2.08366 11.2501H1.66699"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
