<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7577 24.0979L17.4993 23.942L17.241 24.0979L10.0155 28.459L11.9329 20.2396L12.0015 19.9457L11.7734 19.7481L5.3891 14.2174L13.7949 13.5042L14.0954 13.4787L14.213 13.2011L17.4993 5.44731L20.7857 13.2011L20.9033 13.4787L21.2037 13.5042L29.6096 14.2174L23.2253 19.7481L22.9972 19.9457L23.0658 20.2396L24.9832 28.459L17.7577 24.0979Z"
      stroke="#D1D5E1"
    />
    <circle
      cx="17.5"
      cy="17.5"
      r="17"
      stroke="#D1D5E1"
    />
  </svg>
</template>
