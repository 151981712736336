<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5879)">
      <path
        d="M15.0924 3.33325H4.91016L7.16536 19.5833H12.8268L15.0924 3.33325Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.3125 6.24976H14.6823"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.487 0.161377L11.4922 6.24992"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5879">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
