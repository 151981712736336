import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "faq",
    name: "faq",
    redirect: { name: "faq:default" },
    component: load("faq/Faq"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_FAQ],
      icon: "info",
      menuItems: "leftSideMenu.faq",
    },
    children: [
      {
        path: "article",
        name: "faq:default",
        component: load("faq/ArticleDefault"),
        props: true,
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_FAQ],
          icon: "info",
          menuItems: "leftSideMenu.faq",
        },
      },
      {
        path: "article/:index/:subindex",
        name: "faq:article",
        component: load("faq/Article"),
        props: true,
        meta: {
          requiresAuth: true,
          accessPermissions: [AccessPermissionsEnum.CAN_READ_FAQ],
          icon: "info",
          menuItems: "leftSideMenu.faq",
        },
      },
    ],
  },
];
