<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.65625 11.6665H15.8542"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1224 9.58325H17.0859C17.6385 9.58325 18.1684 9.80275 18.5591 10.1934C18.9498 10.5841 19.1693 11.1141 19.1693 11.6666C19.1693 12.2191 18.9498 12.749 18.5591 13.1397C18.1684 13.5304 17.6385 13.7499 17.0859 13.7499H15.3984"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0872 18.3332C14.3893 18.3332 16.2539 13.1092 16.2539 6.6665H1.25391C1.25391 13.1092 3.11849 18.3332 5.42057 18.3332H12.0872Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0872 6.6665C15.0872 6.00346 14.8239 5.36758 14.355 4.89874C13.8862 4.4299 13.2503 4.1665 12.5872 4.1665H12.5039C12.5042 3.75359 12.3819 3.34989 12.1523 3.00666C11.9228 2.66344 11.5964 2.39616 11.2147 2.23878C10.8329 2.08139 10.413 2.04099 10.0083 2.12271C9.60352 2.20443 9.23218 2.40458 8.94141 2.69775C8.75379 2.38423 8.48831 2.12453 8.17074 1.94384C7.85317 1.76315 7.49428 1.66762 7.12891 1.6665C5.96745 1.6665 5.00391 2.61442 5.00391 3.74984C4.67541 3.74915 4.35001 3.81335 4.04639 3.93874C3.74277 4.06413 3.4669 4.24826 3.23461 4.48054C3.00233 4.71282 2.81821 4.98869 2.69281 5.29232C2.56742 5.59594 2.50323 5.92134 2.50391 6.24984V6.6665"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
