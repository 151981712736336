<template>
  <svg
    width="12"
    height="24"
    viewBox="0 0 12 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="path-1-inside-1_2401_7316"
      fill="white"
    >
      <path
        d="M0 0H8C10.2091 0 12 1.79086 12 4V20C12 22.2091 10.2091 24 8 24H0V0Z"
      />
    </mask>
    <path
      d="M0 0H8C10.2091 0 12 1.79086 12 4V20C12 22.2091 10.2091 24 8 24H0V0Z"
      fill="white"
    />
    <path
      d="M4 8L8 12L4 16"
      stroke="#A0A0A0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0 -1H8C10.7614 -1 13 1.23858 13 4H11C11 2.34315 9.65685 1 8 1H0V-1ZM13 20C13 22.7614 10.7614 25 8 25H0V23H8C9.65685 23 11 21.6569 11 20H13ZM0 24V0V24ZM8 -1C10.7614 -1 13 1.23858 13 4V20C13 22.7614 10.7614 25 8 25V23C9.65685 23 11 21.6569 11 20V4C11 2.34315 9.65685 1 8 1V-1Z"
      fill="#A0A0A0"
      mask="url(#path-1-inside-1_2401_7316)"
    />
  </svg>
</template>
