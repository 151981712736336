<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58731)">
      <path
        d="M9.99805 2.08394V0.000610352"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.0817 8.33398H19.165"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.915 8.33398H0.831665"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.33167 17.084H11.665"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.49805 2.25061L5.24805 4.00061"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.498 2.25061L14.748 4.00061"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.6653 17.0839L14.7174 12.5943C15.3499 11.724 15.7294 10.6957 15.8139 9.62315C15.8985 8.5506 15.6847 7.47557 15.1964 6.51693C14.708 5.55828 13.964 4.75337 13.0467 4.1912C12.1294 3.62902 11.0745 3.33148 9.99862 3.33148C8.92275 3.33148 7.86783 3.62902 6.95052 4.1912C6.03321 4.75337 5.28924 5.55828 4.80088 6.51693C4.31252 7.47557 4.09879 8.5506 4.18332 9.62315C4.26785 10.6957 4.64736 11.724 5.27987 12.5943L8.33195 17.0839V18.7505C8.33195 18.9715 8.41975 19.1835 8.57603 19.3398C8.73231 19.4961 8.94427 19.5839 9.16529 19.5839H10.832C11.053 19.5839 11.2649 19.4961 11.4212 19.3398C11.5775 19.1835 11.6653 18.9715 11.6653 18.7505V17.0839Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58731">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
