<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5931)">
      <path
        d="M7.24609 8.33317L5.78776 1.98942C5.76655 1.89758 5.71483 1.81564 5.64105 1.75699C5.56726 1.69834 5.47577 1.66644 5.38151 1.6665H2.91797"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.60156 8.33326L10.7474 1.13013C10.7733 1.04399 10.8263 0.968535 10.8986 0.915026C10.9708 0.861518 11.0585 0.832832 11.1484 0.833257H14.1693"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.33594 11.668L10.0043 9.99964L11.6708 11.6661L10.0024 13.3345L8.33594 11.668Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.08594 15.4165H9.58594V17.9165H7.08594V15.4165Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.92318 19.5832C5.61393 19.5836 5.31551 19.4693 5.08559 19.2625C4.85568 19.0557 4.7106 18.7709 4.67839 18.4634L3.29297 5.4165H15.043L13.6576 18.4634C13.6253 18.7709 13.4803 19.0557 13.2503 19.2625C13.0204 19.4693 12.722 19.5836 12.4128 19.5832H5.92318Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.60156 8.33325H14.737"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.776 7.90609L15 7.9165C15.4945 7.9165 15.9778 7.76988 16.3889 7.49518C16.8 7.22048 17.1205 6.83003 17.3097 6.37321C17.4989 5.9164 17.5484 5.41373 17.452 4.92878C17.3555 4.44383 17.1174 3.99837 16.7678 3.64874C16.4181 3.29911 15.9727 3.061 15.4877 2.96454C15.0028 2.86808 14.5001 2.91759 14.0433 3.10681C13.5865 3.29603 13.196 3.61646 12.9213 4.02758C12.6466 4.4387 12.5 4.92205 12.5 5.41651"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5931">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
