<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56319)">
      <path
        d="M15.2307 6.89587L13.1057 4.77087"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.85539 13.0624L3.26164 14.6562C3.06792 14.462 2.82927 14.3186 2.56686 14.2388C2.30445 14.1589 2.02638 14.1451 1.75734 14.1985C1.48829 14.2519 1.23658 14.3708 1.02453 14.5448C0.812481 14.7188 0.64665 14.9424 0.541747 15.1959C0.436844 15.4493 0.396113 15.7247 0.423166 15.9977C0.450219 16.2706 0.544221 16.5327 0.696833 16.7606C0.849444 16.9885 1.05595 17.1752 1.29802 17.3042C1.5401 17.4332 1.81027 17.5004 2.08456 17.4999H2.50122V17.9166C2.50077 18.1903 2.5677 18.4598 2.69612 18.7015C2.82454 18.9432 3.01049 19.1495 3.23755 19.3022C3.46461 19.455 3.7258 19.5495 3.99803 19.5774C4.27027 19.6053 4.54519 19.5657 4.79851 19.4621C5.05182 19.3586 5.27575 19.1943 5.4505 18.9837C5.62525 18.7731 5.74546 18.5227 5.8005 18.2546C5.85554 17.9865 5.84372 17.709 5.7661 17.4466C5.68847 17.1842 5.54741 16.9449 5.35539 16.7499L6.95435 15.151"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.725 10.387C19.862 9.25001 18.7441 6.28864 16.228 3.77261C13.712 1.25658 10.7506 0.138644 9.61364 1.27564C8.47665 2.41263 9.59458 5.374 12.1106 7.89003C14.6266 10.4061 17.588 11.524 18.725 10.387Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.48029 1.43219L5.21987 7.20302C4.44512 8.24627 4.07123 9.53328 4.16641 10.8293C4.2616 12.1252 4.81951 13.3438 5.73837 14.2627C6.65723 15.1815 7.8758 15.7394 9.17177 15.8346C10.4677 15.9298 11.7548 15.5559 12.798 14.7811L18.5688 10.5207"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56319">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
