<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6178)">
      <path
        d="M8.52604 12.5519L10 9.7915L11.474 12.5519C11.6091 12.8059 11.6762 13.0905 11.6687 13.3781C11.6612 13.6656 11.5795 13.9464 11.4313 14.193C11.2832 14.4396 11.0738 14.6437 10.8234 14.7853C10.573 14.927 10.2903 15.0015 10.0026 15.0015C9.71493 15.0015 9.43216 14.927 9.18179 14.7853C8.93143 14.6437 8.72199 14.4396 8.57387 14.193C8.42574 13.9464 8.34397 13.6656 8.3365 13.3781C8.32903 13.0905 8.39612 12.8059 8.53125 12.5519H8.52604Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0859 0.416504H7.91927C7.45903 0.416504 7.08594 0.7896 7.08594 1.24984V1.6665C7.08594 2.12674 7.45903 2.49984 7.91927 2.49984H12.0859C12.5462 2.49984 12.9193 2.12674 12.9193 1.6665V1.24984C12.9193 0.7896 12.5462 0.416504 12.0859 0.416504Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3346 19.5831H6.66797C6.33645 19.5831 6.01851 19.4514 5.78409 19.217C5.54966 18.9826 5.41797 18.6646 5.41797 18.3331V8.33309C5.41797 7.79216 5.59341 7.26583 5.91797 6.83309L7.66797 4.49976C7.83025 4.28339 7.91797 4.02022 7.91797 3.74976V2.49976H12.0846V3.74976C12.0846 4.02022 12.1724 4.28339 12.3346 4.49976L14.0846 6.83309C14.4092 7.26583 14.5846 7.79216 14.5846 8.33309V18.3331C14.5846 18.6646 14.4529 18.9826 14.2185 19.217C13.9841 19.4514 13.6662 19.5831 13.3346 19.5831Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6178">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
