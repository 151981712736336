<template>
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.0001 8.77027V4.48796C29.0001 2.01524 27.1444 0 24.8676 0H7.13378C4.85574 0.0225186 3.00012 2.03776 3.00012 4.48796V8.77022C3.00012 9.20518 3.31682 9.54916 3.71738 9.54916H28.284C28.6845 9.54916 29.0001 9.20518 29.0001 8.77022V8.77027Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.0001 12.5265V8.2667C29.0001 5.79283 27.1444 3.77759 24.8676 3.77759H7.13378C4.85574 3.77759 3.00012 5.79283 3.00012 8.2667V12.549C3.00012 12.9839 3.31682 13.3279 3.71738 13.3279H28.284C28.6845 13.3042 29.0001 12.9615 29.0001 12.5265Z"
      fill="#FBBC04"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.0001 16.3052V12.0229C29.0001 9.54911 27.1444 7.53381 24.8676 7.53381H7.13378C4.85574 7.53381 3.00012 9.54911 3.00012 12.0229V16.3052C3.00012 16.7402 3.31682 17.083 3.71738 17.083H28.284C28.6845 17.0605 29.0001 16.7166 29.0001 16.3052Z"
      fill="#EA4335"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5324 14.2439L3.00012 10.0762V19.5108C3.00012 21.9846 4.85579 23.9999 7.13373 23.9999H24.8676C27.1444 23.9999 29.0001 21.9846 29.0001 19.5108V9.89307L25.057 13.0075C23.4549 14.2664 21.4512 14.7249 19.5324 14.2439V14.2439Z"
      fill="#4285F4"
    />
  </svg>
</template>
