<template>
  <svg
    width="30"
    height="16"
    viewBox="0 0 30 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.059 10.2636C17.6172 10.2636 19.6908 8.18991 19.6908 5.6318C19.6908 3.07373 17.6172 1 15.059 1C12.501 1 10.4272 3.07373 10.4272 5.6318C10.4272 8.18991 12.501 10.2636 15.059 10.2636Z"
      stroke="black"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M8.64551 13.5946C9.3689 12.5657 10.3293 11.7258 11.4455 11.1461C12.5617 10.5663 13.801 10.2637 15.0588 10.2636C16.3167 10.2635 17.556 10.5662 18.6723 11.1458C19.7885 11.7255 20.749 12.5652 21.4724 13.5941"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3296 2.0133C21.9666 1.83381 22.6348 1.79292 23.289 1.89338C23.9432 1.99386 24.5683 2.23335 25.1221 2.59573C25.676 2.95812 26.1457 3.43499 26.4997 3.99418C26.8538 4.55342 27.0839 5.18206 27.1746 5.83771C27.2652 6.49329 27.2143 7.16076 27.0252 7.79504C26.8362 8.42938 26.5134 9.01576 26.0787 9.51486C25.6439 10.0139 25.1072 10.414 24.5049 10.6882C23.9024 10.9624 23.2482 11.1043 22.5864 11.1043"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.5869 11.1044C23.8448 11.1034 25.0845 11.4056 26.2008 11.9854C27.3172 12.5652 28.2774 13.4054 29.0002 14.4349"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.67041 2.0133C8.03336 1.83381 7.36517 1.79292 6.71102 1.89338C6.05679 1.99386 5.43171 2.23335 4.87789 2.59573C4.324 2.95812 3.85427 3.43499 3.50025 3.99418C3.14617 4.55342 2.91608 5.18206 2.82544 5.83771C2.7348 6.49329 2.78575 7.16076 2.97479 7.79504C3.16384 8.42938 3.48657 9.01576 3.92132 9.51486C4.35607 10.0139 4.89279 10.414 5.49513 10.6882C6.09755 10.9624 6.75178 11.1043 7.41362 11.1043"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.41309 11.1044C6.15516 11.1034 4.91555 11.4056 3.79922 11.9854C2.68281 12.5652 1.7226 13.4054 0.999827 14.4349"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
