<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.001 6.096a2.791 2.791 0 0 1 2.793 2.793 2.797 2.797 0 0 1-2.69 2.794.91.91 0 0 0-.207 0m1.644 7.183a3.86 3.86 0 0 0 1.564-.695c1.245-.934 1.245-2.474 0-3.408-.439-.335-.973-.558-1.54-.686M6.038 6.097a2.791 2.791 0 0 0-2.793 2.792 2.797 2.797 0 0 0 2.69 2.794.91.91 0 0 1 .207 0m-1.644 7.183a3.86 3.86 0 0 1-1.564-.695c-1.245-.934-1.245-2.474 0-3.408.439-.335.973-.558 1.54-.686m7.74-2.498a1.448 1.448 0 0 0-.263 0 3.528 3.528 0 0 1-3.407-3.536A3.54 3.54 0 0 1 12.087 4.5a3.54 3.54 0 0 1 .128 7.079Zm-3.99 2.945c-1.931 1.293-1.931 3.4 0 4.685 2.195 1.468 5.794 1.468 7.99 0 1.93-1.293 1.93-3.4 0-4.685-2.187-1.46-5.787-1.46-7.99 0Z"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
