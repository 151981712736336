<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.6001H18.6667"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3334 28.0001C22.329 28.0001 28.0001 22.329 28.0001 15.3334C28.0001 8.33781 22.329 2.66675 15.3334 2.66675C8.33781 2.66675 2.66675 8.33781 2.66675 15.3334C2.66675 22.329 8.33781 28.0001 15.3334 28.0001Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3334 29.3334L26.6667 26.6667"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
