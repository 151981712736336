<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56401)">
      <path
        d="M10.0016 13.7498C13.6835 13.7498 16.6683 10.7651 16.6683 7.08317C16.6683 3.40127 13.6835 0.416504 10.0016 0.416504C6.31973 0.416504 3.33496 3.40127 3.33496 7.08317C3.33496 10.7651 6.31973 13.7498 10.0016 13.7498Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0016 8.74984C10.9221 8.74984 11.6683 8.00364 11.6683 7.08317C11.6683 6.1627 10.9221 5.4165 10.0016 5.4165C9.08115 5.4165 8.33496 6.1627 8.33496 7.08317C8.33496 8.00364 9.08115 8.74984 10.0016 8.74984Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0016 11.2498C12.3028 11.2498 14.1683 9.38436 14.1683 7.08317C14.1683 4.78198 12.3028 2.9165 10.0016 2.9165C7.70044 2.9165 5.83496 4.78198 5.83496 7.08317C5.83496 9.38436 7.70044 11.2498 10.0016 11.2498Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0015 13.7499V19.9999"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56401">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
