<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58762)">
      <path
        d="M12.5 9.1661H10V5.83276H12.5"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15 9.16614H12.5V4.16614H15"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15 2.49951H17.5V9.16618H15V2.49951Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.5 0.416138H19.5833V11.2495H9.58333"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.16634 4.5828C5.31693 4.5828 6.24967 3.65006 6.24967 2.49947C6.24967 1.34888 5.31693 0.416138 4.16634 0.416138C3.01575 0.416138 2.08301 1.34888 2.08301 2.49947C2.08301 3.65006 3.01575 4.5828 4.16634 4.5828Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.1665 19.5828V13.7495"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.08317 12.9164H1.6665C1.33498 12.9164 1.01704 12.7847 0.78262 12.5503C0.5482 12.3159 0.416504 11.9979 0.416504 11.6664V8.33309C0.416504 7.78056 0.635997 7.25065 1.0267 6.85995C1.4174 6.46925 1.9473 6.24976 2.49984 6.24976H5.83317C6.38571 6.24976 6.91561 6.46925 7.30631 6.85995C7.69701 7.25065 7.9165 7.78056 7.9165 8.33309V11.6664C7.9165 11.9979 7.78481 12.3159 7.55039 12.5503C7.31597 12.7847 6.99802 12.9164 6.6665 12.9164H6.24984"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.08301 8.74951V19.5828H6.24967V8.74951"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58762">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
