<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56288)">
      <path
        d="M3.7489 12.5886C3.74882 10.8404 4.26715 9.13151 5.23833 7.67795C6.20952 6.22438 7.58994 5.09146 9.20502 4.42246C10.8201 3.75345 12.5973 3.57842 14.3119 3.91949C16.0264 4.26056 17.6014 5.10242 18.8374 6.33859L18.9937 6.49484C19.1805 6.68154 19.3286 6.9032 19.4297 7.14717C19.5308 7.39114 19.5828 7.65264 19.5828 7.91672C19.5828 8.1808 19.5308 8.4423 19.4297 8.68627C19.3286 8.93023 19.1805 9.1519 18.9937 9.33859L9.33744 18.9948C9.15075 19.1816 8.92908 19.3298 8.68511 19.4309C8.44115 19.5319 8.17965 19.584 7.91557 19.584C7.65149 19.584 7.38999 19.5319 7.14602 19.4309C6.90205 19.3298 6.68039 19.1816 6.49369 18.9948L6.33744 18.8386C5.51672 18.0178 4.8657 17.0434 4.42156 15.971C3.97742 14.8987 3.74885 13.7493 3.7489 12.5886V12.5886Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.34269 15.7708C3.72444 15.8992 3.08125 15.8234 2.50975 15.5549C1.93825 15.2864 1.46932 14.8397 1.1734 14.2819C0.877476 13.7242 0.770558 13.0854 0.868707 12.4617C0.966856 11.8379 1.26477 11.2629 1.71769 10.8229C2.53019 10.0312 2.92081 8.89583 2.92081 7.76562V6.45833C2.92081 5.51902 3.29395 4.61818 3.95814 3.95399C4.62233 3.2898 5.52317 2.91666 6.46248 2.91666H7.76977C8.90519 2.91666 10.0354 2.52604 10.8271 1.71354C11.267 1.26062 11.8421 0.962706 12.4658 0.864557C13.0896 0.766408 13.7283 0.873326 14.2861 1.16925C14.8439 1.46517 15.2906 1.9341 15.5591 2.5056C15.8275 3.0771 15.9033 3.72029 15.775 4.33854"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56288">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
