<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.31641 9.16675H16.181"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1185 10H17.082C17.6346 10 18.1645 10.2195 18.5552 10.6102C18.9459 11.0009 19.1654 11.5308 19.1654 12.0833C19.1654 12.6359 18.9459 13.1658 18.5552 13.5565C18.1645 13.9472 17.6346 14.1667 17.082 14.1667H15.3945"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0833 18.7502C14.3854 18.7502 16.25 13.5262 16.25 7.0835H1.25C1.25 13.5262 3.11458 18.7502 5.41667 18.7502H12.0833Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.1445 7.08341C14.4206 3.97925 11.7122 1.66675 8.49349 1.66675H8.33203C8.33203 2.32979 8.06864 2.96567 7.5998 3.43451C7.13096 3.90336 6.49507 4.16675 5.83203 4.16675H5.76953C3.69661 4.16675 2.01953 5.47404 2.01953 7.08341"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <defs>
      <clipPath id="clip0_2852_6050">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
