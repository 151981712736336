<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58644)">
      <path
        d="M9.99988 2.08315V-0.000183105"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5826 6.66644H16.666"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.41598 6.66644H3.33264"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.36938 1.86957L6.08293 3.58311"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.2499 1.83319L13.4999 3.58319"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.8332 15.8333C16.9838 15.8333 17.9165 14.9006 17.9165 13.75C17.9165 12.5994 16.9838 11.6666 15.8332 11.6666C14.6826 11.6666 13.7499 12.5994 13.7499 13.75C13.7499 14.9006 14.6826 15.8333 15.8332 15.8333Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.0826 19.9998V19.5832C12.0826 19.0306 12.3021 18.5007 12.6928 18.11C13.0835 17.7193 13.6134 17.4998 14.166 17.4998H17.4993C18.0518 17.4998 18.5817 17.7193 18.9724 18.11C19.3631 18.5007 19.5826 19.0306 19.5826 19.5832V19.9998"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.16598 15.8333C5.31657 15.8333 6.24931 14.9006 6.24931 13.75C6.24931 12.5994 5.31657 11.6666 4.16598 11.6666C3.01538 11.6666 2.08264 12.5994 2.08264 13.75C2.08264 14.9006 3.01538 15.8333 4.16598 15.8333Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M0.41626 19.9998V19.5832C0.41626 19.0306 0.635753 18.5007 1.02645 18.11C1.41715 17.7193 1.94706 17.4998 2.49959 17.4998H5.83293C6.38546 17.4998 6.91537 17.7193 7.30607 18.11C7.69677 18.5007 7.91626 19.0306 7.91626 19.5832V19.9998"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.74988 11.25H11.2499"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.10868 8.32773L8.74931 11.2496V12.4996C8.74931 12.6101 8.79321 12.7161 8.87135 12.7942C8.94949 12.8724 9.05547 12.9163 9.16597 12.9163H10.8326C10.9431 12.9163 11.0491 12.8724 11.1273 12.7942C11.2054 12.7161 11.2493 12.6101 11.2493 12.4996V11.2496L12.8899 8.33294C13.182 7.82618 13.3356 7.25148 13.3352 6.66657C13.3349 6.08167 13.1807 5.50714 12.888 5.00071C12.5954 4.49428 12.1746 4.07377 11.668 3.78141C11.1614 3.48905 10.5868 3.33514 10.0019 3.33514C9.41701 3.33514 8.8424 3.48905 8.3358 3.78141C7.8292 4.07377 7.40844 4.49428 7.1158 5.00071C6.82315 5.50714 6.66891 6.08167 6.66858 6.66657C6.66825 7.25148 6.82183 7.82618 7.11389 8.33294L7.10868 8.32773Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58644">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
