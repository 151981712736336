<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75098 1.66681H16.251V4.16681H3.75098V1.66681Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.251 4.16681L17.501 8.33348V18.3335H2.50098V8.33348L3.75098 4.16681"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M10.8343 11.2501H10.001V12.0835H10.8343V11.2501Z"
      fill="#0E0E22"
    />

    <path
      d="M9.58431 9.58344H8.75098V10.4168H9.58431V9.58344Z"
      fill="#0E0E22"
    />

    <path
      d="M6.25098 7.91681C7.33283 7.10542 8.64866 6.66681 10.001 6.66681C11.3533 6.66681 12.6691 7.10542 13.751 7.91681L10.001 15.8335L6.25098 7.91681Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.0166 9.46367C12.1814 8.73397 11.11 8.33185 10.001 8.33185C8.89194 8.33185 7.82052 8.73397 6.98535 9.46367"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
