<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58689)">
      <path
        d="M5.41593 8.33315C6.33641 8.33315 7.0826 7.58696 7.0826 6.66648C7.0826 5.74601 6.33641 4.99982 5.41593 4.99982C4.49546 4.99982 3.74927 5.74601 3.74927 6.66648C3.74927 7.58696 4.49546 8.33315 5.41593 8.33315Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.49927 12.9163V11.458C2.49927 11.0712 2.65291 10.7003 2.9264 10.4268C3.19989 10.1533 3.57083 9.99963 3.9576 9.99963H6.87427C7.26104 9.99963 7.63197 10.1533 7.90547 10.4268C8.17896 10.7003 8.3326 11.0712 8.3326 11.458V12.9163"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5826 9.58301L15.8326 10.833H17.9159V12.9163L19.1659 14.1663L17.9159 15.4163V17.4997H15.8326L14.5826 18.7497L13.3326 17.4997H11.2493V15.4163L9.99927 14.1663L11.2493 12.9163V10.833H13.3326L14.5826 9.58301Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.9158 8.75002V3.75002C17.9158 3.52901 17.828 3.31704 17.6717 3.16076C17.5154 3.00448 17.3035 2.91669 17.0824 2.91669H1.2491C1.02809 2.91669 0.81613 3.00448 0.659849 3.16076C0.503569 3.31704 0.415771 3.52901 0.415771 3.75002V15.4167C0.415771 15.6377 0.503569 15.8497 0.659849 16.0059C0.81613 16.1622 1.02809 16.25 1.2491 16.25H4.16577V15.8334C4.16577 15.5018 4.29747 15.1839 4.53189 14.9495C4.76631 14.715 5.08425 14.5834 5.41577 14.5834C5.74729 14.5834 6.06524 14.715 6.29966 14.9495C6.53408 15.1839 6.66577 15.5018 6.66577 15.8334V16.25H9.16577"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99927 5.41644H16.2493"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.99927 7.49982H16.2493"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58689">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
