<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5984)">
      <path
        d="M6.25 2.9165H13.75L14.3333 3.69255C14.7661 4.26953 15 4.97131 15 5.69255V16.8071C15 17.5284 14.7661 18.2301 14.3333 18.8071L13.75 19.5832H6.25L5.66667 18.8071C5.23393 18.2301 5 17.5284 5 16.8071V5.69255C5 4.97131 5.23393 4.26953 5.66667 3.69255L6.25 2.9165Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15 11.6613C14.1302 11.1665 13.2031 10.4165 12.0833 10.4165C10.4167 10.4165 9.58333 12.0832 7.91667 12.0832C6.79687 12.0832 5.86979 11.3332 5 10.8384"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.4844 2.9165L12.7604 0.963379"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5984">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
