<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4154 12.0833H9.58203V12.9166H10.4154V12.0833Z"
      fill="#0E0E22"
    />

    <path
      d="M10.4154 9.1665H9.58203V9.99984H10.4154V9.1665Z"
      fill="#0E0E22"
    />

    <path
      d="M13.3333 12.0833H12.5V12.9166H13.3333V12.0833Z"
      fill="#0E0E22"
    />

    <path
      d="M10.4154 15H9.58203V15.8333H10.4154V15Z"
      fill="#0E0E22"
    />

    <path
      d="M7.5013 12.0833H6.66797V12.9166H7.5013V12.0833Z"
      fill="#0E0E22"
    />

    <path
      d="M12.5013 10H11.668V10.8333H12.5013V10Z"
      fill="#0E0E22"
    />

    <path
      d="M8.33333 10H7.5V10.8333H8.33333V10Z"
      fill="#0E0E22"
    />

    <path
      d="M12.5013 14.1665H11.668V14.9998H12.5013V14.1665Z"
      fill="#0E0E22"
    />

    <path
      d="M8.33333 14.1665H7.5V14.9998H8.33333V14.1665Z"
      fill="#0E0E22"
    />

    <path
      d="M3.75 4.58301H16.25V6.66634H3.75V4.58301Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.3125 4.58293L13.75 1.66626H11.6094C11.516 2.02121 11.3077 2.33524 11.017 2.55933C10.7263 2.78342 10.3696 2.90495 10.0026 2.90495C9.63558 2.90495 9.27888 2.78342 8.9882 2.55933C8.69752 2.33524 8.48923 2.02121 8.39583 1.66626H6.25L4.6875 4.58293"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.51562 6.66626L5.89583 18.7496H14.1042L15.4844 6.66626"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
