export default {
  install: (app, options) => {
    app.config.globalProperties.$console = {
      log(...message) {
        console.log("--------------------->");
        console.log(
          `[${options.prefix}] ${message.reduce(
            (acc, curr) => `${acc} ${JSON.stringify(curr, null, 2)}`,
            ""
          )}`
        );
        console.log("--------------------->");
      },
      warn(message) {
        console.warn(message);
      },
      error(message) {
        console.error(message);
      },
    };
  },
};
