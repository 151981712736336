<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74976 4.16693H3.74976V1.66693H16.2498V4.16693H11.2498"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.2498 4.16693L17.4998 8.3336V18.3336H2.49976V8.3336L3.74976 4.16693"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.2498 1.66693V5.8336H8.74976V1.66693"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
