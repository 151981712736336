<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3341 1.66656H1.66748V14.1666H18.3341V1.66656Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.001 14.1666V19.1666"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.6675 14.1666L15.2144 19.0468"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.33447 14.1666L4.7876 19.0468"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M8.75098 7.91675H6.25098V11.2501H8.75098V7.91675Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.251 6.25012H8.75098V11.2501H11.251V6.25012Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M13.751 4.58337H11.251V11.25H13.751V4.58337Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
