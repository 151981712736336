<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="30"
    height="28"
    viewBox="0 0 30 28"
    data-code="61597"
    data-tags="credit-card"
  >
    <g
      fill="#444"
      transform="scale(0.02734375 0.02734375)"
    >
      <path
        d="M1005.714 73.143c50.286 0 91.429 41.143 91.429 91.429v694.857c0 50.286-41.143 91.429-91.429 91.429h-914.286c-50.286 0-91.429-41.143-91.429-91.429v-694.857c0-50.286 41.143-91.429 91.429-91.429h914.286zM91.429 146.286c-9.714 0-18.286 8.571-18.286 18.286v128h950.857v-128c0-9.714-8.571-18.286-18.286-18.286h-914.286zM1005.714 877.714c9.714 0 18.286-8.571 18.286-18.286v-347.429h-950.857v347.429c0 9.714 8.571 18.286 18.286 18.286h914.286zM146.286 804.571v-73.143h146.286v73.143h-146.286zM365.714 804.571v-73.143h219.429v73.143h-219.429z"
      />
    </g>
  </svg>
</template>
