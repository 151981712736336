<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 10H5C4.44771 10 4 10.4477 4 11V15C4 15.5523 4.44771 16 5 16H27C27.5523 16 28 15.5523 28 15V11C28 10.4477 27.5523 10 27 10Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26 16V25C26 25.2652 25.8946 25.5196 25.7071 25.7071C25.5196 25.8946 25.2652 26 25 26H7C6.73478 26 6.48043 25.8946 6.29289 25.7071C6.10536 25.5196 6 25.2652 6 25V16"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 10V26"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.6569 8.58581C20.2426 10 16 10 16 10C16 10 16 5.75738 17.4142 4.34317C17.9769 3.78109 18.7398 3.46547 19.5352 3.4657C20.3305 3.46592 21.0932 3.78198 21.6556 4.34438C22.218 4.90678 22.5341 5.66949 22.5343 6.46484C22.5346 7.2602 22.2189 8.02309 21.6569 8.58581V8.58581Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.3432 8.58581C11.7574 10 16 10 16 10C16 10 16 5.75738 14.5858 4.34317C14.0231 3.78109 13.2602 3.46547 12.4648 3.4657C11.6695 3.46592 10.9068 3.78198 10.3444 4.34438C9.78198 4.90678 9.46592 5.66949 9.4657 6.46484C9.46547 7.2602 9.78109 8.02309 10.3432 8.58581V8.58581Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
