<template>
  <svg
    width="13"
    height="25"
    viewBox="0 0 13 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 8L5 12L9 16"
      stroke="#A0A0A0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13 1H5C2.79086 1 1 2.79086 1 5V20C1 22.2091 2.79086 24 5 24H13"
      stroke="#A0A0A0"
    />
  </svg>
</template>
