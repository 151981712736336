<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33333 2.50004V7.16671C3.33333 9.76671 5.4 11.8334 8 11.8334C10.6 11.8334 12.6667 9.76671 12.6667 7.16671V2.50004C12.6667 2.10004 12.4 1.83337 12 1.83337C11.6 1.83337 11.3333 2.10004 11.3333 2.50004V7.16671C11.3333 9.03337 9.86667 10.5 8 10.5C6.13333 10.5 4.66667 9.03337 4.66667 7.16671V2.50004C4.66667 2.10004 4.4 1.83337 4 1.83337C3.6 1.83337 3.33333 2.10004 3.33333 2.50004ZM2 14.5C2 14.1 2.26667 13.8334 2.66667 13.8334H13.3333C13.7333 13.8334 14 14.1 14 14.5C14 14.9 13.7333 15.1667 13.3333 15.1667H2.66667C2.26667 15.1667 2 14.9 2 14.5Z"
      fill="#595A5B"
    />
  </svg>
</template>
