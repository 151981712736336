function getPathFromUri(uri) {
  const decodedUri = decodeURIComponent(uri);
  let url;
  try {
    url = new URL(decodedUri);
  } catch (error) {
    console.error("Произошла ошибка при создании объекта URL:", error);
    return "";
  }
  return "/" + url.pathname.split("/o/")[1];
}

function hexToRgb(hex) {
  if (hex.charAt && hex.charAt(0) === "#") {
    hex = removeHash(hex);
  }

  if (hex.length === 3) {
    hex = expand(hex);
  }

  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return "rgb(" + [r, g, b] + ")";
}

function removeHash(hex) {
  var arr = hex.split("");
  arr.shift();
  return arr.join("");
}

function expand(hex) {
  return hex
    .split("")
    .reduce(function (accum, value) {
      return accum.concat([value, value]);
    }, [])
    .join("");
}

/* ================================== */

function rgbToHex(color) {
  color = color.toLowerCase();
  if (typeof color !== "string") {
    // throw error of input isn't typeof string
    throw new Error("color has to be type of `string`");
  } else if (color.charAt(0) === "#") {
    // or return if already rgb color
    return color;
  }

  /**
   * strip spaces
   */
  var strippedColor = color.replace(/\s+/g, "");

  /**
   * parse input
   */
  var digits =
    /(.*?)rgb(a)??\((\d{1,3}),(\d{1,3}),(\d{1,3})(,([01]|1.0*|0??\.([0-9]{0,})))??\)/.exec(
      strippedColor
    );

  if (!digits) {
    // or throw error if input isn't a valid rgb(a) color
    throw new Error(
      "given color (" + color + ") isn't a valid rgb or rgba color"
    );
  }

  var red = parseInt(digits[3], 10);
  var green = parseInt(digits[4], 10);
  var blue = parseInt(digits[5], 10);
  // eslint-disable-next-line no-useless-escape
  var alpha = digits[6] ? /([0-9\.]+)/.exec(digits[6])[0] : "1";
  var rgb = (blue | (green << 8) | (red << 16) | (1 << 24))
    .toString(16)
    .slice(1);

  // parse alpha value into float
  if (alpha.charAt(0) === ".") {
    alpha = parseFloat("0" + alpha);
  }

  // cut alpha value after 2 digits after comma
  alpha = parseFloat(Math.round(alpha * 100)) / 100;

  return "#" + rgb.toString(16);
}
function translitRuEn(str) {
  var magic = function (lit) {
    var arrayLits = [
      ["-", ""],
      ["а", "a"],
      ["б", "b"],
      ["в", "v"],
      ["г", "g"],
      ["д", "d"],
      ["е", "e"],
      ["ё", "yo"],
      ["ж", "zh"],
      ["з", "z"],
      ["и", "i"],
      ["й", "j"],
      ["к", "k"],
      ["л", "l"],
      ["м", "m"],
      ["н", "n"],
      ["о", "o"],
      ["п", "p"],
      ["р", "r"],
      ["с", "s"],
      ["т", "t"],
      ["у", "u"],
      ["ф", "f"],
      ["х", "h"],
      ["ц", "c"],
      ["ч", "ch"],
      ["ш", "w"],
      ["щ", "shh"],
      ["ъ", ""],
      ["ы", "y"],
      ["ь", ""],
      ["э", "e"],
      ["ю", "yu"],
      ["я", "ya"],
      ["А", "A"],
      ["Б", "B"],
      ["В", "V"],
      ["Г", "G"],
      ["Д", "D"],
      ["Е", "E"],
      ["Ё", "YO"],
      ["Ж", "ZH"],
      ["З", "Z"],
      ["И", "I"],
      ["Й", "J"],
      ["К", "K"],
      ["Л", "L"],
      ["М", "M"],
      ["Н", "N"],
      ["О", "O"],
      ["П", "P"],
      ["Р", "R"],
      ["С", "S"],
      ["Т", "T"],
      ["У", "U"],
      ["Ф", "F"],
      ["Х", "H"],
      ["Ц", "C"],
      ["Ч", "CH"],
      ["Ш", "W"],
      ["Щ", "SHH"],
      ["Ъ", ""],
      ["Ы", "Y"],
      ["Ь", ""],
      ["Э", "E"],
      ["Ю", "YU"],
      ["Я", "YA"],
      ["0", "0"],
      ["1", "1"],
      ["2", "2"],
      ["3", "3"],
      ["4", "4"],
      ["5", "5"],
      ["6", "6"],
      ["7", "7"],
      ["8", "8"],
      ["9", "9"],
      ["a", "a"],
      ["b", "b"],
      ["c", "c"],
      ["d", "d"],
      ["e", "e"],
      ["f", "f"],
      ["g", "g"],
      ["h", "h"],
      ["i", "i"],
      ["j", "j"],
      ["k", "k"],
      ["l", "l"],
      ["m", "m"],
      ["n", "n"],
      ["o", "o"],
      ["p", "p"],
      ["q", "q"],
      ["r", "r"],
      ["s", "s"],
      ["t", "t"],
      ["u", "u"],
      ["v", "v"],
      ["w", "w"],
      ["x", "x"],
      ["y", "y"],
      ["z", "z"],
      ["A", "A"],
      ["B", "B"],
      ["C", "C"],
      ["D", "D"],
      ["E", "E"],
      ["F", "F"],
      ["G", "G"],
      ["H", "H"],
      ["I", "I"],
      ["J", "J"],
      ["K", "K"],
      ["L", "L"],
      ["M", "M"],
      ["N", "N"],
      ["O", "O"],
      ["P", "P"],
      ["Q", "Q"],
      ["R", "R"],
      ["S", "S"],
      ["T", "T"],
      ["U", "U"],
      ["V", "V"],
      ["W", "W"],
      ["X", "X"],
      ["Y", "Y"],
      ["Z", "Z"],
    ];
    var efim360ru = arrayLits
      .map((i) => {
        if (i[0] === lit) {
          return i[1];
        } else {
          return undefined;
        }
      })
      .filter((i) => i != undefined);
    if (efim360ru.length > 0) {
      return efim360ru[0];
    } else {
      return "";
    }
  };
  return Array.from(str)
    .map((i) => magic(i))
    .join("");
}
function customSort(a, b, field) {
  const nameA = a[field].toUpperCase();
  const nameB = b[field].toUpperCase();

  if (nameA === null || nameA === undefined) {
    return -1;
  }
  if (nameB === null || nameB === undefined) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
function shortenFileName(
  container,
  fileName,
  placeholder = "Вложите агентский договор"
) {
  if (!fileName) return placeholder;
  const maxLength = container.value / 8; // Примерное соотношение ширины контейнера и длины имени файла
  const extensionIndex = fileName?.lastIndexOf(".");
  const extension = fileName?.substring(extensionIndex);
  const fileNameWithoutExtension = fileName?.substring(0, extensionIndex);

  if (fileName?.length <= maxLength) {
    return fileName;
  }

  const remainingChars = maxLength - extension?.length - 3; // 3 для трех точек
  const truncatedName = fileNameWithoutExtension?.substring(0, remainingChars);

  return truncatedName + "..." + extension;
}

class WorkOurs {
  constructor(workDay) {
    let startMinutes =
      parseInt(workDay.startDay.hours) * 60 +
      parseInt(workDay.startDay.minutes);
    let endMinutes =
      parseInt(workDay.endDay.hours) * 60 + parseInt(workDay.endDay.minutes);
    if (endMinutes < startMinutes) endMinutes += 1440;
    /* =========================================== */
    this.start = startMinutes;
    this.end = endMinutes;
    /* =========================================== */
    this.breaks = workDay.breakes.map((brk, index) => {
      let breakStartMinutes =
        parseInt(brk.startBreake.hours) * 60 +
        parseInt(brk.startBreake.minutes);
      let breakEndMinutes =
        parseInt(brk.endBreake.hours) * 60 + parseInt(brk.endBreake.minutes);

      if (breakStartMinutes < startMinutes) breakStartMinutes += 1440;
      if (breakEndMinutes < startMinutes) breakEndMinutes += 1440;

      return {
        start: breakStartMinutes,
        end: breakEndMinutes,
        id: index,
      };
    });
    /* =========================================== */
  }
}
function validateBreaks(schedule) {
  let errorMessages = [];

  for (let i = 0; i < schedule.length; i++) {
    const workDay = schedule[i];
    const workHours = new WorkOurs(workDay);
    console.log(workHours);

    workHours.breaks.forEach((currentBreak, j) => {
      if (
        currentBreak.start < workHours.start ||
        currentBreak.end > workHours.end
      ) {
        errorMessages.push(
          `${workDay.workDay}, ${getOrdinal(
            j
          )} перерыв, не попадает в рабочие часы.`
        );
      }

      workHours.breaks.forEach((otherBreak) => {
        if (
          currentBreak.id !== otherBreak.id &&
          ((currentBreak.start >= otherBreak.start &&
            currentBreak.start < otherBreak.end) ||
            (currentBreak.end > otherBreak.start &&
              currentBreak.end <= otherBreak.end))
        ) {
          errorMessages.push(
            `${workDay.workDay}, ${getOrdinal(currentBreak.id)} и ${getOrdinal(
              otherBreak.id
            )} перерывы пересекаются.`
          );
        }
      });
    });
  }

  return errorMessages.length > 0 ? errorMessages.join("\n") : "No errors";
}
function getOrdinal(n) {
  const ordinals = [
    "первый",
    "второй",
    "третий",
    "четвертый",
    "пятый",
    "шестой",
    "седьмой",
    "восьмой",
    "девятый",
    "десятый",
    "одиннадцатый",
    "двенадцатый",
    "тринадцатый",
    "четырнадцатый",
    "пятнадцатый",
    "шестнадцатый",
    "семнадцатый",
    "восемнадцатый",
    "девятнадцатый",
    "двадцатый",
  ]; // и так далее
  return ordinals[n];
}
export {
  getPathFromUri,
  hexToRgb,
  rgbToHex,
  translitRuEn,
  customSort,
  shortenFileName,
  validateBreaks,
};
