<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9999 2.66659C8.63612 2.66659 2.66659 8.63612 2.66659 15.9999C2.66659 23.3637 8.63612 29.3333 15.9999 29.3333C23.3637 29.3333 29.3333 23.3637 29.3333 15.9999C29.3333 8.63612 23.3637 2.66659 15.9999 2.66659Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 11.3333V19.3333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 16.6665L16 20.6665L12 16.6665"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
