<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.83252 12.7082C0.83252 12.432 0.942266 12.167 1.13762 11.9717C1.33297 11.7763 1.59792 11.6666 1.87419 11.6666H18.1242C18.4005 11.6666 18.6654 11.7763 18.8608 11.9717C19.0561 12.167 19.1659 12.432 19.1659 12.7082C19.1659 12.9845 19.0561 13.2495 18.8608 13.4448C18.6654 13.6402 18.4005 13.7499 18.1242 13.7499H1.87419C1.59792 13.7499 1.33297 13.6402 1.13762 13.4448C0.942266 13.2495 0.83252 12.9845 0.83252 12.7082V12.7082Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.0096 13.7498L17.4106 18.3332H2.59294L1.98877 13.7498"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M8.33236 3.74988H7.49902V4.58321H8.33236V3.74988Z"
      fill="#0E0E22"
    />

    <path
      d="M12.4989 3.33313H11.6655V4.16646H12.4989V3.33313Z"
      fill="#0E0E22"
    />

    <path
      d="M10.8324 4.99982H9.99902V5.83315H10.8324V4.99982Z"
      fill="#0E0E22"
    />

    <path
      d="M3.33154 11.6667V9.16669"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.665 9.16669V11.6667"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0503 9.16671H3.12321C2.84694 9.16671 2.58199 9.05696 2.38664 8.86161C2.19129 8.66626 2.08154 8.40131 2.08154 8.12504C2.08154 7.84877 2.19129 7.58382 2.38664 7.38847C2.58199 7.19312 2.84694 7.08337 3.12321 7.08337H16.8732C17.1495 7.08337 17.4144 7.19312 17.6098 7.38847C17.8051 7.58382 17.9149 7.84877 17.9149 8.12504C17.9149 8.40131 17.8051 8.66626 17.6098 8.86161C17.4144 9.05696 17.1495 9.16671 16.8732 9.16671H12.4513"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.6649 7.08335V6.87502C16.6649 4.00002 13.6805 1.66669 9.99821 1.66669C6.31592 1.66669 3.33154 4.00002 3.33154 6.87502V7.08335"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.66504 7.08337C7.20398 7.08304 7.7265 7.26886 8.14421 7.60942L11.2484 10.1459L14.3525 7.60942C14.7702 7.26886 15.2928 7.08304 15.8317 7.08337"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
