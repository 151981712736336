<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 12.9167H15"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M1.72266 10H15.7747"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.1185 7.5H17.082C17.6346 7.5 18.1645 7.71949 18.5552 8.11019C18.9459 8.50089 19.1654 9.0308 19.1654 9.58333C19.1654 10.1359 18.9459 10.6658 18.5552 11.0565C18.1645 11.4472 17.6346 11.6667 17.082 11.6667H15.3945"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.0625 8.052C15.1458 7.91659 14.0573 7.08325 12.5 7.08325C10.8333 7.08325 10.4167 7.91659 8.75 7.91659C7.08333 7.91659 6.66667 7.08325 5 7.08325C3.44271 7.08325 2.35417 7.92179 1.4375 8.052"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0833 16.2499C14.3854 16.2499 16.25 11.026 16.25 4.58325H1.25C1.25 11.026 3.11458 16.2499 5.41667 16.2499H12.0833Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
