<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_6008)">
      <path
        d="M6.75 17.9166L4.5 3.33325H14.6667L12.4167 17.9166"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5 18.7498C5 18.5288 5.0878 18.3169 5.24408 18.1606C5.40036 18.0043 5.61232 17.9165 5.83333 17.9165H13.3333C13.5543 17.9165 13.7663 18.0043 13.9226 18.1606C14.0789 18.3169 14.1667 18.5288 14.1667 18.7498C14.1667 18.9709 14.0789 19.1828 13.9226 19.3391C13.7663 19.4954 13.5543 19.5832 13.3333 19.5832H5.83333C5.61232 19.5832 5.40036 19.4954 5.24408 19.3391C5.0878 19.1828 5 18.9709 5 18.7498Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.6667 3.33321C13.2828 2.5803 12.6983 1.94817 11.9777 1.50669C11.2571 1.0652 10.4284 0.831543 9.58333 0.831543C8.73823 0.831543 7.90958 1.0652 7.18897 1.50669C6.46835 1.94817 5.88383 2.5803 5.5 3.33321"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.7044 9.58325H15.1628C15.3323 9.58342 15.4995 9.6232 15.651 9.69943C15.8025 9.77566 15.9341 9.88622 16.0353 10.0223C16.1364 10.1584 16.2044 10.3162 16.2338 10.4833C16.2632 10.6503 16.2531 10.8219 16.2044 10.9843L15.2357 14.2187C15.1685 14.4428 15.0309 14.6393 14.8433 14.7791C14.6557 14.9189 14.428 14.9945 14.194 14.9947H12.8711"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.88672 5.83325H14.2825"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_6008">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
