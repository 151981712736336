<template>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12_2619)">
      <path
        d="M148 0H12.0001C5.37271 0 0.00012207 5.37258 0.00012207 12V148C0.00012207 154.627 5.3727 160 12.0001 160H148C154.628 160 160 154.627 160 148V12C160 5.37258 154.628 0 148 0Z"
        fill="white"
      />
      <path
        d="M60.6898 14.6551C60.6898 15.1796 60.5047 15.6268 60.1348 15.9967C59.7649 16.3666 59.3178 16.5517 58.7932 16.5517H57.0691C56.5447 16.5517 56.0975 16.3666 55.7276 15.9967C55.3576 15.6268 55.1725 15.1796 55.1725 14.6551V12.931C55.1725 12.4066 55.3575 11.9594 55.7275 11.5895C56.0976 11.2195 56.5447 11.0344 57.0691 11.0344H58.7932C59.3178 11.0344 59.765 11.2194 60.1348 11.5894C60.5047 11.9594 60.6898 12.4066 60.6898 12.931V14.6551Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 12.931V11.0344H68.1036C67.5792 11.0344 67.1319 11.2194 66.762 11.5894C66.3921 11.9594 66.207 12.4066 66.207 12.931V14.6551C66.207 15.1795 66.392 15.6268 66.762 15.9967C67.132 16.3666 67.5792 16.5517 68.1036 16.5517H71.7243V12.931Z"
        fill="#0E0E22"
      />
      <path
        d="M75.3449 11.0344H71.7242V16.5517H75.3449C75.8693 16.5517 76.3166 16.3666 76.6865 15.9967C77.0564 15.6268 77.2415 15.1795 77.2415 14.6551V12.931C77.2415 12.4066 77.0564 11.9593 76.6865 11.5894C76.3166 11.2195 75.8693 11.0344 75.3449 11.0344Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 12.931V11.0344H90.1726C89.6481 11.0344 89.2009 11.2194 88.831 11.5894C88.461 11.9594 88.276 12.4066 88.276 12.931V16.5517H93.7932V12.931Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 11.0344H93.7932V16.5517H99.3105V11.0344Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 12.931C104.828 12.4066 104.643 11.9593 104.273 11.5894C103.903 11.2195 103.456 11.0344 102.931 11.0344H99.3105V16.5517H104.828V12.931Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 20.1724C66.207 20.697 66.0219 21.1441 65.652 21.514C65.2821 21.8839 64.835 22.069 64.3104 22.069H62.5863C62.062 22.069 61.6147 21.8839 61.2448 21.514C60.8748 21.1441 60.6898 20.697 60.6898 20.1724V18.4483C60.6898 17.924 60.8748 17.4767 61.2447 17.1068C61.6148 16.7368 62.062 16.5518 62.5863 16.5518H64.3104C64.835 16.5518 65.2822 16.7368 65.652 17.1067C66.022 17.4768 66.207 17.924 66.207 18.4483V20.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 18.4483V16.5518H79.1381C78.6137 16.5518 78.1664 16.7368 77.7965 17.1067C77.4266 17.4767 77.2415 17.9239 77.2415 18.4483V20.1724C77.2415 20.6969 77.4265 21.1441 77.7965 21.514C78.1665 21.884 78.6137 22.069 79.1381 22.069H82.7588V18.4483Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 16.5518H82.7587V22.069H88.276V16.5518Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 16.5518H88.276V22.069H93.7932V16.5518Z"
        fill="#0E0E22"
      />
      <path
        d="M104.817 18.319H104.828V16.5518H99.3105V18.319H99.3212C99.3141 18.3765 99.3105 18.4196 99.3105 18.4483V20.1724C99.3105 20.6969 99.4955 21.1441 99.8655 21.514C100.235 21.884 100.683 22.069 101.207 22.069H102.931C103.456 22.069 103.903 21.884 104.273 21.514C104.643 21.1441 104.828 20.6969 104.828 20.1724V18.4483C104.828 18.4196 104.824 18.3765 104.817 18.319Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 25.6895C60.6898 26.2141 60.5047 26.6612 60.1348 27.0311C59.7649 27.401 59.3178 27.5861 58.7932 27.5861H57.0691C56.5447 27.5861 56.0975 27.401 55.7276 27.0311C55.3576 26.6612 55.1725 26.2141 55.1725 25.6895V23.9654C55.1725 23.441 55.3575 22.9938 55.7275 22.6239C56.0976 22.2539 56.5447 22.0688 57.0691 22.0688H58.7932C59.3178 22.0688 59.765 22.2538 60.1348 22.6238C60.5047 22.9939 60.6898 23.441 60.6898 23.9654V25.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 25.6895C77.2415 26.2141 77.0564 26.6612 76.6865 27.0311C76.3166 27.401 75.8695 27.5861 75.3449 27.5861H73.6208C73.0964 27.5861 72.6492 27.401 72.2793 27.0311C71.9093 26.6612 71.7242 26.2141 71.7242 25.6895V23.9654C71.7242 23.441 71.9092 22.9938 72.2792 22.6239C72.6493 22.2539 73.0964 22.0688 73.6208 22.0688H75.3449C75.8695 22.0688 76.3167 22.2538 76.6865 22.6238C77.0564 22.9939 77.2415 23.441 77.2415 23.9654V25.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 22.0688H88.276V27.5861H93.7932V22.0688Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 29.4827V27.5862H62.5863C62.0619 27.5862 61.6147 27.7712 61.2447 28.1412C60.8748 28.5111 60.6898 28.9583 60.6898 29.4827V31.2069C60.6898 31.7313 60.8748 32.1785 61.2447 32.5484C61.6147 32.9184 62.0619 33.1034 62.5863 33.1034H66.207V29.4827Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 29.4827C71.7243 28.9583 71.5392 28.5111 71.1693 28.1412C70.7994 27.7712 70.3521 27.5862 69.8277 27.5862H66.207V33.1034H71.7243V29.4827Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 27.5862H88.276V33.1034H93.7932V27.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M97.4139 27.5862H93.7932V33.1034H97.4139C97.9383 33.1034 98.3855 32.9184 98.7555 32.5484C99.1254 32.1785 99.3105 31.7313 99.3105 31.2069V29.4827C99.3105 28.9583 99.1254 28.5111 98.7555 28.1412C98.3855 27.7712 97.9383 27.5862 97.4139 27.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7135 34.8705H71.7243V33.1033H66.207V34.8705H66.2178C66.2106 34.928 66.207 34.9711 66.207 34.9998V36.724C66.207 37.2484 66.392 37.6956 66.762 38.0655C67.132 38.4355 67.5792 38.6205 68.1036 38.6205H69.8277C70.3521 38.6205 70.7994 38.4355 71.1693 38.0655C71.5392 37.6956 71.7243 37.2484 71.7243 36.724V34.9998C71.7243 34.9711 71.7208 34.928 71.7135 34.8705Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 34.9998V33.1033H79.1381C78.6137 33.1033 78.1664 33.2883 77.7965 33.6583C77.4266 34.0282 77.2415 34.4754 77.2415 34.9998V38.6205H82.7588V34.9998Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 33.1033H82.7587V38.6205H88.276V33.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 33.1033H88.276V38.6205H91.8967C92.4211 38.6205 92.8683 38.4355 93.2383 38.0655C93.6082 37.6956 93.7932 37.2484 93.7932 36.724V33.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 34.9998C104.828 34.4754 104.643 34.0282 104.273 33.6583C103.903 33.2883 103.456 33.1033 102.931 33.1033H101.207C100.683 33.1033 100.235 33.2883 99.8655 33.6583C99.4955 34.0282 99.3105 34.4754 99.3105 34.9998V36.724C99.3105 36.7527 99.3141 36.7959 99.3212 36.8533H99.3105V38.6205H104.828V36.8533H104.817C104.824 36.7959 104.828 36.7528 104.828 36.724V34.9998Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 40.5172C60.6898 39.9927 60.5047 39.5455 60.1348 39.1756C59.7649 38.8057 59.3176 38.6206 58.7932 38.6206H57.0691C56.5447 38.6206 56.0975 38.8056 55.7275 39.1756C55.3576 39.5456 55.1725 39.9927 55.1725 40.5172V42.2413C55.1725 42.27 55.1761 42.3132 55.1833 42.3706H55.1725V44.1378H60.6898V42.3706H60.679C60.6863 42.3132 60.6898 42.2701 60.6898 42.2413V40.5172Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 38.6206H77.2415V44.1378H82.7588V38.6206Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 38.6206H99.3105V44.1378H104.828V38.6206Z"
        fill="#0E0E22"
      />
      <path
        d="M60.679 45.9052H60.6898V44.1379H55.1725V45.9052H55.1833C55.1761 45.9627 55.1725 46.0057 55.1725 46.0345V47.7586C55.1725 48.283 55.3575 48.7303 55.7275 49.1002C56.0975 49.4701 56.5447 49.6552 57.0691 49.6552H58.7932C59.3176 49.6552 59.7649 49.4701 60.1348 49.1002C60.5047 48.7303 60.6898 48.283 60.6898 47.7586V46.0345C60.6898 46.0057 60.6863 45.9627 60.679 45.9052Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 47.7586C71.7243 48.2832 71.5392 48.7303 71.1693 49.1002C70.7994 49.4701 70.3522 49.6552 69.8277 49.6552H68.1036C67.5792 49.6552 67.132 49.4701 66.7621 49.1002C66.3921 48.7303 66.207 48.2832 66.207 47.7586V46.0345C66.207 45.5101 66.392 45.0629 66.762 44.693C67.1321 44.323 67.5792 44.1379 68.1036 44.1379H69.8277C70.3523 44.1379 70.7995 44.3229 71.1693 44.6929C71.5392 45.063 71.7243 45.5101 71.7243 46.0345V47.7586Z"
        fill="#0E0E22"
      />
      <path
        d="M82.748 45.9052H82.7588V44.1379H77.2415V45.9052H77.2523C77.2451 45.9627 77.2415 46.0057 77.2415 46.0345V47.7586C77.2415 48.283 77.4265 48.7303 77.7965 49.1002C78.1665 49.4701 78.6137 49.6552 79.1381 49.6552H80.8622C81.3866 49.6552 81.8338 49.4701 82.2038 49.1002C82.5737 48.7303 82.7588 48.283 82.7588 47.7586V46.0345C82.7588 46.0057 82.7553 45.9627 82.748 45.9052Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 46.0345C93.7932 45.5101 93.6082 45.0628 93.2383 44.6929C92.8683 44.323 92.4211 44.1379 91.8967 44.1379H90.1726C89.6481 44.1379 89.2009 44.3229 88.831 44.6929C88.461 45.0629 88.276 45.5101 88.276 46.0345V47.7586C88.276 47.7874 88.2796 47.8305 88.2868 47.888H88.276V49.6552H93.7932V47.888H93.7825C93.7898 47.8305 93.7932 47.7874 93.7932 47.7586V46.0345Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 44.1379H99.3105V49.6552H104.828V44.1379Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 53.2757C66.207 53.8002 66.0219 54.2474 65.652 54.6173C65.2821 54.9872 64.835 55.1723 64.3104 55.1723H62.5863C62.062 55.1723 61.6147 54.9872 61.2448 54.6173C60.8748 54.2474 60.6898 53.8002 60.6898 53.2757V51.5516C60.6898 51.0272 60.8748 50.58 61.2447 50.2101C61.6148 49.8401 62.062 49.655 62.5863 49.655H64.3104C64.835 49.655 65.2822 49.84 65.652 50.21C66.022 50.58 66.207 51.0272 66.207 51.5516V53.2757Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 51.5516C77.2415 51.0272 77.0564 50.5799 76.6865 50.21C76.3166 49.8401 75.8693 49.655 75.3449 49.655H73.6208C73.0964 49.655 72.6492 49.84 72.2792 50.21C71.9093 50.58 71.7242 51.0272 71.7242 51.5516V53.2757C71.7242 53.3045 71.7278 53.3476 71.735 53.405H71.7242V55.1723H77.2415V53.405H77.2307C77.238 53.3476 77.2415 53.3045 77.2415 53.2757V51.5516Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 49.655H88.276V55.1723H93.7932V49.655Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 49.655H93.7932V55.1723H99.3105V49.655Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 49.655H99.3105V55.1723H104.828V49.655Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5518 57.0689C16.5518 56.5445 16.3668 56.0973 15.9969 55.7273C15.6269 55.3574 15.1797 55.1724 14.6553 55.1724H12.9312C12.4067 55.1724 11.9595 55.3574 11.5896 55.7273C11.2197 56.0973 11.0346 56.5445 11.0346 57.0689V58.7931C11.0346 58.8218 11.0382 58.8649 11.0454 58.9224H11.0346V60.6896H16.5518V58.9224H16.5411C16.5484 58.8649 16.5518 58.8219 16.5518 58.7931V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M27.5863 57.0689C27.5863 56.5445 27.4013 56.0973 27.0314 55.7273C26.6614 55.3574 26.2142 55.1724 25.6898 55.1724H23.9656C23.4412 55.1724 22.994 55.3574 22.6241 55.7273C22.2541 56.0973 22.0691 56.5445 22.0691 57.0689V58.7931C22.0691 58.8218 22.0727 58.8649 22.0799 58.9224H22.0691V60.6896H27.5863V58.9224H27.5756C27.5829 58.8649 27.5863 58.8219 27.5863 58.7931V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M49.6553 57.0689V55.1724H46.0346C45.5102 55.1724 45.063 55.3574 44.693 55.7273C44.3231 56.0973 44.1381 56.5445 44.1381 57.0689V58.7931C44.1381 59.3175 44.3231 59.7647 44.693 60.1346C45.063 60.5046 45.5102 60.6896 46.0346 60.6896H49.6553V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 57.0689C55.1725 56.5445 54.9875 56.0973 54.6175 55.7273C54.2476 55.3574 53.8004 55.1724 53.276 55.1724H49.6553V60.6896H55.1725V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 57.0689V55.1724H68.1036C67.5792 55.1724 67.1319 55.3574 66.762 55.7273C66.3921 56.0973 66.207 56.5445 66.207 57.0689V60.6896H71.7243V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 55.1724H71.7242V60.6896H77.2415V55.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 55.1724H77.2415V60.6896H82.7588V55.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 55.1724H82.7587V60.6896H88.276V55.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 55.1724H88.276V60.6896H93.7932V55.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 55.1724H99.3105V60.6896H104.828V55.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 57.0689C121.379 56.5445 121.194 56.0973 120.824 55.7273C120.455 55.3574 120.007 55.1724 119.483 55.1724H117.759C117.234 55.1724 116.787 55.3574 116.417 55.7273C116.047 56.0973 115.862 56.5445 115.862 57.0689V58.7931C115.862 58.8218 115.866 58.8649 115.873 58.9224H115.862V60.6896H121.379V58.9224H121.369C121.376 58.8649 121.379 58.8219 121.379 58.7931V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M137.931 58.7931C137.931 59.3176 137.746 59.7647 137.376 60.1346C137.006 60.5045 136.559 60.6896 136.035 60.6896H134.31C133.786 60.6896 133.339 60.5045 132.969 60.1346C132.599 59.7647 132.414 59.3176 132.414 58.7931V57.0689C132.414 56.5446 132.599 56.0973 132.969 55.7274C133.339 55.3574 133.786 55.1724 134.31 55.1724H136.035C136.559 55.1724 137.006 55.3574 137.376 55.7273C137.746 56.0974 137.931 56.5446 137.931 57.0689V58.7931Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 57.0689C148.966 56.5445 148.781 56.0973 148.411 55.7273C148.041 55.3574 147.593 55.1724 147.069 55.1724H145.345C144.821 55.1724 144.373 55.3574 144.003 55.7273C143.633 56.0973 143.448 56.5445 143.448 57.0689V58.7931C143.448 58.8218 143.452 58.8649 143.459 58.9224H143.448V60.6896H148.966V58.9224H148.955C148.962 58.8649 148.966 58.8219 148.966 58.7931V57.0689Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5518 60.6897H11.0346V66.2069H16.5518V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M22.0691 60.6897H16.5518V66.2069H22.0691V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M27.5863 60.6897H22.0691V66.2069H27.5863V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M31.207 60.6897H27.5863V66.2069H31.207C31.7314 66.2069 32.1786 66.0219 32.5486 65.652C32.9185 65.282 33.1035 64.8348 33.1035 64.3104V62.5862C33.1035 62.0618 32.9185 61.6146 32.5486 61.2447C32.1786 60.8747 31.7314 60.6897 31.207 60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 62.5862C44.138 62.0618 43.953 61.6146 43.5831 61.2447C43.2131 60.8747 42.7659 60.6897 42.2415 60.6897H40.5173C39.9929 60.6897 39.5457 60.8747 39.1758 61.2447C38.8058 61.6147 38.6208 62.0618 38.6208 62.5862V64.3104C38.6208 64.3391 38.6244 64.3823 38.6315 64.4397H38.6208V66.2069H44.138V64.4397H44.1273C44.1346 64.3823 44.138 64.3392 44.138 64.3104V62.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 60.6897H49.6553V66.2069H55.1725V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M58.7932 60.6897H55.1725V66.2069H58.7932C59.3176 66.2069 59.7649 66.0219 60.1348 65.652C60.5047 65.282 60.6898 64.8348 60.6898 64.3104V62.5862C60.6898 62.0618 60.5047 61.6146 60.1348 61.2447C59.7649 60.8747 59.3176 60.6897 58.7932 60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 60.6897H66.207V66.2069H71.7243V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 60.6897H71.7242V66.2069H77.2415V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 60.6897H82.7587V64.3104C82.7587 64.8348 82.9437 65.282 83.3137 65.652C83.6837 66.0219 84.1309 66.2069 84.6553 66.2069H88.276V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 60.6897H88.276V66.2069H93.7932V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 60.6897H93.7932V66.2069H99.3105V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 60.6897H99.3105V66.2069H102.931C103.456 66.2069 103.903 66.0219 104.273 65.652C104.643 65.282 104.828 64.8348 104.828 64.3104V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 62.5862V60.6897H112.242C111.717 60.6897 111.27 60.8747 110.9 61.2447C110.53 61.6147 110.345 62.0618 110.345 62.5862V66.2069H115.862V62.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 60.6897H115.862V66.2069H119.483C120.007 66.2069 120.455 66.0219 120.824 65.652C121.194 65.282 121.379 64.8348 121.379 64.3104V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M132.414 62.5862C132.414 62.0618 132.229 61.6146 131.859 61.2447C131.489 60.8747 131.042 60.6897 130.517 60.6897H128.793C128.269 60.6897 127.822 60.8747 127.452 61.2447C127.082 61.6147 126.897 62.0618 126.897 62.5862V64.3104C126.897 64.3391 126.9 64.3823 126.907 64.4397H126.897V66.2069H132.414V64.4397H132.403C132.41 64.3823 132.414 64.3392 132.414 64.3104V62.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 62.5862V60.6897H139.828C139.303 60.6897 138.856 60.8747 138.486 61.2447C138.116 61.6147 137.931 62.0618 137.931 62.5862V64.3104C137.931 64.8348 138.116 65.282 138.486 65.652C138.856 66.0219 139.303 66.2069 139.828 66.2069H143.448V62.5862Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 60.6897H143.448V66.2069H148.966V60.6897Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5518 66.2068H11.0346V71.724H16.5518V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M27.5756 67.974H27.5863V66.2068H22.0691V67.974H22.0799C22.0727 68.0315 22.0691 68.0746 22.0691 68.1033V69.8275C22.0691 70.3519 22.2541 70.7991 22.6241 71.169C22.9941 71.539 23.4412 71.724 23.9656 71.724H25.6898C26.2142 71.724 26.6614 71.539 27.0314 71.169C27.4013 70.7991 27.5863 70.3519 27.5863 69.8275V68.1033C27.5863 68.0746 27.5829 68.0315 27.5756 67.974Z"
        fill="#0E0E22"
      />
      <path
        d="M38.6208 68.1033V66.2068H35.0001C34.4757 66.2068 34.0285 66.3918 33.6586 66.7618C33.2886 67.1318 33.1036 67.5789 33.1036 68.1033V69.8275C33.1036 70.3519 33.2886 70.7991 33.6586 71.169C34.0285 71.539 34.4757 71.724 35.0001 71.724H38.6208V68.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 66.2068H38.6208V71.724H44.138V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M49.6553 66.2068H44.1381V71.724H49.6553V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 66.2068H49.6553V71.724H55.1725V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 68.1033V66.2068H62.5863C62.0619 66.2068 61.6147 66.3918 61.2447 66.7618C60.8748 67.1318 60.6898 67.5789 60.6898 68.1033V69.8275C60.6898 70.3519 60.8748 70.7991 61.2447 71.169C61.6147 71.539 62.0619 71.724 62.5863 71.724H66.207V68.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 66.2068H66.207V71.724H71.7243V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 66.2068H71.7242V71.724H75.3449C75.8693 71.724 76.3166 71.539 76.6865 71.169C77.0564 70.7991 77.2415 70.3519 77.2415 69.8275V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M99.2997 67.974H99.3105V66.2068H93.7932V67.974H93.804C93.7968 68.0315 93.7932 68.0746 93.7932 68.1033V69.8275C93.7932 70.3519 93.9782 70.7991 94.3482 71.169C94.7182 71.539 95.1654 71.724 95.6898 71.724H97.4139C97.9383 71.724 98.3855 71.539 98.7555 71.169C99.1254 70.7991 99.3105 70.3519 99.3105 69.8275V68.1033C99.3105 68.0746 99.307 68.0315 99.2997 67.974Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 68.1033V66.2068H106.724C106.2 66.2068 105.753 66.3918 105.383 66.7618C105.013 67.1318 104.828 67.5789 104.828 68.1033V69.8275C104.828 70.3519 105.013 70.7991 105.383 71.169C105.753 71.539 106.2 71.724 106.724 71.724H110.345V68.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 66.2068H110.345V71.724H115.862V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 68.1033V66.2068H123.276C122.752 66.2068 122.304 66.3918 121.934 66.7618C121.565 67.1318 121.379 67.5789 121.379 68.1033V69.8275C121.379 70.3519 121.564 70.7991 121.934 71.169C122.304 71.539 122.752 71.724 123.276 71.724H126.897V68.1033Z"
        fill="#0E0E22"
      />
      <path
        d="M132.414 66.2068H126.897V71.724H132.414V66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M136.035 66.2068H132.414V71.724H136.035C136.559 71.724 137.006 71.539 137.376 71.169C137.746 70.7991 137.931 70.3519 137.931 69.8275V68.1033C137.931 67.5789 137.746 67.1317 137.376 66.7618C137.006 66.3918 136.559 66.2068 136.035 66.2068Z"
        fill="#0E0E22"
      />
      <path
        d="M148.955 67.974H148.966V66.2068H143.448V67.974H143.459C143.452 68.0315 143.448 68.0746 143.448 68.1033V69.8275C143.448 70.3519 143.633 70.7991 144.003 71.169C144.373 71.539 144.821 71.724 145.345 71.724H147.069C147.593 71.724 148.041 71.539 148.411 71.169C148.781 70.7991 148.966 70.3519 148.966 69.8275V68.1033C148.966 68.0746 148.962 68.0315 148.955 67.974Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5411 73.4913H16.5518V71.7241H11.0346V73.4913H11.0454C11.0382 73.5488 11.0346 73.5919 11.0346 73.6207V75.3448C11.0346 75.8692 11.2196 76.3165 11.5896 76.6864C11.9596 77.0563 12.4067 77.2414 12.9312 77.2414H14.6553C15.1797 77.2414 15.6269 77.0563 15.9969 76.6864C16.3668 76.3165 16.5518 75.8692 16.5518 75.3448V73.6207C16.5518 73.5919 16.5484 73.5488 16.5411 73.4913Z"
        fill="#0E0E22"
      />
      <path
        d="M33.1035 75.3448C33.1035 75.8693 32.9184 76.3165 32.5486 76.6864C32.1786 77.0563 31.7315 77.2414 31.207 77.2414H29.4829C28.9585 77.2414 28.5113 77.0563 28.1413 76.6864C27.7714 76.3165 27.5863 75.8693 27.5863 75.3448V73.6207C27.5863 73.0963 27.7713 72.6491 28.1413 72.2792C28.5113 71.9092 28.9585 71.7241 29.4829 71.7241H31.207C31.7316 71.7241 32.1787 71.9091 32.5486 72.2791C32.9185 72.6491 33.1035 73.0963 33.1035 73.6207V75.3448Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 71.7241H38.6208V77.2414H44.138V71.7241Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 71.7241H49.6553V77.2414H55.1725V71.7241Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 73.6207C60.6898 73.0963 60.5047 72.649 60.1348 72.2791C59.7649 71.9092 59.3176 71.7241 58.7932 71.7241H55.1725V77.2414H60.6898V73.6207Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7135 73.4913H71.7243V71.7241H66.207V73.4913H66.2178C66.2106 73.5488 66.207 73.5919 66.207 73.6207V75.3448C66.207 75.8692 66.392 76.3165 66.762 76.6864C67.132 77.0563 67.5792 77.2414 68.1036 77.2414H69.8277C70.3521 77.2414 70.7994 77.0563 71.1693 76.6864C71.5392 76.3165 71.7243 75.8692 71.7243 75.3448V73.6207C71.7243 73.5919 71.7208 73.5488 71.7135 73.4913Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 73.6207V71.7241H79.1381C78.6137 71.7241 78.1664 71.9091 77.7965 72.2791C77.4266 72.6491 77.2415 73.0963 77.2415 73.6207V77.2414H82.7588V73.6207Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 73.6207C88.276 73.0963 88.0909 72.649 87.721 72.2791C87.3511 71.9092 86.9038 71.7241 86.3794 71.7241H82.7587V77.2414H88.276V73.6207Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 71.7241H110.345V77.2414H115.862V71.7241Z"
        fill="#0E0E22"
      />
      <path
        d="M132.403 73.4913H132.414V71.7241H126.897V73.4913H126.907C126.9 73.5488 126.897 73.5919 126.897 73.6207V75.3448C126.897 75.8692 127.082 76.3165 127.452 76.6864C127.822 77.0563 128.269 77.2414 128.793 77.2414H130.517C131.042 77.2414 131.489 77.0563 131.859 76.6864C132.229 76.3165 132.414 75.8692 132.414 75.3448V73.6207C132.414 73.5919 132.41 73.5488 132.403 73.4913Z"
        fill="#0E0E22"
      />
      <path
        d="M22.0691 80.8619C22.0691 81.3864 21.884 81.8335 21.5141 82.2035C21.1441 82.5733 20.697 82.7585 20.1725 82.7585H18.4484C17.924 82.7585 17.4768 82.5733 17.1069 82.2035C16.7369 81.8335 16.5518 81.3864 16.5518 80.8619V79.1378C16.5518 78.6134 16.7368 78.1662 17.1068 77.7962C17.4768 77.4263 17.924 77.2412 18.4484 77.2412H20.1725C20.6971 77.2412 21.1443 77.4262 21.5141 77.7962C21.884 78.1662 22.0691 78.6134 22.0691 79.1378V80.8619Z"
        fill="#0E0E22"
      />
      <path
        d="M38.6208 79.1378V77.2412H35.0001C34.4757 77.2412 34.0285 77.4262 33.6586 77.7962C33.2886 78.1662 33.1036 78.6133 33.1036 79.1378V80.8619C33.1036 81.3863 33.2886 81.8335 33.6586 82.2035C34.0285 82.5734 34.4757 82.7585 35.0001 82.7585H38.6208V79.1378Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 77.2412H38.6208V82.7585H44.138V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M49.6553 77.2412H44.1381V82.7585H49.6553V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 77.2412H49.6553V82.7585H55.1725V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 77.2412H55.1725V82.7585H60.6898V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 79.1378C66.207 78.6133 66.022 78.1661 65.652 77.7962C65.2821 77.4263 64.8349 77.2412 64.3104 77.2412H60.6898V82.7585H66.207V79.1378Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 77.2412H77.2415V82.7585H82.7588V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 77.2412H82.7587V82.7585H86.3794C86.9038 82.7585 87.3511 82.5734 87.721 82.2035C88.0909 81.8335 88.276 81.3863 88.276 80.8619V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 79.1378C104.828 78.6133 104.643 78.1661 104.273 77.7962C103.903 77.4263 103.456 77.2412 102.931 77.2412H101.207C100.683 77.2412 100.235 77.4262 99.8655 77.7962C99.4955 78.1662 99.3105 78.6133 99.3105 79.1378V80.8619C99.3105 80.8906 99.3141 80.9338 99.3212 80.9912H99.3105V82.7585H104.828V80.9912H104.817C104.824 80.9338 104.828 80.8907 104.828 80.8619V79.1378Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 77.2412H110.345V82.7585H115.862V77.2412Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 79.1378C148.966 78.6133 148.781 78.1661 148.411 77.7962C148.041 77.4263 147.593 77.2412 147.069 77.2412H145.345C144.821 77.2412 144.373 77.4262 144.003 77.7962C143.633 78.1662 143.448 78.6133 143.448 79.1378V80.8619C143.448 80.8906 143.452 80.9338 143.459 80.9912H143.448V82.7585H148.966V80.9912H148.955C148.962 80.9338 148.966 80.8907 148.966 80.8619V79.1378Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 82.7585H38.6208V88.2758H44.138V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 82.7585H55.1725V86.3792C55.1725 86.9036 55.3575 87.3509 55.7275 87.7208C56.0975 88.0907 56.5447 88.2758 57.0691 88.2758H60.6898V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 82.7585H60.6898V88.2758H64.3104C64.8349 88.2758 65.2821 88.0907 65.652 87.7208C66.022 87.3509 66.207 86.9036 66.207 86.3792V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 82.7585H77.2415V88.2758H82.7588V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 84.6551V82.7585H95.6898C95.1654 82.7585 94.7181 82.9435 94.3482 83.3135C93.9783 83.6835 93.7932 84.1307 93.7932 84.6551V88.2758H99.3105V84.6551Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 82.7585H99.3105V88.2758H104.828V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 82.7585H110.345V88.2758H115.862V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M119.483 82.7585H115.862V88.2758H119.483C120.007 88.2758 120.455 88.0907 120.824 87.7208C121.194 87.3509 121.379 86.9036 121.379 86.3792V84.6551C121.379 84.1307 121.194 83.6835 120.824 83.3135C120.455 82.9436 120.007 82.7585 119.483 82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 84.6551V82.7585H139.828C139.303 82.7585 138.856 82.9435 138.486 83.3135C138.116 83.6835 137.931 84.1307 137.931 84.6551V86.3792C137.931 86.9036 138.116 87.3509 138.486 87.7208C138.856 88.0907 139.303 88.2758 139.828 88.2758H143.448V84.6551Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 82.7585H143.448V88.2758H148.966V82.7585Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5518 90.1724V88.2759H12.9312C12.4067 88.2759 11.9595 88.4609 11.5896 88.8309C11.2197 89.2008 11.0346 89.648 11.0346 90.1724V91.8966C11.0346 92.421 11.2196 92.8682 11.5896 93.2381C11.9596 93.6081 12.4067 93.7931 12.9312 93.7931H16.5518V90.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M22.0691 88.2759H16.5518V93.7931H22.0691V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M25.6898 88.2759H22.0691V93.7931H25.6898C26.2142 93.7931 26.6614 93.6081 27.0314 93.2381C27.4013 92.8682 27.5863 92.421 27.5863 91.8966V90.1724C27.5863 89.648 27.4013 89.2008 27.0314 88.8309C26.6614 88.4609 26.2142 88.2759 25.6898 88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M44.138 88.2759H38.6208V91.8966C38.6208 92.421 38.8058 92.8682 39.1758 93.2381C39.5458 93.6081 39.9929 93.7931 40.5173 93.7931H44.138V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M49.6553 88.2759H44.1381V93.7931H49.6553V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1725 90.1724C55.1725 89.648 54.9875 89.2008 54.6175 88.8309C54.2476 88.4609 53.8004 88.2759 53.276 88.2759H49.6553V93.7931H55.1725V90.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 88.2759H77.2415V93.7931H82.7588V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 88.2759H82.7587V93.7931H88.276V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 88.2759H88.276V93.7931H93.7932V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 88.2759H93.7932V93.7931H99.3105V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 88.2759H99.3105V93.7931H104.828V88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M115.851 90.0431H115.862V88.2759H110.345V90.0431H110.356C110.349 90.1006 110.345 90.1437 110.345 90.1724V91.8966C110.345 92.421 110.53 92.8682 110.9 93.2381C111.27 93.6081 111.717 93.7931 112.242 93.7931H113.966C114.49 93.7931 114.937 93.6081 115.307 93.2381C115.677 92.8682 115.862 92.421 115.862 91.8966V90.1724C115.862 90.1437 115.859 90.1006 115.851 90.0431Z"
        fill="#0E0E22"
      />
      <path
        d="M132.414 90.1724V88.2759H128.793C128.269 88.2759 127.822 88.4609 127.452 88.8309C127.082 89.2008 126.897 89.648 126.897 90.1724V93.7931H132.414V90.1724Z"
        fill="#0E0E22"
      />
      <path
        d="M136.035 88.2759H132.414V93.7931H136.035C136.559 93.7931 137.006 93.6081 137.376 93.2381C137.746 92.8682 137.931 92.421 137.931 91.8966V90.1724C137.931 89.648 137.746 89.2008 137.376 88.8309C137.006 88.4609 136.559 88.2759 136.035 88.2759Z"
        fill="#0E0E22"
      />
      <path
        d="M148.955 90.0431H148.966V88.2759H143.448V90.0431H143.459C143.452 90.1006 143.448 90.1437 143.448 90.1724V91.8966C143.448 92.421 143.633 92.8682 144.003 93.2381C144.373 93.6081 144.821 93.7931 145.345 93.7931H147.069C147.593 93.7931 148.041 93.6081 148.411 93.2381C148.781 92.8682 148.966 92.421 148.966 91.8966V90.1724C148.966 90.1437 148.962 90.1006 148.955 90.0431Z"
        fill="#0E0E22"
      />
      <path
        d="M38.6208 95.6895C38.6208 95.1651 38.4358 94.7179 38.0658 94.3479C37.6959 93.978 37.2487 93.793 36.7243 93.793H35.0001C34.4757 93.793 34.0285 93.978 33.6586 94.3479C33.2886 94.7179 33.1036 95.1651 33.1036 95.6895V97.4137C33.1036 97.4424 33.1072 97.4855 33.1143 97.543H33.1036V99.3102H38.6208V97.543H38.6101C38.6173 97.4855 38.6208 97.4425 38.6208 97.4137V95.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M55.1618 95.5602H55.1725V93.793H49.6553V95.5602H49.666C49.6589 95.6177 49.6553 95.6608 49.6553 95.6895V97.4137C49.6553 97.9381 49.8403 98.3853 50.2103 98.7552C50.5802 99.1252 51.0274 99.3102 51.5518 99.3102H53.276C53.8004 99.3102 54.2476 99.1252 54.6175 98.7552C54.9875 98.3853 55.1725 97.9381 55.1725 97.4137V95.6895C55.1725 95.6608 55.169 95.6177 55.1618 95.5602Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 95.6895V93.793H68.1036C67.5792 93.793 67.1319 93.978 66.762 94.3479C66.3921 94.7179 66.207 95.1651 66.207 95.6895V97.4137C66.207 97.9381 66.392 98.3853 66.762 98.7552C67.132 99.1252 67.5792 99.3102 68.1036 99.3102H71.7243V95.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 93.793H71.7242V99.3102H77.2415V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 93.793H77.2415V99.3102H80.8622C81.3866 99.3102 81.8338 99.1252 82.2038 98.7552C82.5737 98.3853 82.7588 97.9381 82.7588 97.4137V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 93.793H88.276V99.3102H93.7932V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 93.793H93.7932V99.3102H99.3105V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 93.793H99.3105V99.3102H104.828V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 95.6895C110.345 95.1651 110.16 94.7179 109.79 94.3479C109.42 93.978 108.973 93.793 108.448 93.793H104.828V99.3102H110.345V95.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 95.6895V93.793H117.759C117.234 93.793 116.787 93.978 116.417 94.3479C116.047 94.7179 115.862 95.1651 115.862 95.6895V99.3102H121.379V95.6895Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 93.793H121.379V99.3102H126.897V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M132.414 93.793H126.897V99.3102H130.517C131.042 99.3102 131.489 99.1252 131.859 98.7552C132.229 98.3853 132.414 97.9381 132.414 97.4137V93.793Z"
        fill="#0E0E22"
      />
      <path
        d="M16.5518 101.207V99.3103H12.9312C12.4067 99.3103 11.9595 99.4953 11.5896 99.8653C11.2197 100.235 11.0346 100.682 11.0346 101.207V102.931C11.0346 103.455 11.2196 103.903 11.5896 104.273C11.9596 104.642 12.4067 104.828 12.9312 104.828H16.5518V101.207Z"
        fill="#0E0E22"
      />
      <path
        d="M20.1725 99.3103H16.5518V104.828H20.1725C20.6969 104.828 21.1441 104.642 21.5141 104.273C21.884 103.903 22.0691 103.455 22.0691 102.931V101.207C22.0691 100.682 21.884 100.235 21.5141 99.8653C21.1441 99.4954 20.6969 99.3103 20.1725 99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M38.6101 101.078H38.6208V99.3103H33.1036V101.078H33.1143C33.1072 101.135 33.1036 101.178 33.1036 101.207V102.931C33.1036 103.455 33.2886 103.903 33.6586 104.273C34.0285 104.642 34.4757 104.828 35.0001 104.828H36.7243C37.2487 104.828 37.6959 104.642 38.0658 104.273C38.4358 103.903 38.6208 103.455 38.6208 102.931V101.207C38.6208 101.178 38.6173 101.135 38.6101 101.078Z"
        fill="#0E0E22"
      />
      <path
        d="M49.6553 102.931C49.6553 103.456 49.4702 103.903 49.1003 104.273C48.7304 104.642 48.2833 104.828 47.7588 104.828H46.0346C45.5103 104.828 45.063 104.642 44.6931 104.273C44.3231 103.903 44.1381 103.456 44.1381 102.931V101.207C44.1381 100.682 44.3231 100.235 44.693 99.8653C45.0631 99.4954 45.5103 99.3103 46.0346 99.3103H47.7588C48.2833 99.3103 48.7305 99.4953 49.1003 99.8653C49.4703 100.235 49.6553 100.682 49.6553 101.207V102.931Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 101.207C60.6898 100.682 60.5047 100.235 60.1348 99.8653C59.7649 99.4954 59.3176 99.3103 58.7932 99.3103H57.0691C56.5447 99.3103 56.0975 99.4953 55.7275 99.8653C55.3576 100.235 55.1725 100.682 55.1725 101.207V102.931C55.1725 102.96 55.1761 103.003 55.1833 103.06H55.1725V104.828H60.6898V103.06H60.679C60.6863 103.003 60.6898 102.96 60.6898 102.931V101.207Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 101.207V99.3103H84.6553C84.1309 99.3103 83.6836 99.4953 83.3137 99.8653C82.9438 100.235 82.7587 100.682 82.7587 101.207V104.828H88.276V101.207Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 99.3103H88.276V104.828H93.7932V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 99.3103H99.3105V104.828H104.828V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 99.3103H104.828V104.828H110.345V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 99.3103H110.345V104.828H115.862V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 99.3103H115.862V104.828H121.379V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 99.3103H121.379V104.828H126.897V99.3103Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 102.931C143.448 103.456 143.263 103.903 142.893 104.273C142.523 104.642 142.076 104.828 141.552 104.828H139.828C139.303 104.828 138.856 104.642 138.486 104.273C138.116 103.903 137.931 103.456 137.931 102.931V101.207C137.931 100.682 138.116 100.235 138.486 99.8653C138.856 99.4954 139.303 99.3103 139.828 99.3103H141.552C142.076 99.3103 142.524 99.4953 142.893 99.8653C143.263 100.235 143.448 100.682 143.448 101.207V102.931Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 104.828H55.1725V110.345H60.6898V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 104.828H60.6898V110.345H66.207V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M69.8277 104.828H66.207V110.345H69.8277C70.3521 110.345 70.7994 110.16 71.1693 109.79C71.5392 109.42 71.7243 108.973 71.7243 108.448V106.724C71.7243 106.2 71.5392 105.753 71.1693 105.383C70.7994 105.013 70.3521 104.828 69.8277 104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 104.828H82.7587V108.448C82.7587 108.973 82.9437 109.42 83.3137 109.79C83.6837 110.16 84.1309 110.345 84.6553 110.345H88.276V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 104.828H88.276V110.345H91.8967C92.4211 110.345 92.8683 110.16 93.2383 109.79C93.6082 109.42 93.7932 108.973 93.7932 108.448V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 104.828H99.3105V110.345H104.828V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 104.828H121.379V110.345H126.897V104.828Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 106.724C148.966 106.2 148.781 105.753 148.411 105.383C148.041 105.013 147.593 104.828 147.069 104.828H145.345C144.821 104.828 144.373 105.013 144.003 105.383C143.633 105.753 143.448 106.2 143.448 106.724V108.448C143.448 108.477 143.452 108.52 143.459 108.578H143.448V110.345H148.966V108.578H148.955C148.962 108.52 148.966 108.477 148.966 108.448V106.724Z"
        fill="#0E0E22"
      />
      <path
        d="M60.679 112.112H60.6898V110.345H55.1725V112.112H55.1833C55.1761 112.169 55.1725 112.213 55.1725 112.241V113.965C55.1725 114.49 55.3575 114.937 55.7275 115.307C56.0975 115.677 56.5447 115.862 57.0691 115.862H58.7932C59.3176 115.862 59.7649 115.677 60.1348 115.307C60.5047 114.937 60.6898 114.49 60.6898 113.965V112.241C60.6898 112.213 60.6863 112.169 60.679 112.112Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 113.965C77.2415 114.49 77.0564 114.937 76.6865 115.307C76.3166 115.677 75.8695 115.862 75.3449 115.862H73.6208C73.0964 115.862 72.6492 115.677 72.2793 115.307C71.9093 114.937 71.7242 114.49 71.7242 113.965V112.241C71.7242 111.717 71.9092 111.27 72.2792 110.9C72.6493 110.53 73.0964 110.345 73.6208 110.345H75.3449C75.8695 110.345 76.3167 110.53 76.6865 110.9C77.0564 111.27 77.2415 111.717 77.2415 112.241V113.965Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 110.345H99.3105V115.862H104.828V110.345Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 113.965C115.862 114.49 115.677 114.937 115.307 115.307C114.937 115.677 114.49 115.862 113.966 115.862H112.242C111.717 115.862 111.27 115.677 110.9 115.307C110.53 114.937 110.345 114.49 110.345 113.965V112.241C110.345 111.717 110.53 111.27 110.9 110.9C111.27 110.53 111.717 110.345 112.242 110.345H113.966C114.49 110.345 114.937 110.53 115.307 110.9C115.677 111.27 115.862 111.717 115.862 112.241V113.965Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 110.345H121.379V115.862H126.897V110.345Z"
        fill="#0E0E22"
      />
      <path
        d="M148.955 112.112H148.966V110.345H143.448V112.112H143.459C143.452 112.169 143.448 112.213 143.448 112.241V113.965C143.448 114.49 143.633 114.937 144.003 115.307C144.373 115.677 144.821 115.862 145.345 115.862H147.069C147.593 115.862 148.041 115.677 148.411 115.307C148.781 114.937 148.966 114.49 148.966 113.965V112.241C148.966 112.213 148.962 112.169 148.955 112.112Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 117.759C71.7243 117.234 71.5392 116.787 71.1693 116.417C70.7994 116.047 70.3521 115.862 69.8277 115.862H68.1036C67.5792 115.862 67.1319 116.047 66.762 116.417C66.3921 116.787 66.207 117.234 66.207 117.759V119.483C66.207 119.511 66.2106 119.555 66.2178 119.612H66.207V121.379H71.7243V119.612H71.7135C71.7208 119.555 71.7243 119.512 71.7243 119.483V117.759Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 117.759C88.276 117.234 88.0909 116.787 87.721 116.417C87.3511 116.047 86.9038 115.862 86.3794 115.862H84.6553C84.1309 115.862 83.6836 116.047 83.3137 116.417C82.9438 116.787 82.7587 117.234 82.7587 117.759V119.483C82.7587 119.511 82.7623 119.555 82.7695 119.612H82.7587V121.379H88.276V119.612H88.2652C88.2725 119.555 88.276 119.512 88.276 119.483V117.759Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 115.862H99.3105V121.379H104.828V115.862Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 115.862H121.379V121.379H126.897V115.862Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 117.759C143.448 117.234 143.263 116.787 142.893 116.417C142.523 116.047 142.076 115.862 141.552 115.862H139.828C139.303 115.862 138.856 116.047 138.486 116.417C138.116 116.787 137.931 117.234 137.931 117.759V119.483C137.931 119.511 137.935 119.555 137.942 119.612H137.931V121.379H143.448V119.612H143.438C143.445 119.555 143.448 119.512 143.448 119.483V117.759Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 123.276V121.379H62.5863C62.0619 121.379 61.6147 121.564 61.2447 121.934C60.8748 122.304 60.6898 122.751 60.6898 123.276V126.896H66.207V123.276Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 121.379H66.207V126.896H71.7243V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 121.379H71.7242V126.896H77.2415V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 121.379H77.2415V126.896H82.7588V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 121.379H82.7587V126.896H86.3794C86.9038 126.896 87.3511 126.711 87.721 126.341C88.0909 125.971 88.276 125.524 88.276 125V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 123.276V121.379H95.6898C95.1654 121.379 94.7181 121.564 94.3482 121.934C93.9783 122.304 93.7932 122.751 93.7932 123.276V126.896H99.3105V123.276Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 121.379H99.3105V126.896H104.828V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 121.379H104.828V126.896H110.345V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 121.379H110.345V126.896H115.862V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 121.379H115.862V126.896H121.379V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 121.379H121.379V126.896H126.897V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 121.379H137.931V126.896H143.448V121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M147.069 121.379H143.448V126.896H147.069C147.593 126.896 148.041 126.711 148.411 126.341C148.781 125.971 148.966 125.524 148.966 125V123.276C148.966 122.751 148.781 122.304 148.411 121.934C148.041 121.564 147.593 121.379 147.069 121.379Z"
        fill="#0E0E22"
      />
      <path
        d="M66.1962 128.664H66.207V126.896H60.6898V128.664H60.7005C60.6933 128.721 60.6898 128.764 60.6898 128.793V130.517C60.6898 131.042 60.8748 131.489 61.2447 131.859C61.6147 132.229 62.0619 132.414 62.5863 132.414H64.3104C64.8349 132.414 65.2821 132.229 65.652 131.859C66.022 131.489 66.207 131.042 66.207 130.517V128.793C66.207 128.764 66.2035 128.721 66.1962 128.664Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 126.896H77.2415V132.414H82.7588V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 126.896H93.7932V132.414H99.3105V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 126.896H99.3105V132.414H104.828V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 126.896H104.828V132.414H110.345V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 126.896H121.379V132.414H126.897V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M137.931 128.793V126.896H134.31C133.786 126.896 133.339 127.081 132.969 127.451C132.599 127.821 132.414 128.269 132.414 128.793V130.517C132.414 131.042 132.599 131.489 132.969 131.859C133.339 132.229 133.786 132.414 134.31 132.414H137.931V128.793Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 126.896H137.931V132.414H143.448V126.896Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 136.035C60.6898 136.559 60.5047 137.006 60.1348 137.376C59.7649 137.746 59.3178 137.931 58.7932 137.931H57.0691C56.5447 137.931 56.0975 137.746 55.7276 137.376C55.3576 137.006 55.1725 136.559 55.1725 136.035V134.31C55.1725 133.786 55.3575 133.339 55.7275 132.969C56.0976 132.599 56.5447 132.414 57.0691 132.414H58.7932C59.3178 132.414 59.765 132.599 60.1348 132.969C60.5047 133.339 60.6898 133.786 60.6898 134.31V136.035Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 134.31C71.7243 133.786 71.5392 133.339 71.1693 132.969C70.7994 132.599 70.3521 132.414 69.8277 132.414H68.1036C67.5792 132.414 67.1319 132.599 66.762 132.969C66.3921 133.339 66.207 133.786 66.207 134.31V136.035C66.207 136.063 66.2106 136.106 66.2178 136.164H66.207V137.931H71.7243V136.164H71.7135C71.7208 136.106 71.7243 136.063 71.7243 136.035V134.31Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 132.414H77.2415V137.931H82.7588V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 132.414H82.7587V137.931H88.276V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 132.414H88.276V137.931H93.7932V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M99.3105 132.414H93.7932V137.931H97.4139C97.9383 137.931 98.3855 137.746 98.7555 137.376C99.1254 137.006 99.3105 136.559 99.3105 136.035V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 132.414H104.828V137.931H110.345V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 134.31V132.414H117.759C117.234 132.414 116.787 132.599 116.417 132.969C116.047 133.339 115.862 133.786 115.862 134.31V137.931H121.379V134.31Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 132.414H121.379V137.931H126.897V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M130.517 132.414H126.897V137.931H130.517C131.042 137.931 131.489 137.746 131.859 137.376C132.229 137.006 132.414 136.559 132.414 136.035V134.31C132.414 133.786 132.229 133.339 131.859 132.969C131.489 132.599 131.042 132.414 130.517 132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M143.448 132.414H137.931V136.035C137.931 136.559 138.116 137.006 138.486 137.376C138.856 137.746 139.303 137.931 139.828 137.931H143.448V132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M147.069 132.414H143.448V137.931H147.069C147.593 137.931 148.041 137.746 148.411 137.376C148.781 137.006 148.966 136.559 148.966 136.035V134.31C148.966 133.786 148.781 133.339 148.411 132.969C148.041 132.599 147.593 132.414 147.069 132.414Z"
        fill="#0E0E22"
      />
      <path
        d="M66.207 139.827V137.931H62.5863C62.0619 137.931 61.6147 138.116 61.2447 138.486C60.8748 138.856 60.6898 139.303 60.6898 139.827V141.552C60.6898 142.076 60.8748 142.523 61.2447 142.893C61.6147 143.263 62.0619 143.448 62.5863 143.448H66.207V139.827Z"
        fill="#0E0E22"
      />
      <path
        d="M71.7243 137.931H66.207V143.448H71.7243V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M77.2415 137.931H71.7242V143.448H77.2415V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M82.7588 137.931H77.2415V143.448H80.8622C81.3866 143.448 81.8338 143.263 82.2038 142.893C82.5737 142.523 82.7588 142.076 82.7588 141.552V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 137.931H88.276V143.448H93.7932V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M104.828 139.827V137.931H101.207C100.683 137.931 100.235 138.116 99.8655 138.486C99.4955 138.856 99.3105 139.303 99.3105 139.827V143.448H104.828V139.827Z"
        fill="#0E0E22"
      />
      <path
        d="M110.345 137.931H104.828V143.448H108.448C108.973 143.448 109.42 143.263 109.79 142.893C110.16 142.523 110.345 142.076 110.345 141.552V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M121.379 137.931H115.862V141.552C115.862 142.076 116.047 142.523 116.417 142.893C116.787 143.263 117.234 143.448 117.759 143.448H121.379V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M126.897 137.931H121.379V143.448H125C125.525 143.448 125.972 143.263 126.342 142.893C126.712 142.523 126.897 142.076 126.897 141.552V137.931Z"
        fill="#0E0E22"
      />
      <path
        d="M60.6898 147.069C60.6898 147.593 60.5047 148.041 60.1348 148.411C59.7649 148.78 59.3178 148.965 58.7932 148.965H57.0691C56.5447 148.965 56.0975 148.78 55.7276 148.411C55.3576 148.041 55.1725 147.593 55.1725 147.069V145.345C55.1725 144.82 55.3575 144.373 55.7275 144.003C56.0976 143.633 56.5447 143.448 57.0691 143.448H58.7932C59.3178 143.448 59.765 143.633 60.1348 144.003C60.5047 144.373 60.6898 144.82 60.6898 145.345V147.069Z"
        fill="#0E0E22"
      />
      <path
        d="M88.276 145.345V143.448H84.6553C84.1309 143.448 83.6836 143.633 83.3137 144.003C82.9438 144.373 82.7587 144.82 82.7587 145.345V147.069C82.7587 147.593 82.9437 148.041 83.3137 148.411C83.6837 148.78 84.1309 148.965 84.6553 148.965H88.276V145.345Z"
        fill="#0E0E22"
      />
      <path
        d="M93.7932 143.448H88.276V148.965H91.8967C92.4211 148.965 92.8683 148.78 93.2383 148.411C93.6082 148.041 93.7932 147.593 93.7932 147.069V143.448Z"
        fill="#0E0E22"
      />
      <path
        d="M104.817 145.215H104.828V143.448H99.3105V145.215H99.3212C99.3141 145.273 99.3105 145.316 99.3105 145.345V147.069C99.3105 147.593 99.4955 148.041 99.8655 148.411C100.235 148.78 100.683 148.965 101.207 148.965H102.931C103.456 148.965 103.903 148.78 104.273 148.411C104.643 148.041 104.828 147.593 104.828 147.069V145.345C104.828 145.316 104.824 145.273 104.817 145.215Z"
        fill="#0E0E22"
      />
      <path
        d="M115.862 147.069C115.862 147.593 115.677 148.041 115.307 148.411C114.937 148.78 114.49 148.965 113.966 148.965H112.242C111.717 148.965 111.27 148.78 110.9 148.411C110.53 148.041 110.345 147.593 110.345 147.069V145.345C110.345 144.82 110.53 144.373 110.9 144.003C111.27 143.633 111.717 143.448 112.242 143.448H113.966C114.49 143.448 114.937 143.633 115.307 144.003C115.677 144.373 115.862 144.82 115.862 145.345V147.069Z"
        fill="#0E0E22"
      />
      <path
        d="M132.414 147.069C132.414 147.593 132.229 148.041 131.859 148.411C131.489 148.78 131.042 148.965 130.517 148.965H128.793C128.269 148.965 127.822 148.78 127.452 148.411C127.082 148.041 126.897 147.593 126.897 147.069V145.345C126.897 144.82 127.082 144.373 127.452 144.003C127.822 143.633 128.269 143.448 128.793 143.448H130.517C131.042 143.448 131.489 143.633 131.859 144.003C132.229 144.373 132.414 144.82 132.414 145.345V147.069Z"
        fill="#0E0E22"
      />
      <path
        d="M148.966 147.069C148.966 147.593 148.781 148.041 148.411 148.411C148.041 148.78 147.594 148.965 147.069 148.965H145.345C144.821 148.965 144.373 148.78 144.003 148.411C143.633 148.041 143.448 147.593 143.448 147.069V145.345C143.448 144.82 143.633 144.373 144.003 144.003C144.373 143.633 144.821 143.448 145.345 143.448H147.069C147.594 143.448 148.041 143.633 148.411 144.003C148.781 144.373 148.966 144.82 148.966 145.345V147.069Z"
        fill="#0E0E22"
      />
      <path
        d="M36.4698 11.0376H24.2201C18.2501 11.0376 13.1923 14.9819 11.5753 20.373C11.4081 20.9307 11.2775 21.5038 11.1868 22.0897C11.0867 22.7362 11.0346 23.3981 11.0346 24.0721V36.6126C11.0346 43.8034 16.9513 49.6509 24.2201 49.6509L30.345 49.6521L36.4698 49.6506C43.7382 49.6506 49.6553 43.803 49.6553 36.6122V24.0721C49.6553 16.8848 43.7386 11.0376 36.4698 11.0376ZM43.8622 36.6126C43.8622 40.6075 40.5462 43.8578 36.4683 43.8578L30.345 43.859L24.2216 43.8575C20.1437 43.8575 16.8277 40.6071 16.8277 36.6126V24.0721C16.8277 22.8242 17.1514 21.6494 17.721 20.6229C18.2694 19.6353 19.0453 18.7861 19.9795 18.143C21.1806 17.3162 22.6432 16.8307 24.2197 16.8307H36.4698C40.5462 16.8307 43.8622 20.0791 43.8622 24.0721V36.6126Z"
        fill="#0E0E22"
      />
      <path
        d="M135.78 11.0376H123.53C117.56 11.0376 112.503 14.9819 110.886 20.373C110.718 20.9307 110.588 21.5038 110.497 22.0897C110.397 22.7362 110.345 23.3981 110.345 24.0721V36.6126C110.345 43.8034 116.262 49.6509 123.53 49.6509L129.655 49.6521L135.78 49.6506C143.049 49.6506 148.966 43.803 148.966 36.6122V24.0721C148.966 16.8848 143.049 11.0376 135.78 11.0376ZM143.173 36.6126C143.173 40.6075 139.857 43.8578 135.779 43.8578L129.655 43.859L123.532 43.8575C119.454 43.8575 116.138 40.6071 116.138 36.6126V24.0721C116.138 22.8242 116.462 21.6494 117.031 20.6229C117.58 19.6353 118.356 18.7861 119.29 18.143C120.491 17.3162 121.954 16.8307 123.53 16.8307H135.78C139.857 16.8307 143.173 20.0791 143.173 24.0721V36.6126Z"
        fill="#0E0E22"
      />
      <path
        d="M36.4698 110.348H24.2201C18.2501 110.348 13.1923 114.292 11.5753 119.683C11.4081 120.241 11.2775 120.814 11.1868 121.4C11.0867 122.046 11.0346 122.708 11.0346 123.382V135.923C11.0346 143.114 16.9513 148.961 24.2201 148.961L30.345 148.962L36.4698 148.961C43.7382 148.961 49.6553 143.113 49.6553 135.923V123.382C49.6553 116.195 43.7386 110.348 36.4698 110.348ZM43.8622 135.923C43.8622 139.918 40.5462 143.168 36.4683 143.168L30.345 143.169L24.2216 143.168C20.1437 143.168 16.8277 139.917 16.8277 135.923V123.382C16.8277 122.135 17.1514 120.96 17.721 119.933C18.2694 118.946 19.0453 118.096 19.9795 117.453C21.1806 116.626 22.6432 116.141 24.2197 116.141H36.4698C40.5462 116.141 43.8622 119.389 43.8622 123.382V135.923Z"
        fill="#0E0E22"
      />
      <path
        d="M26.5962 38.6206C24.0985 38.6206 22.0668 36.6291 22.0668 34.1834V26.5037C22.0668 25.7397 22.2654 25.0207 22.6131 24.3916C22.949 23.7861 23.4243 23.2657 23.9969 22.8731C24.7323 22.3669 25.6287 22.0688 26.5937 22.0688H34.0961C36.5914 22.0688 38.6231 24.058 38.6231 26.5036V34.1832C38.6231 36.6287 36.5916 38.6204 34.0961 38.6204H30.345L26.5962 38.6206Z"
        fill="#0E0E22"
      />
      <path
        d="M125.907 38.6206C123.409 38.6206 121.377 36.6291 121.377 34.1834V26.5037C121.377 25.7397 121.576 25.0207 121.924 24.3916C122.259 23.7861 122.735 23.2657 123.307 22.8731C124.043 22.3669 124.939 22.0688 125.904 22.0688H133.406C135.902 22.0688 137.933 24.058 137.933 26.5036V34.1832C137.933 36.6287 135.902 38.6204 133.406 38.6204H129.655L125.907 38.6206Z"
        fill="#0E0E22"
      />
      <path
        d="M26.5962 137.931C24.0985 137.931 22.0668 135.939 22.0668 133.494V125.814C22.0668 125.05 22.2654 124.331 22.6131 123.702C22.949 123.096 23.4243 122.576 23.9969 122.183C24.7323 121.677 25.6287 121.379 26.5937 121.379H34.0961C36.5914 121.379 38.6231 123.368 38.6231 125.814V133.494C38.6231 135.939 36.5916 137.931 34.0961 137.931H30.345L26.5962 137.931Z"
        fill="#0E0E22"
      />
    </g>
    <defs>
      <clipPath id="clip0_12_2619">
        <rect
          width="160"
          height="160"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
