<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56120)">
      <path
        d="M10.0003 19.5836C10.1713 19.5831 10.3384 19.5325 10.4809 19.4381C10.6235 19.3437 10.7352 19.2096 10.8024 19.0524L14.8493 9.58362H5.15137L9.19824 19.0524C9.26541 19.2096 9.37717 19.3437 9.51972 19.4381C9.66227 19.5325 9.82935 19.5831 10.0003 19.5836Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.10986 2.9586C7.19965 2.25467 7.54299 1.60761 8.07557 1.13864C8.60816 0.669667 9.29346 0.41095 10.0031 0.41095C10.7127 0.41095 11.398 0.669667 11.9306 1.13864C12.4632 1.60761 12.8065 2.25467 12.8963 2.9586"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.15153 9.5836C4.2557 8.8961 3.75049 7.84401 3.75049 6.66693C3.75049 4.59401 5.52132 2.91693 7.70882 2.91693C9.89632 2.91693 11.6672 4.59401 11.6672 6.66693C11.6672 7.84401 11.0942 8.8961 10.1984 9.5836"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.0005 3.60966C10.6783 3.15556 11.4763 2.91434 12.2922 2.91695C14.4797 2.91695 16.2505 4.59404 16.2505 6.66695C16.2505 7.84404 15.7453 8.89612 14.8494 9.58362"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56120">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
