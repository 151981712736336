<template>
  <div class="profile">
    <div class="profile__text">
      {{ firstLast }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileIcon",
  props: {
    firstLast: { type: String, default: () => "" },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  height: 40px;
  width: 40px;
  // background: linear-gradient(180deg, #0052cc 0%, #00cdd7 100%);
  background: #10b3c7;
  border-radius: 50%;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  @include flex-center;

  color: #ffffff;
  cursor: pointer;
}
</style>
