<template>
  <!-- Footer table -->
  <div v-if="maxPaginationNumber" class="customize-footer">
    <div class="customize-footer__index">
      <!--      <span
        >Показано с {{ currentPageFirstIndex }} по {{ currentPageLastIndex }} из
        {{ clientItemsLength }} записей
      </span>-->
      <select-per-page
        v-model="perPageValue"
        title="Отображать результатов:"
        :options="[25, 50, 100, 150]"
      />
    </div>
    <div class="pagination-row">
      <button class="prev-page" :disabled="isFirstPage" @click="prevPage">
        <v-icon
          name="prev-page-icon"
          stroke="none"
          width="20"
          height="20"
          pointer
        />
      </button>

      <div class="pagination-row__buttons">
        <span
          v-for="paginationNumber in maxPaginationNumber"
          :key="paginationNumber"
          :class="[
            'pagination-row__button',
            {
              'pagination-row__button-active':
                paginationNumber === currentPaginationNumber,
            },
          ]"
          @click="updatePage(paginationNumber)"
        >
          {{ paginationNumber }}
        </span>
      </div>

      <button class="next-page" :disabled="isLastPage" @click="nextPage">
        <v-icon
          name="next-page-icon"
          stroke="none"
          width="20"
          height="20"
          pointer
        />
      </button>
    </div>
  </div>
  <!-- End Footer -->
</template>

<script setup>
import { watch, computed } from "vue";
import SelectPerPage from "@/components/UI/table/SelectPerPage.vue";

// eslint-disable-next-line no-undef
const emit = defineEmits({
  "update:modelValue": null,
});
// eslint-disable-next-line no-undef
const props = defineProps({
  dataTable: {
    type: Object,
    default: () => ({}),
  },
  modelValue: {
    type: Number,
    default: 5,
  },
});
const perPageValue = computed({
  get: () => props.modelValue,
  set: (newValue) => emit("update:modelValue", newValue),
});

/* Footer Table */
// const currentPageFirstIndex = computed(
//   () => props.dataTable?.currentPageFirstIndex
// );
// const currentPageLastIndex = computed(
//   () => props.dataTable?.currentPageLastIndex
// );
// const clientItemsLength = computed(() => props.dataTable?.clientItemsLength);

const currentPaginationNumber = computed(
  () => props.dataTable?.currentPaginationNumber
);
// pagination related
const maxPaginationNumber = computed(
  () => props.dataTable?.maxPaginationNumber
);
// const rowsPerPageActiveOption = computed(
//   () => props.dataTable?.rowsPerPageActiveOption
// );
// const rowsPerPageOptions = computed(() => props.dataTable?.rowsPerPageOptions);
const isFirstPage = computed(() => props.dataTable?.isFirstPage);
const isLastPage = computed(() => props.dataTable?.isLastPage);

const nextPage = () => {
  props.dataTable.nextPage();
};
const prevPage = () => {
  props.dataTable.prevPage();
};
const updatePage = (paginationNumber) => {
  props.dataTable.updatePage(paginationNumber);
};
const updateRowsPerPageSelect = () => {
  props.dataTable.updateRowsPerPageActiveOption(Number(perPageValue.value));
};
watch(perPageValue, () => {
  updateRowsPerPageSelect();
});
/* END Footer Table */
</script>

<style lang="scss" scoped>
.customize-footer {
  height: 66px;
  position: sticky;
  bottom: -16px;
  top: -32px;
  background: #ffffff;
  width: 100%;
  padding: 0px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: flex-end;
}

.pagination-row {
  display: flex;
  height: 100%;
  align-items: center;
  &__buttons {
    display: flex;
  }
  &__button {
    border-radius: 8px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 28px;
    height: 28px;
    position: relative;
    text-align: center;
    font: 400 12px/130% "Inter", sans-serif;
    color: #595a5b;
    cursor: pointer;
  }
  &__button:not(&__button-active):hover {
    background: #f5f6f8;
  }
  &__button-active {
    background: #00cdd7;
    color: #ffffff;
    cursor: auto;
  }
}
.prev-page,
.next-page {
  border: 0;
  background-color: transparent;
  outline: none;
  display: flex;
  width: 28px;
  height: 28px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
</style>
