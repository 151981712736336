import { computed } from "vue";
import { db } from "@/firebase";
import { getPathFromUri, hexToRgb } from "@/utils/helpers";
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  setDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import store from "@/store";

const merchant = computed(() => store.getters.merchant);
const loyalties = {
  /* DISCOUNT  ====================================== */
  discount: {
    description: "discount",
    headerFields: [
      {
        key: "discount",
        label: "СКИДКА",
        value: "<*percent*>%",
      },
    ],
    auxiliaryFields: [
      {
        key: "status",
        label: "СТАТУС",
        value: "<*level*>",
      },
      {
        key: "toDiscount",
        label: `До скидки <*toNextPercent*>%`,
        value: "<*toNextEdge*>",
      },
    ],
  },
  /* BONUS ====================================== */
  bonus: {
    description: "bonus",
    headerFields: [
      {
        key: "bonus",
        label: "БАЛАНС",
        value: "<*currentBonus*>",
      },
    ],
    auxiliaryFields: [
      {
        key: "status",
        label: "СТАТУС",
        value: "<*level*>",
      },
      {
        key: "toDiscount",
        label: "До бонуса <*toNextBonusPercent*>%",
        value: "<*toNextBonusEdge*>",
      },
    ],
  },
  /* CLUBCARD  ====================================== */
  clubcard: {
    description: "clubcard",
    headerFields: [
      {
        key: "deposit",
        label: "ДЕПОЗИТ",
        value: "<*currentDeposit*>",
      },
    ],
    auxiliaryFields: [
      {
        key: "logoText",
        value: "<*logoText*>",
      },
      {
        key: "cardNumber",
        label: "НОМЕР КАРТЫ",
        value: "<*externalId*>",
      },
    ],
  },
  /* COUPON {START, END}  ====================================== */
  coupon_start: {
    description: "coupon_start",
    auxiliaryFields: [
      {
        key: "toGift",
        label: "<*signatureForAccumulation*>",
      },
      {
        key: "points",
        value: "<*currentPoint*>/<*activateОnValue*>",
      },
    ],
  },
  coupon_end: {
    description: "coupon_end",
    auxiliaryFields: [
      {
        key: "getGift",
        label: "<placeToFillCongratulations*>",
      },
    ],
  },
};
export const checkClubcardUserId = async (
  userId /*  = "j6AjrHYM4afZtzSb8YCecwRjHx23" */
) => {
  try {
    const { merchantId } = await merchant.value;
    const docRefCards = collection(db, "cards");

    const q = query(
      docRefCards,
      // where("merchantId", "==", "D3r713bNOWO4n3XXWUg4"),
      where("merchantId", "==", merchantId),
      where("states.externalId", "==", userId)
    );
    // return true;
    let docSnap;
    try {
      docSnap = await getDocs(q);
    } catch (error) {
      console.log(error);
      //       console.log("docSnap Error");
    }
    return !docSnap.empty;
  } catch (error) {
    console.log("checkClubcardUserId:error ===>>> ", error);
  }
};
export const updateDeposit = async ({ userId = "A012", amount = 0 }) => {
  try {
    const { merchantId } = await merchant.value;
    const docRefCards = collection(db, "cards");

    const q = query(
      docRefCards,
      // where("merchantId", "==", "D3r713bNOWO4n3XXWUg4"),
      where("merchantId", "==", merchantId),
      where("states.externalId", "==", userId)
    );
    const docSnap = await getDocs(q);

    docSnap.forEach(async (doc) => {
      const deposits = JSON.parse(JSON.stringify(doc.data().states.deposits));
      const depositMain = deposits.map((d) => {
        if (d.type === "main") d.deposit += +amount;
        return d;
      });
      await updateDoc(doc.ref, { "states.deposits": depositMain });
    });
  } catch (error) {
    //     console.log("updateDeposit:error ===>>> ", error);
  }
};
export const getLoyalties = async (target) => {
  try {
    const { loyaltiesId } = await merchant.value;
    const docRefLoyalty = doc(db, "loyalties", loyaltiesId);
    const docSnap = await getDoc(docRefLoyalty);
    target.value = docSnap.data();
  } catch (error) {
    //     console.log("getLoyalties:fnFirebase.js ===>>> ", error);
  }
};
export const fnGetLoyalties = async () => {
  return new Promise((resolve, reject) => {
    try {
      const { loyaltiesId } = merchant.value;
      const docRefLoyalty = doc(db, "loyalties", loyaltiesId);
      getDoc(docRefLoyalty).then((docSnap) => {
        const data = docSnap.data();
        const currentLoyalty = data.active.toString();

        if (!currentLoyalty) return resolve({});
        const { levels } = data[currentLoyalty];
        resolve(levels);
      });
    } catch (error) {
      reject(error);
    }
  });
};
export const fnGetLoyaltiesType = async () => {
  return new Promise((resolve, reject) => {
    try {
      const { loyaltiesId } = merchant.value;
      const docRefLoyalty = doc(db, "loyalties", loyaltiesId);
      getDoc(docRefLoyalty).then((docSnap) => {
        const data = docSnap.data();
        const currentLoyalty = data.active.toString();
        console.log("currentLoyalty", JSON.stringify(currentLoyalty));

        if (!currentLoyalty) return resolve();
        const { levels } = data[currentLoyalty];
        resolve({ levels, type: currentLoyalty });
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getStatusLoyalties = async (target) => {
  const merch = merchant.value;
  if (!merch) return;
  try {
    const { loyaltiesId } = await merchant.value;
    const docRef = doc(db, "loyalties", loyaltiesId);
    onSnapshot(docRef, (doc) => {
      target.value = doc.data()["active"];
    });
  } catch (error) {
    //     console.log("getStatusLoyalties", error);
  }
};
export const getLoyaltyAndType = async (cb) => {
  try {
    const { loyaltiesId } = merchant.value;
    const docRef = doc(db, "loyalties", loyaltiesId);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data();
    const active = data.active.toString();
    if (!active) throw new Error("No active loyalties");
    cb({ type: active, loyalty: data[active] });
  } catch (error) {
    console.log("getStatusLoyalties", error);
  }
};
export const getStatusLoyaltiesV = async () => {
  const merch = merchant.value;
  console.log("merch", merch);

  if (!merch) return;
  try {
    const { loyaltiesId } = merchant.value;
    const docRef = doc(db, "loyalties", loyaltiesId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data()["active"].length > 0;
    }
    return false;
  } catch (error) {
    //     console.log("getStatusLoyalties", error);
  }
};
export const getStatusMessengerIntegrations = async () => {
  const { merchantId } = merchant.value;
  if (!merchantId) return;
  try {
    const docRef = doc(db, "merchants-integrations", merchantId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const keys = Object.keys(docSnap.data());
      return keys.includes("telegram") || keys.includes("whatsapp");
    }
    return false;
  } catch (error) {
    //     console.log("getStatusLoyalties", error);
  }
};
export const updateTemplate = async (wallet) => {
  const { loyaltiesId, merchantId, merchantName } = merchant.value;
  const loyaltiesRef = doc(db, "loyalties", loyaltiesId);
  const type = await getDoc(loyaltiesRef).then((doc) => {
    const data = doc.data();
    return data.active.toString();
  });
  const mainSettings = {
    type: "storeCard",
    organizationName: wallet.logoText,
    logoText: wallet.logoText,
    images: {
      icon: getPathFromUri(wallet.logo),
      logo: getPathFromUri(wallet.logo),
      strip: getPathFromUri(wallet.image),
      iconURL: wallet.logo || "",
      logoURL: wallet.logo || "",
      stripURL: wallet.image || "",
    },
    foregroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-6"]),
    labelColor: hexToRgb(wallet?.selectedPreset.colors["relief-5"]),
    backgroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-10"]),
    secondaryFields: [
      {
        key: "cardOwner",
        label: "Владелец карты",
        value: "<*fullname*>",
      },
    ],
    barcode: [
      {
        message: "<*uid*>:" + merchantId,
        format: "PKBarcodeFormatQR",
        messageEncoding: "iso-8859-1",
      },
    ],
  };

  const template = JSON.stringify({ ...mainSettings, ...loyalties[type] });
  try {
    await setDoc(
      loyaltiesRef,
      { [type]: { template }, merchantName },
      { merge: true }
    );
  } catch (error) {
    console.error(error.message);
  }
};
export const getDataLoyalties = async (target, type) => {
  try {
    const { loyaltiesId } = await merchant.value;
    const docRef = doc(db, "loyalties", loyaltiesId);
    const docSnap = await getDoc(docRef);
    const temp = docSnap.data()[type];
    for (const key in temp) {
      target[key] = temp[key];
      if (
        key === "maxApplyBonus" &&
        typeof temp[key]?.rate === "number" &&
        temp[key]?.rate < 1
      ) {
        target[key].rate = Math.round(+temp[key]?.rate * 100);
      }
    }
  } catch (error) {
    console.log("getDataLoyalties", error);
  }
};
export const saveDataLoyalties = async (target, type) => {
  const { walletId, merchantId, merchantName } = merchant.value;

  const walletRef = doc(db, "wallet", walletId);
  let wallet = null;
  try {
    wallet = (await getDoc(walletRef)).data();
  } catch (error) {
    console.log(error);
  }
  /* ============================================================== */

  /* ============================================================= */
  /* Main Setting */
  // logoText: getPathFromUri(wallet.logoText),

  const mainSettings = {
    type: "storeCard",
    organizationName: wallet.logoText,
    logoText: wallet.logoText,
    images: {
      icon: getPathFromUri(wallet.logo),
      logo: getPathFromUri(wallet.logo),
      strip: getPathFromUri(wallet.image),
      iconURL: wallet.logo || "",
      logoURL: wallet.logo || "",
      stripURL: wallet.image || "",
    },
    foregroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-6"]),
    labelColor: hexToRgb(wallet?.selectedPreset.colors["relief-5"]),
    backgroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-10"]),
    secondaryFields: [
      {
        key: "cardOwner",
        label: "Владелец карты",
        value: "<*fullname*>",
      },
    ],
    barcode: [
      {
        message: "<*uid*>:" + merchantId,
        format: "PKBarcodeFormatQR",
        messageEncoding: "iso-8859-1",
      },
    ],
  };

  const template = JSON.stringify({ ...mainSettings, ...loyalties[type] });
  try {
    const { loyaltiesId } = merchant.value;
    const loyaltiesRef = doc(db, "loyalties", loyaltiesId);
    console.log("loyaltiesRef:merchantName", loyaltiesRef, merchantName);
    const newTarget = JSON.parse(JSON.stringify(target));
    for (const key in newTarget) {
      if (
        key === "maxApplyBonus" &&
        typeof newTarget[key]?.rate === "number" &&
        newTarget[key]?.rate > 1
      ) {
        newTarget[key].rate = newTarget[key]?.rate / 100;
      }
    }

    await setDoc(
      loyaltiesRef,
      { [type]: { ...newTarget, template }, merchantId, merchantName },
      { merge: true }
    );
  } catch (error) {
    console.error(error.message);
  }
};

export const updateStatusLoyalty = async (loyalty) => {
  try {
    const { loyaltiesId, merchantId, merchantName } = merchant.value;
    const loyaltiesRef = doc(db, "loyalties", loyaltiesId);

    const activeLoyalty = (await getDoc(loyaltiesRef)).data()["active"];
    console.log("activeLoyalty:updateStatusLoyalty", {
      active: [...new Set([...activeLoyalty, loyalty])],
      merchantId,
      merchantName,
    });

    await setDoc(
      loyaltiesRef,
      {
        active: [...new Set([...activeLoyalty, loyalty])],
        merchantId,
        merchantName,
      },
      { merge: true }
    );
  } catch (error) {
    console.error(error.message);
  }
};
export const deleteStatusLoyalty = async () => {
  try {
    const { loyaltiesId } = merchant.value;
    const loyaltiesRef = doc(db, "loyalties", loyaltiesId);
    await updateDoc(loyaltiesRef, {
      active: [],
    });
  } catch (error) {
    console.error(error.message);
  }
};
export const deleteLoyalty = async (loyalty) => {
  try {
    const { loyaltiesId } = merchant.value;
    const loyaltiesRef = doc(db, "loyalties", loyaltiesId);
    await setDoc(loyaltiesRef, { [loyalty]: {} }, { merge: true });
  } catch (error) {
    console.error(error.message);
  }
};
export const getUserCards = async (phone) => {
  try {
    const { merchantId } = merchant.value;
    const docRefCards = collection(db, "cards");

    const q = query(
      docRefCards,
      where("merchantId", "==", merchantId),
      where("phone", "==", phone)
    );
    let docSnap;
    try {
      docSnap = await getDocs(q);
    } catch (error) {
      console.log(error);
    }
    if (docSnap.empty) {
      return [];
    }
    const temp = [];
    docSnap.forEach((doc) => {
      temp.push(doc.data());
    });
    return temp;
  } catch (error) {
    console.log("getUserCards:error ===>>> ", error);
  }
};
