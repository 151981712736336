<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.832 9.58325C11.5224 9.58325 12.082 9.02361 12.082 8.33325C12.082 7.6429 11.5224 7.08325 10.832 7.08325C10.1417 7.08325 9.58203 7.6429 9.58203 8.33325C9.58203 9.02361 10.1417 9.58325 10.832 9.58325Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.9987 14.5833L2.08203 3.75H17.9154L9.9987 14.5833Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10 14.5833V19.1666"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.83203 19.1667H14.1654"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.30078 5.41675H16.6966"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M11.3906 7.21346L14.7396 0.505127"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.42578 11.1562L10.2747 9.45312"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
