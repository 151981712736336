<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24829 1.66687H14.5816V18.3335H1.24829V1.66687Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M17.0818 3.33356V16.6669"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.7483 3.33356V16.6669"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.99845 6.66689C5.91893 6.66689 6.66512 5.9207 6.66512 5.00022C6.66512 4.07975 5.91893 3.33356 4.99845 3.33356C4.07798 3.33356 3.33179 4.07975 3.33179 5.00022C3.33179 5.9207 4.07798 6.66689 4.99845 6.66689Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.415 6.66689C11.3354 6.66689 12.0816 5.9207 12.0816 5.00022C12.0816 4.07975 11.3354 3.33356 10.415 3.33356C9.49448 3.33356 8.74829 4.07975 8.74829 5.00022C8.74829 5.9207 9.49448 6.66689 10.415 6.66689Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M5.41585 4.58337H4.58252V5.41671H5.41585V4.58337Z"
      fill="#0E0E22"
    />

    <path
      d="M10.8324 4.58337H9.99902V5.41671H10.8324V4.58337Z"
      fill="#0E0E22"
    />

    <path
      d="M5.83252 13.3334H4.16585C3.70562 13.3334 3.33252 13.7065 3.33252 14.1667V15.8334C3.33252 16.2936 3.70562 16.6667 4.16585 16.6667H5.83252C6.29276 16.6667 6.66585 16.2936 6.66585 15.8334V14.1667C6.66585 13.7065 6.29276 13.3334 5.83252 13.3334Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M5.41585 14.5834H4.58252V15.4167H5.41585V14.5834Z"
      fill="#0E0E22"
    />

    <path
      d="M11.249 13.3334H9.58236C9.12212 13.3334 8.74902 13.7065 8.74902 14.1667V15.8334C8.74902 16.2936 9.12212 16.6667 9.58236 16.6667H11.249C11.7093 16.6667 12.0824 16.2936 12.0824 15.8334V14.1667C12.0824 13.7065 11.7093 13.3334 11.249 13.3334Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M10.8324 14.5834H9.99902V15.4167H10.8324V14.5834Z"
      fill="#0E0E22"
    />

    <path
      d="M5.83252 8.33337H4.16585C3.70562 8.33337 3.33252 8.70647 3.33252 9.16671V10.8334C3.33252 11.2936 3.70562 11.6667 4.16585 11.6667H5.83252C6.29276 11.6667 6.66585 11.2936 6.66585 10.8334V9.16671C6.66585 8.70647 6.29276 8.33337 5.83252 8.33337Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M5.41585 9.5835H4.58252V10.4168H5.41585V9.5835Z"
      fill="#0E0E22"
    />

    <path
      d="M11.249 8.33337H9.58236C9.12212 8.33337 8.74902 8.70647 8.74902 9.16671V10.8334C8.74902 11.2936 9.12212 11.6667 9.58236 11.6667H11.249C11.7093 11.6667 12.0824 11.2936 12.0824 10.8334V9.16671C12.0824 8.70647 11.7093 8.33337 11.249 8.33337Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M10.8324 9.5835H9.99902V10.4168H10.8324V9.5835Z"
      fill="#0E0E22"
    />
  </svg>
</template>
