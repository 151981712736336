<template>
  <text-input
    ref="inputRef"
    v-model="formattedNumber"
    type="text"
    pattern="[1-9]\d*"
    no-paste
  />
</template>
<script setup>
import { onMounted, computed } from "vue";
import { useCurrencyInput } from "vue-currency-input";
// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  currencyCode: {
    type: String,
    default: "UAH",
  },
});
const priceOption = {
  locale: i18n.global.locale,
  currency: props.currencyCode,
  currencyDisplay: "hidden",
  precision: 2,
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  useGrouping: true,
  accountingSign: false,
  valueRange: { min: 0 },
};
const { inputRef, numberValue, setValue } = useCurrencyInput(priceOption);
import TextInput from "./TextInput.vue";
import i18n from "@/i18n";

// eslint-disable-next-line no-undef
const emit = defineEmits({
  "update:modelValue": null,
});
const formattedNumber = computed({
  get: () => {
    console.log("numberValue", numberValue.value);

    return numberValue;
  },
  set: (val) => {
    emit("update:modelValue", val);
  },
});

onMounted(() => {
  setTimeout(() => {
    setValue(props.modelValue);
  }, 200);
});
</script>
