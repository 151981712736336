<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3477 7.08325H18.6602"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.17969 7.08325H9.49219"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.83594 11.6667V17.9167"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.91797 17.9167H8.7513"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M3.20052 4.58325L2.39844 6.41658C2.14964 6.98725 2.04682 7.61087 2.09927 8.2312C2.15172 8.85153 2.35777 9.44904 2.69885 9.96984C3.03993 10.4906 3.5053 10.9183 4.05297 11.2143C4.60065 11.5103 5.2134 11.6653 5.83594 11.6653C6.45848 11.6653 7.07123 11.5103 7.6189 11.2143C8.16658 10.9183 8.63194 10.4906 8.97302 9.96984C9.3141 9.44904 9.52016 8.85153 9.57261 8.2312C9.62505 7.61087 9.52224 6.98725 9.27344 6.41658L8.47135 4.58325H3.20052Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.0039 11.6667V17.9167"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0859 17.9167H17.9193"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.3633 4.58325L11.5664 6.41658C11.3176 6.98725 11.2148 7.61087 11.2672 8.2312C11.3197 8.85153 11.5257 9.44904 11.8668 9.96984C12.2079 10.4906 12.6733 10.9183 13.2209 11.2143C13.7686 11.5103 14.3814 11.6653 15.0039 11.6653C15.6264 11.6653 16.2392 11.5103 16.7869 11.2143C17.3345 10.9183 17.7999 10.4906 18.141 9.96984C18.4821 9.44904 18.6881 8.85153 18.7406 8.2312C18.793 7.61087 18.6902 6.98725 18.4414 6.41658L17.6393 4.58325H12.3633Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.0026 4.58327C5.00232 4.19667 4.89447 3.81777 4.69111 3.48897C4.48776 3.16017 4.19693 2.89443 3.85116 2.7215C3.50538 2.54857 3.11831 2.47527 2.73325 2.50979C2.34819 2.54431 1.98033 2.68529 1.67083 2.91696C1.36133 3.14864 1.1224 3.46186 0.980771 3.82159C0.839141 4.18131 0.800402 4.57336 0.868886 4.95385C0.93737 5.33434 1.11038 5.68827 1.36855 5.97603C1.62672 6.2638 1.95987 6.47406 2.33073 6.58327"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
