<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 2.625L2.625 9.375"
      stroke="darkgray"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.375 9.375L2.625 2.625"
      stroke="darkgray"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
