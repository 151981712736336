<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.78015 3.74994H2.4989C1.80619 3.74994 1.2489 4.30723 1.2489 4.99994V16.2499C1.2489 16.9426 1.80619 17.4999 2.4989 17.4999H17.4989C18.1916 17.4999 18.7489 16.9426 18.7489 16.2499V7.08327C18.7489 6.39056 18.1916 5.83327 17.4989 5.83327H2.91557"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M17.0822 5.83327V4.99994C17.0822 4.30723 16.5249 3.74994 15.8322 3.74994L12.8427 3.75515"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M18.7489 9.58337H13.3322C12.1812 9.58337 11.2489 10.5157 11.2489 11.6667C11.2489 12.8177 12.1812 13.75 13.3322 13.75H18.7489"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M13.7486 11.2499H12.9153V12.0833H13.7486V11.2499Z"
      fill="#0E0E22"
    />

    <path
      d="M14.0718 5.83327L11.5614 1.59369L4.1239 5.83327"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
