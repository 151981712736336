<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56092)">
      <path
        d="M0.415161 15.0001H17.0818V18.7501H0.415161V15.0001Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.91516 15.0001V11.2501H19.5818V15.0001H17.0818"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.3318 11.2501V7.50012H1.66516V11.2501H2.91516"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.64461 5.4845L8.26961 5.32304C8.10417 5.25375 7.95557 5.14967 7.83391 5.01786C7.71226 4.88605 7.6204 4.72961 7.56456 4.55915C7.50873 4.38869 7.49024 4.20821 7.51034 4.02997C7.53045 3.85173 7.58867 3.6799 7.68107 3.52616L8.16024 2.72408C8.25263 2.57034 8.31086 2.39851 8.33096 2.22028C8.35107 2.04204 8.33257 1.86156 8.27674 1.6911C8.22091 1.52064 8.12905 1.36419 8.00739 1.23238C7.88574 1.10058 7.73714 0.996493 7.5717 0.927205L7.18628 0.765747"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.9774 5.4845L11.6024 5.32304C11.4369 5.25375 11.2883 5.14967 11.1667 5.01786C11.045 4.88605 10.9532 4.72961 10.8973 4.55915C10.8415 4.38869 10.823 4.20821 10.8431 4.02997C10.8632 3.85173 10.9214 3.6799 11.0138 3.52616L11.493 2.72408C11.5854 2.57034 11.6436 2.39851 11.6637 2.22028C11.6838 2.04204 11.6653 1.86156 11.6095 1.6911C11.5537 1.52064 11.4618 1.36419 11.3402 1.23238C11.2185 1.10058 11.0699 0.996493 10.9045 0.927205L10.519 0.765747"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.23779 7.50012C8.23779 8.422 9.02425 9.16679 9.99821 9.16679C10.9722 9.16679 11.7586 8.422 11.7586 7.50012"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56092">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
