<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7507 0.833374C13.7507 3.34379 12.4903 4.58337 10.0007 4.58337C10.0007 2.10942 11.2507 0.833374 13.7507 0.833374Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M6.25073 2.08337C8.72469 2.08337 10.0007 3.349 10.0007 4.58337V7.08337"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.9645 18.6198C10.6773 18.4343 10.3427 18.3357 10.0009 18.3357C9.65909 18.3357 9.32452 18.4343 9.03737 18.6198C8.55877 18.912 8.02065 19.093 7.46273 19.1495C6.90481 19.2059 6.34134 19.1363 5.81392 18.9458C5.2865 18.7553 4.80858 18.4489 4.41546 18.049C4.02233 17.6491 3.72403 17.166 3.54258 16.6354L1.92279 11.9063C1.73575 11.3677 1.65097 10.7989 1.67279 10.2292C1.76133 7.97917 3.54258 6.08334 5.77695 5.85938C6.91494 5.73972 8.05634 6.05202 8.97487 6.73438C9.26864 6.9616 9.62953 7.08487 10.0009 7.08487C10.3723 7.08487 10.7332 6.9616 11.027 6.73438C11.9455 6.05202 13.0869 5.73972 14.2249 5.85938C16.4645 6.08334 18.2405 7.98438 18.329 10.2292C18.3509 10.7989 18.2661 11.3677 18.079 11.9063L16.4592 16.6354C16.2778 17.166 15.9795 17.6491 15.5864 18.049C15.1932 18.4489 14.7153 18.7553 14.1879 18.9458C13.6605 19.1363 13.097 19.2059 12.5391 19.1495C11.9812 19.093 11.4431 18.912 10.9645 18.6198V18.6198Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
