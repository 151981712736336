<template>
  <div id="nav" class="navbar">
    <span class="menuitem">
      {{ activeMenuItem }}
    </span>
    <div class="links">
      <!-- v-if="isNotEmployee() && clubcardEnabled" -->
      <action-button v-if="false" action variant1 @click="open">
        Пополнить депозит клиента
      </action-button>
      <dropdown-menu :items="menuItems">
        <template #icon>
          <username-badge :first-last="firstLast" />
        </template>
        <template #role>
          {{ role.label }}
        </template>
        <template #firstLast>
          {{ fl }}
        </template>
      </dropdown-menu>
    </div>
  </div>
</template>
<script setup>
import { AccessPermissionsEnum } from "@/router/constants";
import UsernameBadge from "./UsernameBadge.vue";
import DropdownMenu from "@/components/UI/dropdownMenu/DropdownMenu.vue";
import { ref, onMounted, onUnmounted, computed } from "vue";
import { getDoc, doc } from "@firebase/firestore";
import { db, getUserState } from "@/firebase";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useAuthService } from "@/modules/auth/auth.service";
import { useModal } from "vue-final-modal";
import TopUpAccount from "@/components/UI/modals/TopUpAccount.vue";
import i18n from "@/i18n";
// import { useToast } from "vue-toastification";
import { getStatusLoyalties } from "@/services/loyalty.service";

// const toast = useToast();
const route = useRoute();
const store = useStore();
const merchantId = ref();
const { checkHasPermission } = useAuthService();
const firstname = ref(" ");
const lastname = ref(" ");
const role = ref(" ");
const activeLoyalty = ref();
// const clubcardEnabled = computed(() => {
//   if (activeLoyalty?.value && activeLoyalty?.value.length !== 0) {
//      return activeLoyalty?.value[0] === 'clubcard'
//   }
// return false
// });
const firstLast = computed(() => {
  if (firstname?.value === "?") {
    return "?";
  }
  return firstname?.value[0]?.toUpperCase() + lastname?.value[0]?.toUpperCase();
});
const fl = computed(() => {
  if (firstname?.value === "?") {
    return "No data";
  }
  return `${firstname?.value} ${lastname?.value}`;
});
const items = ref([
  {
    title: "nav.account",
    name: "account",
    path: "account",
    icon: "user",
    accessPermissions: [AccessPermissionsEnum.CAN_READ_SUBSCRIPTION],
  },
  // {
  //   title: "nav.profile",
  //   name: "profile",
  //   path: "profile",
  //   icon: "profile-settings",
  // },
  // {
  //   title: "nav.bank-links",
  //   name: "bank-links",
  //   path: "bank-links",
  //   icon: "credit-card",
  //   accessPermissions: [AccessPermissionsEnum.CAN_READ_BANKS],
  // },
  {
    title: "main.signout",
    name: "login",
    path: "login",
    addClick: true,
    icon: "signout",
  },
]);
const menuItems = computed(() =>
  items.value.filter(
    (it) =>
      !Object.hasOwn(it, "accessPermissions") ||
      checkHasPermission(it.accessPermissions)
  )
);
const activeMenuItem = computed(() =>
  i18n.global.t(store.getters.get_menuitem || "_")
);
// const isPathEndsWith = (suffix) => {
//   return route.path.endsWith(suffix);
// };

// const isNotEmployee = () => {
//   return ["admin", "operator"].includes(role.value.value);
// };
const { open, close } = useModal({
  component: TopUpAccount,
  attrs: {
    onConfirm() {
      // console.log("val", val);
      // toast.success("Депозит пополнен");
      close();
    },
    onClose() {
      close();
    },
  },
  slots: {
    default: "<p>The content of the modal</p>",
  },
});

onMounted(async () => {
  try {
    const isAuth = await getUserState();

    if (isAuth !== null) {
      const docRef = doc(db, "users", isAuth.uid);
      const docSnap = await getDoc(docRef);
      const user = docSnap.data();
      merchantId.value = user.merchantId;
      if (firstname.value) {
        firstname.value = user.firstname || "?";
        lastname.value = user.lastname || "";
      } else {
        firstname.value = user.firstname || "?";
        lastname.value = user.lastname || "";
      }
      firstname.value = user.firstname || "?";
      lastname.value = user.lastname || "";
      role.value = user.role || "no data";
    }
    if (route.meta.menuItems)
      store.commit("SET_MENUITEM", route.meta.menuItems);
  } catch (error) {
    console.log(error);
  }
  await getStatusLoyalties(activeLoyalty);
});
onUnmounted(async () => {});
</script>

<style lang="scss" scoped>
.navbar {
  background: #ffffff !important;
  z-index: 10;
}

#nav {
  width: 100%;
  height: 64px;
  left: 0px;
  top: 0px;
  background: #f5f6f8;
  display: flex;
  justify-content: space-between;
  position: sticky;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  .logo {
    width: 260px;
    min-width: 260px;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .links {
    padding: 12px 28px 12px 0px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * + * {
      margin-left: 20px;
    }
  }
}
#nav .logo.mobile {
  width: 85px !important;
  min-width: 85px !important;
  &:hover {
    width: 260px;
    min-width: 260px;
  }
  transition: all 0.3s ease;
}
.menuitem {
  display: flex;
  align-items: center;
  padding: 12px 0px 12px 32px;
  color: #0e0e22;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  gap: 30px;
}
.temp-info {
  width: 100%;
  text-align: center;
  display: flex;
  gap: 30px;
}
</style>
