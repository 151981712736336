import moment from "moment";

export default {
  install: (app) => {
    app.config.globalProperties.$filters = {
      getDate(date, format) {
        return moment(date).format(format);
      },
      diffDate(d1, d2) {
        return Math.abs(moment(d2).diff(moment(d1), "d"));
      },
      capitalize(str) {
        return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str;
      },
    };
  },
};
