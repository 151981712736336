<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5979)">
      <path
        d="M5 5.4165H6.25C6.47101 5.4165 6.68297 5.5043 6.83926 5.66058C6.99554 5.81686 7.08333 6.02882 7.08333 6.24984V7.9165C7.08333 8.24802 7.21503 8.56597 7.44945 8.80039C7.68387 9.03481 8.00181 9.1665 8.33333 9.1665C8.66485 9.1665 8.9828 9.03481 9.21722 8.80039C9.45164 8.56597 9.58333 8.24802 9.58333 7.9165V6.24984C9.58333 6.02882 9.67113 5.81686 9.82741 5.66058C9.98369 5.5043 10.1957 5.4165 10.4167 5.4165H15C15.0002 7.89788 14.5739 10.3608 13.7396 12.6978L13.1354 14.3905C12.8547 15.1755 12.8456 16.0319 13.1094 16.8228L13.7135 18.6405C13.7489 18.7483 13.7582 18.8631 13.7407 18.9752C13.7232 19.0874 13.6794 19.1939 13.6128 19.2858C13.5463 19.3778 13.4589 19.4527 13.3578 19.5044C13.2567 19.5561 13.1448 19.5831 13.0312 19.5832H6.96875C6.85522 19.5831 6.74332 19.5561 6.64223 19.5044C6.54115 19.4527 6.45374 19.3778 6.38717 19.2858C6.32061 19.1939 6.27678 19.0874 6.25927 18.9752C6.24177 18.8631 6.25108 18.7483 6.28646 18.6405L6.89062 16.8228C7.15444 16.0319 7.14527 15.1755 6.86458 14.3905L6.26042 12.6978C5.42614 10.3608 4.99979 7.89788 5 5.4165V5.4165Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M9.30339 3.70296C9.22526 2.56755 8.10547 1.6665 6.73568 1.6665C5.36589 1.6665 4.16797 2.63525 4.16797 3.82796C4.16797 4.45817 4.49089 5.02067 5.0013 5.4165"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0026 5.4165C15.5182 5.06234 15.8359 4.56755 15.8359 4.02588C15.8359 2.95296 14.5964 2.08317 13.0703 2.08317H12.9193C12.9193 1.1613 11.8932 0.416504 10.6276 0.416504C9.36198 0.416504 8.33594 1.1613 8.33594 2.08317V2.13525"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5979">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
