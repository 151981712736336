<template>
  <svg
    width="100"
    height="30"
    viewBox="0 0 100 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.14877 15.5103C0.154224 16.0414 0.574047 16.4757 1.10475 16.499L42.6799 18.3286L72.6944 19.6494C74.8535 24.6925 79.8861 28.2021 85.7137 28.1423C93.4453 28.0629 99.6486 21.7309 99.5693 13.9993C99.4899 6.26771 93.1578 0.0643842 85.4263 0.143768C79.5573 0.204028 74.569 3.86715 72.5408 9.0104L42.6041 10.9444L1.07527 13.6272C0.545154 13.6615 0.134333 14.1042 0.139787 14.6354L0.14877 15.5103Z"
      fill="url(#paint0_linear_63_654)"
    />
    <ellipse
      cx="9.78419"
      cy="9.78397"
      rx="9.78419"
      ry="9.78397"
      transform="matrix(-0.0102863 -0.999947 0.999947 -0.0102477 75.8885 24.0286)"
      fill="#00CDD7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_63_654"
        x1="109.959"
        y1="8.14494"
        x2="102.957"
        y2="51.0011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0E0E22" />
        <stop
          offset="1"
          stop-color="#3C3C4C"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
