<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56300)">
      <path
        d="M0.832031 9.58313V9.9998C0.831957 11.7226 1.29631 13.4136 2.17621 14.8948C3.05611 16.3759 4.31902 17.5925 5.83203 18.4165V19.5831H14.1654V18.4165C15.6784 17.5925 16.9413 16.3759 17.8212 14.8948C18.7011 13.4136 19.1654 11.7226 19.1654 9.9998V9.58313H0.832031Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.4204 5.78101L19.3579 1.78101"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.353 5.05188L16.3374 0.45813"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.18632 9.58321C1.95474 8.45693 2.10238 7.28567 2.60622 6.25209C3.11005 5.21851 3.94176 4.38071 4.97163 3.86935C6.0015 3.35798 7.17165 3.20179 8.29959 3.42513C9.42753 3.64847 10.4499 4.2388 11.2071 5.10404"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M3.90453 9.58315C3.66822 8.81606 3.69948 7.99145 3.9932 7.24446C4.28691 6.49746 4.82566 5.8724 5.52115 5.47169C6.21663 5.07098 7.02761 4.9184 7.82117 5.03896C8.61472 5.15952 9.3438 5.54606 9.8889 6.13523"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.73301 9.58304C5.58863 9.36936 5.48988 9.12819 5.44294 8.87462C5.396 8.62104 5.40188 8.36051 5.4602 8.1093C5.51852 7.8581 5.62803 7.62163 5.78189 7.41467C5.93574 7.20771 6.13064 7.03471 6.35439 6.9065C6.57814 6.77828 6.82594 6.6976 7.08229 6.66949C7.33864 6.64139 7.59803 6.66647 7.84424 6.74316C8.09046 6.81986 8.31821 6.94652 8.51325 7.11523C8.70829 7.28394 8.86644 7.49107 8.9778 7.72367"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.5041 9.58311C14.5729 9.39427 14.5951 9.19162 14.5689 8.99235C14.5427 8.79308 14.4689 8.60306 14.3536 8.43841C14.2384 8.27375 14.0851 8.13931 13.9069 8.04649C13.7286 7.95368 13.5306 7.90521 13.3296 7.90521C13.1286 7.90521 12.9306 7.95368 12.7523 8.04649C12.574 8.13931 12.4208 8.27375 12.3055 8.43841C12.1903 8.60306 12.1164 8.79308 12.0902 8.99235C12.0641 9.19162 12.0863 9.39427 12.1551 9.58311"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M16.2174 9.58316C16.2813 9.16755 16.2546 8.74304 16.1391 8.33874C16.0235 7.93443 15.822 7.55989 15.5482 7.24077C15.2743 6.92165 14.9348 6.66552 14.5527 6.48991C14.1706 6.31431 13.7551 6.22339 13.3346 6.22339C12.9141 6.22339 12.4986 6.31431 12.1166 6.48991C11.7345 6.66552 11.3949 6.92165 11.1211 7.24077C10.8473 7.55989 10.6457 7.93443 10.5302 8.33874C10.4147 8.74304 10.3879 9.16755 10.4518 9.58316"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M17.8944 9.58313C17.9048 9.44771 17.9152 9.30709 17.9152 9.16646C17.9152 7.95089 17.4323 6.7851 16.5728 5.92556C15.7132 5.06602 14.5474 4.58313 13.3319 4.58313C12.1163 4.58313 10.9505 5.06602 10.091 5.92556C9.23142 6.7851 8.74854 7.95089 8.74854 9.16646C8.74854 9.30709 8.75895 9.44771 8.76937 9.58313"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56300">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
