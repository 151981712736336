// import { getAuth } from "firebase/auth";
// import { computed } from "vue";
// import store from "@/store";
// const role = computed(() => store.getters.role);

export async function defaultRedirectMiddleware(to, from, next) {
  // try {
  //   if (role.value === "back-office") {
  //     console.log('next({ name: "main", replace: true })', to);

  //     return next({ name: "back-office" });
  //     //
  //   } else next();
  // } catch (error) {
  //   console.log("Logger => \ndefaultRedirect.middleware => \n", error);
  // }
  next();
}
