<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_56084)">
      <path
        d="M1.76526 3.33352L2.9163 1.25018H11.2496L12.3955 3.33352"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M0.833252 3.3335H13.3333V5.41683H0.833252V3.3335Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.59888 5.41687L2.97909 17.9169H6.56242"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M12.2919 7.91687L12.5679 5.41687"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.99963 7.91687L9.58297 12.5002H14.1663L18.7496 7.91687"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.99963 7.91687L6.76005 19.1669H16.9892L18.7496 7.91687H4.99963Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_56084">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
