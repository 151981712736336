<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6 8.23333C12.2667 7.63333 12.6667 6.76667 12.6667 5.83333C12.6667 3.96667 11.2 2.5 9.33337 2.5H4.00004C3.60004 2.5 3.33337 2.76667 3.33337 3.16667V8.5V13.8333C3.33337 14.2333 3.60004 14.5 4.00004 14.5H10C11.8667 14.5 13.3334 13.0333 13.3334 11.1667C13.3334 9.9 12.6 8.83333 11.6 8.23333ZM4.66671 3.83333H9.33337C10.4667 3.83333 11.3334 4.7 11.3334 5.83333C11.3334 6.96667 10.4667 7.83333 9.33337 7.83333H4.66671V3.83333ZM4.66671 13.1667H10C11.1334 13.1667 12 12.3 12 11.1667C12 10.0333 11.1334 9.16667 10 9.16667H9.33337H4.66671V13.1667Z"
      fill="#595A5B"
    />
  </svg>
</template>
