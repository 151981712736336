<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2852_5906)">
      <path
        d="M10 16.6665V19.5832"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.83203 19.5833H14.1654"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.04688 7.9165H14.9531"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M7.72005 7.91659L6.05859 3.19263"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.3477 10.3852C15.4712 11.1589 15.4253 11.9502 15.2133 12.7046C15.0013 13.4589 14.6282 14.1582 14.1197 14.7543C13.6112 15.3504 12.9794 15.8291 12.2679 16.1574C11.5564 16.4856 10.7822 16.6556 9.9987 16.6556C9.21516 16.6556 8.44095 16.4856 7.72948 16.1574C7.01802 15.8291 6.38623 15.3504 5.87772 14.7543C5.36921 14.1582 4.99608 13.4589 4.78408 12.7046C4.57207 11.9502 4.52624 11.1589 4.64974 10.3852L5.51432 4.99976H14.4883L15.3477 10.3852Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M10.6836 7.91651L12.8294 0.713384C12.8553 0.627246 12.9083 0.551787 12.9806 0.498278C13.0529 0.444769 13.1405 0.416084 13.2305 0.416509H16.2513"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M8.875 2.28109L5.28125 0.807129L3.64062 3.9738L8.875 2.28109Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_5906">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
