<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 3.99984H10.4C8.15979 3.99984 7.03969 3.99984 6.18404 4.43581C5.43139 4.8193 4.81947 5.43123 4.43597 6.18388C4 7.03952 4 8.15963 4 10.3998V21.5998C4 23.84 4 24.9602 4.43597 25.8158C4.81947 26.5684 5.43139 27.1804 6.18404 27.5639C7.03969 27.9998 8.15979 27.9998 10.4 27.9998H22.6667C23.9066 27.9998 24.5266 27.9998 25.0353 27.8635C26.4156 27.4937 27.4938 26.4155 27.8637 25.0351C28 24.5264 28 23.9065 28 22.6665M25.3333 10.6665V2.6665M21.3333 6.6665H29.3333M14 11.3332C14 12.8059 12.8061 13.9998 11.3333 13.9998C9.86057 13.9998 8.66667 12.8059 8.66667 11.3332C8.66667 9.86041 9.86057 8.6665 11.3333 8.6665C12.8061 8.6665 14 9.86041 14 11.3332ZM19.9867 15.8907L8.7082 26.1439C8.07382 26.7206 7.75663 27.009 7.72857 27.2588C7.70425 27.4753 7.78727 27.69 7.95091 27.8339C8.1397 27.9998 8.56837 27.9998 9.42571 27.9998H21.9413C23.8602 27.9998 24.8196 27.9998 25.5732 27.6775C26.5193 27.2728 27.2729 26.5191 27.6776 25.5731C28 24.8195 28 23.86 28 21.9411C28 21.2955 28 20.9727 27.9294 20.672C27.8407 20.2942 27.6706 19.9403 27.431 19.635C27.2403 19.3921 26.9883 19.1904 26.4841 18.7871L22.7544 15.8034C22.2499 15.3997 21.9976 15.1979 21.7197 15.1267C21.4748 15.0639 21.2172 15.072 20.9767 15.1501C20.7039 15.2387 20.4648 15.456 19.9867 15.8907Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <!-- stroke="#BCBBBB" -->
</template>
