<template>
  <svg
    width="242"
    height="188"
    viewBox="0 0 242 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="121"
      cy="94"
      r="23"
      stroke="#00CDD7"
      stroke-width="4"
    />
    <circle
      cx="121"
      cy="94"
      r="38"
      stroke="#00CDD7"
      stroke-width="4"
    />
    <circle
      cx="121"
      cy="94"
      r="53"
      stroke="#00CDD7"
      stroke-width="4"
    />
    <circle
      cx="121"
      cy="94"
      r="68"
      stroke="#00CDD7"
      stroke-width="4"
    />
  </svg>
</template>
