<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.875 18.7495C15.2242 18.7495 18.75 15.2238 18.75 10.8745C18.75 6.52527 15.2242 2.99951 10.875 2.99951C6.52576 2.99951 3 6.52527 3 10.8745C3 15.2238 6.52576 18.7495 10.875 18.7495Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.4431 16.4434L20.9994 20.9997"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
