<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
d="M26.1049 27.0002H5.89497C5.64887 27.0002 5.41142 26.9094 5.22804 26.7453C5.04467 26.5812 4.92826 26.3552 4.90108 26.1106L3.1233 10.1106C3.10777 9.97084 3.1219 9.82935 3.16476 9.6954C3.20763 9.56146 3.27826 9.43806 3.37206 9.33326C3.46586 9.22847 3.5807 9.14464 3.7091 9.08724C3.83749 9.02985 3.97655 9.00018 4.11719 9.00018H27.8827C28.0233 9.00018 28.1624 9.02985 28.2907 9.08724C28.4191 9.14464 28.534 9.22847 28.6278 9.33326C28.7216 9.43806 28.7922 9.56146 28.8351 9.6954C28.8779 9.82935 28.8921 9.97084 28.8765 10.1106L27.0988 26.1106C27.0716 26.3552 26.9552 26.5812 26.7718 26.7453C26.5884 26.9094 26.351 27.0002 26.1049 27.0002Z" 
          stroke="#595A5B" 
          stroke-width="2" 
          stroke-linecap="round" 
          stroke-linejoin="round"/>
    <path
d="M11 13.0002V9.00018C11 7.6741 11.5268 6.40233 12.4645 5.46465C13.4021 4.52697 14.6739 4.00018 16 4.00018C17.3261 4.00018 18.5979 4.52697 19.5355 5.46465C20.4732 6.40233 21 7.6741 21 9.00018V13.0002" 
          stroke="#595A5B" 
          stroke-width="2" 
          stroke-linecap="round" 
          stroke-linejoin="round"/>
  </svg>
</template>