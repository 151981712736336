<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2515_43027)">
      <path
        d="M1.3501 3.33352L2.50114 1.25018H10.8345L11.9803 3.33352"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M0.417969 3.3335H12.918V5.41683H0.417969V3.3335Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.18359 5.41687L2.5638 17.9169H6.42318"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M11.8765 7.91687L12.1525 5.41687"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.20947 14.5835L6.67822 18.62C6.73684 18.7801 6.84318 18.9184 6.98289 19.0162C7.12259 19.114 7.28894 19.1666 7.45947 19.1668H16.2928C16.4633 19.1666 16.6297 19.114 16.7694 19.0162C16.9091 18.9184 17.0154 18.7801 17.0741 18.62L18.5428 14.5835"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M4.16797 13.5418C4.16797 13.2656 4.27772 13.0006 4.47307 12.8053C4.66842 12.6099 4.93337 12.5002 5.20964 12.5002H18.543C18.8192 12.5002 19.0842 12.6099 19.2795 12.8053C19.4749 13.0006 19.5846 13.2656 19.5846 13.5418C19.5846 13.8181 19.4749 14.0831 19.2795 14.2784C19.0842 14.4738 18.8192 14.5835 18.543 14.5835H5.20964C4.93337 14.5835 4.66842 14.4738 4.47307 14.2784C4.27772 14.0831 4.16797 13.8181 4.16797 13.5418Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.20947 12.5002L6.67822 8.46375C6.73684 8.3036 6.84318 8.1653 6.98289 8.06751C7.12259 7.96972 7.28894 7.91714 7.45947 7.91687H16.2928C16.4633 7.91714 16.6297 7.96972 16.7694 8.06751C16.9091 8.1653 17.0154 8.3036 17.0741 8.46375L18.5428 12.5002"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2515_43027">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
