<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0005 15.4169V14.1669"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0005 7.0835V8.3335"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.5005 9.99998V9.58331C12.5005 8.8906 11.9432 8.33331 11.2505 8.33331H8.75049C8.05778 8.33331 7.50049 8.8906 7.50049 9.58331V9.99998C7.50049 10.6927 8.05778 11.25 8.75049 11.25H11.2505C11.9432 11.25 12.5005 11.8073 12.5005 12.5V12.9166C12.5005 13.6094 11.9432 14.1666 11.2505 14.1666H8.75049C8.05778 14.1666 7.50049 13.6094 7.50049 12.9166V12.5"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0007 19.1668C14.3729 19.1668 17.9173 15.6224 17.9173 11.2502C17.9173 6.87791 14.3729 3.3335 10.0007 3.3335C5.6284 3.3335 2.08398 6.87791 2.08398 11.2502C2.08398 15.6224 5.6284 19.1668 10.0007 19.1668Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0005 3.33364V1.25031"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.50049 1.25031H12.5005"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.5996 5.65116L17.3288 3.922"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
