<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16797 12.9167V18.7501"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5 18.75H13.3333"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M5.22526 9.88542C5.79297 10.0156 6.22526 10.4479 6.42318 10.9948C6.6263 11.56 6.99894 12.0487 7.4902 12.3941C7.98146 12.7396 8.56739 12.925 9.16797 12.925C9.76854 12.925 10.3545 12.7396 10.8457 12.3941C11.337 12.0487 11.7096 11.56 11.9128 10.9948C12.1107 10.4479 12.543 10.0156 13.1107 9.88542C14.1219 9.65315 15.0244 9.08466 15.6705 8.27292C16.3167 7.46117 16.6684 6.4542 16.668 5.41667V3.75H1.66797V5.41667C1.66758 6.4542 2.01922 7.46117 2.6654 8.27292C3.31158 9.08466 4.21406 9.65315 5.22526 9.88542Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M16.5898 6.25H16.668C17.1624 6.25 17.6458 6.10338 18.0569 5.82867C18.468 5.55397 18.7884 5.16352 18.9777 4.70671C19.1669 4.24989 19.2164 3.74723 19.1199 3.26228C19.0235 2.77732 18.7854 2.33187 18.4357 1.98223C18.0861 1.6326 17.6406 1.3945 17.1557 1.29804C16.6707 1.20157 16.1681 1.25108 15.7113 1.4403C15.2544 1.62952 14.864 1.94995 14.5893 2.36108C14.3146 2.7722 14.168 3.25555 14.168 3.75"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M1.66797 5.41675H16.668"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
