<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 16.5001H16.5183M27.5 27.5001H27.5183M29.3333 14.6667L14.6667 29.3334M40.3333 22.0001C40.3333 32.1253 32.1252 40.3334 22 40.3334C11.8748 40.3334 3.66666 32.1253 3.66666 22.0001C3.66666 11.8749 11.8748 3.66675 22 3.66675C32.1252 3.66675 40.3333 11.8749 40.3333 22.0001ZM17.4167 16.5001C17.4167 17.0063 17.0063 17.4167 16.5 17.4167C15.9937 17.4167 15.5833 17.0063 15.5833 16.5001C15.5833 15.9938 15.9937 15.5834 16.5 15.5834C17.0063 15.5834 17.4167 15.9938 17.4167 16.5001ZM28.4167 27.5001C28.4167 28.0063 28.0063 28.4167 27.5 28.4167C26.9937 28.4167 26.5833 28.0063 26.5833 27.5001C26.5833 26.9938 26.9937 26.5834 27.5 26.5834C28.0063 26.5834 28.4167 26.9938 28.4167 27.5001Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
