<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9997 12.136V17.4694M15.9997 22.8027H16.013M14.1535 5.32501L3.18694 24.2672C2.57866 25.3178 2.27453 25.8432 2.31948 26.2743C2.35868 26.6504 2.55571 26.9921 2.86152 27.2145C3.21213 27.4694 3.81915 27.4694 5.03318 27.4694H26.9662C28.1802 27.4694 28.7873 27.4694 29.1379 27.2145C29.4437 26.9921 29.6407 26.6504 29.6799 26.2743C29.7249 25.8432 29.4207 25.3178 28.8124 24.2672L17.8459 5.32501C17.2398 4.27813 16.9368 3.75468 16.5414 3.57888C16.1966 3.42553 15.8028 3.42553 15.458 3.57888C15.0626 3.75468 14.7595 4.27813 14.1535 5.32501Z"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
