<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="url(#paint0_linear_2681_22262)"
    />
    <path
      d="M3.59579 7.82349C5.92222 6.78859 7.47355 6.10632 8.24975 5.77669C10.466 4.83551 10.9265 4.67201 11.2267 4.66656C11.2927 4.66543 11.4403 4.68213 11.5359 4.76135C11.6166 4.82823 11.6388 4.91859 11.6495 4.98201C11.6601 5.04542 11.6733 5.18989 11.6628 5.30277C11.5427 6.59118 11.0231 9.7178 10.7587 11.1608C10.6468 11.7714 10.4265 11.9762 10.2133 11.9962C9.74986 12.0397 9.39795 11.6835 8.94909 11.3831C8.24671 10.913 7.84992 10.6204 7.16814 10.1616C6.38023 9.63149 6.891 9.34012 7.34003 8.86394C7.45754 8.73932 9.49944 6.84302 9.53896 6.67099C9.54391 6.64948 9.54849 6.56928 9.50183 6.52694C9.45517 6.48459 9.3863 6.49907 9.3366 6.51059C9.26615 6.52691 8.1441 7.28414 5.97044 8.78226C5.65195 9.00556 5.36347 9.11435 5.105 9.10865C4.82006 9.10237 4.27195 8.94416 3.86449 8.80892C3.36472 8.64305 2.96751 8.55535 3.0021 8.27366C3.02012 8.12693 3.21801 7.97687 3.59579 7.82349Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2681_22262"
        x1="8"
        y1="0"
        x2="8"
        y2="15.8813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2AABEE" />
        <stop
          offset="1"
          stop-color="#229ED9"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
