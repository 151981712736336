<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25024 9.99988C1.25024 9.66836 1.38194 9.35041 1.61636 9.11599C1.85078 8.88157 2.16872 8.74988 2.50024 8.74988H17.5002C17.8318 8.74988 18.1497 8.88157 18.3841 9.11599C18.6185 9.35041 18.7502 9.66836 18.7502 9.99988C18.7502 10.3314 18.6185 10.6493 18.3841 10.8838C18.1497 11.1182 17.8318 11.2499 17.5002 11.2499H2.50024C2.16872 11.2499 1.85078 11.1182 1.61636 10.8838C1.38194 10.6493 1.25024 10.3314 1.25024 9.99988Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.28149 8.77069L4.58879 2.62486C4.64826 2.46569 4.75496 2.32851 4.89459 2.23169C5.03423 2.13488 5.20012 2.08306 5.37004 2.08319H14.6305C14.8004 2.08306 14.9663 2.13488 15.1059 2.23169C15.2455 2.32851 15.3522 2.46569 15.4117 2.62486L17.7138 8.77069"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.28687 11.229L4.58895 17.3748C4.64842 17.534 4.75512 17.6712 4.89476 17.768C5.03439 17.8648 5.20028 17.9166 5.3702 17.9165H14.6306C14.8005 17.9166 14.9664 17.8648 15.1061 17.768C15.2457 17.6712 15.3524 17.534 15.4119 17.3748L17.7192 11.229"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
