<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58681)">
      <path
        d="M15.0015 19.583C17.0725 19.583 18.7515 17.9041 18.7515 15.833C18.7515 13.7619 17.0725 12.083 15.0015 12.083C12.9304 12.083 11.2515 13.7619 11.2515 15.833C11.2515 17.9041 12.9304 19.583 15.0015 19.583Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M15.0015 13.7496V15.8329L16.491 17.3225"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.00146 19.5829V13.7496"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.91813 12.9165H2.50146C2.16994 12.9165 1.852 12.7848 1.61758 12.5504C1.38316 12.3159 1.25146 11.998 1.25146 11.6665V8.33315C1.25146 7.78062 1.47096 7.25071 1.86166 6.86001C2.25236 6.46931 2.78226 6.24982 3.3348 6.24982H6.0848C6.34959 6.24998 6.61134 6.30622 6.85283 6.41484C7.09431 6.52347 7.31005 6.68201 7.48584 6.88003L10.5692 10.3488C10.7362 10.5569 10.8169 10.8211 10.7948 11.087C10.7727 11.3529 10.6495 11.6002 10.4504 11.7778C10.2514 11.9555 9.99173 12.05 9.72505 12.0419C9.45836 12.0338 9.20496 11.9236 9.01709 11.7342L7.0848 9.58315V14.1665"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.91797 8.74957V19.5829H7.08464V13.7496"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M5.0013 4.58287C6.1519 4.58287 7.08464 3.65013 7.08464 2.49953C7.08464 1.34894 6.1519 0.416199 5.0013 0.416199C3.85071 0.416199 2.91797 1.34894 2.91797 2.49953C2.91797 3.65013 3.85071 4.58287 5.0013 4.58287Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58681">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
