<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.80469 10.4165H15.6901"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />

    <path
      d="M9.16536 11.6665H8.33203V12.4998H9.16536V11.6665Z"
      fill="#0E0E22"
    />

    <path
      d="M11.6654 14.1665H10.832V14.9998H11.6654V14.1665Z"
      fill="#0E0E22"
    />

    <path
      d="M5.83333 13.3333H5V14.1666H5.83333V13.3333Z"
      fill="#0E0E22"
    />

    <path
      d="M16.1185 7.49976H17.082C17.6346 7.49976 18.1645 7.71925 18.5552 8.10995C18.9459 8.50065 19.1654 9.03056 19.1654 9.58309C19.1654 10.1356 18.9459 10.6655 18.5552 11.0562C18.1645 11.4469 17.6346 11.6664 17.082 11.6664H15.3945"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0833 16.2499C14.3854 16.2499 16.25 11.026 16.25 4.58325H1.25C1.25 11.026 3.11458 16.2499 5.41667 16.2499H12.0833Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
