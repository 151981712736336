import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "catalog",
    name: "catalog",
    component: load("catalog/View"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_CATALOG_POINTS],
      icon: "catalog",
      menuItems: "leftSideMenu.catalog",
    },
    redirect: {
      name: "catalog:settings",
    },
    children: [
      /*    {
        path: "points",
        name: "catalog:points",
        component: load("points/Points"),
        meta: {
          requiresAuth: true,
          icon: "phone",
          menuItems: "leftSideMenu.catalog",
        },
      }, */
      {
        path: "settings",
        name: "catalog:settings",
        component: load("catalog/Categories"),
        meta: {
          requiresAuth: true,
          icon: "gear",
          menuItems: "leftSideMenu.catalog",
        },
      },
      {
        path: "qrcode",
        name: "catalog:qrcode",
        component: load("catalog/QrCatalog"),
        meta: {
          requiresAuth: true,
          icon: "qrcode",
          menuItems: "leftSideMenu.catalog",
        },
      },
    ],
  },
  {
    path: "products/create/:pointId/:categoryId",
    name: "new-product",

    component: load("catalog/EditProduct"),
    meta: {
      requiresAuth: true,
      icon: "catalog",
      menuItems: "leftSideMenu.catalog",
    },
    children: [],
  },
  {
    path: "products/edit/:pointId/:categoryId/:productId",
    name: "edit-product",
    component: load("catalog/EditProduct"),
    props: true,
    meta: {
      requiresAuth: true,
      icon: "catalog",
      type: "edit",
      menuItems: "leftSideMenu.catalog",
    },
    children: [],
  },
];
