import { reactive, computed } from "vue";
import { db } from "@/firebase";

import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  onSnapshot,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  where,
} from "firebase/firestore";
import store from "@/store";

const merchant = computed(() => store.getters.merchant);

export const deleteDocInCollection = async (id, collectionName) => {
  try {
    const docRef = doc(db, `${collectionName}/${id}`);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("deleteDocInCollection ==> ", error.message);
  }
};
export const fetchCollectionsSubscription = async (
  target /* , subscriptions */
) => {
  try {
    const collectionRef = collection(db, "merchants");
    onSnapshot(
      collectionRef,
      async (snapshot) => {
        target.value = [];
        snapshot.docs.map((doc) => {
          target.value.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      },
      (error) => {
        console.error(error);
      }
    );

    // const collectionSnapshot = await getDocs(collectionRef);
    // target.value = collectionSnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching collection:", error);
  }
};
export const updateData = (collectionName, target) => {
  const q = query(collection(db, collectionName));
  onSnapshot(
    q,
    async (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      for (const key in data[0]) {
        target[key] = data[0][key];
      }
      //       console.log("target", target);
    },
    (error) => {
      console.error(error);
    }
  );
};
export const getCollectionData = (collectionName, target) => {
  const q = query(collection(db, collectionName));
  onSnapshot(
    q,
    async (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      for (const key in data) {
        target[key] = data[key];
      }
    },
    (error) => {
      console.error(error);
    }
  );
};

export const updateDataRef = (collectionName, target) => {
  const { merchantId } = merchant.value;
  const q = query(
    collection(db, collectionName),
    where("merchantId", "==", merchantId)
  );
  console.log("merchantId:updateDataRef", merchantId);

  onSnapshot(
    q,
    async (snapshot) => {
      target.value = [];
      snapshot.docs.map((doc) => {
        target.value.push({
          ...doc.data(),
          id: doc.id,
        });
      });
    },
    (error) => {
      console.error(error);
    }
  );
};
class Employee {
  constructor(data) {
    this.id = "";
    this.name = "";
    this.email = "";
    this.role = "";
    this.phones = [];
    this.address = "";
    this.merchantId = "";
  }
}
export const updateDataRefFn = (collectionName, userRole, callback) => {
  const { merchantId } = merchant.value;
  const q = query(
    collection(db, collectionName),
    where("merchantId", "==", merchantId)
  );

  onSnapshot(
    q,
    async (snapshot) => {
      const target = [];
      snapshot.docs.map((doc) => {
        const docdata = doc.data();
        if (userRole?.value === "admin" && docdata?.role?.value !== "admin") {
          target.push({
            ...docdata,
            id: doc.id,
            phoneNumber:
              docdata?.phones?.map((phone) => phone.number).join(";") || "",
            searchRole: docdata?.role?.label,
            fullname: [
              docdata?.firstname,
              docdata?.patrpatronymic || "",
              docdata?.lastname,
            ]
              .join(" ")
              .replace(/\s+/, " "),
          });
        }
        if (
          userRole?.value === "operator" &&
          docdata?.role?.value === "employee"
        ) {
          target.push({
            ...docdata,
            id: doc.id,
          });
        }
      });

      callback(target);
    },
    (error) => {
      console.error(error);
    }
  );
  // onSnapshot(
  //   q,
  //   async (snapshot) => {
  //     target.value = [];
  //     snapshot.docs.map((doc) => {
  //       const docdata = doc.data();
  //       // console.log("role ==>>", docdata?.role?.value, userRole?.value);
  //       if (userRole?.value === "admin" && docdata?.role?.value !== "admin") {
  //         target.value.push({
  //           ...docdata,
  //           id: doc.id,
  //           phoneNumber:
  //             docdata?.phones?.map((phone) => phone.number).join(";") || "",
  //           searchRole: docdata?.role?.label,
  //         });
  //       }
  //       if (
  //         userRole?.value === "operator" &&
  //         docdata?.role?.value === "employee"
  //       ) {
  //         target.value.push({
  //           ...docdata,
  //           id: doc.id,
  //         });
  //       }
  //     });
  //   },
  //   (error) => {
  //     console.error(error);
  //   }
  // );
};
/* ========================= */

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const updateArrayField = async (fieldName, value, operation) => {
  //   console.log("updateArrayField:merchantId", merchant);

  const docRef = doc(collection(db, "merchants"), merchant);
  if (operation === "add") {
    value.id = uuidv4();
    //     console.log("updateArrayField:value", value);
    await updateDoc(docRef, { [fieldName]: arrayUnion(value) });
  } else if (operation === "remove") {
    await updateDoc(docRef, { [fieldName]: arrayRemove(value) });
  }
  return;
};

/* Get collection */
export async function getCollection(strCollection, uid) {
  const userRef = doc(db, strCollection, uid);
  // console.log("strCollection, uid", strCollection, uid);

  try {
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      //       console.log("Такого документа нет!");
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}

/* Get collection by Name */
export async function getCollectionByName(strCollection) {
  const userRef = collection(db, strCollection);
  try {
    const userDoc = await getDocs(userRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      //       console.log("Такого документа нет!");
      return null;
    }
  } catch (error) {
    console.log(error);
  }
}

/* Get collection snap */
export async function getCollectionSnap(strCollection, uid) {
  const userRef = doc(db, strCollection, uid);
  const item = reactive({});
  onSnapshot(
    userRef,
    async (snapshot) => {
      const data = snapshot.docs.map((el) => ({
        ...el.data(),
        id: el.id,
      }));
      for (const key in data[0]) {
        item[key] = data[0][key];
      }
    },
    (error) => {
      console.error(error);
    }
  );

  return item;
}
export async function setUserDisabled(uid) {
  const userRef = doc(db, "users", uid);
  await updateDoc(userRef, {
    disabled: true,
  });
}
