<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2512_58595)">
      <path
        d="M7.60889 8.7605L12.3901 4.573"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M2.58789 5.84412L5.74414 8.73995"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3333 5.00012C14.0236 5.00012 14.5833 4.44048 14.5833 3.75012C14.5833 3.05977 14.0236 2.50012 13.3333 2.50012C12.6429 2.50012 12.0833 3.05977 12.0833 3.75012C12.0833 4.44048 12.6429 5.00012 13.3333 5.00012Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M1.66675 6.25012C2.3571 6.25012 2.91675 5.69048 2.91675 5.00012C2.91675 4.30977 2.3571 3.75012 1.66675 3.75012C0.976392 3.75012 0.416748 4.30977 0.416748 5.00012C0.416748 5.69048 0.976392 6.25012 1.66675 6.25012Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.66675 10.8334C7.3571 10.8334 7.91675 10.2737 7.91675 9.58337C7.91675 8.89302 7.3571 8.33337 6.66675 8.33337C5.97639 8.33337 5.41675 8.89302 5.41675 9.58337C5.41675 10.2737 5.97639 10.8334 6.66675 10.8334Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M14.3333 4.50037L17.3333 6.75037"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.3333 8.75037C19.0236 8.75037 19.5833 8.19072 19.5833 7.50037C19.5833 6.81001 19.0236 6.25037 18.3333 6.25037C17.6429 6.25037 17.0833 6.81001 17.0833 7.50037C17.0833 8.19072 17.6429 8.75037 18.3333 8.75037Z"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M18.3327 10.8334V17.5H1.66602V8.33337"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M6.66602 17.5001V12.9167"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
      <path
        d="M13.3333 17.5002V7.08356"
        stroke="#0E0E22"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2512_58595">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
