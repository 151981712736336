<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4995 18.7504H2.49951C1.8068 18.7504 1.24951 18.1931 1.24951 17.5004V15.0004C1.24951 14.3077 1.8068 13.7504 2.49951 13.7504H17.4995C18.1922 13.7504 18.7495 14.3077 18.7495 15.0004V17.5004C18.7495 18.1931 18.1922 18.7504 17.4995 18.7504Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.58301 3.33356H16.2497C16.9424 3.33356 17.4997 3.89085 17.4997 4.58356V13.7502"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M2.49951 13.7502V4.58356C2.49951 3.89085 3.0568 3.33356 3.74951 3.33356H5.41618"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.8324 16.2501H4.16577"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M15.4162 5.41693H12.4995V7.50027H15.4162V5.41693Z"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.16577 9.58356H5.83244"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.49951 9.58356H9.16618"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M4.16577 11.6667H5.83244"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M7.49951 11.6667H9.16618"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.833 9.58356H12.4997"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.1658 9.58356H15.8324"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.833 11.6667H12.4997"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M14.1658 11.6667H15.8324"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M10.8324 7.50012H4.16577"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
    <path
      d="M9.58244 7.50026V1.66693H5.41577V7.50026"
      stroke="#0E0E22"
      stroke-miterlimit="10"
    />
  </svg>
</template>
