// function load(component) {
//   return () => import(`@/modules/${component}.vue`);
// }

export default [
  {
    path: "access-error",
    name: "access-error",
    component: () => import("@/modules/auth/pages/AccessErrorPage.vue"),
    // component: load("auth/pages/AccessErrorPage"),
    meta: {
      requiresAuth: true,
      icon: "error",
    },
    children: [],
  },
];
