import { getUserState } from "@/firebase";
import { computed } from "vue";

import store from "@/store";

const merchant = computed(() => store.getters.merchant);

export async function checkAuthMiddleware(to, from, next) {
  /* Auth */
  const requiresAuth = to.matched.some((r) => r.meta.requiresAuth);
  const requiresUnauth = to.matched.some((r) => r.meta.requiresUnauth);

  const isAuth = await getUserState();
  if (requiresAuth && !isAuth) {
    const locale = localStorage.getItem("locale") || "ru";

    next({
      name: "login",
      params: { locale },
      replace: true,
    });

    return;
  } else if (requiresAuth && !merchant.value) {
    await store.dispatch("get_merchant");

    return next();
  } else if (requiresUnauth && isAuth) {
    next();

    return;
  }

  store.commit("SET_MENUITEM", to.meta.menuItems);

  next();
}
